"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function copyToClipboard(text) {
    return {
        effectType: "copy-to-clipboard",
        text: text,
    };
}
exports.copyToClipboard = copyToClipboard;
function withCopyToClipboard() {
    return function (effect) {
        switch (effect.effectType) {
            case "copy-to-clipboard":
                var textArea = document.createElement("textarea");
                textArea.value = effect.text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('Copy');
                document.body.removeChild(textArea);
        }
    };
}
exports.withCopyToClipboard = withCopyToClipboard;
