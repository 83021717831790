"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function loadHeadwayApp() {
    return {
        effectType: "load-headway-app",
    };
}
exports.loadHeadwayApp = loadHeadwayApp;
function withHeadwayApp(dispatch, account) {
    return function (effect) {
        switch (effect.effectType) {
            case "load-headway-app":
                var config_1 = {
                    selector: ".changelog",
                    account: account,
                };
                var Headway = window.Headway;
                if (Headway) {
                    Headway.init(config_1);
                }
                else {
                    var getHeadwayScript_1 = function () {
                        var headwayJS = document.querySelector('#headway-js');
                        if (headwayJS) {
                            headwayJS.addEventListener('load', function () {
                                window.Headway.init(config_1);
                            });
                        }
                        else {
                            window.setTimeout(getHeadwayScript_1, 300);
                        }
                    };
                }
                break;
        }
    };
}
exports.withHeadwayApp = withHeadwayApp;
