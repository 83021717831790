"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var flash_reducer_1 = require("../../reducers/subreducers/flash-reducer");
var initial_loading_reducer_1 = require("./initial-loading-reducer");
var sequence_services_1 = require("../../core/services/sequence-services");
var time_services_1 = require("../../core/services/time-services");
var requestErrorDuration = 2000;
var connectionRestoredDuration = 2000;
function reduceConnectionStatus(state, action) {
    var effects = [];
    switch (action.type) {
        case "complete-request":
            if (action.success)
                break;
        case "upload-failure":
            state.flashes = tslib_1.__assign({}, state.flashes);
            state.flashes.connectionWarning = null;
            state.flashes.requestError = flash_reducer_1.createFlash("danger", action.when + requestErrorDuration);
            effects = sequence_services_1.sequence(effects, time_services_1.requestTick(requestErrorDuration));
            break;
        case "went-offline":
            state = tslib_1.__assign({}, state);
            state.connectionErrors = tslib_1.__assign({}, state.connectionErrors);
            state.connectionErrors[exports.isOnlineErrorName] = "warning";
            state.flashes = tslib_1.__assign({}, state.flashes);
            state.flashes.connectionWarning = flash_reducer_1.createFlash("warning");
            break;
        case "action-cable-disconnected":
        case "action-cable-rejected":
            state = tslib_1.__assign({}, state);
            state.connectionErrors = tslib_1.__assign({}, state.connectionErrors);
            state.connectionErrors[exports.cableConnectedErrorName] = "warning";
            state.flashes = tslib_1.__assign({}, state.flashes);
            state.flashes.connectionWarning = flash_reducer_1.createFlash("warning");
            break;
        case "went-online":
            var hadUrgentConnectionError = false;
            if (state.connectionErrors[exports.isOnlineErrorName] && Object.keys(state.connectionErrors).length > 0) {
                hadUrgentConnectionError = true;
                state = tslib_1.__assign({}, state);
                state.connectionErrors = tslib_1.__assign({}, state.connectionErrors);
                delete state.connectionErrors[exports.isOnlineErrorName];
            }
        case "action-cable-connected":
            if (state.connectionErrors[exports.cableConnectedErrorName] && Object.keys(state.connectionErrors).length > 0) {
                hadUrgentConnectionError = true;
                state = tslib_1.__assign({}, state);
                state.connectionErrors = tslib_1.__assign({}, state.connectionErrors);
                delete state.connectionErrors[exports.cableConnectedErrorName];
            }
            if (hadUrgentConnectionError && Object.keys(state.connectionErrors).filter(function (k) { return state.connectionErrors[k]; }).length === 0) {
                state = tslib_1.__assign({}, state);
                state.flashes = tslib_1.__assign({}, state.flashes);
                state.flashes.connectionWarning = null;
                state.flashes.connectionRestored = flash_reducer_1.createFlash("success", action.when + connectionRestoredDuration);
                effects = sequence_services_1.sequence(effects, initial_loading_reducer_1.loadCustomerRooms(state.loggedInUser.id));
                effects = sequence_services_1.sequence(effects, time_services_1.requestTick(connectionRestoredDuration));
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceConnectionStatus = reduceConnectionStatus;
exports.isOnlineErrorName = "is-online-error";
exports.cableConnectedErrorName = "cable-connected-error";
