"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var classesForBalloon = class_generator_1.classNamesGeneratorFor(function (add) {
    add("dark", React.createElement("div", { className: "bg-dark-gray white" }));
    add("light", React.createElement("div", { className: "bg-white dark-gray" }));
    add("bellow", React.createElement("div", { className: "top100" }));
    add("above", React.createElement("div", { className: "bottom0" }));
    add("over", React.createElement("div", { className: "top--0_5" }));
    add("rightOf", React.createElement("div", { className: "left100" }));
    add("shadowUp", React.createElement("div", { className: "shadow-elevate-up-dark br2" }), React.createElement("div", { className: "shadow-1" }));
    add("hoverOnly", React.createElement("div", { className: "z-1" }), React.createElement("div", { className: "z-4" }));
}, React.createElement("div", { className: "absolute" }));
var classesForTriangle = class_generator_1.classNamesGeneratorFor(function (add) {
    add("dark", React.createElement("div", { className: "b--dark-gray" }));
    add("light", React.createElement("div", { className: "b--white" }));
    add("gray", React.createElement("div", { className: "b--light-gray" }));
    add("pointUp", React.createElement("div", { className: "bt--transparent bl--transparent br--transparent" }));
    add("pointDown", React.createElement("div", { className: "bb--transparent bl--transparent br--transparent" }));
    add("pointLeft", React.createElement("div", { className: "bt--transparent bl--transparent bb--transparent" }));
    add("small", React.createElement("div", { className: "bw3" }));
    add("big", React.createElement("div", { className: "bw4" }));
}, React.createElement("div", { className: "w-0 h-0 bw3 ba" }));
function BalloonTriangle(props) {
    return React.createElement("div", { className: classesForTriangle(props) });
}
exports.BalloonTriangle = BalloonTriangle;
function Balloon(props) {
    var centerSelfOffset = 0;
    var centerInContainerOffset = 0;
    var boxStyles = {};
    if (props.centerWithinContainer) {
        centerInContainerOffset = props.centerWithinContainer[0] / 2;
        boxStyles.left = centerInContainerOffset;
    }
    if (props.rightWithinContainer) {
        boxStyles.right = '-2px';
    }
    if (props.centerSelfAs) {
        centerSelfOffset = props.centerSelfAs[0] / 2;
        boxStyles.marginLeft = centerSelfOffset * -1;
        boxStyles.width = props.centerSelfAs[0];
        if (props.alignLeft) {
            boxStyles.marginLeft *= 1.5;
        }
    }
    if (props.rightSelfAs) {
        boxStyles.marginRight = 0;
        boxStyles.width = props.rightSelfAs[0];
    }
    return React.createElement("div", { style: boxStyles, className: classesForBalloon(props) }, props.children);
}
exports.Balloon = Balloon;
