"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var sequence_services_1 = require("../../core/services/sequence-services");
var input_debouncing_services_1 = require("../../core/services/input-debouncing-services");
function inputChange(target, text) {
    return {
        type: "input-change",
        target: target,
        text: text
    };
}
exports.inputChange = inputChange;
function inputChangeDispatcher(dispatch, target, value) {
    return function (e) {
        e.stopPropagation();
        dispatch(inputChange(target, value === undefined ? e.target.value : value));
    };
}
exports.inputChangeDispatcher = inputChangeDispatcher;
function reduceInputs(state, a) {
    var effects = [];
    switch (a.type) {
        case 'input-change':
            effects = sequence_services_1.sequence(effects, input_debouncing_services_1.clearInputDebouncing());
            if (state[a.target] === a.text)
                break;
            state = tslib_1.__assign({}, state);
            state[a.target] = a.text;
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceInputs = reduceInputs;
