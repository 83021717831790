"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var sequence_services_1 = require("../core/services/sequence-services");
var json_resources_1 = require("../core/json-resources");
var routes_1 = require("../routes");
exports.usageLoadingRequestName = "request-ajax-usage-data";
function reduceUsagePage(state, action) {
    var effects = [];
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.usageLoadingRequestName) {
                if (action.success) {
                    state = tslib_1.__assign({}, state);
                    state.usagePage = tslib_1.__assign({}, state.usagePage);
                    var res = JSON.parse(action.response);
                    if (res.data) {
                        state.usagePage = res.data;
                    }
                }
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceUsagePage = reduceUsagePage;
function beginUsageLoad(state) {
    var effects = [];
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.usageLoadingRequestName], routes_1.RapiV1TenantsPath + '/usage'));
    return { state: state, effects: effects };
}
exports.beginUsageLoad = beginUsageLoad;
