"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var operator_state_1 = require("./operator-state");
var reducers_1 = require("../core/reducers");
var json_resources_1 = require("../core/json-resources");
var index_1 = require("../stores/index");
var removeResource = index_1.Stores.removeResource;
var toggles_reducer_1 = require("./subreducers/toggles-reducer");
var initialState = operator_state_1.OperatorState.initialState;
var toast_reducer_1 = require("./toast-reducer");
var I18n_1 = require("../I18n");
var sequence_services_1 = require("../core/services/sequence-services");
function toggleResource(toggleAction) {
    return {
        type: "toggle-resource",
        toggleAction: toggleAction
    };
}
exports.toggleResource = toggleResource;
function closeFullScreenResourceModal(resourceType) {
    return {
        type: "close-fullscreen-resource-modal",
        resourceType: resourceType
    };
}
exports.closeFullScreenResourceModal = closeFullScreenResourceModal;
function beginEditResource(resourceType, ids) {
    return {
        type: "begin-edit-resource",
        resourceType: resourceType,
        ids: ids
    };
}
exports.beginEditResource = beginEditResource;
function beginCreateResource(resourceType) {
    return {
        type: "begin-create-resource",
        resourceType: resourceType
    };
}
exports.beginCreateResource = beginCreateResource;
function deleteToggledResources(resourceType, ids) {
    return {
        type: "delete-toggled-resources",
        resourceType: resourceType,
        ids: ids
    };
}
exports.deleteToggledResources = deleteToggledResources;
var stateSubreducer = reducers_1.subReducersFor();
var commonSubreducer = reducers_1.subReducersFor();
var reduceResourceToggle = stateSubreducer("resourcePageCommonState", commonSubreducer("toggles", toggles_reducer_1.reduceToggle));
function reduceFinishEditingResource(state) {
    state = tslib_1.__assign({}, state);
    var connectionPage = state.connectionPage = tslib_1.__assign({}, state.connectionPage);
    connectionPage.externalServiceSettings = {};
    var commonState = state.resourcePageCommonState = tslib_1.__assign({}, state.resourcePageCommonState);
    commonState.currentEditingIds = [];
    commonState.showResourceModal = false;
    commonState.toggles = {};
    return state;
}
exports.reduceFinishEditingResource = reduceFinishEditingResource;
function reduceResourcePageCommon(state, action) {
    var _a, _b;
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    switch (action.type) {
        case "visit":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.resourcePageSearch = initialState.inputs.resourcePageSearch;
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.resourcePageReverseOrder = initialState.toggles.resourcePageReverseOrder;
            if (state.customersPage.showResourceModal) {
                state.customersPage = tslib_1.__assign({}, state.customersPage);
                state.customersPage.showResourceModal = initialState.customersPage.showResourceModal;
            }
            state.resourcePageCommonState = tslib_1.__assign({}, initialState.resourcePageCommonState);
            break;
        case "toggle-resource":
            (_a = sequence_services_1.sequenceReduction(effects, reduceResourceToggle(state, action.toggleAction)), state = _a.state, effects = _a.effects);
            break;
        case "complete-request":
            if (action.name[0] === exports.deleteToggledResourcesRequestName) {
                if (!action.success)
                    break;
                var resourceType = action.name[1];
                var resourceId = action.name[2];
                state = tslib_1.__assign({}, state);
                var commonState_1 = state.resourcePageCommonState = tslib_1.__assign({}, state.resourcePageCommonState);
                commonState_1.toggles = tslib_1.__assign({}, commonState_1.toggles);
                delete commonState_1.toggles[resourceId];
                state.stores = tslib_1.__assign({}, state.stores);
                state.stores = removeResource({ type: resourceType, id: resourceId }, state.stores);
            }
            break;
        case "delete-toggled-resources":
            for (var _i = 0, _c = action.ids; _i < _c.length; _i++) {
                var resourceId = _c[_i];
                effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceDeleteByUrl([exports.deleteToggledResourcesRequestName, action.resourceType, resourceId], "/rapi/v1/" + action.resourceType + "/" + resourceId));
            }
            (_b = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastFAQDeleted[language])), state = _b.state, effects = _b.effects);
            break;
        case "close-fullscreen-resource-modal":
            state = reduceFinishEditingResource(state);
            break;
        case "begin-edit-resource":
            state = tslib_1.__assign({}, state);
            var commonState = state.resourcePageCommonState = tslib_1.__assign({}, state.resourcePageCommonState);
            commonState.currentEditingIds = action.ids;
            commonState.showResourceModal = true;
            commonState.editingResourceType = action.resourceType;
            break;
        case "begin-create-resource":
            state = tslib_1.__assign({}, state);
            var commonState = state.resourcePageCommonState = tslib_1.__assign({}, state.resourcePageCommonState);
            commonState.showResourceModal = true;
            commonState.currentEditingIds = [];
            commonState.editingResourceType = action.resourceType;
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceResourcePageCommon = reduceResourcePageCommon;
exports.deleteToggledResourcesRequestName = "delete-toggled-resources";
