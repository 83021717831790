"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var MemosStore;
(function (MemosStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    MemosStore.indexer = new bisect_1.Indexer("byId");
    MemosStore.indexer.addIndex("byId", function (md) { return [assertInnerId(md)]; });
    MemosStore.indexer.addIndex("byResourceTypeAndResourceId", function (md) { return [md.attributes.resource_type, md.attributes.resource_id]; });
})(MemosStore = exports.MemosStore || (exports.MemosStore = {}));
