"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var MembersStore;
(function (MembersStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    MembersStore.indexer = new bisect_1.Indexer("byId");
    MembersStore.indexer.addIndex("byId", function (md) { return [assertInnerId(md)]; });
    MembersStore.indexer.addIndex("byRoomIdAndUserId", function (md) { return [getRelationshipId(md, "room"),
        getRelationshipId(md, "user")]; });
    MembersStore.indexer.addIndex("byRoomId", function (md) { return [getRelationshipId(md, "room")]; });
    MembersStore.indexer.addIndex("byUserId", function (md) { return [getRelationshipId(md, "user")]; });
})(MembersStore = exports.MembersStore || (exports.MembersStore = {}));
