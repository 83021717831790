"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var sequence_services_1 = require("../../core/services/sequence-services");
var google_analytics_services_1 = require("../../core/services/google-analytics-services");
var last_read_at_reducer_1 = require("../../reducers/last-read-at-reducer");
function clearPopupMessages() {
    return {
        type: "clear-popup-messages",
    };
}
exports.clearPopupMessages = clearPopupMessages;
function captureAndEditWidgetColors(event) {
    return {
        type: "capture-and-edit-widget-color",
        event: event
    };
}
exports.captureAndEditWidgetColors = captureAndEditWidgetColors;
function reduceWidgetFrame(state, action) {
    var _a, _b;
    var effects = [];
    switch (action.type) {
        case "toggle":
            if (action.target === "widgetMaximized") {
                state = tslib_1.__assign({}, state);
                state.toggles = tslib_1.__assign({}, state.toggles);
                state.toggles.widgetFocused = state.toggles.widgetMaximized || state.provider_login_mode;
                (_a = sequence_services_1.sequenceReduction(effects, last_read_at_reducer_1.tryToMarkReadWidget(state)), state = _a.state, effects = _a.effects);
                state.popupMessageIds = [];
                effects = sequence_services_1.sequence(effects, google_analytics_services_1.sendGoogleAnalyticsEvent('Button Clicked', 'ok-sky_chat', state.clientCode));
            }
            if (action.target === "widgetFocused") {
                (_b = sequence_services_1.sequenceReduction(effects, last_read_at_reducer_1.tryToMarkReadWidget(state)), state = _b.state, effects = _b.effects);
            }
            break;
        case "clear-popup-messages":
            state = tslib_1.__assign({}, state);
            state.popupMessageIds = [];
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.widgetFocused = false;
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceWidgetFrame = reduceWidgetFrame;
