"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var json_resources_1 = require("../../core/json-resources");
var stores_1 = require("../../stores");
var loadResources = stores_1.Stores.loadResources;
var array_utils_1 = require("../../utils/array-utils");
var resource_api_1 = require("../../schemas/resource-api");
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var initial_loading_reducer_1 = require("./initial-loading-reducer");
var chat_room_reducer_1 = require("./chat-room-reducer");
var sequence_services_1 = require("../../core/services/sequence-services");
var xhr_1 = require("../../utils/xhr");
function loadNextPageIfNeeded(state, stringifiedRequestName) {
    var effects = [];
    if (!stringifiedRequestName)
        return effects;
    var nextRequest = state.nextPagedRequests[stringifiedRequestName];
    if (!nextRequest)
        return effects;
    if (state.loadingPagedRequests[stringifiedRequestName])
        return effects;
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch(array_utils_1.parseStringifiedArray(stringifiedRequestName), nextRequest));
    return effects;
}
exports.loadNextPageIfNeeded = loadNextPageIfNeeded;
exports.parseXdSetCookie = function (xhr) {
    var headers = xhr_1.parseResponseHeaders(xhr);
    var setCookies = headers['xd-set-cookie'];
    if (setCookies) {
        return setCookies.split("*");
    }
    return [];
};
function reduceCompleteRequest(state, action) {
    var effects = [];
    var paginatedRequests = initial_loading_reducer_1.initialLoadingPagedRequestNames.concat(chat_room_reducer_1.chatRoomPagedRequestNames);
    switch (action.type) {
        case "complete-request":
            var setCookies = xhr_1.parseHeaderString(action.headers)['xd-set-cookie'];
            if (setCookies) {
                setCookies.split("*");
            }
            if (paginatedRequests.indexOf(action.name[0]) !== -1) {
                state.loadingPagedRequests = tslib_1.__assign({}, state.loadingPagedRequests);
                delete state.loadingPagedRequests[array_utils_1.stringifyArray(action.name)];
            }
            var responseDetails = json_resources_1.getResourceResponseDetails(action);
            if (!responseDetails.resources || responseDetails.resources.length === 0)
                break;
            state = tslib_1.__assign({}, state);
            state.stores = loadResources(responseDetails.resources, state.stores);
            var apiResponse = responseDetails.apiResponse;
            if (!apiResponse || isErrorResponse(apiResponse))
                break;
            if (paginatedRequests.indexOf(action.name[0]) !== -1) {
                if (!apiResponse.links)
                    break;
                if (apiResponse.links.next) {
                    state.nextPagedRequests = tslib_1.__assign({}, state.nextPagedRequests);
                    state.nextPagedRequests[array_utils_1.stringifyArray(action.name)] = apiResponse.links.next;
                }
                else {
                    state.nextPagedRequests = tslib_1.__assign({}, state.nextPagedRequests);
                    delete state.nextPagedRequests[array_utils_1.stringifyArray(action.name)];
                }
            }
            else {
                if (responseDetails.resources.length <= 0)
                    break;
                var resourceType = responseDetails.resources[0].type;
                if (!resourceType)
                    break;
                var indexerName = stores_1.stateNameByModelType[resourceType];
                if (!indexerName)
                    break;
                var loadedCount = state.stores[indexerName][Object.keys(state.stores[indexerName])[0]].length;
                if (apiResponse.meta && apiResponse.meta.record_count && loadedCount >= apiResponse.meta.record_count)
                    break;
                effects = sequence_services_1.sequence(effects, json_resources_1.requestNextIfAvailable(action.name, responseDetails));
            }
            break;
        case "loading-request":
            if (paginatedRequests.indexOf(action.name[0]) !== -1) {
                state = tslib_1.__assign({}, state);
                state.loadingPagedRequests = tslib_1.__assign({}, state.loadingPagedRequests);
                state.loadingPagedRequests[array_utils_1.stringifyArray(action.name)] = true;
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceCompleteRequest = reduceCompleteRequest;
