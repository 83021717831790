"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var cloudinary_utils_1 = require("../utils/cloudinary-utils");
var classesForAvatar = class_generator_1.classNamesGeneratorFor(function (add) {
}, React.createElement("div", { className: "icon-avatar-user-default br-100 relative" }));
function UserAvatar(props) {
    var styles = {};
    var user = props.user;
    var tenant = props.tenant;
    var defaultUrl = "https://cdn.ok-sky.com/img/av2.png";
    if (user && user.attributes.default_avatar_url) {
        defaultUrl = user.attributes.default_avatar_url;
    }
    else if (user && props.appSettings && props.appSettings.unknown_user_avatar_url) {
        defaultUrl = user.attributes.default_avatar_url;
    }
    if (defaultUrl) {
        styles.backgroundImage = "url(" + defaultUrl + ")";
    }
    if (user && tenant && tenant.id) {
        if (user.attributes.user_type !== 'Customer' && tenant.attributes.default_avatar_url) {
            styles.backgroundImage = "url(" + tenant.attributes.default_avatar_url + ")";
        }
        if (user.attributes.user_type === 'Customer' && tenant.attributes.default_customer_avatar_url) {
            styles.backgroundImage = "url(" + tenant.attributes.default_customer_avatar_url + ")";
        }
    }
    if (user && user.attributes.gravatar_id) {
        styles.backgroundImage =
            "url(https://gravatar.com/avatar/" + user.attributes.gravatar_id + ".png?d=" + encodeURIComponent(defaultUrl) + ")";
    }
    if (user && user.attributes.avatar_url) {
        styles.backgroundImage = "url(" + cloudinary_utils_1.getCloudinaryResizeUrl(user.attributes.avatar_url, 40, 40, 'c_thumb') + ")";
    }
    if (!user && tenant && tenant.id) {
        if (defaultUrl === "https://cdn.ok-sky.com/img/av2.png" && tenant.attributes.default_customer_avatar_url) {
            styles.backgroundImage = "url(" + tenant.attributes.default_customer_avatar_url + ")";
        }
    }
    return React.createElement("div", { className: classesForAvatar(props), style: styles, onClick: props.onClick },
        props.user && !props.user.attributes.is_active ? React.createElement("div", { className: "bg-black w-100 h-100 o-20 br-100 absolute" }) : null,
        props.children);
}
exports.UserAvatar = UserAvatar;
