"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var xhrs = [];
function xhr(xhrConfig) {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhrs.push(xhr);
    xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
            var idx = xhrs.indexOf(xhr);
            if (idx > -1)
                xhrs.splice(idx, 1);
            if (xhr.status === 0)
                return;
            if (xhrConfig.onComplete)
                xhrConfig.onComplete(xhr);
        }
    };
    xhr.open(xhrConfig.method, xhrConfig.url, true);
    for (var header in xhrConfig.headers) {
        xhr.setRequestHeader(header, xhrConfig.headers[header]);
    }
    xhr.send(xhrConfig.data);
    return xhr;
}
exports.xhr = xhr;
;
exports.parseHeaderString = function (headerString) {
    var result = {};
    if (!headerString) {
        return result;
    }
    var headerPairs = headerString.split('\u000d\u000a');
    for (var i = 0; i < headerPairs.length; i++) {
        var headerPair = headerPairs[i];
        var idx = headerPair.indexOf('\u003a\u0020');
        if (idx > 0) {
            result[headerPair.substring(0, idx).toLowerCase()] = headerPair.substring(idx + 2);
        }
    }
    return result;
};
exports.parseResponseHeaders = function (xhr) {
    var headerStr = "";
    try {
        headerStr = xhr.getAllResponseHeaders();
    }
    catch (e) {
        console.error(e);
    }
    return exports.parseHeaderString(headerStr);
};
