"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
function StickerContent(props) {
    var settings = props.settings;
    var sticker_url = "https://stickershop.line-scdn.net/stickershop/v1/sticker/" + settings.sticker_global_id + "/PC/sticker.png";
    return React.createElement("div", { className: "w-100" },
        React.createElement("div", { className: "pb3 word-wrap" }, React.createElement("img", { src: sticker_url })));
}
exports.StickerContent = StickerContent;
