"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var svgs_1 = require("./svgs");
function alertClassName(props) {
    var className = " pv3 ph2 w-100 relative test--alert ";
    if (props.level) {
        className += " alert-" + props.level;
    }
    return className;
}
;
function Alert(props) {
    return React.createElement("div", { className: alertClassName(props) },
        React.createElement("div", { className: "dt bn dib bg-none pointer fr ", onClick: function () { return props.onClose(props.target); } },
            React.createElement("div", { className: "dtc v-mid" },
                React.createElement(svgs_1.SVGClose, { width: 16, height: 16 }))),
        React.createElement("div", { className: "mh0" },
            React.createElement("div", { className: "f3 tc" },
                React.createElement("p", { className: "pa0 ma0" }, props.children))));
}
exports.Alert = Alert;
