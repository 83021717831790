"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var widget_state_1 = require("../reducers/widget-state");
var toggles_reducer_1 = require("../../reducers/subreducers/toggles-reducer");
var color_editable_1 = require("../../components/color-editable");
var key_navigation_utils_1 = require("../../utils/key-navigation-utils");
var memoizers_1 = require("../../utils/memoizers");
var object_helpers_1 = require("../../utils/object-helpers");
var svgs_1 = require("../../components/svgs");
var tracker_entry_reducer_1 = require("../reducers/tracker-entry-reducer");
var WidgetCloseButton;
(function (WidgetCloseButton) {
    WidgetCloseButton.getBackgroundWidgetHideButton = function () {
        return React.createElement(svgs_1.SVGCross, { width: "16", height: "16", className: "ma db br-100" });
    };
    WidgetCloseButton.style = {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '100%',
        position: 'fixed',
        cursor: 'pointer',
        display: 'flex',
        alignItems: "center",
        height: '16px',
        width: '16px',
    };
    function render(dispatch) {
        var dispatcher = {
            toggleWidgetHideOnKeyDown: function (e) {
                if (key_navigation_utils_1.isEnterOrSpace(e)) {
                    dispatch(tracker_entry_reducer_1.goTrackerWidgetRemove());
                    dispatch(toggles_reducer_1.toggle("widgetHidden"));
                }
            },
            toggleWidgetHideOnClick: function () { return dispatch(toggles_reducer_1.toggle("widgetHidden")); },
            trackerOnClick: function () {
                dispatch(tracker_entry_reducer_1.goTrackerWidgetRemove());
            }
        };
        return memoizers_1.memoizeBySomeProperties({
            colors: object_helpers_1.pick(widget_state_1.WidgetV2State.initialState.colors, "widget_hide_button", "widget_hide_button_fill", "outline"),
        }, function (state) {
            return React.createElement(color_editable_1.ColorEditable, { colors: state.colors, editedColors: {
                    backgroundColor: "widget_hide_button",
                    fill: "widget_hide_button_fill",
                    outlineColor: "outline",
                }, className: "test--widget-close-button", role: "button", onClick: function () { dispatcher.trackerOnClick(); dispatcher.toggleWidgetHideOnClick(); }, tabIndex: 0, onKeyDown: dispatcher.toggleWidgetHideOnKeyDown, style: tslib_1.__assign({}, WidgetCloseButton.style) },
                React.createElement(svgs_1.SVGCross, { width: "16", height: "16", className: "ma db br-100" }));
        });
    }
    WidgetCloseButton.render = render;
})(WidgetCloseButton = exports.WidgetCloseButton || (exports.WidgetCloseButton = {}));
