"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var tracker_events_1 = require("../core/tracker-events");
function LinkMessageContent(props) {
    var settings = props.settings;
    var imageUrl = settings.image_url ? settings.image_url : "no_image.png";
    if (!settings)
        return React.createElement("div", null);
    var color = props.color ? props.color : "#2ea7e0";
    var dataActionId = null;
    if (props.message && props.message.id && props.message.id !== null && props.message.id !== "") {
        dataActionId = "oksky-link-ms-" + props.message.id;
    }
    return React.createElement("div", { className: "w-100" },
        React.createElement("div", { className: "pb3 word-wrap" },
            React.createElement("a", tslib_1.__assign({ href: settings.url, style: { color: color }, target: "_blank", onClick: function (event) { return tracker_events_1.eventClickHandler(event); }, onKeyDown: function (e) { return tracker_events_1.eventKeyDownHandler(e); } }, dataActionId ? { "data-link-action-id": dataActionId } : {}), settings.url)),
        React.createElement("div", { className: "f3 overflow-hidden gpu bl--moon-gray bl bw2-5" },
            React.createElement("div", { className: "fr" },
                React.createElement("img", { className: "might_broken_image h2 relative db tc lh-2 f5 min-w-80px", src: imageUrl })),
            React.createElement("div", { className: "overflow-hidden gpu w-auto pl3 pr3" },
                React.createElement("div", { className: "fw7", style: { color: color } }, settings.title),
                React.createElement("div", null, settings.description))));
}
exports.LinkMessageContent = LinkMessageContent;
