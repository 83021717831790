"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
function classNamesGeneratorFor(initializer, defaults, ignoreGivenStyles) {
    if (defaults === void 0) { defaults = null; }
    if (ignoreGivenStyles === void 0) { ignoreGivenStyles = false; }
    var classesForProps = {};
    var classesForInverseProps = {};
    var defaultStyle = defaults && defaults.props.className ? defaults.props.className : "";
    initializer(function (k, e, inverse) {
        if (k in classesForProps) {
            throw new Error("Duplicate class definition for " + k);
        }
        classesForProps[k] = e.props.className;
        if (inverse) {
            classesForInverseProps[k] = inverse.props.className;
        }
    });
    return function (props) {
        if (props == null)
            return " " + defaultStyle + " ";
        var classNames = defaultStyle;
        var unusedInverseClasses = tslib_1.__assign({}, classesForInverseProps);
        var k;
        for (k in classesForProps) {
            if (props[k]) {
                classNames += " " + classesForProps[k];
                delete unusedInverseClasses[k];
            }
        }
        for (k in unusedInverseClasses) {
            classNames += " " + unusedInverseClasses[k];
        }
        if (props.className && !ignoreGivenStyles)
            classNames += " " + props.className;
        return " " + classNames + " ";
    };
}
exports.classNamesGeneratorFor = classNamesGeneratorFor;
