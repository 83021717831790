"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function sendOnlineStatusAction() {
    return {
        type: "send-online-status-action"
    };
}
exports.sendOnlineStatusAction = sendOnlineStatusAction;
function withIndicateOnline(dispatch) {
    setInterval(function () {
        dispatch(sendOnlineStatusAction());
    }, 30000);
    return function () {
    };
}
exports.withIndicateOnline = withIndicateOnline;
