"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var object_helpers_1 = require("../../src/utils/object-helpers");
var ajax_services_1 = require("../../src/core/services/ajax-services");
var action_cable_services_1 = require("../../src/core/services/action-cable-services");
function getRecordedData(record) {
    return JSON.parse(record.data);
}
exports.getRecordedData = getRecordedData;
function normalizeQuery(query) {
    return query.split("&").map(function (s) { return decodeURIComponent(s); }).sort().join("&");
}
function doesAjaxMatch(ajax, record) {
    return !ajaxDiff(ajax, record);
}
exports.doesAjaxMatch = doesAjaxMatch;
function strictCmp(a, b) {
    return a === b;
}
function presenceCmp(a, b) {
    return !!a === !!b;
}
function checkAjaxPart(type, depth, configured, recorded, cmp) {
    if (cmp === void 0) { cmp = strictCmp; }
    if (!cmp(configured, recorded)) {
        return {
            type: type,
            depth: depth,
            configured: configured,
            recorded: recorded
        };
    }
}
function ajaxDiff(ajax, record) {
    var config = ajax.config;
    var result = null;
    var configUrlParts = ajax_services_1.getAjaxUrl(config).split("?");
    var recordRequestParts = record.request.path.split("?");
    result = checkAjaxPart("base url", 0, configUrlParts[0], recordRequestParts[0]);
    if (result)
        return result;
    result = checkAjaxPart("url segment length", 1, configUrlParts.length, recordRequestParts.length);
    if (result)
        return result;
    if (configUrlParts.length > 1) {
        result = checkAjaxPart("url query", 2, normalizeQuery(configUrlParts[1]), normalizeQuery(recordRequestParts[1]));
        if (result)
            return result;
    }
    if (config.json) {
        var configBody = config.json;
        var recordBody = void 0;
        result = checkAjaxPart("json presence", 3, config.json, record.request.body, presenceCmp);
        if (result)
            return result;
        try {
            recordBody = JSON.parse(JSON.parse(record.request.body));
        }
        catch (e) {
            result = checkAjaxPart("json validity", 4, config.json, record.request.body, strictCmp);
            if (result)
                return result;
        }
        result = checkAjaxPart("json body", 5, configBody, recordBody, object_helpers_1.deepEqual);
        if (result)
            return result;
    }
    if (config.body) {
        result = checkAjaxPart("request body", 4, config.body, record.request.body);
        if (result)
            return result;
    }
    return checkAjaxPart("request verb", 6, config.method.toLowerCase(), record.request.verb.toLowerCase());
}
exports.ajaxDiff = ajaxDiff;
function completeRequestFromRecord(request, record) {
    return ajax_services_1.completeRequest(request, record.response.status, record.response.body, "");
}
exports.completeRequestFromRecord = completeRequestFromRecord;
function recordedRequestToString(record) {
    var request = record.request;
    return request.verb + " " + request.path;
}
exports.recordedRequestToString = recordedRequestToString;
function requestAjaxToString(request) {
    return request.config.method + " " + ajax_services_1.getAjaxUrl(request.config);
}
exports.requestAjaxToString = requestAjaxToString;
function isRecordedRequestData(d) {
    return ('request' in d);
}
exports.CableDataNames = "create_message";
function withRecordedCableData(dispatch, recordedCableData) {
    return function () {
        var cableDataCollection = [];
        if (recordedCableData && recordedCableData[exports.CableDataNames]) {
            cableDataCollection = recordedCableData[exports.CableDataNames].slice();
            cableDataCollection.map(function (cableData) {
                var dataToSend = JSON.parse(cableData.data);
                dispatch(action_cable_services_1.actionCableReceivedMessage(cableData.broadcasting, dataToSend));
            });
        }
    };
}
exports.withRecordedCableData = withRecordedCableData;
function withRecordedAjaxReplay(dispatch, recordedRequestsData, respondWith404, missedRequests) {
    if (respondWith404 === void 0) { respondWith404 = true; }
    if (missedRequests === void 0) { missedRequests = {}; }
    for (var k in recordedRequestsData) {
        var v = recordedRequestsData[k];
        if (isRecordedRequestData(v)) {
            missedRequests[k] = null;
        }
    }
    return function (effect) {
        switch (effect.effectType) {
            case "request-ajax":
                var matchingData = void 0;
                var nextMissedDiffs = {};
                for (var k in missedRequests) {
                    var record = recordedRequestsData[k];
                    var bestMatch = missedRequests[k];
                    if (isRecordedRequestData(record)) {
                        var diff = ajaxDiff(effect, record);
                        if (!diff) {
                            matchingData = record;
                            delete missedRequests[k];
                            continue;
                        }
                        if (!bestMatch || bestMatch.depth < diff.depth) {
                            nextMissedDiffs[k] = diff;
                        }
                    }
                }
                if (matchingData) {
                    var recordedResponse = completeRequestFromRecord(effect, matchingData);
                    dispatch(recordedResponse);
                }
                else {
                    if (respondWith404) {
                        var fourOFour = ajax_services_1.completeRequest(effect, 404, "", "");
                        dispatch(fourOFour);
                    }
                    Object.assign(missedRequests, nextMissedDiffs);
                }
        }
    };
}
exports.withRecordedAjaxReplay = withRecordedAjaxReplay;
