"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var sequence_services_1 = require("../core/services/sequence-services");
var routes_1 = require("../routes");
var json_resources_1 = require("../core/json-resources");
var resource_api_1 = require("../schemas/resource-api");
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var navigation_services_1 = require("../core/services/navigation-services");
var moment = require('moment');
function searchReports() {
    return {
        type: "search-reports"
    };
}
exports.searchReports = searchReports;
function setReportStartDate(date) {
    return {
        type: "set-report-start-date",
        date: date
    };
}
exports.setReportStartDate = setReportStartDate;
function setReportEndDate(date) {
    return {
        type: "set-report-end-date",
        date: date
    };
}
exports.setReportEndDate = setReportEndDate;
function beginReportSearch(state, query) {
    var effects = [];
    state = tslib_1.__assign({}, state);
    state.reportsPage = tslib_1.__assign({}, state.reportsPage);
    if (query["start_date"]) {
        state.reportsPage.startDate = moment(parseInt(query["start_date"]) * 1000);
    }
    if (query["end_date"]) {
        state.reportsPage.endDate = moment(parseInt(query["end_date"]) * 1000);
    }
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.searchReportsInitialResponseTimesRequestName], routes_1.RapiV1ReportsPath + "/search_initial_response_times", query));
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.searchReportsDailySupportsRequestName], routes_1.RapiV1ReportsPath + "/search_daily_supports", query));
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.searchReportsFAQSRequestName], routes_1.RapiV1ReportsPath + "/search_faqs", query));
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.searchReportsVisitsRequestName], routes_1.RapiV1ReportsPath + "/search_visits", query));
    return { state: state, effects: effects };
}
exports.beginReportSearch = beginReportSearch;
function reduceReportsPage(state, action) {
    var effects = [];
    switch (action.type) {
        case "complete-request":
            if (exports.searchReportsRequestNames.includes(action.name[0])) {
                state = tslib_1.__assign({}, state);
                state.reportsPage = tslib_1.__assign({}, state.reportsPage);
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                if (responseDetails.resources) {
                    var report = responseDetails.resources[0];
                    if (report && report.type === "reports") {
                        state.reportsPage.reports = tslib_1.__assign({}, state.reportsPage.reports, report);
                    }
                }
            }
            break;
        case "set-report-start-date":
            state = tslib_1.__assign({}, state);
            state.reportsPage = tslib_1.__assign({}, state.reportsPage);
            state.reportsPage.startDate = action.date;
            break;
        case "set-report-end-date":
            state = tslib_1.__assign({}, state);
            state.reportsPage = tslib_1.__assign({}, state.reportsPage);
            state.reportsPage.endDate = action.date;
            break;
        case "search-reports":
            var query = {};
            state = tslib_1.__assign({}, state);
            state.reportsPage = tslib_1.__assign({}, state.reportsPage);
            if (!state.reportsPage.startDate) {
                state.reportsPage.startDate = moment(0);
            }
            query["start_date"] = state.reportsPage.startDate.unix();
            if (!state.reportsPage.endDate) {
                state.reportsPage.endDate = moment();
            }
            if (state.reportsPage.endDate.unix() < state.reportsPage.startDate.unix()) {
                state.reportsPage.endDate = state.reportsPage.startDate;
            }
            query["end_date"] = state.reportsPage.endDate.unix();
            effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({
                pathname: "/reports/search",
                search: navigation_services_1.encodeSearch(query)
            }));
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceReportsPage = reduceReportsPage;
exports.searchReportsInitialResponseTimesRequestName = "search-reports-initial-response-timesrequest-name";
exports.searchReportsDailySupportsRequestName = "search-reports-daily-supports-request-name";
exports.searchReportsFAQSRequestName = "search-reports-faqs-request-name";
exports.searchReportsVisitsRequestName = "search-reports-visits-request-name";
exports.searchReportsRequestNames = [exports.searchReportsInitialResponseTimesRequestName, exports.searchReportsDailySupportsRequestName, exports.searchReportsFAQSRequestName, exports.searchReportsVisitsRequestName];
