"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var routes_1 = require("../../routes");
function logout() {
    return { effectType: "logout" };
}
exports.logout = logout;
function withLogout(dispatch, targetHref) {
    if (targetHref === void 0) { targetHref = routes_1.DestroyUserSessionPath; }
    return function (effect) {
        switch (effect.effectType) {
            case "logout":
                location.href = targetHref;
                break;
        }
    };
}
exports.withLogout = withLogout;
