"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var addRelationship = resource_api_1.ResourceApi.addRelationship;
var newResource = resource_api_1.ResourceApi.newResource;
function getVideoType(cloudinaryResponse) {
    var videoTypes = ["mp4", "webm", "flv", "mov", "ogv", "3gp", "3g2", "wmv", "mpeg", "flv", "mkv", "avi"];
    var type = "video";
    if (cloudinaryResponse.format && videoTypes.indexOf(cloudinaryResponse.format) === -1) {
        type = "audio";
    }
    else {
        type = "video";
    }
    return type;
}
exports.getVideoType = getVideoType;
function createNewMessageFromUpload(cloudinaryResponse, room) {
    var message = newResource("messages");
    var type = null;
    if (cloudinaryResponse != null) {
        switch (cloudinaryResponse.resource_type) {
            case "image":
                type = "image";
                break;
            case "raw":
                type = "file";
                break;
            case "video":
                type = getVideoType(cloudinaryResponse);
                break;
        }
    }
    message.attributes.kind = type;
    message.attributes.settings = (cloudinaryResponse != null ? getMessageSettings(cloudinaryResponse) : {});
    if (room) {
        addRelationship(message, "room", room);
    }
    return message;
}
exports.createNewMessageFromUpload = createNewMessageFromUpload;
function getMessageSettings(cloudinaryResponse) {
    switch (cloudinaryResponse.resource_type) {
        case "image":
            return getImageSettings(cloudinaryResponse);
        case "raw":
            return getFileSettings(cloudinaryResponse);
        case "video":
            if (getVideoType(cloudinaryResponse) === "audio") {
                return getAudioSettings(cloudinaryResponse);
            }
            else {
                return getVideoSettings(cloudinaryResponse);
            }
        default:
            return {};
    }
}
exports.getMessageSettings = getMessageSettings;
function getImageSettings(cloudinaryResponse) {
    var result = {};
    result.type = cloudinaryResponse.resource_type;
    result.alt = cloudinaryResponse.original_filename;
    result.src_url = cloudinaryResponse.secure_url;
    return result;
}
function getFileSettings(cloudinaryResponse) {
    var result = {};
    result.type = cloudinaryResponse.resource_type;
    result.url = cloudinaryResponse.secure_url;
    result.filename = cloudinaryResponse.original_filename;
    result.size = cloudinaryResponse.bytes;
    return result;
}
function getVideoSettings(cloudinaryResponse) {
    var result = {};
    result.type = cloudinaryResponse.resource_type;
    result.src_url = cloudinaryResponse.secure_url;
    result.size = cloudinaryResponse.bytes;
    result.original_filename = cloudinaryResponse.original_filename;
    result.format = cloudinaryResponse.format;
    return result;
}
function getAudioSettings(cloudinaryResponse) {
    var result = {};
    result.type = cloudinaryResponse.resource_type;
    result.src_url = cloudinaryResponse.secure_url;
    result.size = cloudinaryResponse.bytes;
    result.original_filename = cloudinaryResponse.original_filename;
    result.format = cloudinaryResponse.format;
    return result;
}
