"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var ClientsStore;
(function (ClientsStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    ClientsStore.indexer = new bisect_1.Indexer("byId");
    ClientsStore.indexer.addIndex("byId", function (client) { return [assertInnerId(client)]; });
})(ClientsStore = exports.ClientsStore || (exports.ClientsStore = {}));
