"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
function AudioMessageContent(props) {
    var audio = props.message.attributes.settings;
    if (!audio)
        return null;
    var filename = audio.original_filename || "";
    var widthClass = props.isSmall ? "wimagesmall" : "wimage";
    return React.createElement("div", null,
        React.createElement("div", { className: "f3" }, filename),
        React.createElement("div", null,
            React.createElement("audio", { className: widthClass, preload: audio.preload, controls: true, autoPlay: audio.autoplay, src: audio.src_url, loop: audio.loop })));
}
exports.AudioMessageContent = AudioMessageContent;
