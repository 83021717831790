"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var bisect_1 = require("../../utils/bisect");
var resource_api_1 = require("../../schemas/resource-api");
var chat_list_message_1 = require("../../components/chat-list-message");
var chat_input_reducer_1 = require("../../reducers/chat-input-reducer");
var layouts_1 = require("../../components/layouts");
var draft_messages_reducer_1 = require("../../reducers/draft-messages-reducer");
var message_operations_reducer_1 = require("../../reducers/message-operations-reducer");
var I18n_1 = require("../../I18n");
var inputs_reducer_1 = require("../../reducers/subreducers/inputs-reducer");
var users_util_1 = require("../../utils/users-util");
var class_generator_1 = require("../../components/class-generator");
var complete_request_reducer_1 = require("../../reducers/complete-request-reducer");
var fake_progress_bar_1 = require("../../components/fake-progress-bar");
var add_comment_modal_1 = require("./add-comment-modal");
var formats_1 = require("../../components/formats");
var date_time_format_1 = require("../../components/date-time-format");
var svgs_1 = require("../../components/svgs");
var scroll_utils_1 = require("../../utils/scroll-utils");
var search_input_1 = require("../../components/search-input");
var select_single_1 = require("../../components/select-single");
var array_utils_1 = require("../../utils/array-utils");
var request_button_1 = require("../../components/request-button");
var string_utils_1 = require("../../utils/string-utils");
var navigation_services_1 = require("../../core/services/navigation-services");
var date_picker_1 = require("../../components/date-picker");
var moment = require("moment");
var classNamesForTab = class_generator_1.classNamesGeneratorFor(function (add) {
    add("selected", React.createElement("div", { className: "white bg-light-blue" }), React.createElement("div", { className: "pointer hover-select" }));
    add("borderBottom", React.createElement("div", { className: "bb b--light-gray" }));
}, React.createElement("div", { className: "w-100 pa3 tc f4 dark-gray" }));
function Tab(props) {
    return React.createElement("div", { className: classNamesForTab(props), onClick: props.selected ? null : props.onSelect },
        React.createElement(layouts_1.VCenteringContainer, null,
            React.createElement(layouts_1.VCentered, { className: "relative" }, props.children)));
}
exports.Tab = Tab;
function ResolveButton(props) {
    return React.createElement(chat_list_message_1.ToolButton, { className: "mb1 ml2 bg-true-light-silver shadow-elevate-up", onClick: props.onClick }, I18n_1.i18n.draftMessagesResolveMessage[props.language]);
}
exports.ResolveButton = ResolveButton;
var DraftMessagesPage;
(function (DraftMessagesPage) {
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    function render(dispatch) {
        var dispatcher = {
            toggleInReview: function (on) { return dispatch(draft_messages_reducer_1.markReadDraftMessagesResolved(on)); },
            scrollBottom: function (e) {
                if (scroll_utils_1.isAtBottomOf(e.currentTarget)) {
                    dispatch(draft_messages_reducer_1.loadNextDraftMessages());
                }
            },
            loadFilterResolved: function () { return dispatch(draft_messages_reducer_1.loadFilterResolvedMessages()); },
            setDraftMessageSearchInput: function (e) { return dispatch(draft_messages_reducer_1.loadSearchInput(e.target.value)); },
            setDraftMessageResolvedDropdownStatus: function (resolvedStatus) { return dispatch(draft_messages_reducer_1.loadDropdownStatus(resolvedStatus)); },
            setDraftMessagesDate: function (from, to) { return dispatch(draft_messages_reducer_1.setDraftMessagesDate(from, to)); },
            supportUser: function (user) { return dispatch(draft_messages_reducer_1.loadOperatorDropdown(user)); },
            resolveDraftMessages: function (status, resource) { return dispatch(draft_messages_reducer_1.resolveDraftMessages(status, resource)); },
            copyMessageTextToClipboard: function (messageId, resourceType) { return dispatch(message_operations_reducer_1.copyMessageTextToClipboard(messageId, resourceType)); },
            copyTextToClipboard: function (text) { return dispatch(message_operations_reducer_1.copyTextToClipboard(text)); },
            startFileMessageShare: function (messageId) { return dispatch(message_operations_reducer_1.startFileMessageShare(messageId)); },
            toggleAddCommentModal: function (status, resource) { return dispatch(draft_messages_reducer_1.draftMessagesResolveToggle(status, resource)); },
        };
        return function (state) {
            var user = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [state.draftMessagesPage.draftMessageCreateUserId]);
            var userSelectLabel = function (user) {
                return users_util_1.userDisplayName(state, user);
            };
            var draftMessages = draft_messages_reducer_1.filterDraftMessages(bisect_1.Indexer.getAllMatching(state.stores.draftMessageResources.byId, []), state);
            var draftMessagesResolvedValues = ["resolved", "approved", "rejected"];
            var draftMessagesResolvedLabel = function (status) {
                switch (status) {
                    case "resolved":
                        return I18n_1.i18n.draftMessagesResolvedMessage[language];
                    case "approved":
                        return I18n_1.i18n.draftMessagesApprovedMessage[language];
                    case "rejected":
                        return I18n_1.i18n.draftMessagesRejectedMessage[language];
                }
            };
            var rooms = draftMessages.map(function (dm) { return bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [getRelationshipId(dm, "room")]); });
            rooms = rooms.filter(function (room, i) { return array_utils_1.findIndex(rooms, function (r) { return r && room && r.id === room.id; }) === i; });
            var language = state.loggedInUser.attributes.language;
            var unresolvedDraftMessages = state.roomCounts && (users_util_1.isAtLeastA(state.loggedInUser, "Supervisor") ? state.roomCounts.total_draft_messages_in_review
                : state.roomCounts.draft_messages_in_review) || 0;
            var unreadRejectedMessagesCount = state.roomCounts && state.roomCounts.unread_draft_messages_rejected || 0;
            var unreadApprovedMessagesCount = state.roomCounts && state.roomCounts.unread_draft_messages_approved || 0;
            var unreadResolvedMessagesCount = unreadRejectedMessagesCount + unreadApprovedMessagesCount;
            var showProgressBar = complete_request_reducer_1.areRequestsStillLoading(state.loadingPagedRequests, [
                array_utils_1.stringifyArray([draft_messages_reducer_1.loadFilteredResolvedDraftMessagesRequest]),
                array_utils_1.stringifyArray([draft_messages_reducer_1.loadInReviewDraftMessagesRequest, state.loggedInUser.id]),
                array_utils_1.stringifyArray([draft_messages_reducer_1.loadUnreadResolvedDraftMessagesRequest, state.loggedInUser.id])
            ]);
            return React.createElement(layouts_1.FlexContainer, { vertical: true },
                React.createElement(layouts_1.Column, { fixedColumn: true },
                    React.createElement("div", { className: "w-100 flex flex-column items-stretch f3 br b--light-gray flex-none" },
                        React.createElement("div", null,
                            React.createElement("div", { className: "w-50 fl" },
                                React.createElement(Tab, { className: "test--draft-message-in-review-button", borderBottom: true, selected: state.toggles.draftMessageInReview, onSelect: function () { return dispatcher.toggleInReview(true); } },
                                    React.createElement("div", { className: "pa2" },
                                        React.createElement("span", null, I18n_1.i18n.draftMessagesInReviewMessage[language]),
                                        unresolvedDraftMessages ? React.createElement("span", null, " (" + unresolvedDraftMessages + ")") : null))),
                            React.createElement("div", { className: "w-50 fr" },
                                React.createElement(Tab, { className: "test--draft-message-resolved-button", borderBottom: true, selected: !state.toggles.draftMessageInReview, onSelect: function () { return dispatcher.toggleInReview(false); } },
                                    React.createElement("div", { className: "pa2" },
                                        React.createElement("span", null, I18n_1.i18n.draftMessagesReviewedMessage[language]),
                                        unreadResolvedMessagesCount ?
                                            React.createElement("span", null, " (" + unreadResolvedMessagesCount + " " + I18n_1.i18n.newCounter[language] + ")")
                                            : null))))),
                    !state.toggles.draftMessageInReview &&
                        React.createElement("div", { className: "pa3 w-100" },
                            React.createElement("div", { className: "w-33 dib" },
                                React.createElement(search_input_1.SearchInput, { placeholder: I18n_1.i18n.searchPlaceholder[language], onChange: function (e) { return dispatcher.setDraftMessageSearchInput(e); }, onCancel: inputs_reducer_1.inputChangeDispatcher(dispatch, "draftMessageSearchInput", ""), value: state.inputs.draftMessageSearchInput })),
                            React.createElement("div", { className: "dib tr mr2 w-33 pl4" },
                                React.createElement("div", { className: "dib w-35" },
                                    React.createElement("div", { className: "ma2 dib f4 fr" }, I18n_1.i18n.draftMessagesPageStatusLabel[language]),
                                    React.createElement(select_single_1.SelectSingle, { labeler: draftMessagesResolvedLabel, values: draftMessagesResolvedValues, value: state.draftMessagesPage.draftMessageResolvedDropdown, onChange: function (resolvedStatus) { return dispatcher.setDraftMessageResolvedDropdownStatus(resolvedStatus); }, hidePlaceholder: true })),
                                users_util_1.isAtLeastA(state.loggedInUser, "Supervisor") ?
                                    React.createElement("div", { className: "dib mr2 w-50 fr pl2 pr2" },
                                        React.createElement("div", { className: "ma2 dib fr f4" }, I18n_1.i18n.operatorLabel[language]),
                                        React.createElement(select_single_1.SelectSingle, { labeler: userSelectLabel, values: state.visibleOperators.allOperators, value: user, onChange: dispatcher.supportUser, placeholder: I18n_1.i18n.dropdownPlaceholder[language] }))
                                    : null),
                            React.createElement("div", { className: "w-33 dib" },
                                React.createElement("div", { className: "tr" },
                                    language === "en" && React.createElement("div", { className: "ma2 dib b fw3" }, I18n_1.i18n.from[language]),
                                    React.createElement("div", { className: "dib" },
                                        React.createElement(date_picker_1.DatePicker, { className: "br3 pa2", onChange: function (date) { return dispatcher.setDraftMessagesDate(moment(date), null); }, language: state.loggedInUser.attributes.language, value: state.draftMessagesPage.fromDate && state.draftMessagesPage.fromDate.toDate() })),
                                    language === "ja" && React.createElement("div", { className: "ma2 dib b fw3" }, I18n_1.i18n.from[language]),
                                    language === "en" && React.createElement("div", { className: "ma2 dib b fw3" }, I18n_1.i18n.to[language]),
                                    React.createElement("div", { className: "dib mr2" },
                                        React.createElement(date_picker_1.DatePicker, { className: "br3 pa2", onChange: function (date) { return dispatcher.setDraftMessagesDate(null, moment(date)); }, language: state.loggedInUser.attributes.language, value: state.draftMessagesPage.toDate && state.draftMessagesPage.toDate.toDate() })),
                                    language === "ja" && React.createElement("div", { className: "ma2 dib b fw3" }, I18n_1.i18n.to[language]),
                                    React.createElement("div", { className: "dib" },
                                        React.createElement(request_button_1.RequestButton, { onClick: dispatcher.loadFilterResolved, className: "pa3 white br3 test--search-past-supports-button" }, I18n_1.i18n.searchPlaceholder[language])))))),
                React.createElement(layouts_1.Row, { stretchRow: true, className: "relative overflow-y-auto gpu", onScroll: dispatcher.scrollBottom },
                    React.createElement("div", { className: "dt--fixed collapse absolute top-0 left-0 f3 pa3" },
                        rooms.map(function (room) {
                            draftMessages = draft_messages_reducer_1.filterDraftMessages(bisect_1.Indexer.getAllMatching(state.stores.draftMessageResources.byRoomIdAndCreatedAt, [room.id]), state);
                            var canSeeRoom = users_util_1.isAtLeastA(state.loggedInUser, "Supervisor") || bisect_1.Indexer.getFirstMatching(state.stores.members.byRoomIdAndUserId, [room.id, state.loggedInUser.id]);
                            return draftMessages.length > 0 &&
                                React.createElement("div", { className: "pb3" },
                                    React.createElement("div", { className: "bb bl br3 bg-white b--light-gray shadow-elevate-up-right" },
                                        users_util_1.isAtLeastA(state.loggedInUser, "Supervisor") && state.toggles.draftMessageInReview &&
                                            React.createElement("div", { className: "ph3 pv2 bg-silver fr" },
                                                state.draftMessagesPage.draftMessageResolveToggle[room.id] && React.createElement(add_comment_modal_1.AddCommentModal, { onInput: inputs_reducer_1.inputChangeDispatcher(dispatch, "draftMessageComment"), onCancel: function () { return dispatcher.toggleAddCommentModal("hide", room); }, onApprove: function () { return dispatcher.resolveDraftMessages("approve", room); }, onReject: function () { return dispatcher.resolveDraftMessages("reject", room); }, commentValue: state.inputs.draftMessageComment, language: language }),
                                                React.createElement(ResolveButton, { onClick: function () { return dispatcher.toggleAddCommentModal("show", room); }, language: language })),
                                        React.createElement("div", { className: "v-top bg-silver overflow-hidden gpu" },
                                            React.createElement("div", { className: "dib pa3 f2rem" },
                                                React.createElement("a", { className: "no-underline mid-gray " + (canSeeRoom ? " hover-light-blue pointer" : "no-pointer-events no-underline mid-gray"), href: "/supports/" + room.id, onClick: navigation_services_1.visitDispatcher(dispatch) }, string_utils_1.highlightStringWithMultipleKeywords(room.attributes.name && room.attributes.name.trim(), state.inputs.draftMessageSearchInput, "fw7 bg-lightest-blue", room.id)))),
                                        React.createElement("div", null, draftMessages.map(function (sdm) {
                                            var createUser = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [getRelationshipId(sdm, "created_user")]);
                                            var isCreatedUser = createUser && createUser.id == state.loggedInUser.id;
                                            var message = chat_input_reducer_1.createNewTextMessageFor(room, sdm.attributes.message_content);
                                            message.id = sdm.id;
                                            message.attributes.created_at_unix = sdm.attributes.created_at_unix;
                                            message.attributes.kind = sdm.attributes.kind;
                                            message.attributes.settings = sdm.attributes.settings;
                                            var resolvedUser = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [getRelationshipId(sdm, "resolved_user")]);
                                            return React.createElement("div", { className: "pa3 cf cb relative", key: "draft-messages-" + sdm.id },
                                                state.draftMessagesPage.draftMessageResolveToggle[sdm.id] && React.createElement(add_comment_modal_1.AddCommentModal, { onInput: inputs_reducer_1.inputChangeDispatcher(dispatch, "draftMessageComment"), onCancel: function () { return dispatcher.toggleAddCommentModal("hide", sdm); }, onApprove: function () { return dispatcher.resolveDraftMessages("approve", sdm); }, onReject: function () { return dispatcher.resolveDraftMessages("reject", sdm); }, commentValue: state.inputs.draftMessageComment, language: language }),
                                                resolvedUser &&
                                                    React.createElement("div", { className: isCreatedUser || sdm.attributes.comment ? "ba b--light-gray br2" : "" },
                                                        React.createElement("div", { className: "pv3 pl3 pr4 w-100 f5 hide-child" },
                                                            React.createElement("div", { className: "dib b " + (sdm.attributes.comment ? "" : " gray") }, sdm.attributes.status === "approved" ? I18n_1.i18n.draftMessagesApprovedMessage[language] : I18n_1.i18n.draftMessagesRejectedMessage[language]),
                                                            React.createElement("div", { className: "dib ml2" }, formats_1.formatName(resolvedUser)),
                                                            React.createElement("div", { className: "dib ml2" },
                                                                React.createElement(date_time_format_1.DateTimeFormat, { unixTime: sdm.attributes.resolved_time_unix })),
                                                            sdm.attributes.comment && React.createElement("div", null,
                                                                React.createElement("div", { className: "fr" },
                                                                    React.createElement(chat_list_message_1.ToolButton, { onClick: function () { return dispatcher.copyTextToClipboard(sdm.attributes.comment); } },
                                                                        React.createElement(svgs_1.SVGCopy, { width: 16, height: 16 }))),
                                                                React.createElement("div", { className: "f4 pa3" }, string_utils_1.highlightStringWithMultipleKeywords(sdm.attributes.comment && sdm.attributes.comment.trim(), state.inputs.draftMessageSearchInput, "fw7 bg-lightest-blue", sdm.id))))),
                                                React.createElement(chat_list_message_1.ChatListMessage, { createUser: createUser, currentUser: state.loggedInUser, isCurrentUser: isCreatedUser, message: message, highlightedText: state.inputs.draftMessageSearchInput, externalRoom: room.attributes.is_external, appSettings: state.appSettings, copyTextToClipBoard: function () { return dispatcher.copyMessageTextToClipboard(sdm.id, "draft_messages"); }, startFileMessageShare: function () { return dispatcher.startFileMessageShare(sdm.id); }, onToggleAddCommentModal: function () { return dispatcher.toggleAddCommentModal("show", sdm); }, isAtleastASupervisor: users_util_1.isAtLeastA(state.loggedInUser, "Supervisor"), inTraining: state.loggedInUser.attributes.in_training, draftMessageState: sdm.attributes.status, lang: language }));
                                        }))));
                        }),
                        showProgressBar ? React.createElement(fake_progress_bar_1.FakeProgressBar, null)
                            : rooms.length === 0 &&
                                React.createElement("div", { className: "moon-gray f1 pa3 mt6 tc" }, I18n_1.i18n.draftMessagesPageNotLoaded[language]))));
        };
    }
    DraftMessagesPage.render = render;
})(DraftMessagesPage = exports.DraftMessagesPage || (exports.DraftMessagesPage = {}));
