"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operator_state_1 = require("../reducers/operator-state");
var bisect_1 = require("./bisect");
var I18n_1 = require("../I18n");
var object_helpers_1 = require("./object-helpers");
var memoizers_1 = require("./memoizers");
var formats_1 = require("../components/formats");
exports.recomputeVisibleOperators = memoizers_1.memoizeBySomeProperties({
    loggedInUser: operator_state_1.OperatorState.initialState.loggedInUser,
    stores: object_helpers_1.pick(operator_state_1.OperatorState.initialState.stores, "users")
}, function (state) {
    var visibleOperators = {
        allOperators: [],
        activeOperators: []
    };
    if (exports.isAtLeastA(state.loggedInUser, "Supervisor")) {
        visibleOperators.allOperators = bisect_1.Indexer.getAllMatching(state.stores.users.byNotCustomerAndUsername, [true]);
        visibleOperators.activeOperators = bisect_1.Indexer.getAllMatching(state.stores.users.byNotCustomerAndIsActive, [true, true]);
    }
    return visibleOperators;
});
var UserTypeValues = {
    "Customer": 0,
    "Operator": 1,
    "Supervisor": 2,
    "Administrator": 3
};
exports.isAtLeastA = function (user, userType) {
    return UserTypeValues[user.attributes.user_type] >= UserTypeValues[userType];
};
function statusText(status, language) {
    switch (status) {
        case "offline":
        case "hidden":
            return I18n_1.i18n.statusOffline[language];
        case "available":
            return I18n_1.i18n.statusAvailable[language];
        case "away":
            return I18n_1.i18n.statusAway[language];
        case "busy":
            return I18n_1.i18n.statusBusy[language];
        default:
            return "";
    }
}
exports.statusText = statusText;
function userDisplayName(state, user) {
    if (!user)
        return formats_1.formatName(user);
    var isFullNameDuplicate = user.attributes.full_name && bisect_1.Indexer.getAllMatching(state.stores.users.byNotCustomerAndFullName, [true, user.attributes.full_name]).length > 1;
    var moreInfo = (isFullNameDuplicate) ? " (" + user.attributes.name + ")" : "";
    return formats_1.formatName(user) + moreInfo;
}
exports.userDisplayName = userDisplayName;
function canUserViewRoom(state, user, room) {
    if (!user || !room)
        return false;
    if (room.attributes.kind === "support_room" && exports.isAtLeastA(user, "Supervisor")) {
        return true;
    }
    var supportGroup = bisect_1.Indexer.getFirstMatching(state.stores.supports.byRoomIdAndUserId, [room.id, user.id]);
    if (supportGroup) {
        return true;
    }
    return !!bisect_1.Indexer.getFirstMatching(state.stores.members.byRoomIdAndUserId, [room.id, user.id]);
}
exports.canUserViewRoom = canUserViewRoom;
