"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var QuestionsStore;
(function (QuestionsStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    QuestionsStore.indexer = new bisect_1.Indexer("byId");
    QuestionsStore.indexer.addIndex("byId", function (question) { return [assertInnerId(question)]; });
    QuestionsStore.indexer.addIndex("bySurveyId", function (question) { return [getRelationshipId(question, "survey")]; });
    QuestionsStore.indexer.addIndex("byRoomId", function (question) { return [getRelationshipId(question, "room")]; });
})(QuestionsStore = exports.QuestionsStore || (exports.QuestionsStore = {}));
