"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
function sizeUpdate(name, size) {
    return {
        type: "size-update",
        name: name, size: size
    };
}
exports.sizeUpdate = sizeUpdate;
function reduceSizings(state, a) {
    var effects = [];
    switch (a.type) {
        case 'size-update':
            state = tslib_1.__assign({}, state);
            state[a.name] = a.size;
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceSizings = reduceSizings;
