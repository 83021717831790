"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var resource_api_1 = require("../schemas/resource-api");
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var ajax_services_1 = require("./services/ajax-services");
var sequence_services_1 = require("./services/sequence-services");
exports.jsonResourceHeaders = {};
exports.jsonResourceHeaders['Accept'] = "application/vnd.api+json";
exports.jsonResourceHeaders['Content-Type'] = "application/vnd.api+json";
function requestResourceWrite(name, data, createUrl) {
    var ajaxConfig = {};
    ajaxConfig.headers = exports.jsonResourceHeaders;
    if (data.links) {
        ajaxConfig.url = data.links.self;
    }
    else if (createUrl) {
        ajaxConfig.url = createUrl;
    }
    else {
        throw new Error("Cannot write resource without self link or createUrl! " + JSON.stringify(data));
    }
    if (data.id) {
        ajaxConfig.method = "PATCH";
    }
    else {
        ajaxConfig.method = "POST";
    }
    data = tslib_1.__assign({}, data);
    if ('id' in data && !data.id) {
        delete data.id;
    }
    if ('links' in data) {
        delete data.links;
    }
    ajaxConfig.json = { data: data };
    return ajax_services_1.requestAjax(name, ajaxConfig);
}
exports.requestResourceWrite = requestResourceWrite;
function requestResourcePut(name, url, query) {
    if (query === void 0) { query = {}; }
    var ajaxConfig = {};
    ajaxConfig.url = url;
    ajaxConfig.method = "PUT";
    ajaxConfig.headers = exports.jsonResourceHeaders;
    if (query) {
        ajaxConfig.query = ajax_services_1.encodeQueryParts(query);
    }
    return ajax_services_1.requestAjax(name, ajaxConfig);
}
exports.requestResourcePut = requestResourcePut;
function requestResourceFetch(name, url, query) {
    if (query === void 0) { query = {}; }
    var ajaxConfig = {};
    ajaxConfig.url = url;
    ajaxConfig.method = "GET";
    ajaxConfig.headers = exports.jsonResourceHeaders;
    if (query) {
        ajaxConfig.query = ajax_services_1.encodeQueryParts(query);
    }
    return ajax_services_1.requestAjax(name, ajaxConfig);
}
exports.requestResourceFetch = requestResourceFetch;
function requestResourceDeleteByUrl(name, url) {
    var ajaxConfig = {};
    ajaxConfig.url = url;
    ajaxConfig.method = "DELETE";
    ajaxConfig.headers = exports.jsonResourceHeaders;
    return ajax_services_1.requestAjax(name, ajaxConfig);
}
exports.requestResourceDeleteByUrl = requestResourceDeleteByUrl;
function getResourceResponseDetails(completed) {
    if (completed.success && !(completed.status == 204 || completed.status === 202)) {
        var response = JSON.parse(completed.response);
        var completedResource = { completed: completed, apiResponse: response, resources: [] };
        if (isErrorResponse(response)) {
            completed = completedResource.completed = tslib_1.__assign({}, completed);
            completed.success = false;
            response.errors.forEach(function (error) {
                console.error(completed, error.status, error.title, error.detail, error.source);
            });
        }
        else {
            completedResource.resources = resource_api_1.ResourceApi.allResourcesInResponse(response);
        }
        return completedResource;
    }
    return { completed: completed };
}
exports.getResourceResponseDetails = getResourceResponseDetails;
function requestNextIfAvailable(name, details) {
    var effects = [];
    var response = details.apiResponse;
    if (!response)
        return;
    if (isErrorResponse(response))
        return;
    if (response.links) {
        if (response.links.next) {
            effects = sequence_services_1.sequence(effects, requestResourceFetch(name, response.links.next));
        }
    }
    return effects;
}
exports.requestNextIfAvailable = requestNextIfAvailable;
