"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var widget_state_1 = require("../reducers/widget-state");
var bisect_1 = require("../../utils/bisect");
var toggles_reducer_1 = require("../../reducers/subreducers/toggles-reducer");
var color_editable_1 = require("../../components/color-editable");
var unread_message_pill_1 = require("../../components/unread-message-pill");
var svgs_1 = require("../../components/svgs");
var cloudinary_utils_1 = require("../../utils/cloudinary-utils");
var key_navigation_utils_1 = require("../../utils/key-navigation-utils");
var memoizers_1 = require("../../utils/memoizers");
var object_helpers_1 = require("../../utils/object-helpers");
var tracker_entry_reducer_1 = require("../reducers/tracker-entry-reducer");
var WidgetButton;
(function (WidgetButton) {
    WidgetButton.style = {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '100%',
        position: 'fixed',
        width: '60px',
        height: '60px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: "center",
    };
    function getBackgroundImage(widgetMaximized, backgroundUrl, buttonImageStyles) {
        buttonImageStyles = buttonImageStyles || { imageWidth: 60, imageHeight: 60, imageCustomFlg: false };
        var imageWidth = buttonImageStyles.imageWidth || 60;
        var imageHeight = buttonImageStyles.imageHeight || 60;
        var imageCustomFlg = buttonImageStyles.imageCustomFlg || false;
        var maximizedSVG = React.createElement(svgs_1.SVGCross, { width: "60%", height: "70%", style: { margin: 'auto', display: 'block', borderRadius: '100%' } });
        var minimizedImage = React.createElement(svgs_1.SVGChatMessage, { width: "75%", height: "75%", style: { margin: 'auto', display: 'block' } });
        if (!!backgroundUrl) {
            var imageStyle = { margin: 'auto', display: 'block' };
            var imageType = backgroundUrl.split(".").pop();
            if (imageType === "svg") {
                minimizedImage = React.createElement("img", { src: backgroundUrl, width: "75%", height: "75%", style: imageStyle });
            }
            else {
                if (imageCustomFlg) {
                    minimizedImage =
                        React.createElement("img", { src: cloudinary_utils_1.getCloudinaryResizeUrl(backgroundUrl, imageWidth, imageHeight), width: "100%", height: "100%", style: tslib_1.__assign({}, imageStyle) });
                }
                else {
                    minimizedImage =
                        React.createElement("img", { src: cloudinary_utils_1.getCloudinaryResizeUrl(backgroundUrl, 60, 60), width: "100%", height: "100%", style: tslib_1.__assign({}, imageStyle, { borderRadius: '100%' }) });
                }
            }
        }
        return widgetMaximized ? maximizedSVG : minimizedImage;
    }
    WidgetButton.getBackgroundImage = getBackgroundImage;
    function render(dispatch) {
        var dispatcher = {
            toggleWidgetMaximizedOnKeyDown: function (e, widgetMaximized) {
                if (key_navigation_utils_1.isEnterOrSpace(e)) {
                    dispatch(tracker_entry_reducer_1.goWidgetMaximizedTracker(widgetMaximized));
                    dispatch(toggles_reducer_1.toggle("widgetMaximized"));
                }
            },
            toggleWidgetMaximizedOnClick: function () { return dispatch(toggles_reducer_1.toggle("widgetMaximized")); },
            toggleWidgetHideOnClick: function () { return dispatch(toggles_reducer_1.toggle("widgetHidden")); },
            trackerOnClick: function (widgetMaximized) {
                dispatch(tracker_entry_reducer_1.goWidgetMaximizedTracker(widgetMaximized));
            }
        };
        return memoizers_1.memoizeBySomeProperties({
            stores: object_helpers_1.pick(widget_state_1.WidgetV2State.initialState.stores, "rooms", "members"),
            colors: object_helpers_1.pick(widget_state_1.WidgetV2State.initialState.colors, "widget_button", "message_date_text", "widget_button_fill", "outline", "unread_message_background", "unread_message_text", "settings_text", "settings_subtext"),
            toggles: object_helpers_1.pick(widget_state_1.WidgetV2State.initialState.toggles, "widgetMaximized"),
            button_background_url: widget_state_1.WidgetV2State.initialState.button_background_url,
            loggedInUser: widget_state_1.WidgetV2State.initialState.loggedInUser,
            plan_settings: widget_state_1.WidgetV2State.initialState.plan_settings,
            buttonImageStyles: widget_state_1.WidgetV2State.initialState.buttonImageStyles,
        }, function (state) {
            var totalUnreadMessageCount = 0;
            var rooms = bisect_1.Indexer.getAllMatching(state.stores.rooms.byCreateUserId, [state.loggedInUser.id]);
            rooms.map(function (room) {
                var member = bisect_1.Indexer.getFirstMatching(state.stores.members.byRoomIdAndUserId, [room.id, state.loggedInUser.id]);
                totalUnreadMessageCount += member && member.attributes.unread_message_count || 0;
            });
            var buttonStyle = tslib_1.__assign({}, WidgetButton.style);
            var maximizeWidget = state.toggles.widgetMaximized;
            var buttonImageStyles = {};
            if (maximizeWidget) {
                buttonImageStyles.imageCustomFlg = false;
                buttonImageStyles.imageWidth = 60;
                buttonImageStyles.imageHeight = 60;
            }
            else {
                buttonImageStyles = state.buttonImageStyles;
            }
            if (state.button_background_url || state.button_background_url !== null || state.button_background_url !== "") {
                if (buttonImageStyles.imageCustomFlg) {
                    buttonStyle.width = buttonImageStyles.imageWidth + 'px';
                    buttonStyle.height = buttonImageStyles.imageHeight + 'px';
                }
            }
            return React.createElement(color_editable_1.ColorEditable, { colors: state.colors, editedColors: {
                    backgroundColor: "widget_button",
                    fill: "widget_button_fill",
                    outlineColor: "outline",
                }, className: "test--widget-button", role: "button", onClick: function () { dispatcher.trackerOnClick(state.toggles.widgetMaximized); dispatcher.toggleWidgetMaximizedOnClick(); }, tabIndex: 0, onKeyDown: function (e) { dispatcher.toggleWidgetMaximizedOnKeyDown(e, state.toggles.widgetMaximized); }, style: tslib_1.__assign({}, buttonStyle) },
                React.createElement("div", { tabIndex: -1, className: "no-focus-glow w-100" },
                    (!state.toggles.widgetMaximized && !state.plan_settings.scenario_chat_flg) &&
                        React.createElement(unread_message_pill_1.UnreadMessagePill, { className: "absolute test--widget-unread-message-count", style: { right: totalUnreadMessageCount >= 10 ? "8px" : "5px" }, colors: state.colors, big: true, underTop: true, unreadCount: totalUnreadMessageCount }),
                    getBackgroundImage(state.toggles.widgetMaximized, state.button_background_url, state.buttonImageStyles)));
        });
    }
    WidgetButton.render = render;
})(WidgetButton = exports.WidgetButton || (exports.WidgetButton = {}));
