"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var input_debouncing_services_1 = require("../core/services/input-debouncing-services");
var debouncingTimeMs = 250;
var DebouncingInputBase = (function (_super) {
    tslib_1.__extends(DebouncingInputBase, _super);
    function DebouncingInputBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debouncingTimeout = null;
        _this.lastPropValue = undefined;
        _this.lastValue = undefined;
        _this.flush = function () {
            var e = _this.debouncingChangeEvent;
            if (!e)
                return;
            _this.clearDebounce();
            if (_this.inputRef && _this.props.onChange) {
                _this.props.onChange(e);
            }
        };
        _this.onBlur = function (e) {
            _this.flush();
            if (_this.props.onBlur)
                _this.props.onBlur(e);
        };
        _this.onChange = function (e) {
            clearTimeout(_this.debouncingTimeout);
            var lastValue = _this.lastValue;
            _this.lastValue = e.target.value;
            _this.debouncingChangeEvent = e;
            if (!!_this.lastValue != !!lastValue) {
                _this.flush();
                return;
            }
            e.persist();
            _this.debouncingTimeout = setTimeout(_this.flush, debouncingTimeMs);
        };
        _this.setRef = function (e) {
            _this.inputRef = e;
        };
        return _this;
    }
    DebouncingInputBase.prototype.isDebouncing = function () {
        return !!this.debouncingTimeout;
    };
    DebouncingInputBase.prototype.clearDebounce = function () {
        clearTimeout(this.debouncingTimeout);
        this.debouncingChangeEvent = null;
        this.debouncingTimeout = null;
    };
    DebouncingInputBase.prototype.render = function () {
        if (this.inputRef) {
            if (!this.isDebouncing()) {
                this.inputRef.value = this.props.value;
                this.lastValue = this.props.value;
            }
            else if ("value" in this.props && this.props.value !== this.lastPropValue) {
                this.inputRef.value = this.props.value;
                this.lastValue = this.props.value;
                this.clearDebounce();
            }
        }
        this.lastPropValue = this.props.value;
        var props = this.props;
        props = tslib_1.__assign({}, props, { onBlur: this.onBlur, onChange: this.onChange, ref: this.setRef });
        delete props["value"];
        return this.renderInner(props);
    };
    DebouncingInputBase.prototype.renderInner = function (props) {
        throw new Error("implement this is in subclasses");
    };
    DebouncingInputBase.prototype.componentDidMount = function () {
        if (this.inputRef)
            this.inputRef.value = this.props.value;
        this.lastValue = this.props.value;
        document.addEventListener(input_debouncing_services_1.clearInputDebouncingEventName, this.clearDebounce);
        document.addEventListener(input_debouncing_services_1.flushInputDebouncingEventName, this.flush);
    };
    DebouncingInputBase.prototype.componentWillUnmount = function () {
        document.removeEventListener(input_debouncing_services_1.clearInputDebouncingEventName, this.clearDebounce);
        document.removeEventListener(input_debouncing_services_1.flushInputDebouncingEventName, this.flush);
    };
    return DebouncingInputBase;
}(React.Component));
var DebouncingInput = (function (_super) {
    tslib_1.__extends(DebouncingInput, _super);
    function DebouncingInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DebouncingInput.prototype.renderInner = function (props) {
        return React.createElement("input", tslib_1.__assign({}, props));
    };
    return DebouncingInput;
}(DebouncingInputBase));
exports.DebouncingInput = DebouncingInput;
var DebouncingTextInput = (function (_super) {
    tslib_1.__extends(DebouncingTextInput, _super);
    function DebouncingTextInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DebouncingTextInput.prototype.renderInner = function (props) {
        return React.createElement("textarea", tslib_1.__assign({}, props, { style: { resize: "none" }, rows: 1 }));
    };
    return DebouncingTextInput;
}(DebouncingInputBase));
exports.DebouncingTextInput = DebouncingTextInput;
var DebouncingTextArea = (function (_super) {
    tslib_1.__extends(DebouncingTextArea, _super);
    function DebouncingTextArea() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DebouncingTextArea.prototype.renderInner = function (props) {
        return React.createElement("textarea", tslib_1.__assign({}, props));
    };
    return DebouncingTextArea;
}(DebouncingInputBase));
exports.DebouncingTextArea = DebouncingTextArea;
