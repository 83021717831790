"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
function ColorEditable(props) {
    var colorNames = props.colorKeys;
    var editedStyle = {};
    if (props.colors && props.editedColors) {
        colorNames = colorNames || Object.keys(props.editedColors).map(function (cssKey) { return props.editedColors[cssKey]; });
        var styleNames = Object.keys(props.editedColors);
        styleNames.map(function (styleName) {
            editedStyle[styleName] = props.colors[props.editedColors[styleName]];
        });
    }
    return React.createElement("div", { tabIndex: props.tabIndex, className: props.className, "data-colornames": colorNames, style: tslib_1.__assign({}, props.style, editedStyle), onClick: props.onClick, onKeyDown: props.onKeyDown, role: props.role }, props.children);
}
exports.ColorEditable = ColorEditable;
