"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var chat_list_message_1 = require("./chat-list-message");
var svgs_1 = require("./svgs");
var cloudinary_utils_1 = require("../utils/cloudinary-utils");
function ImageMessageContent(props) {
    var image = props.message.attributes.settings;
    if (!image)
        return null;
    var imageWidth = props.isSmall ? 200 : 350;
    var imageWidthClass = props.isSmall ? 'wimagesmall' : 'wimage';
    var resizedUrl = cloudinary_utils_1.getCloudinaryResizeUrl(image.src_url, imageWidth);
    return React.createElement("div", { className: "hide-child " + imageWidthClass },
        React.createElement("div", { className: "fl" },
            React.createElement("img", { src: resizedUrl, alt: image.alt })),
        React.createElement("div", { className: "hide-child relative  " + imageWidthClass },
            React.createElement("a", { href: image.src_url, target: "_blank", download: true },
                React.createElement(chat_list_message_1.ToolButton, { small: true, className: "absolute right-0 ma2" },
                    React.createElement(svgs_1.SVGExpand, { width: 25, height: 25 })))));
}
exports.ImageMessageContent = ImageMessageContent;
