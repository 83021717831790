"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var layouts_1 = require("./layouts");
var svgs_1 = require("./svgs");
var I18n_1 = require("../I18n");
var DatePicker = (function (_super) {
    tslib_1.__extends(DatePicker, _super);
    function DatePicker(props) {
        var _this = _super.call(this, props) || this;
        _this.format = /\d{4}\/\d{1,2}\/\d{1,2}/;
        _this.recalculateDaysInTheMonth = function () {
            _this.maxDays = new Date(_this.date.getFullYear(), _this.date.getMonth() + 1, 0).getDate();
            _this.weeksInTheMonth = [];
            var tmpDay = new Date(_this.date.getFullYear(), _this.date.getMonth(), 1);
            var firstDayOfTheWeek = tmpDay.getDay();
            tmpDay.setDate(tmpDay.getDate() - firstDayOfTheWeek - 1);
            var weekInTheMonth = 0;
            for (var i = 0; i < 42; i++) {
                tmpDay.setDate(tmpDay.getDate() + 1);
                var dayInTheWeek = i % 7;
                weekInTheMonth = (i / 7 | 0) === weekInTheMonth + 1 ? weekInTheMonth + 1 : weekInTheMonth;
                if (weekInTheMonth === 5 && dayInTheWeek === 0 && tmpDay.getDate() < 10) {
                    break;
                }
                _this.weeksInTheMonth[weekInTheMonth] = _this.weeksInTheMonth[weekInTheMonth] || [];
                _this.weeksInTheMonth[weekInTheMonth][dayInTheWeek] = new Date(tmpDay.getTime());
            }
        };
        _this.setInputValue = function () {
            _this.input.value = _this.chosenDate && _this.chosenDate.getFullYear() + "/" + (_this.chosenDate.getMonth() + 1) + "/" + _this.chosenDate.getDate();
        };
        _this.openCalendar = function (e) {
            e.preventDefault();
            _this.showingCalendar = true;
        };
        _this.closeCalendar = function (e) {
            e.preventDefault();
            _this.showingCalendar = false;
        };
        _this.previousMonth = function () {
            _this.date.setMonth(_this.date.getMonth() - 1);
            _this.recalculateDaysInTheMonth();
        };
        _this.nextMonth = function () {
            _this.date.setMonth(_this.date.getMonth() + 1);
            _this.recalculateDaysInTheMonth();
        };
        _this.handleSelectDate = function (date) {
            _this.date = new Date(date.getTime());
            _this.chosenDate = new Date(date.getTime());
            _this.setInputValue();
            _this.recalculateDaysInTheMonth();
            _this.props.onChange(date);
        };
        _this.handleInput = function () {
            if (_this.input.value && _this.format.test(_this.input.value)) {
                var d = _this.input.value.split('/');
                var year = parseInt(d[0]);
                var month = parseInt(d[1]);
                var day = parseInt(d[2]);
                if (year && month && day) {
                    var date = new Date();
                    date.setFullYear(year);
                    date.setMonth(month - 1);
                    date.setDate(day);
                    _this.handleSelectDate(date);
                }
            }
            else {
                _this.props.onChange(null);
            }
        };
        _this.handleInput = _this.handleInput.bind(_this);
        _this.handleSelectDate = _this.handleSelectDate.bind(_this);
        _this.openCalendar = _this.openCalendar.bind(_this);
        _this.closeCalendar = _this.closeCalendar.bind(_this);
        return _this;
    }
    DatePicker.prototype.componentDidMount = function () {
        this.today = new Date();
        this.date = this.props.value || new Date(this.today.getTime());
        this.chosenDate = this.props.value;
        this.setInputValue();
        this.recalculateDaysInTheMonth();
    };
    DatePicker.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { tabIndex: -1, onFocus: this.openCalendar, onBlur: this.closeCalendar },
            React.createElement("input", { className: this.props.className, ref: function (r) { return _this.input = r; }, onChange: this.handleInput, placeholder: this.props.placeholderText || "YYYY/MM/DD" }),
            this.showingCalendar &&
                React.createElement("div", { className: "absolute tc bg-white z-999 shadow-1 br1" },
                    React.createElement("div", { className: "pa3 bg-light-gray dark-gray h-30" },
                        React.createElement("div", { className: "db" },
                            React.createElement("div", { className: "dib pointer fl fill-gray", onClick: this.previousMonth },
                                React.createElement(svgs_1.SVGArrow, { width: 10, height: 10, className: 'dib rotate-90' })),
                            React.createElement("div", { className: "dib f5" }, I18n_1.i18n.monthInTheYear[this.props.language][this.date.getMonth()] + " " + this.date.getFullYear()),
                            React.createElement("div", { className: "dib pointer fr fill-gray", onClick: this.nextMonth },
                                React.createElement(svgs_1.SVGArrow, { width: 10, height: 10, className: 'dib rotate-270' }))),
                        React.createElement("div", { className: "db pt2" },
                            React.createElement(layouts_1.Row, null, I18n_1.i18n.daysOfTheWeek[this.props.language].map(function (dayOfTheWeek, i) {
                                return React.createElement(layouts_1.Column, { key: "day-of-the-week-" + i, className: "ma pa2 br" }, dayOfTheWeek);
                            })))),
                    React.createElement("table", { className: "w-100 pa3" },
                        React.createElement("tbody", { className: "flex flex-column" }, this.weeksInTheMonth.map(function (weekInTheMonth, weekIndex) {
                            return React.createElement("tr", { className: "w-100 flex flex-row ", key: "week-" + weekIndex }, weekInTheMonth.map(function (dayInTheWeek, dayIndex) {
                                var dateNumber = dayInTheWeek.getDate();
                                var colorClasses = 'hover-bg-light-blue hover-white';
                                if (_this.today.toLocaleDateString() === dayInTheWeek.toLocaleDateString()) {
                                    colorClasses += ' bg-light-gray';
                                }
                                if (_this.chosenDate && _this.chosenDate.toLocaleDateString() === dayInTheWeek.toLocaleDateString()) {
                                    colorClasses = ' bg-blue white';
                                }
                                return React.createElement("td", { key: "week-" + weekIndex + "-day-" + dayIndex, onClick: function () { return _this.handleSelectDate(dayInTheWeek); }, className: "pa2 ma pointer br2\n                           " + colorClasses + "\n                  " + ((weekIndex === 0 && dateNumber > 20) || (weekIndex === _this.weeksInTheMonth.length - 1 && dateNumber < 10) ? 'gray' : '') },
                                    dateNumber < 10 ? '0' : '',
                                    dateNumber);
                            }));
                        })))));
    };
    return DatePicker;
}(React.Component));
exports.DatePicker = DatePicker;
