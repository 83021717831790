"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
function init() {
    return {
        type: "@init"
    };
}
exports.init = init;
function ignoredAction() {
    return { type: '' };
}
exports.ignoredAction = ignoredAction;
function isAction(actionOrEffect) {
    return 'type' in actionOrEffect;
}
exports.isAction = isAction;
exports.batchingStart = { type: "batching-start" };
exports.batchingEnd = { type: "batching-end" };
function whileBatchingDispatches(dispatch, f) {
    dispatch(exports.batchingStart);
    try {
        f();
    }
    finally {
        dispatch(exports.batchingEnd);
    }
}
exports.whileBatchingDispatches = whileBatchingDispatches;
function reducerChain(state, action, effects) {
    if (effects === void 0) { effects = []; }
    var chainer = {
        apply: function (reducer) {
            var reduction = reducer(state, action);
            if (reduction.effects && reduction.effects.length) {
                effects = effects.concat(reduction.effects);
            }
            state = reduction.state;
            return chainer;
        },
        result: function () {
            return {
                state: state,
                effects: effects
            };
        }
    };
    return chainer;
}
exports.reducerChain = reducerChain;
function subReducersFor() {
    return function subReducer(key, reducer) {
        return function (state, action) {
            var reduction = reducer(state[key], action);
            if (reduction.state !== state[key]) {
                state = tslib_1.__assign({}, state);
                state[key] = reduction.state;
            }
            var effects = reduction.effects || [];
            return { state: state, effects: effects };
        };
    };
}
exports.subReducersFor = subReducersFor;
function computedFor() {
    var effects = [];
    return function computed(key, compute) {
        return function (state) {
            var next = compute(state);
            if (next !== state[key]) {
                state = tslib_1.__assign({}, state);
                state[key] = next;
            }
            return { state: state, effects: effects };
        };
    };
}
exports.computedFor = computedFor;
