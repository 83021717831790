"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var bisect_1 = require("../../utils/bisect");
var widget_message_1 = require("../../components/widget-message");
var I18n_1 = require("../../I18n");
var layouts_1 = require("../../components/layouts");
var resource_api_1 = require("../../schemas/resource-api");
var svgs_1 = require("../../components/svgs");
var widget_frame_reducer_1 = require("../reducers/widget-frame-reducer");
var toggles_reducer_1 = require("../../reducers/subreducers/toggles-reducer");
var widget_chat_input_1 = require("./widget-chat-input");
var scroll_bar_container_1 = require("../../components/scroll-bar-container");
var chat_scroll_container_1 = require("../../components/chat-scroll-container");
var PopupMessages;
(function (PopupMessages) {
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    function render(dispatch) {
        var dispatcher = {
            clearPopupMessages: function () { return dispatch(widget_frame_reducer_1.clearPopupMessages()); },
        };
        var ChatInput = widget_chat_input_1.WidgetChatInput.render(dispatch);
        return function (state) {
            var orderedPopupMessages = bisect_1.Indexer.getAllMatching(state.stores.messages.byRoomIdAndCreatedAtUnix, [state.currentViewingRoomId])
                .filter(function (roomMessage) { return state.popupMessageIds.indexOf(roomMessage.id) !== -1; });
            var language = state.loggedInUser.attributes.language;
            return React.createElement(layouts_1.Column, { stretchColumn: true, className: "hide-child absolute right-0 bottom-0 w-70 max-h-100 test--popup-box" },
                React.createElement("div", { className: "child pb3" },
                    React.createElement("div", { className: "dib fr" },
                        React.createElement("div", { role: "button", tabIndex: 0, className: "dib white fill-white", style: { outlineColor: state.colors.outline }, onClick: toggles_reducer_1.toggleDispatcher(dispatch, "widgetMaximized", true) },
                            React.createElement("div", { tabIndex: -1, className: "no-focus-glow" },
                                React.createElement(layouts_1.VCenteringContainer, null,
                                    React.createElement(layouts_1.VCentered, null,
                                        React.createElement("div", { className: "dib pointer v-mid bg-gray br2 pa2 o-70 glow" }, I18n_1.i18n.showMore[language]))))),
                        React.createElement("div", { role: "button", tabIndex: 0, className: "dib white fill-white ml6rem", style: { outlineColor: state.colors.outline }, onClick: dispatcher.clearPopupMessages },
                            React.createElement("div", { tabIndex: -1, className: "no-focus-glow" },
                                React.createElement(layouts_1.VCenteringContainer, null,
                                    React.createElement(layouts_1.VCentered, null,
                                        React.createElement("div", { className: "dib pointer v-mid bg-gray br2 o-70 glow" },
                                            React.createElement(svgs_1.SVGCloseNoCircle, { width: 19, height: 19, className: "" })))))))),
                React.createElement(scroll_bar_container_1.ScrollBarContainer, { colors: state.colors, inEditMode: state.fakeWidget.inEditMode },
                    React.createElement(chat_scroll_container_1.ChatScrollContainer, { tabIndex: 0, style: { outlineColor: state.colors.outline }, id: "popup-messages-chat-scroll", className: "slide-up-animate fw6 h-max-widget-ns overflow-y-scroll", roomId: state.currentViewingRoomId }, orderedPopupMessages.map(function (popupMessage) {
                        var user = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [getRelationshipId(popupMessage, "user")]);
                        return React.createElement(widget_message_1.WidgetMessage, { key: "widget-popup-messages-" + popupMessage.id, message: popupMessage, language: language, id: "test--popup-message", user: user, colors: state.colors, tenant: state.thisTenant, timeFormat: state.v2_time_format });
                    }))),
                ChatInput(state));
        };
    }
    PopupMessages.render = render;
})(PopupMessages = exports.PopupMessages || (exports.PopupMessages = {}));
