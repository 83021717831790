"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var I18n_1 = require("../I18n");
function closeAllFeedbackToasts() {
    return {
        type: "close-all-feedback-toasts"
    };
}
exports.closeAllFeedbackToasts = closeAllFeedbackToasts;
function closeFeedbackToast(index) {
    return {
        type: "close-feedback-toast",
        index: index
    };
}
exports.closeFeedbackToast = closeFeedbackToast;
function openToast(state, message) {
    var effects = [];
    var feedbackToast = {};
    feedbackToast.since = state.now;
    var language = state.loggedInUser.attributes.language;
    if (message) {
        feedbackToast.message = message;
    }
    else {
        feedbackToast.message = I18n_1.i18n.feedbackToastDefault[language];
    }
    feedbackToast.page = state.pathParts[0];
    feedbackToast.isClosed = false;
    state = tslib_1.__assign({}, state);
    state.feedbackToasts = state.feedbackToasts.slice();
    state.feedbackToasts.push(feedbackToast);
    return { state: state, effects: effects };
}
exports.openToast = openToast;
function reduceToast(state, action) {
    var effects = [];
    switch (action.type) {
        case "update-time":
            state = tslib_1.__assign({}, state);
            state.feedbackToasts = state.feedbackToasts.slice();
            state.feedbackToasts.map(function (toast) {
                if ((state.now - toast.since) > 2800 && state.pathParts[0] === toast.page) {
                    toast = tslib_1.__assign({}, toast);
                    if (toast.isClosed) {
                        toast = null;
                    }
                    else {
                        toast.isClosed = true;
                    }
                }
                return toast;
            });
            state.feedbackToasts = state.feedbackToasts.filter(function (toast) { return toast; });
            break;
        case "visit":
            state = tslib_1.__assign({}, state);
            state.feedbackToasts = state.feedbackToasts.slice();
            state.feedbackToasts = state.feedbackToasts.filter(function (toast) { return toast.page === state.pathParts[0]; });
            break;
        case "close-all-feedback-toasts":
            state = tslib_1.__assign({}, state);
            state.feedbackToasts = state.feedbackToasts.slice();
            state.feedbackToasts = [];
            break;
        case "close-feedback-toast":
            state = tslib_1.__assign({}, state);
            state.feedbackToasts = state.feedbackToasts.slice();
            state.feedbackToasts[action.index] = tslib_1.__assign({}, state.feedbackToasts[action.index]);
            state.feedbackToasts[action.index].isClosed = true;
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceToast = reduceToast;
