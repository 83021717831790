"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var reducers_1 = require("./reducers");
var dom_1 = require("./dom");
var React = require("react");
var ReactDom = require("react-dom");
var ReactRoot = (function (_super) {
    tslib_1.__extends(ReactRoot, _super);
    function ReactRoot() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReactRoot.prototype.render = function () {
        if (this.view) {
            return this.view(this.state.inner);
        }
        return null;
    };
    ReactRoot.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        return !this.state || this.state.inner !== nextState.inner;
    };
    return ReactRoot;
}(React.Component));
exports.ReactRoot = ReactRoot;
function bootstrap(initialState, reducer, view, servicesProps, outputConsoleLog, rootElementId, hideButton, hidePopup) {
    if (outputConsoleLog === void 0) { outputConsoleLog = false; }
    dom_1.generateRootElement(rootElementId).subscribe(function (element) {
        var root;
        var batchingDepth = 0;
        var curState = initialState;
        var services = null;
        var renderer = function (state, dispatch) {
            if (!root) {
                root = ReactDom.render(React.createElement(ReactRoot, null), element);
                root.view = view(dispatch, hideButton, hidePopup);
            }
            if (state) {
                root.setState({ inner: state });
            }
        };
        var reduceEffects = function (effects) {
            if (services) {
                effects.map(function (effect) {
                    if (effect) {
                        if (outputConsoleLog)
                            console.log("effect", effect);
                        services.map(function (service) { return service(effect); });
                    }
                });
            }
            else {
                console.error("reduce effects called, but services not installed");
            }
        };
        var queuedActions = [];
        var dispatch = function (action) {
            if (installingServices) {
                queuedActions.push(action);
                return;
            }
            var startTimings = Date.now();
            if (action === reducers_1.batchingStart) {
                batchingDepth++;
            }
            else if (action === reducers_1.batchingEnd) {
                batchingDepth--;
            }
            if (outputConsoleLog)
                console.log("action", action);
            var reduction = reducer(curState, action);
            curState = reduction.state;
            if (reduction.effects.length > 0) {
                reduceEffects(reduction.effects);
            }
            if (outputConsoleLog)
                console.log("new state", curState);
            if (outputConsoleLog)
                console.log("reduced in", Date.now() - startTimings, "ms");
            if (batchingDepth <= 0) {
                var startRenderTime = Date.now();
                render(curState);
                var renderTime = Date.now() - startRenderTime;
                if (outputConsoleLog)
                    console.log("rendered in", renderTime, "ms");
                if (outputConsoleLog)
                    console.log("completed in", Date.now() - startTimings, "ms");
                if (renderTime > 50) {
                    if (outputConsoleLog)
                        console.warn("Slow action:  ", renderTime + "ms", action);
                }
            }
        };
        var installingServices = true;
        services = servicesProps(dispatch);
        installingServices = false;
        var render = function (s) { return renderer(s, dispatch); };
        while (queuedActions.length > 0) {
            var tmp = queuedActions.pop();
            dispatch(tmp);
        }
        dispatch({ type: 'init' });
    });
}
exports.bootstrap = bootstrap;
