"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var I18n_1 = require("../../I18n");
var balloon_1 = require("../../components/balloon");
var debouncing_inputs_1 = require("../../components/debouncing-inputs");
var chat_list_message_1 = require("../../components/chat-list-message");
function AddCommentModal(props) {
    var commentValue = props.commentValue || "";
    return React.createElement(balloon_1.Balloon, { className: "br2 max-h-6 overflow-y-auto gpu right-0 pa3", light: true, hoverOnly: true },
        React.createElement(debouncing_inputs_1.DebouncingTextInput, { value: commentValue, autoFocus: true, className: "w-100 mw-100 mh-100 h-100 no-resize b--light-blue--hover pea br2 b--light-gray b--solid", onChange: props.onInput, placeholder: I18n_1.i18n.draftMessagesCommentMessage[props.language] }),
        React.createElement("div", { className: "pt3 fr" },
            React.createElement(chat_list_message_1.ToolButton, { className: "bg-true-light-silver shadow-elevate-up", onClick: props.onCancel }, I18n_1.i18n.cancelMessage[props.language]),
            React.createElement(chat_list_message_1.ToolButton, { className: "ml2 bg-true-light-silver shadow-elevate-up test--message-approve-button", onClick: props.onApprove }, I18n_1.i18n.draftMessagesApproveMessage[props.language]),
            React.createElement(chat_list_message_1.ToolButton, { onClick: props.onReject, className: "ml2 bg-true-light-silver shadow-elevate-up test--message-reject-button" }, I18n_1.i18n.draftMessagesRejectMessage[props.language])));
}
exports.AddCommentModal = AddCommentModal;
