"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var operator_state_1 = require("./operator-state");
var json_resources_1 = require("../core/json-resources");
var sequence_services_1 = require("../core/services/sequence-services");
var routes_1 = require("../routes");
var resource_api_1 = require("../schemas/resource-api");
var newResource = resource_api_1.ResourceApi.newResource;
var addRelationship = resource_api_1.ResourceApi.addRelationship;
var initialState = operator_state_1.OperatorState.initialState;
var complete_request_reducer_1 = require("./complete-request-reducer");
var array_utils_1 = require("../utils/array-utils");
var navigation_services_1 = require("../core/services/navigation-services");
function loadNextSurveys() {
    return {
        type: "load-next-surveys"
    };
}
exports.loadNextSurveys = loadNextSurveys;
function writeFormInput() {
    return {
        type: "ask-question"
    };
}
exports.writeFormInput = writeFormInput;
function writeSurvey() {
    return {
        type: "write-survey"
    };
}
exports.writeSurvey = writeSurvey;
function addSurveyChoice() {
    return {
        type: "add-survey-choice"
    };
}
exports.addSurveyChoice = addSurveyChoice;
function addSurveyLink() {
    return {
        type: "add-survey-link"
    };
}
exports.addSurveyLink = addSurveyLink;
function openSurveysModal() {
    return {
        type: "open-surveys-modal"
    };
}
exports.openSurveysModal = openSurveysModal;
function closeSurveysModal() {
    return {
        type: "close-surveys-modal"
    };
}
exports.closeSurveysModal = closeSurveysModal;
function addNewSurvey() {
    return {
        type: "add-new-survey"
    };
}
exports.addNewSurvey = addNewSurvey;
function deleteSurvey() {
    return {
        type: "delete-survey"
    };
}
exports.deleteSurvey = deleteSurvey;
function deleteNewChoice(newChoiceIndex) {
    return {
        type: "delete-new-choice",
        newChoiceIndex: newChoiceIndex
    };
}
exports.deleteNewChoice = deleteNewChoice;
function deleteNewLink(newLinkIndex) {
    return {
        type: "delete-new-link",
        newLinkIndex: newLinkIndex
    };
}
exports.deleteNewLink = deleteNewLink;
function surveyChoiceKind(kind) {
    return {
        type: "survey-choice-kind",
        kind: kind,
    };
}
exports.surveyChoiceKind = surveyChoiceKind;
exports.beginSurveysLoad = function () {
    var effects = [];
    effects = sequence_services_1.sequence(effects, loadSurveys());
    return effects;
};
function loadSurveys() {
    return json_resources_1.requestResourceFetch([exports.loadSurveysRequestName], routes_1.RapiV1SurveysPath);
}
exports.loadSurveys = loadSurveys;
exports.loadSurveyDetail = function (state, surveyId) {
    var effects = [];
    state = tslib_1.__assign({}, state);
    state.surveysPage = tslib_1.__assign({}, state.surveysPage);
    state.surveysPage.editingSurveyId = surveyId;
    state.surveysPage.isCreatingNewSurvey = false;
    effects = sequence_services_1.sequence(effects, loadQuestions(surveyId));
    return { state: state, effects: effects };
};
function loadQuestions(surveyId) {
    var query = {};
    query.filter = { survey_id: surveyId };
    query.include = ["survey", "room", "room.create_user"];
    return json_resources_1.requestResourceFetch([exports.loadQuestionsRequestName, surveyId], routes_1.RapiV1QuestionsPath, query);
}
exports.loadQuestions = loadQuestions;
function reduceSurveys(state, action) {
    var effects = [];
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.createSurveyRequestName) {
                var response = JSON.parse(action.response);
                state = tslib_1.__assign({}, state);
                state.surveysPage = tslib_1.__assign({}, state.surveysPage);
                state.surveysPage.isCreatingNewSurvey = false;
                state.surveysPage.editingSurveyId = response.data.id;
            }
            break;
        case "load-next-surveys":
            effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, array_utils_1.stringifyArray([exports.loadSurveysRequestName])));
            break;
        case "ask-question": {
            var question = newResource("questions");
            addRelationship(question, "room", { id: state.currentViewingRoomId, type: "rooms" });
            addRelationship(question, "survey", { id: state.inputs.surveysModalSurveySelectedId, type: "surveys" });
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.askSurveyRequestName], question, routes_1.RapiV1QuestionsPath));
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.surveysModalToggle = false;
            break;
        }
        case "write-survey":
            var survey = newResource("surveys");
            survey.id = state.surveysPage.editingSurveyId;
            survey.attributes = tslib_1.__assign({}, survey.attributes, { title: state.inputs.surveyTitle, description: state.inputs.surveyDescription, image_url: state.inputs.surveyImageUrl, image_alt: state.inputs.surveyImageAlt, choices: state.surveysPage.newChoices, links: state.surveysPage.newLinks, select_input: state.surveysPage.selectInputToggle });
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.createSurveyRequestName], survey, routes_1.RapiV1SurveysPath));
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.surveysPage = tslib_1.__assign({}, state.surveysPage);
            state.inputs.surveyTitle = '';
            state.inputs.surveyDescription = '';
            state.inputs.surveyImageUrl = '';
            state.inputs.surveyImageAlt = '';
            state.inputs.surveyChoiceKind = 'radio_choice';
            state.surveysPage.newChoices = [];
            state.surveysPage.newLinks = [];
            state.surveysPage.selectInputToggle = false;
            break;
        case "add-survey-choice":
            state = tslib_1.__assign({}, state);
            state.surveysPage = tslib_1.__assign({}, state.surveysPage);
            state.surveysPage.newChoices = state.surveysPage.newChoices.slice();
            state.surveysPage.newChoices.push({
                action: state.inputs.surveyChoiceAction,
                text: state.inputs.surveyChoiceText,
                kind: state.inputs.surveyChoiceKind,
            });
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.surveyChoiceAction = initialState.inputs.surveyChoiceAction;
            state.inputs.surveyChoiceText = initialState.inputs.surveyChoiceText;
            break;
        case "add-survey-link":
            state = tslib_1.__assign({}, state);
            state.surveysPage = tslib_1.__assign({}, state.surveysPage);
            state.surveysPage.newLinks = state.surveysPage.newLinks.slice();
            state.surveysPage.newLinks.push({
                url: state.inputs.surveyLinkUrl,
                display_message: state.inputs.surveyLinkDisplayMessage,
            });
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.surveyLinkUrl = initialState.inputs.surveyLinkUrl;
            state.inputs.surveyLinkDisplayMessage = initialState.inputs.surveyLinkDisplayMessage;
            break;
        case "open-surveys-modal":
            effects = sequence_services_1.sequence(effects, loadSurveys());
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.surveysModalToggle = true;
            break;
        case "close-surveys-modal":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.toggles.surveysModalToggle = false;
            state.inputs.surveysModalSearch = "";
            break;
        case "add-new-survey":
            state = tslib_1.__assign({}, state);
            state.surveysPage = tslib_1.__assign({}, state.surveysPage);
            state.surveysPage.editingSurveyId = "";
            state.surveysPage.isCreatingNewSurvey = true;
            effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({ pathname: '/surveys' }));
            break;
        case "delete-survey":
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceDeleteByUrl([exports.deleteSurveyRequestName], routes_1.RapiV1SurveysPath + "/" + state.surveysPage.editingSurveyId));
            state = tslib_1.__assign({}, state);
            state.surveysPage = tslib_1.__assign({}, state.surveysPage);
            state.surveysPage.editingSurveyId = "";
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.showSurveysDeleteConfirmModal = false;
            effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({ pathname: "/surveys" }));
            break;
        case "delete-new-choice":
            state = tslib_1.__assign({}, state);
            state.surveysPage = tslib_1.__assign({}, state.surveysPage);
            state.surveysPage.newChoices = state.surveysPage.newChoices.slice(0, action.newChoiceIndex).concat(state.surveysPage.newChoices.slice(action.newChoiceIndex + 1));
            break;
        case "delete-new-link":
            state = tslib_1.__assign({}, state);
            state.surveysPage = tslib_1.__assign({}, state.surveysPage);
            state.surveysPage.newLinks = state.surveysPage.newLinks.slice(0, action.newLinkIndex).concat(state.surveysPage.newLinks.slice(action.newLinkIndex + 1));
            break;
        case "survey-choice-kind":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.surveysPage = tslib_1.__assign({}, state.surveysPage);
            state.inputs.surveyChoiceKind = action.kind;
            if (action.kind === "select_input") {
                state.surveysPage.selectInputToggle = true;
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceSurveys = reduceSurveys;
exports.askSurveyRequestName = "ask-question-request-name";
exports.createSurveyRequestName = "create-survey-request-name";
exports.deleteSurveyRequestName = "delete-survey-request-name";
exports.loadSurveysRequestName = "load-surveys-request-name";
exports.loadQuestionsRequestName = "load-questions-request-name";
exports.surveysPagedRequestNames = [exports.loadSurveysRequestName, exports.loadQuestionsRequestName];
