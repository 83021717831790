"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var json_resources_1 = require("../core/json-resources");
var resource_api_1 = require("../schemas/resource-api");
var newResource = resource_api_1.ResourceApi.newResource;
var addRelationship = resource_api_1.ResourceApi.addRelationship;
var routes_1 = require("../routes");
var chat_history_reducer_1 = require("./chat-history-reducer");
var toast_reducer_1 = require("./toast-reducer");
var I18n_1 = require("../I18n");
var ajax_services_1 = require("../core/services/ajax-services");
var sequence_services_1 = require("../core/services/sequence-services");
var action_cable_services_1 = require("../core/services/action-cable-services");
var last_read_at_reducer_1 = require("./last-read-at-reducer");
var bisect_1 = require("../utils/bisect");
function openPopupRoom(roomId) {
    return {
        type: "open-popup-room",
        roomId: roomId
    };
}
exports.openPopupRoom = openPopupRoom;
function togglePopupRoom(roomId) {
    return {
        type: "toggle-popup-room",
        roomId: roomId
    };
}
exports.togglePopupRoom = togglePopupRoom;
function clickMemberUserBubble(roomId, userId) {
    return {
        type: "click-member-user-bubble",
        roomId: roomId,
        userId: userId
    };
}
exports.clickMemberUserBubble = clickMemberUserBubble;
function deleteMemberFromChat() {
    return {
        type: "delete-member-from-chat"
    };
}
exports.deleteMemberFromChat = deleteMemberFromChat;
function createPrivateRoom(userId) {
    return {
        type: "create-private-room",
        userId: userId
    };
}
exports.createPrivateRoom = createPrivateRoom;
function createGroupRoom(supportRoomId) {
    return {
        type: "create-group-room",
        supportRoomId: supportRoomId
    };
}
exports.createGroupRoom = createGroupRoom;
function addMemberToGroupRoom(userId) {
    return {
        type: "add-member-to-group-room",
        userId: userId
    };
}
exports.addMemberToGroupRoom = addMemberToGroupRoom;
function showGroupRoomAddMemberModal(roomId) {
    return {
        type: "show-group-room-add-member-modal",
        roomId: roomId
    };
}
exports.showGroupRoomAddMemberModal = showGroupRoomAddMemberModal;
function closePopupRoom(roomId) {
    return {
        type: "close-popup-room",
        roomId: roomId
    };
}
exports.closePopupRoom = closePopupRoom;
exports.createOrFindPrivateRoomRequestName = "create-or-find-private-room-loading";
exports.createOrFindGroupRoomRequestName = "create-or-find-group-room-loading";
exports.roomMembersRequestname = "room-members";
exports.addMemberRequestName = "add-member";
exports.removeMemberFromRoomRequestName = "remove-member-from-room";
function createOrFindPrivateRoom(otherUserId) {
    var ajaxConfig = {};
    ajaxConfig.headers = json_resources_1.jsonResourceHeaders;
    ajaxConfig.url = "/rapi/v1/rooms/create_or_find_direct_room?user_id=" + otherUserId;
    ajaxConfig.method = "POST";
    return ajax_services_1.requestAjax([exports.createOrFindPrivateRoomRequestName, otherUserId], ajaxConfig);
}
exports.createOrFindPrivateRoom = createOrFindPrivateRoom;
function createOrFindGroupRoom(supportRoomId) {
    var ajaxConfig = {};
    ajaxConfig.headers = json_resources_1.jsonResourceHeaders;
    ajaxConfig.url = "/rapi/v1/rooms/create_or_find_group_room";
    ajaxConfig.method = "POST";
    ajaxConfig.json = { support_room_id: supportRoomId };
    return ajax_services_1.requestAjax([exports.createOrFindGroupRoomRequestName, supportRoomId], ajaxConfig);
}
exports.createOrFindGroupRoom = createOrFindGroupRoom;
function createMemberFor(userId, roomId) {
    var member = newResource("members");
    addRelationship(member, "user", { type: "users", id: userId });
    addRelationship(member, "room", { type: "rooms", id: roomId });
    return json_resources_1.requestResourceWrite([exports.addMemberRequestName, userId, roomId], member, routes_1.RapiV1MembersPath);
}
exports.createMemberFor = createMemberFor;
function requestRoomMembers(roomId) {
    var query = {};
    query.include = ["user"];
    query.sort = ["-last_read_at"];
    return json_resources_1.requestResourceFetch([exports.roomMembersRequestname, roomId], "/rapi/v1/rooms/" + roomId + "/members");
}
exports.requestRoomMembers = requestRoomMembers;
function requestRemoveMemberFromRoom(memberId) {
    var ajaxConfig = {};
    ajaxConfig.headers = json_resources_1.jsonResourceHeaders;
    ajaxConfig.url = "/rapi/v1/members/" + memberId;
    ajaxConfig.method = "DELETE";
    return ajax_services_1.requestAjax([exports.removeMemberFromRoomRequestName], ajaxConfig);
}
exports.requestRemoveMemberFromRoom = requestRemoveMemberFromRoom;
function reducePopupMessages(state, action) {
    var _a, _b, _c, _d, _e;
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    switch (action.type) {
        case "complete-request":
            if (action.name[0] == exports.createOrFindPrivateRoomRequestName || action.name[0] == exports.createOrFindGroupRoomRequestName) {
                var details = json_resources_1.getResourceResponseDetails(action);
                if (details.resources) {
                    if (details.resources[0].type !== "rooms")
                        throw new Error("Expected find or create room to return a room!  Got a " + details.resources[0].type + " instead!");
                    var roomId = details.resources[0].id;
                    (_a = sequence_services_1.sequenceReduction(effects, addPopupRoom(state, roomId)), state = _a.state, effects = _a.effects);
                }
            }
            break;
        case "toggle-popup-room":
            (_b = sequence_services_1.sequenceReduction(effects, popupRoomMaximizedToggle(action.roomId, state)), state = _b.state, effects = _b.effects);
            break;
        case "show-group-room-add-member-modal":
            state = tslib_1.__assign({}, state);
            state.addingMemberToRoomId = action.roomId;
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.showAddMemberModal = true;
            break;
        case "add-member-to-group-room":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.showAddMemberModal = false;
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.addMemberFilter = "";
            effects = sequence_services_1.sequence(effects, createMemberFor(action.userId, state.addingMemberToRoomId));
            (_c = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastNewMemberAddedToHelpRoom[language])), state = _c.state, effects = _c.effects);
            break;
        case "close-popup-room":
            state = tslib_1.__assign({}, state);
            state.popupRooms = state.popupRooms.filter(function (pr) { return pr.roomId !== action.roomId; });
            state.focusedRoomId = "";
            break;
        case "create-private-room":
            effects = sequence_services_1.sequence(effects, createOrFindPrivateRoom(action.userId));
            break;
        case "create-group-room":
            effects = sequence_services_1.sequence(effects, createOrFindGroupRoom(action.supportRoomId));
            break;
        case "click-member-user-bubble":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.deleteMemberRoomId = action.roomId;
            state.inputs.deleteMemberUserId = action.userId;
            var member = bisect_1.Indexer.getFirstMatching(state.stores.members.byRoomIdAndUserId, [action.roomId, action.userId]);
            if (member) {
                state.inputs.deleteMemberId = member.id;
            }
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.showDeleteMemberFromChatModal = true;
            break;
        case "delete-member-from-chat":
            state = tslib_1.__assign({}, state);
            effects = sequence_services_1.sequence(effects, requestRemoveMemberFromRoom(state.inputs.deleteMemberId));
            state.toggles.showDeleteMemberFromChatModal = false;
            state.inputs.deleteMemberRoomId = "";
            state.inputs.deleteMemberUserId = "";
            state.inputs.deleteMemberId = "";
            break;
        case "open-popup-room":
            var popupRoomIsRendered = !!state.popupRooms.filter(function (pr) { return pr && pr.roomId === action.roomId && pr.isMaximized; }).length;
            if (popupRoomIsRendered) {
                (_d = sequence_services_1.sequenceReduction(effects, popupRoomMaximizedToggle(action.roomId, state, true)), state = _d.state, effects = _d.effects);
            }
            else {
                (_e = sequence_services_1.sequenceReduction(effects, addPopupRoom(state, action.roomId)), state = _e.state, effects = _e.effects);
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reducePopupMessages = reducePopupMessages;
function popupRoomMaximizedToggle(roomId, state, maximize) {
    if (maximize === void 0) { maximize = undefined; }
    var _a;
    var effects = [];
    for (var i = 0; i < state.popupRooms.length; ++i) {
        if (state.popupRooms[i].roomId === roomId) {
            state = tslib_1.__assign({}, state);
            state.popupRooms = state.popupRooms.slice();
            state.popupRooms[i] = tslib_1.__assign({}, state.popupRooms[i]);
            state.popupRooms[i].isMaximized = maximize != null ? maximize : !state.popupRooms[i].isMaximized;
            state.focusedRoomId = state.popupRooms[i].isMaximized ? roomId : "";
            (_a = sequence_services_1.sequenceReduction(effects, last_read_at_reducer_1.tryToMarkReadOperator(state, roomId)), state = _a.state, effects = _a.effects);
            break;
        }
    }
    return { state: state, effects: effects };
}
exports.popupRoomMaximizedToggle = popupRoomMaximizedToggle;
var PrivateRoomWidth = 220;
function addPopupRoom(state, roomId) {
    var _a;
    var effects = [];
    if (state.popupRooms.filter(function (x) { return x.roomId == roomId; }).length == 0) {
        var popupRoom = {};
        popupRoom.roomId = roomId;
        popupRoom.isMaximized = true;
        state = tslib_1.__assign({}, state);
        state.focusedRoomId = roomId;
        state.popupRooms = state.popupRooms.slice();
        if ((state.popupRooms.length + 1) * PrivateRoomWidth > state.sizes.windowSize[0]) {
            if (state.popupRooms[0].roomId === roomId) {
                state.popupRooms.splice(1, 1);
            }
            else {
                state.popupRooms.shift();
            }
        }
        state.popupRooms.push(popupRoom);
        effects = sequence_services_1.sequence(effects, chat_history_reducer_1.requestChatHistory(roomId));
        effects = sequence_services_1.sequence(effects, requestRoomMembers(roomId));
        (_a = sequence_services_1.sequenceReduction(effects, last_read_at_reducer_1.tryToMarkReadOperator(state, roomId)), state = _a.state, effects = _a.effects);
        effects = sequence_services_1.sequence(effects, action_cable_services_1.actionCableSendMessage("MemberChannel", "follow_room", { room_id: roomId }));
    }
    return { state: state, effects: effects };
}
exports.addPopupRoom = addPopupRoom;
