"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var I18n_1 = require("../I18n");
var tracker_events_1 = require("../core/tracker-events");
function VideoTagMessageContent(props) {
    var video = props.message.attributes.settings;
    if (!video)
        return null;
    var dataActionId = null;
    if (props.message && props.message.id && props.message.id !== null && props.message.id !== "") {
        dataActionId = "oksky-video-tag-ms-" + props.message.id;
    }
    return React.createElement("div", { className: "relative" },
        React.createElement("video", { controls: true, autoPlay: false, muted: true, playsInline: true, preload: "none", poster: video.thumbnail_url || undefined, controlsList: "nodownload", width: "100%", "data-video-tag-action-id": dataActionId, onPlay: tracker_events_1.videoPlayHandler, onPause: tracker_events_1.videoPauseHandler, onEnded: tracker_events_1.videoEndedHandler, onError: tracker_events_1.videoOnerrorHandler },
            React.createElement("source", { src: video.src_url, type: video.type }),
            I18n_1.i18n.clientsAutoPostMessageKindsVideoTagNotFound[props.language]));
}
exports.VideoTagMessageContent = VideoTagMessageContent;
