"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var widget_button_1 = require("../widget-v2/views/widget-button");
var react_dom_1 = require("react-dom");
var style_utils_1 = require("../utils/style-utils");
var widget_state_1 = require("../widget-v2/reducers/widget-state");
var initialState = widget_state_1.WidgetV2State.initialState;
var widget_close_button_1 = require("../widget-v2/views/widget-close-button");
var Z_MAX = 2147483645;
var WIDGET_CANVAS_BOTTOM_OFFSET_PX = 100;
var WidgetSpace = (function (_super) {
    tslib_1.__extends(WidgetSpace, _super);
    function WidgetSpace() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.windowWidth = window.innerWidth;
        _this.windowHeight = window.innerHeight;
        _this.setReferences = function () {
            _this.bodyRef = _this.iFrameRef && _this.iFrameRef.contentDocument && _this.iFrameRef.contentDocument.body;
            _this.headRef = _this.iFrameRef && _this.iFrameRef.contentDocument && _this.iFrameRef.contentDocument.head;
            _this.htmlRef = _this.iFrameRef && _this.iFrameRef.contentDocument && _this.iFrameRef.contentDocument.body.parentElement;
            style_utils_1.appendOKSKYStyleToHead(_this.headRef);
            style_utils_1.setOKSKYStyleClassToRoot(_this.htmlRef);
            if (_this.props.isButtonHide && (_this.props.widgetButtonMode || _this.props.closeButtonMode)) {
                if (_this.htmlRef)
                    _this.htmlRef.style.height = "0%";
                if (_this.bodyRef)
                    _this.bodyRef.className = "dn";
            }
            else {
                if (_this.htmlRef) {
                    _this.htmlRef.style.height = "100%";
                }
                if (_this.bodyRef) {
                    _this.bodyRef.className = "h-100 overflow-hidden";
                }
            }
            _this.forceUpdate();
        };
        _this.chooseStyleOrMedia = function () {
            if (_this.windowWidth >= initialState.widgetScreenMinWidthPx && _this.windowHeight >= initialState.widgetScreenMinHeightPx) {
                return tslib_1.__assign({}, _this.style, _this.nsMediaStyle);
            }
            else {
                return _this.style;
            }
        };
        _this.initializeStylesForWidgetSpace = function () {
            _this.style = {};
            _this.nsMediaStyle = {};
            if (_this.props.providerLoginMode) {
                _this.style.position = "fixed";
                _this.style.width = "100%";
                _this.style.height = "100%";
                _this.style.top = "0";
                _this.style.bottom = "0";
                _this.style.left = "0";
                _this.style.right = "0";
            }
            else {
                _this.style.position = "fixed";
                _this.nsMediaStyle.width = '370px';
                if (_this.props.maximized) {
                    _this.style.boxShadow = '0px 0px 25px 0px rgba(0, 0, 0, .13), 0px 0px 25px 0px rgba(0, 0, 0, .13)';
                    _this.nsMediaStyle.borderRadius = '10px';
                    _this.style.width = '100%';
                    _this.style.height = '100%';
                    _this.nsMediaStyle.height = '590px';
                    _this.nsMediaStyle.maxHeight = '80vh';
                }
                else {
                    _this.style.maxHeight = '590px';
                    if (_this.props.closeButtonMode) {
                        _this.nsMediaStyle.width = '16px';
                        _this.nsMediaStyle.height = '16px';
                        _this.style.width = "6%";
                        _this.style.height = "6%";
                    }
                }
            }
        };
        _this.initialize = function () {
            _this.id = "";
            if (_this.props.widgetCanvasMode) {
                _this.initializeStylesForWidgetSpace();
                _this.style = tslib_1.__assign({}, _this.style, WidgetSpace.stylesForWidgetPositions("canvas", _this.props.widgetPosition));
                _this.nsMediaStyle = tslib_1.__assign({}, _this.nsMediaStyle, WidgetSpace.stylesForWidgetPositions("canvas", _this.props.widgetPosition, true));
                _this.id = "widget-canvas-iframe";
            }
            else if (_this.props.popupMessagesMode) {
                _this.initializeStylesForWidgetSpace();
                _this.style = tslib_1.__assign({}, _this.style, WidgetSpace.stylesForWidgetPositions("popup", _this.props.widgetPosition));
                _this.id = "popup-messages-iframe";
            }
            else if (_this.props.widgetButtonMode) {
                _this.style = tslib_1.__assign({}, widget_button_1.WidgetButton.style, WidgetSpace.stylesForWidgetPositions("button", _this.props.widgetPosition), { borderRadius: 0 });
                var buttonImageStyles = _this.props.buttonImageStyles;
                if (buttonImageStyles && buttonImageStyles.imageCustomFlg) {
                    _this.style.width = (buttonImageStyles.imageWidth || 60) + 'px';
                    _this.style.height = (buttonImageStyles.imageHeight || 60) + 'px';
                }
                _this.id = "widget-button-iframe";
            }
            else if (_this.props.closeButtonMode) {
                _this.initializeStylesForWidgetSpace();
                _this.style = tslib_1.__assign({}, widget_close_button_1.WidgetCloseButton.style, WidgetSpace.stylesForWidgetPositions("hide_button", _this.props.widgetPosition), { borderRadius: 0 });
                var buttonImageStyles = _this.props.buttonImageStyles;
                if (buttonImageStyles && buttonImageStyles.imageCustomFlg) {
                    var height = buttonImageStyles.imageHeight;
                    height = height + 20;
                    _this.style.bottom = height + 'px';
                }
                _this.id = "widget-close-button-iframe";
            }
        };
        return _this;
    }
    WidgetSpace.prototype.render = function () {
        var _this = this;
        if (this.props.closeButtonMode || this.props.widgetButtonMode) {
            this.initialize();
        }
        return React.createElement("iframe", { srcDoc: "<!DOCTYPE html>", frameBorder: 0, ref: function (iFrameRef) { return _this.iFrameRef = iFrameRef; }, id: this.id, style: this.chooseStyleOrMedia() },
            this.headRef &&
                react_dom_1.createPortal(React.createElement("meta", { name: "viewport", content: "width=device-width,user-scalable=0" }), this.headRef),
            this.bodyRef && this.props.children &&
                react_dom_1.createPortal(this.props.children, this.bodyRef));
    };
    WidgetSpace.prototype.componentDidMount = function () {
        var _this = this;
        this.initialize();
        window.addEventListener('resize', function () {
            _this.windowWidth = window.innerWidth;
            _this.windowHeight = window.innerHeight;
        });
        this.iFrameRef && this.iFrameRef.addEventListener("load", this.setReferences);
    };
    WidgetSpace.prototype.componentWillUnmount = function () {
        this.iFrameRef && this.iFrameRef.removeEventListener("load", this.setReferences);
    };
    WidgetSpace.prototype.componentDidUpdate = function () {
        if (this.props.popupMessagesMode && this.iFrameRef) {
            if (this.windowWidth < initialState.widgetPopupMinHeightPx || this.windowHeight < initialState.widgetPopupMinHeightPx) {
                this.iFrameRef.style.display = "none";
            }
            else if (this.props.children) {
                this.iFrameRef.style.display = "inherit";
                var container = this.iFrameRef.contentDocument.querySelector('#popup-messages-chat-scroll');
                this.contentRef = this.iFrameRef.contentDocument.querySelector('.test--popup-box');
                if (container && this.contentRef) {
                    if (container.scrollHeight > 0) {
                        this.iFrameRef.style.height = (this.contentRef.clientHeight - container.clientHeight + container.scrollHeight) + 'px';
                    }
                    if (this.contentRef.clientHeight >= this.windowHeight - WIDGET_CANVAS_BOTTOM_OFFSET_PX) {
                        this.iFrameRef.style.height = (this.windowHeight - WIDGET_CANVAS_BOTTOM_OFFSET_PX) + 'px';
                    }
                }
            }
        }
    };
    WidgetSpace.stylesForWidgetPositions = function (widgetMode, widgetPosition, nsMediaQuery) {
        var style = {};
        switch (widgetMode) {
            case "canvas":
                style.zIndex = Z_MAX;
                if (nsMediaQuery) {
                    style.bottom = '100px';
                    style.left = '40px';
                    style.right = '40px';
                }
                else {
                    style.bottom = '0';
                    style.left = '0';
                    style.right = '0';
                }
                break;
            case "popup":
                style.zIndex = Z_MAX - 1;
                style.bottom = '100px';
                style.left = '40px';
                style.right = '40px';
                break;
            case "button":
                style.zIndex = Z_MAX - 1;
                style.bottom = '20px';
                style.left = '20px';
                style.right = '20px';
                break;
            case "hide_button":
                style.zIndex = Z_MAX - 1;
                style.bottom = '80px';
                style.right = '15px';
                break;
        }
        switch (widgetPosition) {
            case "bottom_left":
                return { zIndex: style.zIndex, bottom: style.bottom, left: style.left };
            case "bottom_right":
            default:
                return { zIndex: style.zIndex, bottom: style.bottom, right: style.right };
        }
    };
    return WidgetSpace;
}(React.Component));
exports.WidgetSpace = WidgetSpace;
