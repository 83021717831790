"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var ExportsStore;
(function (ExportsStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    ExportsStore.indexer = new bisect_1.Indexer("byId");
    ExportsStore.indexer.addIndex("byId", function (report) { return [assertInnerId(report)]; });
})(ExportsStore = exports.ExportsStore || (exports.ExportsStore = {}));
