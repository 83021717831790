"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
exports.isEdgeOfMessageRecordRanges = function (state, type, roomId, createdAtUnix) {
    var recordRange = getRecordRangesByRoomId(state, roomId);
    if (recordRange) {
        return recordRange.ranges.some(function (range) {
            if (type === "from") {
                if (range.from === createdAtUnix) {
                    return true;
                }
            }
            else if (type === "to") {
                if (range.to === createdAtUnix) {
                    var determinedRanges_1 = [];
                    recordRange.ranges.filter(function (range) {
                        determinedRanges_1.push({ detectedRange: { from: range.from, to: range.to } });
                    });
                    var newestRangeItem = getUnixtimeFromDeterminedRanges("newest", determinedRanges_1);
                    if (range.to === newestRangeItem) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
            }
        });
    }
    else {
        return false;
    }
};
exports.isCreatedAtUnixOldest = function (state, roomId, createdAtUnix) {
    var recordPosition = getRecordPositionByRoomId(state, roomId);
    if (recordPosition) {
        if (recordPosition.createdAtUnix === createdAtUnix && recordPosition.position === "oldest") {
            return true;
        }
        return false;
    }
};
exports.recordRangeMessages = function (state, resources) {
    var roomIds = uniqueArray(pluckRoomIds(resources));
    if (roomIds.length === 1 && roomIds[0] === "0") {
        return state;
    }
    state.recordRanges = tslib_1.__assign({}, state.recordRanges);
    for (var r = 0; r < roomIds.length; r++) {
        var roomId = roomIds[r];
        if (roomId) {
            var messages = getMessagesByRoomId(resources, roomId);
            var recordRange = getRecordRangesByRoomId(state, roomId);
            var newRange = createNewRangeFromMessages(messages);
            if (newRange.position) {
                var newPosition = {
                    "roomId": roomId,
                    "createdAtUnix": newRange.from,
                    "position": newRange.position
                };
                if (!isObjectContainedInArray(state.recordPositions.messages, newPosition)) {
                    state.recordPositions.messages.push(newPosition);
                }
                newRange = { from: newRange.from, to: newRange.to };
            }
            if (!recordRange) {
                state.recordRanges.messages.push({
                    "id": roomId,
                    "ranges": [newRange]
                });
            }
            else {
                if (!isSameRuleExist(recordRange, newRange) && isRangeSingle(newRange) && isNewSingleRangeIsNewest(recordRange, newRange)) {
                    recordRange.ranges = combineNewSingleRangeForNewestRange(recordRange, newRange);
                }
                if (!isSameRuleExist(recordRange, newRange) && !isRangeSingle(newRange) && isNewRangeSpanMultipleRanges(recordRange, newRange)) {
                    recordRange.ranges = mergeAndCombineExistRange(recordRange, newRange);
                }
                if ((!isSameRuleExist(recordRange, newRange) && !isRangeSingle(newRange)) ||
                    (!isSameRuleExist(recordRange, newRange) && isRangeSingle(newRange) && !isSingleRangeIsAPartOfCoupleRanges(recordRange, newRange)) ||
                    (!isRangeSingle(newRange) && isBorderExist(recordRange, newRange))) {
                    recordRange.ranges = mergeWithExistRange(recordRange, newRange);
                }
                state = mergeWithOtherRecordRanges(state, recordRange, roomId);
            }
        }
    }
    return state;
};
function isNewSingleRangeIsNewest(recordRange, newRange) {
    var determinedRanges = [];
    recordRange.ranges.filter(function (range) {
        determinedRanges.push({ detectedRange: { from: range.from, to: range.to } });
    });
    var newestRangeItem = getUnixtimeFromDeterminedRanges("newest", determinedRanges);
    if (newestRangeItem < newRange.from && newestRangeItem < newRange.to) {
        return true;
    }
    else {
        return false;
    }
}
function combineNewSingleRangeForNewestRange(recordRange, newRange) {
    var determinedRanges = [];
    recordRange.ranges.filter(function (range) {
        determinedRanges.push({ detectedRange: { from: range.from, to: range.to } });
    });
    var newestCoupleRange = getRangeFromDeterminedRanges("newest", determinedRanges);
    var newRanges = [];
    recordRange.ranges.filter(function (range) {
        if (JSON.stringify(range) === JSON.stringify(newestCoupleRange)) {
            newRanges.push({ from: range.from, to: newRange.to });
        }
        else {
            newRanges.push(range);
        }
    });
    return newRanges;
}
function isNewRangeSpanMultipleRanges(recordRange, newRange) {
    var determinedRanges = determineExistRangesCanCombineByNewRange(recordRange, newRange);
    if (determinedRanges.length === 2) {
        return true;
    }
    else {
        return false;
    }
}
function determineExistRangesCanCombineByNewRange(recordRange, newRange) {
    var results = [];
    recordRange.ranges.filter(function (range) {
        if (range.from <= newRange.from && range.to >= newRange.to) {
            results.push({
                detectedRange: range,
                spanFrom: true,
                spanTo: true,
                canDelete: true
            });
        }
        else if (range.from <= newRange.from && range.to >= newRange.from) {
            if (isRangeSingle(range)) {
                results.push({
                    detectedRange: range,
                    spanFrom: false,
                    spanTo: false,
                    canDelete: true
                });
            }
            else {
                results.push({
                    detectedRange: range,
                    spanFrom: true,
                    spanTo: false,
                    canDelete: true
                });
            }
        }
        else if (range.from <= newRange.to && range.to >= newRange.to) {
            if (isRangeSingle(range)) {
                results.push({
                    detectedRange: range,
                    spanFrom: false,
                    spanTo: false,
                    canDelete: true
                });
            }
            else {
                results.push({
                    detectedRange: range,
                    spanFrom: false,
                    spanTo: true,
                    canDelete: true
                });
            }
        }
    });
    return results;
}
function mergeAndCombineExistRange(recordRange, newRange) {
    var determinedRanges = determineExistRangesCanCombineByNewRange(recordRange, newRange);
    var rangeFrom = null;
    var rangeTo = null;
    determinedRanges.forEach(function (determinedRange) {
        if (rangeFrom === null || rangeTo === null) {
            if (determinedRange.spanFrom && determinedRange.spanTo) {
                rangeFrom = determinedRange.detectedRange.from < newRange.From ? determinedRange.detectedRange.from : newRange.from;
                rangeTo = determinedRange.detectedRange.to > newRange.From ? determinedRange.detectedRange.to : newRange.to;
            }
            else if (determinedRange.spanFrom && !determinedRange.spanTo) {
                rangeFrom = determinedRange.detectedRange.from;
            }
            else if (!determinedRange.spanFrom && determinedRange.spanTo) {
                rangeTo = determinedRange.detectedRange.to;
            }
        }
    });
    determinedRanges.forEach(function (dR) {
        if (dR.canDelete) {
            recordRange.ranges = deleteTargetValueFromHash(recordRange.ranges, dR.detectedRange);
        }
    });
    if (rangeFrom === null) {
        rangeFrom = getUnixtimeFromDeterminedRanges("oldest", determinedRanges);
    }
    if (rangeTo === null) {
        rangeTo = getUnixtimeFromDeterminedRanges("newest", determinedRanges);
    }
    if (rangeFrom > rangeTo)
        rangeTo = [rangeFrom, rangeFrom = rangeTo][0];
    if (rangeFrom > newRange.from)
        rangeFrom = newRange.from;
    if (rangeTo < newRange.to)
        rangeTo = newRange.to;
    recordRange.ranges.push({ from: rangeFrom, to: rangeTo });
    return recordRange.ranges;
}
function getUnixtimeFromDeterminedRanges(pattern, determinedRanges) {
    var unixtimes = [];
    determinedRanges.filter(function (determinedRange) {
        if (unixtimes.indexOf(determinedRange.detectedRange.from) === -1) {
            unixtimes.push(determinedRange.detectedRange.from);
        }
        if (unixtimes.indexOf(determinedRange.detectedRange.to) === -1) {
            unixtimes.push(determinedRange.detectedRange.to);
        }
    });
    unixtimes = unixtimes.sort(function (a, b) {
        if (a < b)
            return -1;
        if (a > b)
            return 1;
        return 0;
    });
    if (unixtimes.length === 0) {
        return null;
    }
    else {
        if (pattern === "oldest") {
            return unixtimes[0];
        }
        else if (pattern === "newest") {
            return unixtimes[unixtimes.length - 1];
        }
    }
}
function getRangeFromDeterminedRanges(pattern, determinedRanges) {
    var ranges = [];
    determinedRanges.filter(function (determinedRange) {
        if (ranges.indexOf(JSON.stringify(determinedRange.detectedRange)) === -1) {
            ranges.push(determinedRange.detectedRange);
        }
    });
    ranges = ranges.sort(function (a, b) {
        if (a.to < b.to)
            return -1;
        if (a.to > b.to)
            return 1;
        return 0;
    });
    if (ranges.length === 0) {
        return null;
    }
    else {
        if (pattern === "oldest") {
            return ranges[0];
        }
        else if (pattern === "newest") {
            return ranges[ranges.length - 1];
        }
    }
}
function isSameRuleExist(recordRange, newRange) {
    return recordRange.ranges.some(function (range) {
        if (JSON.stringify(range) === JSON.stringify(newRange)) {
            return true;
        }
    });
}
function isSingleRangeIsAPartOfCoupleRanges(recordRange, newRange) {
    if (!recordRange) {
        return false;
    }
    else {
        var coupleRanges = splitRangesForCouple(recordRange);
        return coupleRanges.some(function (coupleRange) {
            if (newRange.from >= coupleRange.from && coupleRange.to >= newRange.to) {
                return true;
            }
        });
    }
}
function isBorderExist(recordRange, newRange) {
    return recordRange.ranges.some(function (range) {
        if (range.to === newRange.from ||
            newRange.to === range.from ||
            range.from === newRange.from ||
            range.to === newRange.to) {
            return true;
        }
    });
}
function mergeWithOtherRecordRanges(state, recordRange, roomId) {
    var otherRecordRanges = state.recordRanges.messages.filter(function (otherRecordRange) {
        return otherRecordRange.id !== roomId;
    });
    state.recordRanges.messages = otherRecordRanges.concat(recordRange);
    return state;
}
function mergeWithExistRange(recordRange, newRange) {
    var singleRanges = splitRangesForSingle(recordRange);
    var coupleRanges = splitRangesForCouple(recordRange);
    if (isRangeSingle(newRange)) {
        if (!isSingleRangeIsAPartOfCoupleRanges(coupleRanges, newRange)) {
            singleRanges.push(newRange);
        }
    }
    else {
        var determinedSingleRanges = determineExistSingleRangesIsIncludedInNewRange(singleRanges, newRange);
        determinedSingleRanges.forEach(function (dSR) {
            singleRanges = deleteTargetValueFromHash(singleRanges, dSR.detectedSingleRange);
            if (!isObjectContainedInArray(coupleRanges, dSR.newRange)) {
                coupleRanges.push(dSR.newRange);
            }
        });
        var determinedCoupleRanges = determineExistCoupleRangesIsIncludedInNewRange(coupleRanges, newRange);
        determinedCoupleRanges.forEach(function (dCR) {
            if ((dCR.connecter === "from" && dCR.connectee === "to") ||
                (dCR.connecter === "to" && dCR.connectee === "to")) {
                var newCoupleRange = { from: dCR.newRange.from, to: dCR.detectedCoupleRange.to };
                coupleRanges = deleteTargetValueFromHash(coupleRanges, dCR.detectedCoupleRange);
                if (!isObjectContainedInArray(coupleRanges, dCR.newRange)) {
                    coupleRanges.push(newCoupleRange);
                }
            }
            if ((dCR.connecter === "to" && dCR.connectee === "from") ||
                (dCR.connecter === "from" && dCR.connectee === "from")) {
                var newCoupleRange = { from: dCR.detectedCoupleRange.from, to: dCR.newRange.to };
                coupleRanges = deleteTargetValueFromHash(coupleRanges, dCR.detectedCoupleRange);
                if (!isObjectContainedInArray(coupleRanges, dCR.newRange)) {
                    coupleRanges.push(newCoupleRange);
                }
            }
        });
    }
    return singleRanges.concat(coupleRanges);
}
function determineExistSingleRangesIsIncludedInNewRange(singleRanges, newRange) {
    var result = [];
    singleRanges.filter(function (singleRange) {
        if (singleRange.from === newRange.from) {
            result.push({ newRange: newRange, detectedSingleRange: singleRange });
        }
        else if (singleRange.from === newRange.to) {
            result.push({ newRange: newRange, detectedSingleRange: singleRange });
        }
        else if (singleRange.from >= newRange.from && singleRange.to <= newRange.to) {
            result.push({ newRange: newRange, detectedSingleRange: singleRange });
        }
    });
    return result;
}
function determineExistCoupleRangesIsIncludedInNewRange(coupleRanges, newRange) {
    var result = [];
    coupleRanges.filter(function (coupleRange) {
        if (coupleRange.from === newRange.to) {
            result.push({ newRange: newRange, detectedCoupleRange: coupleRange, connecter: "from", connectee: "to" });
        }
        else if (coupleRange.to === newRange.from) {
            result.push({ newRange: newRange, detectedCoupleRange: coupleRange, connecter: "to", connectee: "from" });
        }
        else if (coupleRange.from === newRange.from && coupleRange.to < newRange.to) {
            result.push({ newRange: newRange, detectedCoupleRange: coupleRange, connecter: "from", connectee: "from" });
        }
        else if (coupleRange.to === newRange.to && coupleRange.from > newRange.from) {
            result.push({ newRange: newRange, detectedCoupleRange: coupleRange, connecter: "to", connectee: "to" });
        }
    });
    return result;
}
function deleteTargetValueFromHash(object, targetValue) {
    return object.filter(function (element, index, array) {
        return JSON.stringify(element) !== JSON.stringify(targetValue);
    });
}
function pluckRoomIds(resources) {
    var roomIds = [];
    for (var r = 0; r < resources.length; r++) {
        if (resources[r].relationships && resources[r].relationships.room && resources[r].relationships.room.data) {
            roomIds.push(resources[r].relationships.room.data.id);
        }
        else if (resources[r].attributes.room_id) {
            roomIds.push(resources[r].attributes.room_id);
        }
    }
    return roomIds;
}
function getMessagesByRoomId(resources, roomId) {
    return resources.filter(function (resource) {
        return resource.relationships.room.data.id == roomId;
    });
}
function getRecordRangesByRoomId(state, roomId) {
    var result = state.recordRanges.messages.filter(function (recordRange) {
        return recordRange.id === roomId;
    });
    if (result.length === 0) {
        return null;
    }
    else {
        return result[0];
    }
}
function getRecordPositionByRoomId(state, roomId) {
    var result = state.recordPositions.messages.filter(function (recordPosition) {
        return recordPosition.roomId === roomId;
    });
    if (result.length === 0) {
        return null;
    }
    else {
        return result[0];
    }
}
function createNewRangeFromMessages(messages) {
    var _messages = messages.sort(function (a, b) {
        return a.attributes.created_at_unix - b.attributes.created_at_unix;
    });
    var newRecordRange = { from: null, to: null };
    if (_messages.length === 1) {
        newRecordRange.from = _messages[0].attributes.created_at_unix;
        newRecordRange.to = _messages[0].attributes.created_at_unix;
    }
    else {
        newRecordRange.from = _messages[0].attributes.created_at_unix;
        newRecordRange.to = _messages[_messages.length - 1].attributes.created_at_unix;
    }
    if (_messages[0].attributes.is_oldest) {
        newRecordRange['position'] = "oldest";
    }
    return newRecordRange;
}
function splitRangesForSingle(recordRange) {
    var singleRanges = [];
    if (recordRange.ranges) {
        recordRange.ranges.filter(function (range) {
            if (isRangeSingle(range)) {
                singleRanges.push(range);
            }
        });
    }
    return singleRanges;
}
function splitRangesForCouple(recordRange) {
    var coupleRanges = [];
    if (recordRange.ranges) {
        recordRange.ranges.filter(function (range) {
            if (!isRangeSingle(range)) {
                coupleRanges.push(range);
            }
        });
    }
    return coupleRanges;
}
function isRangeSingle(range) {
    if (range.from === range.to) {
        return true;
    }
    else {
        return false;
    }
}
function uniqueArray(array) {
    return array.filter(function (target, index, self) { return self.indexOf(target) === index; });
}
function isObjectContainedInArray(array, hash) {
    return array.some(function (entity) {
        return JSON.stringify(entity) === JSON.stringify(hash);
    });
}
