"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var color_editable_1 = require("./color-editable");
var classNamesForPill = class_generator_1.classNamesGeneratorFor(function (add) {
    add("underTop", React.createElement("div", { className: "top5" }));
    add("overTop", React.createElement("div", { className: "top--1" }));
    add("absolute", React.createElement("div", { className: "left-1_5 absolute" }));
    add("relative", React.createElement("div", { className: "ml1 relative" }));
    add("big", React.createElement("div", { className: "f4 br-100 ph0_3" }), React.createElement("div", { className: "f5 br-pill ph1" }));
}, React.createElement("div", { className: "white ba b--red bg-red lh-title tc" }));
function UnreadMessagePill(props) {
    var unreadCount = props.unreadCount > 99 ? "99+" : props.unreadCount;
    if (!unreadCount)
        return null;
    return React.createElement(color_editable_1.ColorEditable, { colors: props.colors, editedColors: {
            backgroundColor: "unread_message_background",
            borderColor: "unread_message_background",
            color: "unread_message_text",
        }, style: props.style, className: classNamesForPill(props) }, unreadCount);
}
exports.UnreadMessagePill = UnreadMessagePill;
