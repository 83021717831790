"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var json_resources_1 = require("../core/json-resources");
var routes_1 = require("../routes");
var sequence_services_1 = require("../core/services/sequence-services");
var bisect_1 = require("../utils/bisect");
var customers_page_reducer_1 = require("./customers-page-reducer");
function chatRoomTopScroll(roomId) {
    return { type: "chat-room-top-scroll", roomId: roomId };
}
exports.chatRoomTopScroll = chatRoomTopScroll;
function chatRoomLoadNextOrPreviousMessages(pattern, messageId, createdAtUnix) {
    return { type: "chat-room-load-next-or-previous-messages", pattern: pattern, messageId: messageId, createdAtUnix: createdAtUnix };
}
exports.chatRoomLoadNextOrPreviousMessages = chatRoomLoadNextOrPreviousMessages;
function focusTargetMessage(messageId) {
    return { type: "focus-target-message", messageId: messageId };
}
exports.focusTargetMessage = focusTargetMessage;
function spShowCustomerDetail(editingCustomerId) {
    return { type: "sp-show-customer-detail", editingCustomerId: editingCustomerId };
}
exports.spShowCustomerDetail = spShowCustomerDetail;
function spBackToMessagesList() {
    return { type: "sp-back-to-messages-list" };
}
exports.spBackToMessagesList = spBackToMessagesList;
function requestChatHistory(roomId) {
    var query = {};
    query.include = ["user"];
    query.filter = { room_id: roomId };
    query.sort = ["-created_at"];
    return json_resources_1.requestResourceFetch([exports.chatHistoryRequestName, roomId], routes_1.RapiV1MessagesPath, query);
}
exports.requestChatHistory = requestChatHistory;
function reduceChatHistory(state, action) {
    var effects = [];
    switch (action.type) {
        case "chat-room-top-scroll":
            break;
        case "chat-room-load-next-or-previous-messages":
            var pattern = action.pattern;
            if (pattern !== "next" && pattern !== "previous")
                pattern = "previous";
            var url = routes_1.RapiV1MessagesPath + "/" + action.messageId + ("/" + pattern + "?offset=0");
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.chatLoadNextOrPreviousMessageRequestName], url));
            break;
        case "focus-target-message":
            var message = document.getElementById("messages-" + action.messageId);
            if (message && !state.supportsPage.messageDidFocus) {
                message.focus();
                state = tslib_1.__assign({}, state);
                state.supportsPage.messageDidFocus = true;
            }
            break;
        case "sp-show-customer-detail":
            state = tslib_1.__assign({}, state);
            state.sp = tslib_1.__assign({}, state.sp);
            state.sp.currentRender = "spSupportsCustomerDetail";
            state.customersPage = tslib_1.__assign({}, state.customersPage);
            state.customersPage.editingCustomerId = action.editingCustomerId;
            var user = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [action.editingCustomerId]);
            if (user) {
                var query = {};
                query.filter = { user_uuid: user.attributes.uuid };
                effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([customers_page_reducer_1.customersTrackerEntryLoadingRequestName], routes_1.RapiV1TrackerEntriesPath, query));
                effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([customers_page_reducer_1.customersPageMemosLoadingRequestName], routes_1.RapiV1MemosPath + "?filter[resource_id]=" + action.editingCustomerId));
            }
            break;
        case "sp-back-to-messages-list":
            state = tslib_1.__assign({}, state);
            state.sp = tslib_1.__assign({}, state.sp);
            state.sp.currentRender = "spSupportsMessagesList";
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceChatHistory = reduceChatHistory;
exports.chatLoadNextOrPreviousMessageRequestName = "chat-room-load-next-or-previous-messages";
exports.chatHistoryRequestName = "chat-history";
exports.chatLatestHistoryRequestName = "chat-latest-history";
exports.chatHistoryPagedRequestNames = [exports.chatHistoryRequestName, exports.chatLatestHistoryRequestName];
