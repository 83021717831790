"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var memoizers_1 = require("../../utils/memoizers");
var widget_state_1 = require("./widget-state");
var bisect_1 = require("../../utils/bisect");
var resource_api_1 = require("../../schemas/resource-api");
var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
var object_helpers_1 = require("../../utils/object-helpers");
exports.recomputeRoomListOrder = memoizers_1.memoizeBySomeProperties({
    loggedInUser: widget_state_1.WidgetV2State.initialState.loggedInUser,
    stores: object_helpers_1.pick(widget_state_1.WidgetV2State.initialState.stores, "users", "supports", "messages", "rooms", "members"),
}, function (state) {
    var roomListRows = [];
    var messageIter = bisect_1.Indexer.reverseIter(state.stores.messages.byCreatedAtUnixOfRoomLatest);
    for (var lastMessage = messageIter(); lastMessage; lastMessage = messageIter()) {
        var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [getRelationshipId(lastMessage, "room")]);
        if (!room)
            continue;
        if (room.attributes.kind !== "support_room")
            continue;
        var support = bisect_1.Indexer.getLastMatching(state.stores.supports.byRoomIdAndIsEnded, [room.id, false]);
        var supporter = support && bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [getRelationshipId(support, "user")]);
        var lastMessageUser = lastMessage && bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [getRelationshipId(lastMessage, "user")]);
        if (!lastMessageUser)
            continue;
        var member = bisect_1.Indexer.getFirstMatching(state.stores.members.byRoomIdAndUserId, [room.id, state.loggedInUser.id]);
        roomListRows.push({
            support: support,
            lastMessage: lastMessage,
            supporter: supporter,
            room: room,
            member: member,
            lastMessageUser: lastMessageUser,
        });
    }
    return roomListRows;
});
