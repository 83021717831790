"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ResourceApi;
(function (ResourceApi) {
    function isErrorResponse(response) {
        return 'errors' in response;
    }
    ResourceApi.isErrorResponse = isErrorResponse;
    function assertInnerId(v) {
        if (v && !(v instanceof Array)) {
            return v.id;
        }
        throw new Error("A resource or relationship's id was missing!");
    }
    ResourceApi.assertInnerId = assertInnerId;
    function getRelationshipId(v, relation) {
        var relationship = v.relationships[relation];
        if (!relationship || !relationship.data || relationship.data instanceof Array)
            return "";
        return relationship.data.id;
    }
    ResourceApi.getRelationshipId = getRelationshipId;
    function asResourceId(data) {
        if (!data)
            return;
        if (data instanceof Array) {
            return data.map(function (d) {
                return { type: d.type, id: d.id };
            });
        }
        return { type: data.type, id: data.id };
    }
    ResourceApi.asResourceId = asResourceId;
    function addRelationship(data, relation, related) {
        if (!data.relationships)
            data.relationships = {};
        data.relationships[relation] = { data: asResourceId(related) };
    }
    ResourceApi.addRelationship = addRelationship;
    function allResourcesInResponse(response) {
        return resourceCollection(response.included)
            .concat(resourceCollection(response.data));
    }
    ResourceApi.allResourcesInResponse = allResourcesInResponse;
    function resourceCollection(maybeCollection) {
        if (maybeCollection) {
            if (Array.isArray(maybeCollection))
                return maybeCollection;
            return [maybeCollection];
        }
        return [];
    }
    ResourceApi.resourceCollection = resourceCollection;
    function newResource(type) {
        return {
            type: type,
            id: null,
            attributes: {},
            relationships: {}
        };
    }
    ResourceApi.newResource = newResource;
})(ResourceApi = exports.ResourceApi || (exports.ResourceApi = {}));
