"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var class_generator_1 = require("./class-generator");
var scroll_utils_1 = require("../utils/scroll-utils");
var classNamesForContainer = class_generator_1.classNamesGeneratorFor(function (add) {
    add("dim", React.createElement("div", { className: "o-50" }));
}, React.createElement("div", { className: "o-100" }));
var ChatScrollContainer = (function (_super) {
    tslib_1.__extends(ChatScrollContainer, _super);
    function ChatScrollContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lastScrollTop = 0;
        _this.waitingOnScrollTop = false;
        _this.wasAtBottom = false;
        _this.lastScrollHeight = 0;
        _this.lastClientHeight = 0;
        _this.lastRoomId = "";
        _this.onScroll = function (e) {
            if (!_this.scrollContainerRef)
                return;
            if (_this.props.onScrollTop && _this.scrollContainerRef.scrollTop <= 0 && _this.lastScrollTop !== 0 && !_this.waitingOnScrollTop && !_this.props.isLoadingScrollTopRequest) {
                _this.props.onScrollTop();
                _this.waitingOnScrollTop = true;
            }
            _this.lastScrollTop = _this.scrollContainerRef.scrollTop;
            _this.wasAtBottom = scroll_utils_1.isAtBottomOf(_this.scrollContainerRef);
            if (_this.props.onScroll)
                _this.props.onScroll(e);
        };
        return _this;
    }
    ChatScrollContainer.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: classNamesForContainer(this.props), onDrop: this.props.onDrop, onDragOver: this.props.onDragOver, onDragLeave: this.props.onDragLeave, ref: function (e) { return _this.scrollContainerRef = e; }, id: this.props.id, onWheel: this.onScroll, onScroll: this.onScroll, draggable: false, tabIndex: this.props.tabIndex, style: this.props.style }, this.props.children);
    };
    ChatScrollContainer.prototype.scrollToBottom = function () {
        this.scrollContainerRef.scrollTop = this.scrollContainerRef.scrollHeight;
        this.lastScrollTop = this.scrollContainerRef.scrollTop;
        this.lastScrollHeight = this.scrollContainerRef.scrollHeight;
        this.lastRoomId = this.props.roomId;
        this.lastClientHeight = this.scrollContainerRef.clientHeight;
        this.wasAtBottom = true;
    };
    ChatScrollContainer.prototype.componentDidMount = function () {
        this.scrollToBottom();
    };
    ChatScrollContainer.prototype.componentDidUpdate = function () {
        if (this.lastRoomId !== this.props.roomId ||
            (this.wasAtBottom &&
                (this.lastScrollHeight < this.scrollContainerRef.scrollHeight || this.lastClientHeight > this.scrollContainerRef.clientHeight))) {
            this.scrollToBottom();
        }
        if (this.waitingOnScrollTop && !this.props.isLoadingScrollTopRequest && this.scrollContainerRef.scrollHeight > this.lastScrollHeight) {
            this.scrollContainerRef.scrollTop = this.scrollContainerRef.scrollHeight - this.lastScrollHeight;
            this.waitingOnScrollTop = false;
        }
        this.wasAtBottom = scroll_utils_1.isAtBottomOf(this.scrollContainerRef);
        this.lastRoomId = this.props.roomId;
        this.lastClientHeight = this.scrollContainerRef.clientHeight;
        this.lastScrollHeight = this.scrollContainerRef.scrollHeight;
        if (this.props.onFocusTargetMessage) {
            this.props.onFocusTargetMessage();
        }
    };
    return ChatScrollContainer;
}(React.Component));
exports.ChatScrollContainer = ChatScrollContainer;
