"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var OKSKY_STYLE = require("base.css");
var OKSKY_STYLE_ROOT_CLASS = OKSKY_STYLE.locals.style;
var OKSKY_STYLE_STRING = OKSKY_STYLE.toString();
exports.appendOKSKYStyleToHead = function (head) {
    if (!head)
        return;
    var styleElement = document.createElement("style");
    styleElement.setAttribute('type', 'text/css');
    styleElement.innerText = OKSKY_STYLE_STRING;
    head.appendChild(styleElement);
};
exports.setOKSKYStyleClassToRoot = function (rootElement) {
    if (!rootElement)
        return;
    rootElement.setAttribute('class', OKSKY_STYLE_ROOT_CLASS + ' ' + rootElement.className);
};
