"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var progress_bar_1 = require("./progress-bar");
var initialState = {
    progress: 0
};
var FakeProgressBar = (function (_super) {
    tslib_1.__extends(FakeProgressBar, _super);
    function FakeProgressBar(props) {
        var _this = _super.call(this, props) || this;
        _this.count = 0;
        _this.state = tslib_1.__assign({}, initialState);
        return _this;
    }
    FakeProgressBar.prototype.componentDidMount = function () {
        var _this = this;
        this.timerId = setInterval(function () { return _this.tick(); }, 50);
    };
    FakeProgressBar.prototype.componentWillUnmount = function () {
        clearInterval(this.timerId);
    };
    FakeProgressBar.prototype.tick = function () {
        var _this = this;
        this.count += 1;
        this.setState(function (prevState, props) { return ({
            progress: (1 - (1 / (_this.count + (props.rate ? props.rate : 1)))) * 100
        }); });
    };
    FakeProgressBar.prototype.render = function () {
        return React.createElement("div", null,
            React.createElement(progress_bar_1.ProgressBar, { percent: this.state.progress }));
    };
    return FakeProgressBar;
}(React.Component));
exports.FakeProgressBar = FakeProgressBar;
