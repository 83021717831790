"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var classNamesForRadioButtonGroup = class_generator_1.classNamesGeneratorFor(function (add) {
}, React.createElement("div", { className: "list pa0 ma0" }));
function RadioButtonGroup(props) {
    return React.createElement("ul", { className: classNamesForRadioButtonGroup(props) }, props.values.map(function (value, i) {
        return React.createElement("li", { key: "radio-button-" + value },
            React.createElement("label", { className: "mr3 dib" },
                React.createElement("input", { type: "radio", value: value, checked: props.value === value, onChange: props.onSelect, className: "fancy-radio-button absolute overflow-hidden pa0 bn" }),
                React.createElement("span", { className: "pointer dib relative f3" }, props.labels[i])));
    }));
}
exports.RadioButtonGroup = RadioButtonGroup;
