"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var ScenarioSurveyTemplatesStore;
(function (ScenarioSurveyTemplatesStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    ScenarioSurveyTemplatesStore.indexer = new bisect_1.Indexer("byId");
    ScenarioSurveyTemplatesStore.indexer.addIndex("byId", function (scenarioSurveyTemplate) { return [assertInnerId(scenarioSurveyTemplate)]; });
})(ScenarioSurveyTemplatesStore = exports.ScenarioSurveyTemplatesStore || (exports.ScenarioSurveyTemplatesStore = {}));
