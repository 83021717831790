"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bisect_1 = require("../utils/bisect");
var TemplateResponsesStore;
(function (TemplateResponsesStore) {
    TemplateResponsesStore.indexer = new bisect_1.Indexer("byId");
    TemplateResponsesStore.indexer.addIndex("byId", function (t) { return [t.id]; });
    TemplateResponsesStore.indexer.addIndex("byTitle", function (t) { return [t.attributes.title]; });
    TemplateResponsesStore.indexer.addIndex("byMessageText", function (t) { return [t.attributes.message_text]; });
})(TemplateResponsesStore = exports.TemplateResponsesStore || (exports.TemplateResponsesStore = {}));
