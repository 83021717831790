"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var resource_page_common_reducer_1 = require("./resource-page-common-reducer");
var sequence_services_1 = require("../core/services/sequence-services");
var json_resources_1 = require("../core/json-resources");
var routes_1 = require("../routes");
var bisect_1 = require("../utils/bisect");
var resource_api_1 = require("../schemas/resource-api");
var newResource = resource_api_1.ResourceApi.newResource;
var ajax_services_1 = require("../core/services/ajax-services");
var stores_1 = require("../stores");
var loadResources = stores_1.Stores.loadResources;
exports.tenantsLoadingRequestName = "request-ajax-tenant-data";
exports.updateTenantRequestName = "update-tenant-request";
exports.stripePlansLoadingRequestName = "request-ajax-stripe-plans-data";
exports.stripeProductsLoadingRequestName = "request-ajax-stripe-products-data";
exports.stripeCardsLoadingRequestName = "request-ajax-stripe-cards-data";
exports.stripeCustomersLoadingRequestName = "request-ajax-stripe-customers-data";
exports.stripeSubscriptionsLoadingRequestName = "request-ajax-stripe-subscriptions-data";
function updateTenant() {
    return {
        type: "update-tenant"
    };
}
exports.updateTenant = updateTenant;
function changeTenantConfigure(target, originalValue, position, value) {
    return {
        type: "change-tenant-configure",
        target: target,
        originalValue: originalValue,
        position: position,
        value: value
    };
}
exports.changeTenantConfigure = changeTenantConfigure;
function subscribeTenantToStripe(body) {
    return {
        type: "subscribe-tenant-to-stripe",
        body: body
    };
}
exports.subscribeTenantToStripe = subscribeTenantToStripe;
function addTenantConfigure(target) {
    return {
        type: "add-tenant-configure",
        target: target
    };
}
exports.addTenantConfigure = addTenantConfigure;
function deleteTenantConfigure(target, originalValue, position) {
    return {
        type: "delete-tenant-configure",
        target: target,
        originalValue: originalValue,
        position: position
    };
}
exports.deleteTenantConfigure = deleteTenantConfigure;
function reduceTenantModal(state, action) {
    var effects = [];
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.tenantsLoadingRequestName) {
                if (action.success) {
                    var responseDetails = json_resources_1.getResourceResponseDetails(action);
                    if (!responseDetails.resources)
                        break;
                    if (!responseDetails.resources.length)
                        break;
                    state = tslib_1.__assign({}, state);
                    state.stores = loadResources(responseDetails.resources, state.stores);
                    state.tenantModal = tslib_1.__assign({}, state.tenantModal);
                    var subdomain = location.host.split('.')[0];
                    var tenant_1 = bisect_1.Indexer.getFirstMatching(state.stores.tenants.byName, [subdomain]);
                    state.thisTenant = tenant_1;
                    state.tenantModal.currentTenantId = tenant_1 ? tenant_1.id : "";
                }
            }
            break;
        case "change-tenant-configure":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            if (action.target === "tenant_valid_ip") {
                var validIPs = action.originalValue.split(",");
                validIPs[action.position] = action.value;
                state.inputs.tenantValidIps = validIPs.join(",");
            }
            else if (action.target === "tenant_new_plan") {
                var newPlan = void 0;
                for (var i = 0; i < action.originalValue.length; i++) {
                    var entry = action.originalValue[i];
                    if (entry === action.value) {
                        newPlan = entry;
                    }
                }
                state.tenantModal.tenantNewPlan = newPlan;
            }
            else if (action.target === "tenant_card_payment_pattern") {
                state.inputs.tenantCardPaymentPattern = action.value;
            }
            else if (action.target === "tenant_stripe_card") {
                var stripeCard = void 0;
                for (var i = 0; i < action.originalValue.length; i++) {
                    var entry = action.originalValue[i];
                    if (entry === action.value) {
                        stripeCard = entry;
                    }
                }
                state.tenantModal.tenantStripeCard = stripeCard;
            }
            break;
        case "add-tenant-configure":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            if (action.target === "tenant_valid_ip") {
                state.inputs.tenantValidIps = state.inputs.tenantValidIps + ",";
            }
            break;
        case "delete-tenant-configure":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            if (action.target === "tenant_valid_ip") {
                var newValidIPs = action.originalValue.split(",");
                newValidIPs[action.position] = null;
                newValidIPs = newValidIPs.filter(function (v) { return v; });
                state.inputs.tenantValidIps = newValidIPs.join(",");
            }
            break;
        case "update-tenant":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.tenantEditingToggle = false;
            var editingId = state.resourcePageCommonState.currentEditingIds[0];
            var tenant = bisect_1.Indexer.getFirstMatching(state.stores.tenants.byId, [editingId]);
            if (!tenant)
                break;
            if (Object.keys(tenant).length === 0)
                break;
            var tenantData = newResource("tenants");
            tenantData.id = tenant.id;
            tenantData.attributes.name = tenant.attributes.name;
            tenantData.attributes.customer_error_redirect_url = state.inputs.tenantCustomerErrorRedirectUrl;
            tenantData.attributes.default_customer_avatar_url = state.inputs.tenantDefaultCustomerAvatarUrl;
            tenantData.attributes.default_avatar_url = state.inputs.tenantDefaultAvatarUrl;
            tenantData.attributes.create_user_email = tenant.attributes.create_user_email;
            tenantData.attributes.valid_ips = state.inputs.tenantValidIps || tenant.attributes.valid_ips;
            tenantData.attributes.enable_assign_with_order_of_role = state.toggles.tenantEnableAssignWithOrderOfRole;
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.updateTenantRequestName, editingId], tenantData, routes_1.RapiV1TenantsPath + "/" + editingId));
            state = resource_page_common_reducer_1.reduceFinishEditingResource(state);
            break;
        case "subscribe-tenant-to-stripe":
            effects = sequence_services_1.sequence(effects, requestTenantStripeSubscription(action.body));
            break;
        case "begin-edit-resource":
        case "toggle":
            var isTarget = false;
            if (action.type === "begin-edit-resource" && action.resourceType === "tenants" && action.ids.length !== 0) {
                isTarget = true;
            }
            else if (action.type === "toggle" && action.target === "tenantEditingToggle") {
                isTarget = true;
            }
            if (isTarget) {
                var subdomain = location.host.split('.')[0];
                var tenant_2 = bisect_1.Indexer.getFirstMatching(state.stores.tenants.byName, [subdomain]);
                if (!tenant_2)
                    break;
                if (Object.keys(tenant_2).length === 0)
                    break;
                var allStripePlans = bisect_1.Indexer.getAllMatching(state.stores.stripePlans.byId, []);
                var currentStripePlan = null;
                if (tenant_2) {
                    if (tenant_2.attributes.current_stripe_plan_id !== null) {
                        currentStripePlan = bisect_1.Indexer.getFirstMatching(state.stores.stripePlans.byId, [tenant_2.attributes.current_stripe_plan_id.toString()]);
                    }
                }
                var stripePlans = [];
                for (var i = 0; i < allStripePlans.length; i++) {
                    if (allStripePlans[i] !== currentStripePlan) {
                        stripePlans.push(allStripePlans[i]);
                    }
                }
                var stripeCards = bisect_1.Indexer.getAllMatching(state.stores.stripeCards.byId, []);
                state = tslib_1.__assign({}, state);
                state.inputs = tslib_1.__assign({}, state.inputs);
                state.inputs.tenantCustomerErrorRedirectUrl = tenant_2.attributes.customer_error_redirect_url;
                state.inputs.tenantDefaultCustomerAvatarUrl = tenant_2.attributes.default_customer_avatar_url;
                state.inputs.tenantDefaultAvatarUrl = tenant_2.attributes.default_avatar_url;
                state.inputs.tenantValidIps = tenant_2.attributes.valid_ips;
                if (stripePlans) {
                    state.tenantModal.tenantNewPlan = stripePlans[0];
                }
                if (stripeCards.length === 0) {
                    state.inputs.tenantCardPaymentPattern = "add_new_with_set_default";
                }
                else {
                    state.inputs.tenantCardPaymentPattern = "use_selected_with_set_default";
                }
                if (stripeCards != null) {
                    state.tenantModal.tenantStripeCard = stripeCards[0];
                }
                state.toggles.tenantEnableAssignWithOrderOfRole = tenant_2.attributes.enable_assign_with_order_of_role;
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceTenantModal = reduceTenantModal;
function requestTenantStripeSubscription(body) {
    var ajaxConfig = {};
    ajaxConfig.headers = json_resources_1.jsonResourceHeaders;
    ajaxConfig.url = routes_1.RapiV1TenantsPath + "/subscribe";
    ajaxConfig.method = "POST";
    ajaxConfig.json = body;
    return ajax_services_1.requestAjax([exports.subscribeTenantToStripeRequestName], ajaxConfig);
}
exports.requestTenantStripeSubscription = requestTenantStripeSubscription;
exports.subscribeTenantToStripeRequestName = "subscribe-tenant-to-stripe";
