"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var global_1 = require("../utils/global");
var stores_1 = require("../stores");
var widget_state_1 = require("../widget-v2/reducers/widget-state");
var OperatorState;
(function (OperatorState) {
    OperatorState.addMemberModalInputs = {
        addMemberFilter: "",
        addMemberSelectedUserId: "",
    };
    OperatorState.addMemberModalToggles = {
        showAddMemberModal: false,
    };
    OperatorState.clientsPageToggles = {
        clientViewDesignAccordion: false,
        clientAutoPostAccordion: false,
        clientAwaitingPostAccordion: false,
        clientAwaitingPostDuringBusinessHourAccordion: false,
        clientAwaitingPostAfterBusinessHourAccordion: false,
        clientBusinessHoursSettingAccordion: false,
        clientDisplayRuleAccordion: false,
        clientDisabled: false,
        clientSettingsScheduleNewModal: false,
        clientSettingsScheduleEditModal: false,
        clientSettingsScheduleDeleteModal: false,
        clientV1EnableHeader: false,
        showClientDeleteConfirmModal: false,
        fakeWidgetInitSwitch: false,
        clientV2CollectButtonImageCustomFlg: false,
    };
    OperatorState.clientsPageInputs = {
        clientViewDesignTab: "0",
        clientViewVersionTab: "2",
        clientTitle: "",
        clientUserRegistration: "",
        clientDuringOffHours: "",
        clientNotificationBeforeClosingBusinessHour: "",
        clientSettingsScheduleStartHour: "",
        clientSettingsScheduleStartMinute: "",
        clientSettingsScheduleEndHour: "",
        clientSettingsScheduleEndMinute: "",
        clientSettingsScheduleDayOfWeek: "",
        clientV1EmbeddedCss: "",
        clientV1RoomsCss: "",
        clientV1TimeFormat: "",
        clientV2TimeFormat: "",
        clientV2HeaderTitle: "",
        clientV2HeaderDescription: "",
        clientV2replyGuidance: "",
        clientV2CreateRoomButtonText: "",
        clientV2WidgetPosition: "",
        clientV2WidgetBackgroundUrl: "",
        clientV2CollectEmailsTitle: "",
        clientV2CollectEmailsDescription: "",
        clientV2CollectEmailsErrorMessage: "",
        clientV2CollectButtonImageCustomWidth: "60",
        clientV2CollectButtonImageCustomHeight: "60",
        clientHideDownloadLink: "true",
        widgetPreviewScreen: "room-page",
    };
    OperatorState.clearOnClickToggles = {
        chatTooltip: false,
        chatDropdown: false,
        userDropdown: false,
        settingsSideMenu: false,
        userListTooltip: false,
        userListDropdown: false,
        changeLogDropDown: false,
        resolveDraftMessageByRoomModal: false,
        resolveDraftMessageByMessageModal: false,
        showSupportUserFilter: false,
        showSupportRoomFilter: false,
    };
    OperatorState.customersPageToggles = {
        customersSearchModal: false,
    };
    OperatorState.customersPageInputs = {
        customerDetailTabId: "0",
        customerNewNote: "",
        editingUserId: "",
        editingUserInfoKey: "",
        editingUserInfoValue: "",
        newUserInfoMasterKeywordId: "",
        newUserInfoValue: "",
        searchCustomerNickName: "",
        searchCustomerRoomName: "",
    };
    OperatorState.operatorsPageToggles = {
        operatorInTraining: false,
        operatorSoundNotification: false,
        operatorDeactivateModal: false,
        showRegenerateUserConfirmModal: false,
    };
    OperatorState.operatorsPageInputs = {
        operatorsPageSearchInput: "",
        operatorUuid: "",
        operatorName: "",
        operatorsPageOrderBy: "id",
        operatorDisplayName: "",
        operatorUserType: "",
        operatorMaxSupportCount: "10",
        operatorAssignPriority: "",
        operatorEmail: "",
        operatorTimezone: "",
        operatorAvatarUrl: "",
        operatorLanguage: "ja",
        operatorSoundNotificationType: "",
    };
    OperatorState.masterKeywordsPageInputs = {
        masterKeywordsOrderBy: "key_name",
        masterKeywordId: "",
        masterKeywordKeyName: "",
        masterKeywordKeyTitle: "",
        masterKeywordRank: "",
    };
    OperatorState.masterKeywordsPageToggles = {
        newMasterKeyword: false,
        masterKeywordDeleteModal: false,
    };
    OperatorState.messagesPageToggles = {
        messagesPageSearchWithRegexp: false,
    };
    OperatorState.messagesPageInputs = {
        messageSearchInput: "",
    };
    OperatorState.pastSupportsPageInputs = {
        pastSupportsSearchInput: "",
    };
    OperatorState.supportsPageToggles = {
        chatFileUploadHover: false,
        supportsSidePanel: false,
        supportsSidePanelTooltip: false,
        supportsSidePanelMemo: false,
        supportsSidePanelLink: false,
        supportsSidePanelSupportHistory: false,
        supportsSidePanelFiles: false,
        supportsAssignmentModal: false,
        templateResponsesModalToggle: false,
        surveysModalToggle: false,
        templateResponsesModalDescendingOrder: false,
        surveysModalDescendingOrder: false,
        showFileShareModal: false,
        showUserCreatedModal: false,
        sendOnEnter: false,
        showSendOnEnterBalloon: false,
        showFAQBalloon: false,
        showSurveyBalloon: false,
        supportsMessageDeleteModal: false,
        supportsCreateLinkTypeMessageModal: false,
        supportsCreateLinkTypeMessageModalSubmitButton: false,
        supportsSelfEvaluateModal: false,
        showDeleteMemberFromChatModal: false,
    };
    OperatorState.supportsPageInputs = {
        templateResponsesModalSelectedId: "",
        surveysModalSurveySelectedId: "",
        supportLeftFilteringSupportRoomsSearch: "",
        supportLeftFilteringUsersSearch: "",
        supportsAssignmentModalSearch: "",
        supportSortType: "",
        deleteMemberUserId: "",
        deleteMemberRoomId: "",
        deleteMemberId: "",
    };
    OperatorState.roomCounts = {
        unread_support_room_messages: {},
        unread_private_room_messages: {},
        total_draft_messages_in_review: 0,
        draft_messages_in_review: 0,
        unread_draft_messages_approved: 0,
        unread_draft_messages_rejected: 0,
        read_only: {
            awaiting_support_count: 0,
            private_room_count: 0,
            total_unreplied_support_count: 0,
            total_replied_support_count: 0,
            unreplied_support_count_by_user: {},
            replied_support_count_by_user: {},
            closed_today: 0,
            max_awaiting_sec: 0,
            avg_awaiting_sec: 0,
        }
    };
    OperatorState.draftMessagesPageToggles = {
        draftMessageInReview: true,
    };
    OperatorState.draftMessagesPageInputs = {
        draftMessageComment: "",
        draftMessageSearchInput: "",
    };
    OperatorState.reportsPageInputs = {
        faqSearchInput: "",
        visitedPageSearchInput: "",
    };
    OperatorState.templateResponsesPageInputs = {
        templateResponseSearch: "",
        templateResponseQuestion: "",
        templateResponseAnswer: "",
        templateResponseTags: "",
        templateResponseId: "",
        templateResponsesPageOrderBy: "title",
        templateResponseVideoTagData: [],
        templateResponseDeleteVideoTagId: null,
        templateResponseDeleteVideoTagIdx: null,
        templateResponseYoutubeId: "",
        templateResponseThumbnailLoadingIdx: [],
    };
    OperatorState.templateResponsesPageToggles = {
        newTemplateResponse: false,
        showTemplateResponseDeleteConfirmModal: false,
        templateResponsesPageDescendingOrder: false,
        showTemplateResponseCreateModal: false,
        showViewModeVideoTagModal: true,
        videoTagModal: false,
        youtubeModal: false,
        showVideoTagDeleteModal: false,
        showVideoTagThumbnailDeleteModal: false,
        showYoutubeDeleteModal: false,
        loadingFlg: false,
        showHtmlTagModal: false,
        showHtmlTagContent: false,
    };
    OperatorState.webhooksPageInputs = {
        webhookPayloadUrl: "",
        webhookContentType: "vnd_api_json",
        webhookSecretToken: "",
        webhooksPageSearchInput: "",
        webhooksPageOrderBy: "id",
    };
    OperatorState.webhooksPageToggles = {
        webhookEditingToggle: false,
        webhookIsActive: false,
        showWebhookCreateModal: false,
        showWebhookDeleteModal: false,
    };
    OperatorState.marketingDatasPageToggles = {
        marketingDatasEditingToggle: false,
        marketingDatasIsActive: false,
        marketingDatasCreateModal: false,
        marketingDatasDeleteModal: false,
    };
    OperatorState.connectionPageInputs = {
        connectionPageSearchInput: "",
        connectionPageOrderBy: "id",
        connectionFormSettingsTab: "0",
        externalServiceId: "",
        externalServiceName: "",
        externalServiceKind: "",
        externalServiceServiceCode: "",
    };
    OperatorState.connectionPageToggles = {
        showExternalServiceCreatedModal: false,
        externalServiceIsActive: false,
        externalServiceEditingToggle: false,
        showConnectionPageDeleteConfirmModal: false,
    };
    OperatorState.tenantModalInputs = {
        tenantCustomerErrorRedirectUrl: "",
        tenantDefaultCustomerAvatarUrl: "",
        tenantDefaultAvatarUrl: "",
        tenantValidIps: "",
        tenantCardPaymentPattern: "",
    };
    OperatorState.tenantModalToggles = {
        tenantEditingToggle: false,
        tenantEnableAssignWithOrderOfRole: false,
    };
    OperatorState.reportsPageToggles = {
        faqRankingShowMoreToggle: false,
        visitedPagesShowMoreToggle: false,
    };
    OperatorState.linkMessageInputs = {
        linkMessageUrl: "",
        linkMessageTitle: "",
        linkMessageDescription: "",
        linkMessageImageUrl: "",
    };
    OperatorState.supportSelfEvaluateInputs = {
        selfEvaluationInput: "neither",
        selfCommentInput: "",
    };
    OperatorState.surveysPageInputs = {
        surveyTitle: "",
        surveyDescription: "",
        surveyImageUrl: "",
        surveyImageAlt: "",
        surveyChoiceAction: "create_message",
        surveyChoiceKind: "radio_choice",
        surveyChoiceText: "",
        surveyLinkUrl: "",
        surveyLinkDisplayMessage: "",
        surveysModalOrderBy: "title",
        surveysModalSearch: "",
    };
    OperatorState.surveysPageToggles = {
        showSurveysDeleteConfirmModal: false,
    };
    OperatorState.initialState = {
        appSettings: global_1.global.appSettings || {},
        isMessageSendingByRoom: {},
        activeRequests: {},
        isAssigningSupport: false,
        isUserSettingsValid: false,
        hasUnloadMessage: false,
        widgetV2FakeState: widget_state_1.WidgetV2State.initialState,
        flashes: {
            connectionWarning: null,
            connectionRestored: null,
            requestError: null,
        },
        urgentConnectionErrors: {},
        infoConnectionErrors: {},
        feedbackToasts: [],
        toggles: tslib_1.__assign({ sideMenu: true, resourcePageReverseOrder: false, overviewPageReverseOrder: false, showDraftMessageAlert: false, showRoomHasBeenAssignedAlert: false, showDuplicateMessageModal: false }, OperatorState.operatorsPageToggles, OperatorState.clearOnClickToggles, OperatorState.customersPageToggles, OperatorState.clientsPageToggles, OperatorState.addMemberModalToggles, OperatorState.supportsPageToggles, OperatorState.webhooksPageToggles, OperatorState.marketingDatasPageToggles, OperatorState.connectionPageToggles, OperatorState.tenantModalToggles, OperatorState.reportsPageToggles, OperatorState.masterKeywordsPageToggles, OperatorState.messagesPageToggles, OperatorState.draftMessagesPageToggles, OperatorState.templateResponsesPageToggles, OperatorState.surveysPageToggles),
        sp: {
            currentTab: "tab1",
            currentRender: "spSupportsAwaitingPage",
            tab1OverlapRender: "",
            tab2OverlapRender: "",
            tab3OverlapRender: "",
        },
        resourcePageCommonState: {
            toggles: {},
            currentEditingIds: [],
            showResourceModal: false,
            editingResourceType: "",
        },
        operatorsPage: {
            numberOfOperators: 0,
        },
        templateResponsesPage: {
            tags: [],
            templateResponsesCount: 0,
            templateResponseIdToBeDeleted: "",
            uploadFileErrorFlg: false,
            viewVideoTagData: [],
            youtubeId: "",
            htmlTagContent: "",
            settingKind: "",
            htmlSettingContext: "",
        },
        customersPage: {
            borderKnobExpandSize: "min",
            editingCustomerId: "",
            showResourceModal: false,
            originalToggles: {},
            toggles: {},
            filteredCustomers: [],
            freeSearchInputs: {},
            numberOfCustomers: 0,
        },
        clientsPage: {
            editingClientId: "",
            editingClientClientCode: "",
            awaitingClientLoad: false,
            autoPosts: [],
            awaitingPosts: [],
            awaitingPostsDuringBusinessHour: [],
            awaitingPostsAfterBusinessHour: [],
            notificationBeforeClosingBusinessHour: "",
            whiteListUrls: [],
            blackListUrls: [],
            whiteListRules: [],
            blackListRules: [],
            schedules: [],
            editingScheduleIndex: null,
            saving: false,
            colors: widget_state_1.WidgetV2State.initialState.colors,
            selectedColors: [],
        },
        messagesPage: {
            rooms: [],
            total: 0,
            fromDate: null,
            toDate: null,
            nextLink: "",
            messages: [],
        },
        pastSupportsPage: {
            rooms: [],
            total: 0,
            fromDate: null,
            toDate: null,
            nextLink: "",
            supports: [],
            status: "ended",
            selfEvaluate: "neither",
            isPastSupportSearching: false,
            lastMessageKind: null,
        },
        reportsPage: {
            reports: null,
            startDate: null,
            endDate: null,
        },
        supportsPage: {
            fileShareMessageId: "",
            fileShareRoomId: "",
            chatInputMaxHeightRate: 3,
            supportsMessageIdToBeDeleted: "",
            focusedMessageId: "",
            messageDidFocus: false,
        },
        masterKeywordsPage: {
            masterKeywordIdToBeDeleted: "",
        },
        draftMessagesPage: {
            draftMessageResolvedDropdown: "resolved",
            draftMessageCreateUserId: "",
            fromDate: null,
            toDate: null,
            draftMessageResolveToggle: {},
        },
        surveysPage: {
            newChoices: [],
            newLinks: [],
            isCreatingNewSurvey: false,
            editingSurveyId: "",
            selectInputToggle: false,
        },
        webhooksPage: {
            webhookEvents: [],
            webhookPayloadUrlValid: false,
        },
        marketingDatasPage: {
            marketingDatas: {},
            selectedData: {},
            startDate: null,
            endDate: null,
            selectStartDate: null,
            selectEndDate: null,
            first_view_flg: false,
        },
        scenarioTemplatePage: {
            data: [],
        },
        connectionPage: {
            externalServices: [],
            nextLink: "",
            loadingExternalServices: false,
            isValid: false,
            externalServiceSettings: {},
        },
        tenantModal: {
            currentTenantId: "",
            tenantNewPlan: {},
            tenantStripeCard: {},
        },
        thisTenant: {},
        plan_settings: global_1.global.plan_settings || {},
        usagePage: {
            current_usages: {
                mau_count: 0,
                member_count: 0,
            },
            usage_histories: {
                mau_counts: {},
                member_counts: {},
            }
        },
        editingOperatorValid: false,
        isOperatorEmailDuplicate: false,
        isOperatorNameDuplicate: false,
        isOperatorFullNameDuplicate: false,
        messageContentTooLong: false,
        clientsPageErrors: {},
        clientsPageDuringBusinessHourErrors: {},
        clientsPageAfterBusinessHourErrors: {},
        roomCounts: tslib_1.__assign({}, OperatorState.roomCounts),
        hasPageLoaded: {},
        completedRequests: {},
        stores: stores_1.Stores.initialState,
        sizes: {
            chatTooltipContainer: [0, 0],
            chatTooltipContents: [0, 0],
            userDropdownContainer: [0, 0],
            supportPanelTooltipContents: [0, 0],
            chatListContentSize: [0, 0],
            chatListScrollContainerSize: [0, 0],
            supportChatListContent: [0, 0],
            supportChatListContainerScroll: [0, 0],
            supportChatListViewport: [0, 0],
            userListTooltipContainer: [0, 0],
            userListTooltipContents: [0, 0],
            changeLogTooltipContainer: [0, 0],
            windowSize: [window.innerWidth, window.innerHeight],
        },
        inputs: tslib_1.__assign({ privateMessagesSearch: "", supportsAssignmentModalSelectedUserId: "", supportSearchType: "supporting", supportFilteringPanelUserId: "", supportSearchReplyType: "unreplied", draftMessageCommentModal: "", curEditingMessageText: "", fileShareSearch: "", templateResponsesModalSearch: "", userListSearchInput: "", customersPageOrderBy: "id", resourcePageSearch: "", overviewPageOrderBy: "name", overviewPageSearch: "", scrollPositionToUpdate: "" }, OperatorState.customersPageInputs, OperatorState.operatorsPageInputs, OperatorState.templateResponsesPageInputs, OperatorState.clientsPageInputs, OperatorState.masterKeywordsPageInputs, OperatorState.messagesPageInputs, OperatorState.pastSupportsPageInputs, OperatorState.addMemberModalInputs, OperatorState.supportsPageInputs, OperatorState.draftMessagesPageInputs, OperatorState.webhooksPageInputs, OperatorState.connectionPageInputs, OperatorState.tenantModalInputs, OperatorState.reportsPageInputs, OperatorState.linkMessageInputs, OperatorState.supportSelfEvaluateInputs, OperatorState.surveysPageInputs),
        currentViewingRoomId: "",
        currentViewingRoomName: "",
        ids: {
            fileUploadInput: "file-upload-input",
            chatListContent: "chat-list-content",
            chatTooltipContainer: "chat-tooltip-container",
            chatTooltipContents: "chat-tooltip-contents",
            userDropdownContainer: "create-dropdown",
            privateMessageSearch: "private-message-search",
            supportSearchInput: "support-search-input",
            supportPanelTooltipContents: "support-panel-tooltip-contents",
            userListTooltipContainer: "user-list-tooltip-container",
            userListTooltipContents: "user-list-tooltip-contents",
        },
        loggedInUser: global_1.global.loggedInUser,
        now: Date.now(),
        relativeNow: 0,
        pathParts: [],
        supportDetailsRows: [],
        fakeResourceId: "1",
        defaultPageSize: global_1.global.jsonApiPageSize || 25,
        visibleOperators: {
            allOperators: [],
            activeOperators: [],
        },
        operatorCapacity: [],
        popupRooms: [],
        roomNotifications: [],
        loadingPagedRequests: {},
        nextPagedRequests: {},
        isRoomMarkingRead: {},
        badRoute: false,
        badStatusCode: 404,
        roomTemplateResponses: {},
        roomInputs: {},
        memoInputs: {},
        linkMessageInputs: {},
        localCsvDownloadUrl: { pastSupports: "", messages: { roomMessage: "", messagesPage: "" }, customers: "", templateResponses: "", marketing_datas: "" },
        scrollPositions: {},
        curEditingMessageId: "",
        awaitingSupportRoomLoad: false,
        privateRoomsSummaryRows: [],
        loadingStart: 0,
        showInitialLoadingModal: false,
        addingMemberToRoomId: "",
        localSettings: {},
        documentTitle: document.title,
        activeRequest: {},
        focusedRoomId: "",
        ringANotifySoundAtBatchingEnd: false,
    };
})(OperatorState = exports.OperatorState || (exports.OperatorState = {}));
