"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bisect_1 = require("../utils/bisect");
var UsersStore;
(function (UsersStore) {
    UsersStore.indexer = new bisect_1.Indexer("byId");
    UsersStore.indexer.addIndex("byId", function (md) { return [md.id]; });
    UsersStore.indexer.addIndex("byUuid", function (u) { return [u.attributes.uuid]; });
    UsersStore.indexer.addIndex("byNotCustomerAndId", function (md) { return [md.attributes.user_type !== "Customer", md.id]; });
    UsersStore.indexer.addIndex("byNotCustomerAndUsername", function (md) { return [md.attributes.user_type !== "Customer", md.attributes.full_name || md.attributes.name]; });
    UsersStore.indexer.addIndex("byNotCustomerAndName", function (md) { return [md.attributes.user_type !== "Customer", md.attributes.name]; });
    UsersStore.indexer.addIndex("byNotCustomerAndFullName", function (md) { return [md.attributes.user_type !== "Customer", md.attributes.full_name]; });
    UsersStore.indexer.addIndex("byNotCustomerAndEmail", function (md) { return [md.attributes.user_type !== "Customer", md.attributes.email]; });
    UsersStore.indexer.addIndex("byNotCustomerAndType", function (md) { return [md.attributes.user_type !== "Customer", md.attributes.user_type]; });
    UsersStore.indexer.addIndex("byNotCustomerAndIsActive", function (md) { return [md.attributes.user_type !== "Customer", md.attributes.is_active]; });
    UsersStore.indexer.addIndex("byNotCustomerAndIsOnline", function (md) { return [md.attributes.user_type !== "Customer", md.attributes.status !== "offline"]; });
    UsersStore.indexer.addIndex("byNotCustomerAndCanSupport", function (md) { return [md.attributes.user_type !== "Customer",
        md.attributes.status === "available" && md.attributes.is_active && md.attributes.max_support_count > 0 && md.attributes.active_supports_count < md.attributes.max_support_count]; });
    UsersStore.indexer.addIndex("byEmail", function (md) { return [md.attributes.email]; });
    UsersStore.indexer.addIndex("byName", function (md) { return [md.attributes.name]; });
})(UsersStore = exports.UsersStore || (exports.UsersStore = {}));
