"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tracker_events_1 = require("../core/tracker-events");
exports.WIDGET_V2_OPEN = 'oksky-chat-widget-v2-open';
exports.WIDGET_V2_TRACKER_CHECK_OPEN = 'oksky-chat-widget-v2-tracker-check-open';
exports.WIDGET_V2_CLOSE = 'oksky-chat-widget-v2-close';
exports.WIDGET_V2_RECEIVED_MESSAGE = 'oksky-chat-widget-v2-received-message';
exports.WIDGET_V2_RECEIVED_SOME_MESSAGE = 'oksky-chat-widget-v2-received-some-message';
var receivedMessageIds = {};
function widgetOpen(roomId) {
    if (roomId === void 0) { roomId = null; }
    var stringRoomId;
    if (typeof roomId == 'string')
        stringRoomId = roomId;
    if (typeof roomId == 'number')
        stringRoomId = roomId.toString();
    if (typeof roomId == null)
        stringRoomId = "";
    chatOpenTracker();
    dispatchEvent(exports.WIDGET_V2_OPEN, stringRoomId);
}
exports.widgetOpen = widgetOpen;
function widgetClose() {
    chatCloseTracker();
    dispatchEvent(exports.WIDGET_V2_CLOSE);
}
exports.widgetClose = widgetClose;
function chatOpenTracker(roomId, clientId, clientCode) {
    if (roomId === void 0) { roomId = null; }
    if (clientId === void 0) { clientId = null; }
    if (clientCode === void 0) { clientCode = null; }
    dispatchEvent(tracker_events_1.WIDGET_V2_TRACKER_CHAT_OPEN, { roomId: roomId, clientId: clientId, clientCode: clientCode });
}
exports.chatOpenTracker = chatOpenTracker;
function chatCloseTracker(roomId, clientId, clientCode) {
    if (roomId === void 0) { roomId = null; }
    if (clientId === void 0) { clientId = null; }
    if (clientCode === void 0) { clientCode = null; }
    dispatchEvent(tracker_events_1.WIDGET_V2_TRACKER_CHAT_CLOSE, { roomId: roomId, clientId: clientId, clientCode: clientCode });
}
exports.chatCloseTracker = chatCloseTracker;
function chatHiddenTracker(roomId, clientId, clientCode) {
    if (roomId === void 0) { roomId = null; }
    if (clientId === void 0) { clientId = null; }
    if (clientCode === void 0) { clientCode = null; }
    dispatchEvent(tracker_events_1.WIDGET_V2_TRACKER_CHAT_HIDDEN, { roomId: roomId, clientId: clientId, clientCode: clientCode });
}
exports.chatHiddenTracker = chatHiddenTracker;
function widgetTrackerCheckOpen(ids) {
    if (ids === void 0) { ids = []; }
    if (typeof ids == null)
        ids = [];
    if (!Array.isArray(ids))
        ids = [];
    dispatchEvent(exports.WIDGET_V2_TRACKER_CHECK_OPEN, { ids: ids });
}
exports.widgetTrackerCheckOpen = widgetTrackerCheckOpen;
function receivedMessage(msg) {
    if (!!receivedMessageIds[msg.id])
        return;
    receivedMessageIds[msg.id] = true;
    dispatchEvent(exports.WIDGET_V2_RECEIVED_MESSAGE, msg);
}
exports.receivedMessage = receivedMessage;
function receivedSomeMessage(msg) {
    if (!!receivedMessageIds[msg.id])
        return;
    receivedMessageIds[msg.id] = true;
    dispatchEvent(exports.WIDGET_V2_RECEIVED_SOME_MESSAGE, msg);
}
exports.receivedSomeMessage = receivedSomeMessage;
function dispatchEvent(target, detail) {
    if (detail === void 0) { detail = {}; }
    document.dispatchEvent(new CustomEvent(target, { detail: detail }));
}
exports.dispatchEvent = dispatchEvent;
