"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var classesForColumn = class_generator_1.classNamesGeneratorFor(function (add) {
    add("fixedColumn", React.createElement("div", { className: "flex-none" }));
    add("stretchColumn", React.createElement("div", { className: "flex-auto" }));
    add("rightBorder", React.createElement("div", { className: "br" }));
    add("sideMenuOpened", React.createElement("div", { className: "wmenu width-animate" }));
    add("sideMenuClosed", React.createElement("div", { className: "w3 width-animate" }));
}, React.createElement("div", { className: "b--light-gray flex-column flex items-stretch" }));
function Column(props) {
    return React.createElement("div", { className: classesForColumn(props), onClick: props.onClick, style: props.style }, props.children);
}
exports.Column = Column;
var classesForRow = class_generator_1.classNamesGeneratorFor(function (add) {
    add("stretchRow", React.createElement("div", { className: "flex-auto" }));
    add("fixedRow", React.createElement("div", { className: "flex-none" }));
    add("bottomBorder", React.createElement("div", { className: "bb" }));
}, React.createElement("div", { className: "b--light-gray flex flex-row items-stretch" }));
function Row(props) {
    var borderClass = "";
    if (props.borderKnobExpandSize)
        borderClass = " border-knob-" + props.borderKnobExpandSize;
    return React.createElement("div", { className: classesForRow(props) + borderClass, onWheel: props.onScroll, onScroll: props.onScroll }, props.children);
}
exports.Row = Row;
var classesForFlexContainer = class_generator_1.classNamesGeneratorFor(function (add) {
    add("topContainer", React.createElement("div", { className: "min-w8 min-vh-100 h-1px" }));
    add("vertical", React.createElement("div", { className: "flex-column" }));
    add("horizontal", React.createElement("div", { className: "flex-row" }));
}, React.createElement("div", { className: "flex flex-auto max-vw-100" }));
function FlexContainer(props) {
    return React.createElement("div", { className: classesForFlexContainer(props), key: props.key, id: props.id, tabIndex: props.tabIndex, onFocus: props.onFocus, onBlur: props.onBlur }, props.children);
}
exports.FlexContainer = FlexContainer;
var classesForVCenteringContainer = class_generator_1.classNamesGeneratorFor(function (add) {
}, React.createElement("div", { className: "dt w-100 h-100" }));
function VCenteringContainer(props) {
    return React.createElement("div", { className: classesForVCenteringContainer(props) }, props.children);
}
exports.VCenteringContainer = VCenteringContainer;
var classesForVCentered = class_generator_1.classNamesGeneratorFor(function (add) {
}, React.createElement("div", { className: "dtc v-mid" }));
function VCentered(props) {
    return React.createElement("div", { className: classesForVCentered(props) }, props.children);
}
exports.VCentered = VCentered;
var classesForVBottomed = class_generator_1.classNamesGeneratorFor(function (add) {
}, React.createElement("div", { className: "dtc v-btm" }));
function VBottomed(props) {
    return React.createElement("div", { className: classesForVBottomed(props) }, props.children);
}
exports.VBottomed = VBottomed;
var classesForVTopped = class_generator_1.classNamesGeneratorFor(function (add) {
}, React.createElement("div", { className: "dtc v-top" }));
function VTopped(props) {
    return React.createElement("div", { className: classesForVTopped(props) }, props.children);
}
exports.VTopped = VTopped;
