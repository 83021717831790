"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var layouts_1 = require("../../components/layouts");
var debouncing_inputs_1 = require("../../components/debouncing-inputs");
var I18n_1 = require("../../I18n");
var React = require("react");
var chat_room_reducer_1 = require("../reducers/chat-room-reducer");
var inputs_reducer_1 = require("../../reducers/subreducers/inputs-reducer");
var widget_state_1 = require("../reducers/widget-state");
var svgs_1 = require("../../components/svgs");
var file_upload_reducer_1 = require("../reducers/file-upload-reducer");
var color_editable_1 = require("../../components/color-editable");
var key_navigation_utils_1 = require("../../utils/key-navigation-utils");
var memoizers_1 = require("../../utils/memoizers");
var object_helpers_1 = require("../../utils/object-helpers");
var bisect_1 = require("../../utils/bisect");
function getFile(e) {
    if (key_navigation_utils_1.isEnterOrSpace(e)) {
        e.currentTarget.lastElementChild.click();
    }
}
var WidgetChatInput;
(function (WidgetChatInput) {
    function render(dispatch) {
        document.addEventListener("DOMContentLoaded", function () {
            var element = document.querySelector("#change_humen");
            console.log(element);
        });
        var dispatcher = {
            uploadFile: function (e) {
                var files = e.target.files;
                dispatch(file_upload_reducer_1.uploadFile(files));
            },
            sendMessage: function () { return dispatch(chat_room_reducer_1.sendTextMessage()); },
            sendMessageOnKeyDown: function (e) { return key_navigation_utils_1.isEnterOrSpace(e) && dispatch(chat_room_reducer_1.sendTextMessage()); },
            sendMessageOnEnter: function (e) {
                if ((e.key == 'Enter' || e.keyCode === 13) && !e.shiftKey) {
                    var target = e.target;
                    e.preventDefault();
                    dispatch(chat_room_reducer_1.sendTextMessage(target.value));
                }
            },
            updateInput: function (roomId) { return inputs_reducer_1.inputChangeDispatcher(function (i) {
                dispatch(chat_room_reducer_1.roomInput(i));
            }, roomId); },
            settingRooms: function (roomId, tenantName) {
                dispatch(chat_room_reducer_1.chatRoomChangeHuman(roomId, tenantName));
            },
        };
        return memoizers_1.memoizeBySomeProperties({
            stores: object_helpers_1.pick(widget_state_1.WidgetV2State.initialState.stores, "rooms"),
            roomInputs: widget_state_1.WidgetV2State.initialState.roomInputs,
            currentViewingRoomId: widget_state_1.WidgetV2State.initialState.currentViewingRoomId,
            loggedInUser: widget_state_1.WidgetV2State.initialState.loggedInUser,
            connectionErrors: widget_state_1.WidgetV2State.initialState.connectionErrors,
            toggles: widget_state_1.WidgetV2State.initialState.toggles,
            provider_login_mode: widget_state_1.WidgetV2State.initialState.provider_login_mode,
            isIOS: widget_state_1.WidgetV2State.initialState.isIOS,
            colors: widget_state_1.WidgetV2State.initialState.colors,
            tenant_name: widget_state_1.WidgetV2State.initialState.tenant_name,
            plan_settings: widget_state_1.WidgetV2State.initialState.plan_settings,
        }, function (state) {
            var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [state.currentViewingRoomId]);
            var chatInput = state.roomInputs[state.currentViewingRoomId] || "";
            var language = state.loggedInUser.attributes.language;
            var canSendMessage = chatInput.trim();
            var isDisabled = Object.keys(state.connectionErrors).filter(function (k) { return state.connectionErrors[k] !== "success"; }).length > 0;
            var maximizedWidget = state.toggles.widgetMaximized || state.provider_login_mode;
            var aiflg = state.plan_settings.ai_chat_flg;
            if (state.currentViewingRoomId
                && state.currentViewingRoomId !== null
                && state.currentViewingRoomId !== "") {
                var room_settings = room && room.attributes.settings;
                aiflg = (room_settings && room_settings.ai_chat) || false;
            }
            return React.createElement(layouts_1.Column, { stretchColumn: true, style: { flex: 'none' } },
                state.plan_settings.ai_chat_flg &&
                    React.createElement("button", { onClick: function () { return dispatcher.settingRooms(state.currentViewingRoomId, state.tenant_name); }, id: "change_humen", style: { padding: '1%', backgroundColor: 'rgb(90, 178, 221)', color: '#FFF', textAlign: 'center', cursor: 'pointer', display: state.currentViewingRoomId && aiflg == true ? 'block' : 'none', border: 'none' } }, I18n_1.i18n.widgetChangeHuman[language]),
                React.createElement(layouts_1.Row, { fixedRow: true, className: "relative " + (isDisabled ? 'bg-moon-gray' : 'bg-white') + " pv2 ph4 b--light-gray bblr3 bbrr3      " + (maximizedWidget ? " h3_125rem bt bt--light-gray " : " h3 br3 fr ba child mt3") + " " + (state.isIOS ? "h5_5rem" : "") },
                    React.createElement(layouts_1.FlexContainer, { horizontal: true },
                        React.createElement(layouts_1.Column, { stretchColumn: true },
                            React.createElement(debouncing_inputs_1.DebouncingTextArea, { className: "vh-100 f4 fw5 no-resize b--none pea test--chat-input br3 no-focus-glow suppress-ios-zoom\n               " + (maximizedWidget ? "w-90 pt1-3 pl1" : "w-100 pt2 mt1") + "  " + (isDisabled ? 'bg-moon-gray' : 'bg-white'), value: chatInput, disabled: isDisabled, placeholder: I18n_1.i18n.widgetRoomInputMessage[language], onChange: dispatcher.updateInput(state.currentViewingRoomId), onKeyPress: dispatcher.sendMessageOnEnter })),
                        React.createElement(layouts_1.Column, { fixedColumn: true },
                            React.createElement(color_editable_1.ColorEditable, { className: "dt h-100", colors: state.colors, editedColors: { fill: "send_message_button_outline" } }, canSendMessage ?
                                React.createElement("button", { onClick: function () { return !isDisabled && dispatcher.sendMessage(); }, className: "b--none bg-none dtc tc v-mid " + (isDisabled ? '' : 'pointer') + "\n                " + (maximizedWidget ? "pa h3 " : "pa2") + " test--send-button", style: { outlineColor: state.colors.outline }, onKeyDown: function (e) { return !isDisabled && dispatcher.sendMessageOnKeyDown(e); } },
                                    React.createElement("div", { tabIndex: -1, className: "no-focus-glow" },
                                        React.createElement(svgs_1.SVGSend, { width: 19, height: 19 })))
                                : React.createElement("label", { role: "button", "aria-label": "Upload file", tabIndex: 0, id: "upload-file-label", style: { outlineColor: state.colors.outline, display: aiflg ? "none" : "" }, className: "relative overflow-hidden b--none input-reset dtc tc v-mid " + (maximizedWidget ? "pa h3" : "pa2"), onKeyDown: function (e) { return getFile(e); } },
                                    aiflg,
                                    React.createElement(svgs_1.SVGAddFile, { width: 19, height: 19 }),
                                    React.createElement("input", { tabIndex: -1, type: "file", id: "fileUpload", className: "pointer db min-h-100 tr w2 o-0 absolute right-0 top-0", onChange: dispatcher.uploadFile })))))));
        });
    }
    WidgetChatInput.render = render;
})(WidgetChatInput = exports.WidgetChatInput || (exports.WidgetChatInput = {}));
