"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function sequence(effects, next) {
    return effects.concat(next);
}
exports.sequence = sequence;
function sequenceReduction(effects, reduction) {
    var reducedEffects = reduction.effects;
    if (reducedEffects) {
        effects = effects.concat(reducedEffects);
    }
    return { state: reduction.state, effects: effects };
}
exports.sequenceReduction = sequenceReduction;
