"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function includeGoogleAnalytics() {
    return {
        effectType: "include-google-analytics",
    };
}
exports.includeGoogleAnalytics = includeGoogleAnalytics;
function sendGoogleAnalyticsEvent(action, category, label, value) {
    return {
        effectType: "send-google-analytics-event",
        action: action,
        category: category,
        label: label,
        value: value
    };
}
exports.sendGoogleAnalyticsEvent = sendGoogleAnalyticsEvent;
function withGoogleAnalytics(dispatch, googleAnalyticsConfig) {
    return function (effect) {
        if (!googleAnalyticsConfig)
            return;
        var clientCode = googleAnalyticsConfig.ga_client_code;
        if (!clientCode)
            return;
        var mode = googleAnalyticsConfig.mode;
        switch (effect.effectType) {
            case "send-google-analytics-event":
                if (mode === "ga" && window.ga !== "undefined") {
                    window.ga('oksky.send', {
                        hitType: 'event',
                        eventCategory: effect.category,
                        eventAction: effect.action,
                        eventLabel: effect.label
                    });
                }
                else if (mode === "gtag" && window.gtag !== "undefined") {
                    window.gtag('event', effect.action, {
                        'event_category': effect.category,
                        'event_label': effect.label,
                        'value': effect.value,
                    });
                }
                break;
            case "include-google-analytics":
                if (mode === "ga") {
                    (function (i, s, o, g, r, a, m) {
                        i['GoogleAnalyticsObject'] = r;
                        i[r] = i[r] || function () {
                            (i[r].q = i[r].q || []).push(arguments);
                        }, i[r].l = (1 * new Date());
                        a = s.createElement(o),
                            m = s.getElementsByTagName(o)[0];
                        a.async = 1;
                        a.src = g;
                        m.parentNode.insertBefore(a, m);
                    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
                    window.ga("create", clientCode, "auto", "oksky");
                }
                else if (mode === "gtag") {
                    var googleTagManager = document.createElement("script");
                    googleTagManager.async = true;
                    googleTagManager.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=" + clientCode);
                    document.head.appendChild(googleTagManager);
                    window.dataLayer = window.dataLayer || [];
                    window.gtag = function gtag() {
                        window.dataLayer.push(arguments);
                    };
                    window.gtag('js', new Date());
                    window.gtag('config', clientCode, { 'send_page_view': false });
                }
                break;
        }
    };
}
exports.withGoogleAnalytics = withGoogleAnalytics;
