"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var fake_progress_bar_1 = require("./fake-progress-bar");
var svgs_1 = require("./svgs");
var color_editable_1 = require("./color-editable");
var getBgColor = function (kind) {
    switch (kind) {
        case "error":
            return "bg-washed-red";
        case "progress":
            return "bg-none";
        case "text":
            return "bg-none";
    }
};
function errorContent(props) {
    return React.createElement("div", { className: "fill-red red" },
        React.createElement(svgs_1.SVGAlert, { width: 12, height: 12 }),
        props.roomNotification.attributes.body,
        React.createElement("div", { className: "dib fr fill-light-silver pointer", onClick: function (e) { return props.onClose(props.roomNotification.id); } },
            React.createElement(svgs_1.SVGClose, { className: "w1 h1" })));
}
function progressContent(props) {
    return props.bar ? React.createElement(fake_progress_bar_1.FakeProgressBar, null) :
        React.createElement(color_editable_1.ColorEditable, { colorKeys: [props.colorKey], colors: null, editedColors: null, className: "lds-ellipsis fr" },
            React.createElement(color_editable_1.ColorEditable, { colors: props.colors, editedColors: { background: props.colorKey } }),
            React.createElement(color_editable_1.ColorEditable, { colors: props.colors, editedColors: { background: props.colorKey } }),
            React.createElement(color_editable_1.ColorEditable, { colors: props.colors, editedColors: { background: props.colorKey } }),
            React.createElement(color_editable_1.ColorEditable, { colors: props.colors, editedColors: { background: props.colorKey } }));
}
function textContent(props) {
    return React.createElement("div", null, props.roomNotification.attributes.body);
}
function getRoomNotificationContent(props) {
    switch (props.roomNotification.attributes.kind) {
        case "error":
            return errorContent(props);
        case "progress":
            return progressContent(props);
        case "text":
            return textContent(props);
    }
}
function RoomNotificationMessage(props) {
    return React.createElement("div", { className: "pa4 " + getBgColor(props.roomNotification.attributes.kind) }, getRoomNotificationContent(props));
}
exports.RoomNotificationMessage = RoomNotificationMessage;
