"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var reducers_1 = require("../../core/reducers");
var inputs_reducer_1 = require("../../reducers/subreducers/inputs-reducer");
var chat_room_reducer_1 = require("./chat-room-reducer");
var initial_loading_reducer_1 = require("./initial-loading-reducer");
var complete_request_reducer_1 = require("./complete-request-reducer");
var action_cable_reducer_1 = require("./action-cable-reducer");
var toggles_reducer_1 = require("../../reducers/subreducers/toggles-reducer");
var room_list_reducer_1 = require("./room-list-reducer");
var header_reducer_1 = require("./header-reducer");
var tracker_entry_reducer_1 = require("./tracker-entry-reducer");
var widget_frame_reducer_1 = require("./widget-frame-reducer");
var file_upload_reducer_1 = require("./file-upload-reducer");
var room_list_ordering_computed_1 = require("./room-list-ordering-computed");
var sizing_reducer_1 = require("../../reducers/subreducers/sizing-reducer");
var flash_reducer_1 = require("../../reducers/subreducers/flash-reducer");
var connection_status_reducer_1 = require("./connection-status-reducer");
var in_business_computed_reducer_1 = require("./computed/in-business-computed-reducer");
var settings_page_reducer_1 = require("./settings-page-reducer");
var tracker_checker_reducer_1 = require("./tracker-checker-reducer");
var subReducer = reducers_1.subReducersFor();
var computed = reducers_1.computedFor();
function reduce(state, action) {
    var chain = reducers_1.reducerChain(state, action)
        .apply(complete_request_reducer_1.reduceCompleteRequest)
        .apply(subReducer("sizes", sizing_reducer_1.reduceSizings))
        .apply(subReducer("inputs", inputs_reducer_1.reduceInputs))
        .apply(subReducer("toggles", toggles_reducer_1.reduceToggle))
        .apply(subReducer("flashes", flash_reducer_1.reduceFlash))
        .apply(connection_status_reducer_1.reduceConnectionStatus)
        .apply(tracker_entry_reducer_1.reduceTrackerEntry)
        .apply(widget_frame_reducer_1.reduceWidgetFrame)
        .apply(action_cable_reducer_1.reduceActionCable)
        .apply(chat_room_reducer_1.reduceChatRoom)
        .apply(room_list_reducer_1.reduceRoomList)
        .apply(header_reducer_1.reduceHeader)
        .apply(file_upload_reducer_1.reduceFileUpload)
        .apply(settings_page_reducer_1.reduceSettingsPage)
        .apply(initial_loading_reducer_1.reduceInitialLoading)
        .apply(computed("inBusinessHours", in_business_computed_reducer_1.recomputeInBusiness))
        .apply(computed("errorMessages", settings_page_reducer_1.validateWidgetInputErrors))
        .apply(computed("roomListRows", room_list_ordering_computed_1.recomputeRoomListOrder))
        .apply(tracker_checker_reducer_1.reduceTrackerCheck);
    return chain.result();
}
exports.reduce = reduce;
