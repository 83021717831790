"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var layouts_1 = require("./layouts");
var svgs_1 = require("./svgs");
var classnamesForFullScreenModal = class_generator_1.classNamesGeneratorFor(function (add) {
    add("isOpen", React.createElement("div", { className: "slide-up-animate" }), React.createElement("div", { className: "slide-down-animate" }));
}, React.createElement("div", { className: "bg-white w-100 h-100 z-5 fixed flex flex-column top-0 left-0" }));
function FullScreenModal(props) {
    return React.createElement(layouts_1.Column, { stretchColumn: true, className: classnamesForFullScreenModal(props) }, props.children);
}
exports.FullScreenModal = FullScreenModal;
var classnamesForFullScreenHeader = class_generator_1.classNamesGeneratorFor(function (add) {
}, React.createElement("div", { className: "bg-white dark-gray  tc f3 fw7 pv3 ph4 bb b--light-gray flex-none w-100 relative" }));
function FullScreenHeader(props) {
    return React.createElement("div", { className: classnamesForFullScreenHeader(props) },
        props.children,
        React.createElement("div", { className: "absolute top-0 right-2 h-100 pointer fill-gray", onClick: props.onClose },
            React.createElement(layouts_1.VCenteringContainer, null,
                React.createElement(layouts_1.VCentered, null,
                    React.createElement(svgs_1.SVGClose, { width: 11, height: 11 })))));
}
exports.FullScreenHeader = FullScreenHeader;
function FullScreenBody(props) {
    return React.createElement(layouts_1.Column, { stretchColumn: true, className: props.isScrollable && "overflow-y-auto flex-auto gpu" },
        React.createElement(layouts_1.Column, { stretchColumn: true, className: "ma mv0 " + props.innerClassNames }, props.children));
}
exports.FullScreenBody = FullScreenBody;
var classnamesForFullScreenFooter = class_generator_1.classNamesGeneratorFor(function (add) {
}, React.createElement("div", { className: "bg-silver tc f3 fw7 pa4 w-100" }));
function FullScreenFooter(props) {
    return React.createElement("div", { className: classnamesForFullScreenFooter(props) }, props.children);
}
exports.FullScreenFooter = FullScreenFooter;
var classNamesForFullScreenSaveButton = class_generator_1.classNamesGeneratorFor(function (add) {
    add("disabled", React.createElement("div", { className: "bg-moon-gray" }), React.createElement("div", { className: "bg-bright-blue pointer" }));
}, React.createElement("div", { className: "white fw6 b--none ph4 pv3 br2 min-w-120px" }));
function FullScreenSaveButton(props) {
    return React.createElement("button", { className: props.className || classNamesForFullScreenSaveButton(props), onClick: props.onClick, disabled: props.disabled }, props.children);
}
exports.FullScreenSaveButton = FullScreenSaveButton;
function FullScreenCancelButton(props) {
    return React.createElement("button", { className: props.className || "bg-white gray fw6 ba b--moon-gray ph4 pv3 br2 pointer mr2 min-w-120px", onClick: props.onClick }, props.children);
}
exports.FullScreenCancelButton = FullScreenCancelButton;
