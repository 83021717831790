"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var url_parser_1 = require("../../utils/url-parser");
var cookie_utils_1 = require("../../utils/cookie-utils");
function loadingRequest(name) {
    return {
        type: "loading-request",
        name: name,
    };
}
exports.loadingRequest = loadingRequest;
function requestAjax(name, config) {
    return {
        effectType: "request-ajax",
        name: name,
        config: config
    };
}
exports.requestAjax = requestAjax;
function abortRequest(name, when) {
    if (when === void 0) { when = Date.now(); }
    return {
        effectType: "abort-request",
        name: name,
        when: when
    };
}
exports.abortRequest = abortRequest;
function completeRequest(requestEffect, status, response, headers, when) {
    if (when === void 0) { when = Date.now(); }
    return {
        type: "complete-request",
        name: requestEffect.name,
        success: status >= 200 && status < 300 || status === 304,
        status: status,
        response: response,
        headers: headers,
        when: when
    };
}
exports.completeRequest = completeRequest;
function withAjax(dispatch, queueSize, rootUrl, xdCookieNames) {
    if (queueSize === void 0) { queueSize = 6; }
    if (rootUrl === void 0) { rootUrl = ""; }
    if (xdCookieNames === void 0) { xdCookieNames = []; }
    var requests = {};
    var existing;
    var canceled = false;
    var xhrQueue = [];
    var configsQueue = [];
    var executingCount = 0;
    var checkAndExecuteNext = function () {
        if (canceled)
            return;
        while (executingCount < queueSize && xhrQueue.length) {
            var nextXhr = xhrQueue.shift();
            var nextConfig = configsQueue.shift();
            executingCount++;
            executeXhrWithConfig(nextConfig, nextXhr, rootUrl, xdCookieNames);
        }
    };
    return function (effect) {
        var normalizedName;
        switch (effect.effectType) {
            case "request-ajax":
                normalizedName = effect.name.join("-");
                if (requests[normalizedName]) {
                    if (process.env.NODE_ENV !== "production") {
                        console.warn("A request with the name", effect.name, "was executed twice, the first will be aborted.");
                    }
                    normalizedName = effect.name.join("-");
                    existing = requests[normalizedName];
                    if (existing) {
                        existing.abort();
                        executingCount -= 1;
                        delete requests[normalizedName];
                    }
                    var idx = xhrQueue.indexOf(existing);
                    if (idx !== -1) {
                        xhrQueue.splice(idx, 1);
                        configsQueue.splice(idx, 1);
                    }
                    checkAndExecuteNext();
                }
                if (canceled)
                    break;
                dispatch(loadingRequest(effect.name));
                var xhr_1 = requests[normalizedName] = new XMLHttpRequest();
                var completeXhr_1 = function () {
                    executingCount--;
                    if (requests[normalizedName] === xhr_1) {
                        delete requests[normalizedName];
                    }
                    if (canceled)
                        return;
                    checkAndExecuteNext();
                };
                xhr_1.onerror = function () {
                    completeXhr_1();
                    dispatch(completeRequest(effect, 0, "", ""));
                };
                xhr_1.onload = function () {
                    completeXhr_1();
                    dispatch(completeRequest(effect, xhr_1.status, xhr_1.responseText, xhr_1.getAllResponseHeaders()));
                };
                xhr_1.ontimeout = function () {
                    completeXhr_1();
                    dispatch(completeRequest(effect, 408, "", ""));
                };
                if (executingCount < queueSize) {
                    executingCount++;
                    executeXhrWithConfig(effect.config, xhr_1, rootUrl, xdCookieNames);
                }
                else {
                    xhrQueue.push(xhr_1);
                    configsQueue.push(effect.config);
                }
        }
    };
}
exports.withAjax = withAjax;
function executeXhrWithConfig(config, xhr, rootUrl, xdCookieNames) {
    if (rootUrl === void 0) { rootUrl = ""; }
    if (xdCookieNames === void 0) { xdCookieNames = []; }
    xhr.withCredentials = false;
    xhr.open(config.method, getAjaxUrl(config, rootUrl), true);
    var headers = config.headers;
    if (headers) {
        for (var key in headers) {
            xhr.setRequestHeader(key, headers[key]);
        }
    }
    xhr.setRequestHeader("Xd-Cookie", cookie_utils_1.getXdCookiesHeaderValue(xdCookieNames));
    xhr.send(getAjaxBody(config));
}
exports.executeXhrWithConfig = executeXhrWithConfig;
function getAjaxUrl(config, rootUrl) {
    if (rootUrl === void 0) { rootUrl = ""; }
    var url = url_parser_1.urlJoin(rootUrl, config.url);
    var query = config.query;
    if (query) {
        var parts = [];
        for (var key in query) {
            parts.push(encodeURIComponent(key) + "=" + encodeURIComponent(query[key]));
        }
        if (parts.length)
            url += (url.indexOf("?") === -1 ? "?" : "&") + parts.join("&");
    }
    return url;
}
exports.getAjaxUrl = getAjaxUrl;
function getAjaxBody(config) {
    if (config.body)
        return config.body;
    if (config.json)
        return JSON.stringify(config.json);
    return null;
}
exports.getAjaxBody = getAjaxBody;
var headerSeparator = '\u000d\u000a';
var headerValueSeparator = '\u003a\u0020';
function parseResponseHeaders(headerStr) {
    var headers = {};
    if (!headerStr) {
        return headers;
    }
    var headerPairs = headerStr.split(headerSeparator);
    for (var i = 0, len = headerPairs.length; i < len; i++) {
        var headerPair = headerPairs[i];
        var idx = headerPair.indexOf(headerValueSeparator);
        if (idx > 0) {
            headers[headerPair.substring(0, idx).toLowerCase()] = headerPair.substring(idx + 2);
        }
    }
    return headers;
}
exports.parseResponseHeaders = parseResponseHeaders;
function encodeResponseHeaders(headers) {
    return Object.keys(headers).map(function (k) { return k + headerValueSeparator + headers[k]; }).join(headerSeparator);
}
exports.encodeResponseHeaders = encodeResponseHeaders;
function encodeQueryParts(parts) {
    var result = {};
    for (var k in parts) {
        var value = parts[k];
        if (typeof value === "string") {
            result[k] = value;
        }
        else if (typeof value === "number") {
            result[k] = value + "";
        }
        else if (Array.isArray(value)) {
            result[k] = value.join(",");
        }
        else {
            var subParts = encodeQueryParts(value);
            for (var subKey in subParts) {
                result[k + "[" + subKey + "]"] = subParts[subKey];
            }
        }
    }
    return result;
}
exports.encodeQueryParts = encodeQueryParts;
