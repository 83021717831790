"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var sequence_services_1 = require("./sequence-services");
var locationDefaults = {
    pathname: "",
    search: "",
    hash: "",
};
function historyPush(location) {
    return { effectType: "history-push", location: tslib_1.__assign({}, locationDefaults, location) };
}
exports.historyPush = historyPush;
function historyReplace(location) {
    return {
        effectType: "history-replace",
        location: location
    };
}
exports.historyReplace = historyReplace;
function visit(location) {
    return { type: "visit", location: tslib_1.__assign({}, locationDefaults, location) };
}
exports.visit = visit;
function linkClick(location) {
    var pathname = location.pathname, search = location.search, hash = location.hash;
    return {
        type: 'link-click',
        location: { pathname: pathname, search: search, hash: hash }
    };
}
exports.linkClick = linkClick;
function setOnUnloadMessage(enable) {
    return {
        effectType: 'set-on-unload-message',
        enable: enable
    };
}
exports.setOnUnloadMessage = setOnUnloadMessage;
function clearOnUnloadMessage() {
    return { effectType: "set-on-unload-message", enable: false };
}
exports.clearOnUnloadMessage = clearOnUnloadMessage;
exports.emptyLocation = { pathname: "", hash: "", search: "" };
function withHistory(dispatch, history) {
    var unloadMessage = false;
    var onUnload = function (e) {
        if (unloadMessage) {
            e.returnValue = unloadMessage;
            return unloadMessage;
        }
    };
    window.addEventListener('beforeunload', onUnload);
    history.listen(function (location, action) {
        dispatch(visit(location));
    });
    history.replace(history.location);
    return function (effect) {
        switch (effect.effectType) {
            case "set-on-unload-message":
                unloadMessage = effect.enable;
                break;
            case 'history-replace':
                history.replace(effect.location);
                break;
            case 'history-push':
                history.push(effect.location);
                break;
        }
    };
}
exports.withHistory = withHistory;
function spSetCurrentRender(currentTab, currentRender) {
    return {
        type: "sp-set-current-render",
        currentTab: currentTab,
        currentRender: currentRender,
    };
}
exports.spSetCurrentRender = spSetCurrentRender;
function navigationReducer(route) {
    return function (state, action) {
        var effects = [];
        switch (action.type) {
            case 'visit':
                var isSpMode = action.location.pathname.split("/").slice()[1] === 'sp';
                if (isSpMode) {
                    action.location.pathname = "/" + action.location.pathname.split("/").slice(2).join("/");
                }
                var reduction = route(state, action.location);
                if (reduction.effects) {
                    effects = sequence_services_1.sequence(effects, reduction.effects);
                }
                state = reduction.state;
                break;
            case 'link-click':
                effects = sequence_services_1.sequence(effects, historyPush(action.location));
                break;
            case "sp-set-current-render":
                state = tslib_1.__assign({}, state);
                state.sp.currentTab = action.currentTab;
                state.sp.currentRender = action.currentRender;
                if (action.currentRender === 'spSupportsAwaitingPage') {
                    state.inputs = tslib_1.__assign({}, state.inputs);
                    state.inputs.supportSearchType = 'awaiting';
                }
                if (action.currentRender === 'spSupportsUnrepliedPage') {
                    state.inputs = tslib_1.__assign({}, state.inputs);
                    state.inputs.supportSearchType = 'supporting';
                    state.inputs.supportSearchReplyType = 'unreplied';
                }
                if (action.currentRender === 'spSupportsRepliedPage') {
                    state.inputs = tslib_1.__assign({}, state.inputs);
                    state.inputs.supportSearchType = 'supporting';
                    state.inputs.supportSearchReplyType = 'replied';
                }
                break;
        }
        return { state: state, effects: effects };
    };
}
exports.navigationReducer = navigationReducer;
function inferBasePath() {
    var tags = document.getElementsByTagName("BASE");
    if (tags.length === 0)
        return "/";
    var parts = tags[tags.length - 1].href.split("/");
    return "/" + parts.slice(3).join("/");
}
exports.inferBasePath = inferBasePath;
function visitDispatcher(dispatch) {
    return (function (event) {
        var anchorElement = event.target;
        while (anchorElement.parentElement != null && anchorElement.tagName != "A") {
            anchorElement = anchorElement.parentElement;
        }
        var pathname = anchorElement.pathname, search = anchorElement.search, hash = anchorElement.hash;
        if (pathname[0] !== '/')
            pathname = '/' + pathname;
        var basePath = inferBasePath();
        if (pathname.slice(0, basePath.length) === basePath) {
            pathname = "/" + pathname.slice(basePath.length);
        }
        dispatch(linkClick({ pathname: pathname, search: search, hash: hash }));
        event.preventDefault();
    });
}
exports.visitDispatcher = visitDispatcher;
function spVisitDispatcher(dispatch, currentTab, currentRender) {
    return (function (event) {
        var anchorElement = event.target;
        while (anchorElement.parentElement != null && anchorElement.tagName != "A") {
            anchorElement = anchorElement.parentElement;
        }
        var pathname = anchorElement.pathname, search = anchorElement.search, hash = anchorElement.hash;
        if (pathname[0] !== '/')
            pathname = '/' + pathname;
        var basePath = inferBasePath();
        if (pathname.slice(0, basePath.length) === basePath) {
            pathname = "/" + pathname.slice(basePath.length);
        }
        dispatch(linkClick({ pathname: pathname, search: search, hash: hash }));
        dispatch(spSetCurrentRender(currentTab, currentRender));
        event.preventDefault();
    });
}
exports.spVisitDispatcher = spVisitDispatcher;
function parseSearch(search) {
    var result = {};
    search.replace(/(^\?)/, '').split("&").forEach(function (part) {
        if (!part)
            return;
        var _a = part.split("="), a = _a[0], b = _a[1];
        if (!a || !b)
            return;
        result[decodeURIComponent(a)] = decodeURIComponent(b);
    });
    return result;
}
exports.parseSearch = parseSearch;
function encodeSearch(query) {
    var result = [];
    for (var k in query) {
        result.push(encodeURIComponent(k) + "=" + encodeURIComponent(query[k] + ""));
    }
    return result.join("&");
}
exports.encodeSearch = encodeSearch;
