"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var MessagesStore;
(function (MessagesStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    MessagesStore.indexer = new bisect_1.Indexer("byId");
    MessagesStore.indexer.addIndex("byId", function (message) { return [assertInnerId(message)]; });
    MessagesStore.indexer.addIndex("byRoomIdAndCreatedAtUnix", function (message) { return [getRelationshipId(message, "room"),
        message.attributes.created_at_unix]; });
    MessagesStore.indexer.addIndex("byRoomIdKindAndCreatedAtUnix", function (message) { return [getRelationshipId(message, "room"),
        message.attributes.kind, message.attributes.created_at_unix]; });
    MessagesStore.indexer.addIndex("byRoomIdAndUserId", function (message) { return [getRelationshipId(message, "room"), getRelationshipId(message, "user")]; });
    MessagesStore.indexer.addGroupedIndex("byCreatedAtUnixOfRoomLatest", function (message) { return [message.attributes.created_at_unix]; }, "byRoomIdAndCreatedAtUnix", function (message) { return [getRelationshipId(message, "room")]; }, function (iter, reverseIter) { return reverseIter(); });
})(MessagesStore = exports.MessagesStore || (exports.MessagesStore = {}));
