"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getCookie(name) {
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + name.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1') + '=([^;]*)'));
    return match ? match[1] : null;
}
exports.getCookie = getCookie;
;
function setCookie(name, value, numDays) {
    if (numDays === void 0) { numDays = 365; }
    var d = new Date();
    d.setTime(d.getTime() + (numDays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}
exports.setCookie = setCookie;
function setCookies(cookies, prefix) {
    if (prefix === void 0) { prefix = ""; }
    for (var i = 0; i < cookies.length; ++i) {
        var cookie = cookies[i];
        var splitted = cookie.split("=");
        if (!splitted)
            continue;
        var name = splitted[0];
        var value = splitted[1];
        if (name && value) {
            setCookie(prefix + name, value);
        }
    }
}
exports.setCookies = setCookies;
;
function getXdCookiesHeaderValue(xdCookieNames) {
    if (xdCookieNames === void 0) { xdCookieNames = []; }
    var result = [];
    for (var i = 0; i < xdCookieNames.length; ++i) {
        var cookieName = xdCookieNames[i];
        var cookieValue = getCookie("xd-" + cookieName);
        if (cookieValue) {
            result.push(cookieName + "=" + cookieValue);
        }
    }
    if (result.length > 0) {
        return result.join("; ");
    }
    else {
        return "-";
    }
}
exports.getXdCookiesHeaderValue = getXdCookiesHeaderValue;
;
