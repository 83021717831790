"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var memoizers_1 = require("../../../utils/memoizers");
var widget_state_1 = require("../widget-state");
exports.recomputeInBusiness = memoizers_1.memoizeBySomeProperties({
    schedules: widget_state_1.WidgetV2State.initialState.schedules,
    now: widget_state_1.WidgetV2State.initialState.now,
}, function (state) {
    var inBusiness = false;
    var date = new Date(state.now);
    var day = date.getDay();
    for (var i = 0; i < state.schedules.length; i++) {
        var schedule = state.schedules[i];
        if (schedule.day_of_week === day) {
            var startHour = (schedule.start_time / 100) | 0;
            var startMinute = (schedule.start_time % 100);
            var endHour = (schedule.end_time / 100) | 0;
            var endMinute = (schedule.end_time % 100);
            return date.getHours() >= startHour && date.getMinutes() >= startMinute &&
                date.getHours() <= endHour && date.getHours() <= endMinute;
        }
    }
    return inBusiness;
});
