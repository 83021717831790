"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var balloon_1 = require("./balloon");
var formats_1 = require("./formats");
var user_avatar_1 = require("./user-avatar");
var status_badge_1 = require("./status-badge");
var classNamesForBalloon = class_generator_1.classNamesGeneratorFor(function (add) {
    add("bubbleLeft", React.createElement("div", { className: "nl5" }));
    add("bubbleRight", React.createElement("div", { className: "ml-0_25" }));
    add("bubbleCenter", React.createElement("div", { className: "nl1_5" }));
    add("wide", React.createElement("div", { className: "nl1_5" }));
    add("narrow", React.createElement("div", { className: "nl2_5" }));
}, React.createElement("div", { className: "child w4 word-break left-0 tc pa2 no-pointer-events white f5 z-9999 test--user-bubble-balloon" }));
var classNamesForBalloonTriangle = class_generator_1.classNamesGeneratorFor(function (add) {
    add("bubbleLeft", React.createElement("div", { className: "ml-50" }));
    add("bubbleRight", React.createElement("div", { className: "" }));
    add("bubbleCenter", React.createElement("div", { className: "ml-2_75" }));
    add("extra_small", React.createElement("div", { className: "nl2" }));
    add("wide", React.createElement("div", { className: "right-4" }));
    add("narrow", React.createElement("div", { className: "right-4" }));
}, React.createElement("div", { className: "center absolute top100" }));
var classNamesForUserAvatar = class_generator_1.classNamesGeneratorFor(function (add) {
    add("medium", React.createElement("div", { className: "w3_5 h3_5" }));
    add("normal", React.createElement("div", { className: "w3 h3" }));
    add("small", React.createElement("div", { className: "w2 h2" }));
    add("extra_small", React.createElement("div", { className: "w1 h1" }));
    add("whiteBorder", React.createElement("div", { className: "b-solid ba b--white" }));
}, React.createElement("div", { className: "dib mv2 mh1 " }));
function UserBubble(props) {
    var showBalloon = !!(props.bubbleLeft || props.bubbleRight || props.bubbleCenter || props.wide || props.narrow || props.over);
    return React.createElement("div", { className: "dib hide-child test--user-bubble" },
        React.createElement("div", { className: "relative h-0" }, showBalloon && React.createElement(balloon_1.Balloon, { dark: true, above: !props.over, over: props.over, className: classNamesForBalloon(props) },
            props.withoutUniqueName ? formats_1.formatNameWithoutUniqueName(props.user) : formats_1.formatName(props.user),
            React.createElement(balloon_1.BalloonTriangle, { small: true, pointDown: true, dark: true, className: classNamesForBalloonTriangle(props) }))),
        React.createElement(user_avatar_1.UserAvatar, { className: classNamesForUserAvatar(props) + " bg-white", user: props.user, appSettings: props.appSettings, tenant: props.tenant }, props.showStatus &&
            React.createElement(status_badge_1.StatusBadge, { className: "absolute bottom-0 right-0", user: props.user })));
}
exports.UserBubble = UserBubble;
