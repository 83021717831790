"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function wentOffline(when) {
    if (when === void 0) { when = Date.now(); }
    return { type: "went-offline", when: when };
}
function wentOnline(when) {
    if (when === void 0) { when = Date.now(); }
    return { type: "went-online", when: when };
}
function withOnlineWatcher(dispatch) {
    var onlineHandler = function () {
        dispatch(wentOnline());
    };
    var offlineHandler = function () {
        dispatch(wentOffline());
    };
    window.removeEventListener("online", onlineHandler);
    window.removeEventListener("offline", offlineHandler);
    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);
    return function () {
    };
}
exports.withOnlineWatcher = withOnlineWatcher;
