"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
function requestTick(after) {
    return {
        effectType: "request-tick",
        after: after
    };
}
exports.requestTick = requestTick;
function updateTime(absoluteTime, relativeTime) {
    return { type: "update-time", absoluteTime: absoluteTime, relativeTime: relativeTime };
}
exports.updateTime = updateTime;
function reduceTime(state, action) {
    var effects = [];
    switch (action.type) {
        case "update-time":
            state = tslib_1.__assign({}, state);
            state.now = action.absoluteTime;
            state.relativeNow = action.relativeTime;
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceTime = reduceTime;
function withTime(dispatch, start) {
    if (start === void 0) { start = Date.now(); }
    var lastTimeoutTime;
    return function (effect) {
        switch (effect.effectType) {
            case "request-tick":
                var now = Date.now();
                var requested = now + effect.after;
                if (lastTimeoutTime == null || lastTimeoutTime > requested) {
                    lastTimeoutTime = requested;
                    setTimeout(function () {
                        lastTimeoutTime = null;
                        var now = Date.now();
                        dispatch(updateTime(now, now - start));
                    }, effect.after);
                }
                break;
        }
    };
}
exports.withTime = withTime;
