"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var classNames = class_generator_1.classNamesGeneratorFor(function (add) {
}, React.createElement("div", { className: "" }));
function FancyCheckbox(props) {
    return React.createElement("label", { className: classNames(props) },
        React.createElement("input", { tabIndex: props.tabIndex, type: "checkbox", checked: props.checked, disabled: props.disabled, onChange: props.onToggle, className: "fancy-checkbox absolute overflow-hidden gpu " }),
        React.createElement("span", { className: "pointer dib relative min-w1 min-h-100 mr2" }),
        props.children);
}
exports.FancyCheckbox = FancyCheckbox;
