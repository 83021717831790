"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var routes_1 = require("../routes");
var json_resources_1 = require("../core/json-resources");
var toast_reducer_1 = require("./toast-reducer");
var global_1 = require("../utils/global");
var I18n_1 = require("../I18n");
var array_utils_1 = require("../utils/array-utils");
var sequence_services_1 = require("../core/services/sequence-services");
var moment = require('moment');
function getHistoryDayLimit() {
    return global_1.global.historyDayLimit || 7;
}
exports.getHistoryDayLimit = getHistoryDayLimit;
function getMessageSearchQueryParams(state) {
    var query = { filter: {} };
    if (state.inputs.messageSearchInput) {
        query.filter["query"] = state.inputs.messageSearchInput;
    }
    query.filter["start_date"] = (state.messagesPage.fromDate || moment().startOf('month')).unix();
    query.filter["end_date"] = (state.messagesPage.toDate || moment().endOf('month')).unix();
    return query;
}
exports.getMessageSearchQueryParams = getMessageSearchQueryParams;
function beginMessageSearch(state, query) {
    var _a;
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    if (!query.filter)
        return { state: state, effects: effects };
    state = tslib_1.__assign({}, state);
    state.messagesPage = tslib_1.__assign({}, state.messagesPage);
    state.messagesPage.rooms = [];
    state.messagesPage.messages = [];
    state.loadingPagedRequests = tslib_1.__assign({}, state.loadingPagedRequests);
    state.loadingPagedRequests[array_utils_1.stringifyArray([exports.searchMessagesRequestName])] = true;
    state.inputs = tslib_1.__assign({}, state.inputs);
    if (query.filter["query"]) {
        state.inputs.messageSearchInput = query.filter["query"].toString();
    }
    if (query.filter["start_date"]) {
        state.messagesPage.fromDate = moment(parseInt(query.filter["start_date"].toString()) * 1000);
    }
    if (query.filter["end_date"]) {
        state.messagesPage.toDate = moment(parseInt(query.filter["end_date"].toString()) * 1000);
    }
    query.include = ["room"];
    query.filter["regexp"] = state.toggles.messagesPageSearchWithRegexp ? "true" : "false";
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.searchMessagesRequestName], routes_1.RapiV1MessagesPath, query));
    (_a = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastSearchingForMessage[language])), state = _a.state, effects = _a.effects);
    return { state: state, effects: effects };
}
exports.beginMessageSearch = beginMessageSearch;
function validateMessageContentLength(state) {
    var content = state.roomInputs[state.currentViewingRoomId];
    return content && content.length > 10000;
}
exports.validateMessageContentLength = validateMessageContentLength;
exports.searchMessagesRequestName = "search-messages-request-name";
