"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var generators_1 = require("../../utils/generators");
var axios_1 = require("axios");
function uploadCSV(name, url, files) {
    return {
        effectType: "upload-csv",
        url: url,
        name: name,
        files: files,
    };
}
exports.uploadCSV = uploadCSV;
function csvUploadSuccess(name, filename) {
    return {
        type: "csv-upload-success",
        name: name,
        filename: filename
    };
}
exports.csvUploadSuccess = csvUploadSuccess;
function csvUploadFailure(name, filename) {
    return {
        type: "csv-upload-failure",
        name: name,
        filename: filename
    };
}
exports.csvUploadFailure = csvUploadFailure;
function downloadS3CSV(url) {
    return {
        effectType: "download-s3-csv",
        url: url,
    };
}
exports.downloadS3CSV = downloadS3CSV;
function downloadBackCSV(url, token) {
    return {
        effectType: "download-back-csv",
        url: url,
        token: token
    };
}
exports.downloadBackCSV = downloadBackCSV;
function withCSV(dispatch, token) {
    return function (e) {
        switch (e.effectType) {
            case "download-s3-csv":
                if (!e.url.startsWith("/")) {
                    var anchorS3 = document.createElement("a");
                    anchorS3.setAttribute("display", "none");
                    anchorS3.href = e.url;
                    document.body.appendChild(anchorS3);
                    anchorS3.click();
                    anchorS3.remove();
                }
                break;
            case "download-back-csv":
                var config = {
                    headers: {
                        'X-Access-Token': token
                    },
                    params: {
                        dltoken: e.token
                    },
                    withCredentials: true,
                    responseType: 'blob',
                };
                axios_1.default.get(e.url, config)
                    .then(function (response) {
                    var disposition = response.headers['content-disposition'];
                    var fileNameMatch = disposition.match(/filename="(.+)"/);
                    var fileName = fileNameMatch ? decodeURIComponent(fileNameMatch[1]) : 'download';
                    var a = document.createElement('a');
                    var url = window.URL.createObjectURL(new Blob([response.data]));
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                    .catch(function (error) {
                    console.error(error);
                });
                break;
            case "upload-csv":
                var _loop_1 = function (file) {
                    var formData = new FormData();
                    formData.append('access_token', token);
                    formData.append('csv', file);
                    var xhr = new XMLHttpRequest();
                    xhr.open('POST', e.url, true);
                    var fileRequestName = e.name.concat(generators_1.genLocalId());
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState === xhr.DONE) {
                            if (xhr.status === 201) {
                                dispatch(csvUploadSuccess(fileRequestName, file.name));
                            }
                            else {
                                console.error("error: Unable to import csc", xhr.responseText);
                                dispatch(csvUploadFailure(fileRequestName, file.name));
                            }
                        }
                    };
                    xhr.send(formData);
                };
                for (var _i = 0, _a = e.files; _i < _a.length; _i++) {
                    var file = _a[_i];
                    _loop_1(file);
                }
                break;
        }
    };
}
exports.withCSV = withCSV;
