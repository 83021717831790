"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function setLocalSettings(value) {
    return {
        effectType: "set-local-settings",
        value: value
    };
}
exports.setLocalSettings = setLocalSettings;
function requestLocalSettings() {
    return {
        effectType: "request-local-settings",
    };
}
exports.requestLocalSettings = requestLocalSettings;
function loadLocalSettings(value) {
    return {
        type: "local-settings-response",
        value: value
    };
}
exports.loadLocalSettings = loadLocalSettings;
function withLocalSettings(dispatch, storage) {
    if (storage === void 0) { storage = localStorage; }
    return function (effect) {
        switch (effect.effectType) {
            case "set-local-settings":
                storage.setItem("settings", JSON.stringify(effect.value));
                break;
            case "request-local-settings":
                var value = storage.getItem("settings");
                dispatch(loadLocalSettings(value ? JSON.parse(value) : {}));
                break;
        }
    };
}
exports.withLocalSettings = withLocalSettings;
var MemoryStorage = (function () {
    function MemoryStorage() {
        this.storage = {};
    }
    MemoryStorage.prototype.getItem = function (key) {
        return this.storage[key];
    };
    MemoryStorage.prototype.setItem = function (key, value) {
        this.storage[key] = value;
    };
    return MemoryStorage;
}());
exports.MemoryStorage = MemoryStorage;
