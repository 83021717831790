"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var bisect_1 = require("../../utils/bisect");
var json_resources_1 = require("../../core/json-resources");
var routes_1 = require("../../routes");
var resource_api_1 = require("../../schemas/resource-api");
var newResource = resource_api_1.ResourceApi.newResource;
var addRelationship = resource_api_1.ResourceApi.addRelationship;
var email_valid_1 = require("../../utils/email-valid");
var flash_reducer_1 = require("../../reducers/subreducers/flash-reducer");
var I18n_1 = require("../../I18n");
var time_services_1 = require("../../core/services/time-services");
var sequence_services_1 = require("../../core/services/sequence-services");
function saveWidgetSettings() {
    return {
        type: "save-widget-settings"
    };
}
exports.saveWidgetSettings = saveWidgetSettings;
function saveEmail() {
    return {
        type: "save-email"
    };
}
exports.saveEmail = saveEmail;
function goToSettingsPage() {
    return {
        type: "go-to-settings-page"
    };
}
exports.goToSettingsPage = goToSettingsPage;
function reduceSettingsPage(state, action) {
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.updateUserDataRequestName) {
                state = tslib_1.__assign({}, state);
                state.flashes = tslib_1.__assign({}, state.flashes);
                if (action.success) {
                    var flashDuration = 1000;
                    state.flashes.requestError = null;
                    state.flashes.success = flash_reducer_1.createFlash("success", state.now + flashDuration, I18n_1.i18n.alertSettingsPageSaved[language]);
                    effects = sequence_services_1.sequence(effects, time_services_1.requestTick(flashDuration));
                    var response = JSON.parse(action.response);
                    if (response.data.type === "users") {
                        state.loggedInUser = tslib_1.__assign({}, state.loggedInUser);
                        state.loggedInUser.attributes = tslib_1.__assign({}, state.loggedInUser.attributes, response.data.attributes);
                    }
                }
                else {
                    var response = JSON.parse(action.response);
                    var error = response.errors && response.errors.length > 0 && response.errors[0];
                    state.flashes.success = null;
                    state.flashes.requestError = flash_reducer_1.createFlash("danger", null, error && error.detail);
                }
            }
            break;
        case "save-widget-settings":
            state = tslib_1.__assign({}, state);
            var u = newResource("users");
            u.id = state.loggedInUser.id;
            u.attributes = tslib_1.__assign({}, u.attributes);
            if (state.inputs.userEmailInput && state.inputs.userEmailInput !== state.loggedInUser.attributes.notification_email) {
                u.attributes.notification_email = state.inputs.userEmailInput;
                var userWidgetSettings = bisect_1.Indexer.getFirstMatching(state.stores.userWidgetSettings.byUserIdAndClientId, [state.loggedInUser.id, String(state.client_id)]);
                if (!userWidgetSettings) {
                    userWidgetSettings = newResource("user_widget_settings");
                    addRelationship(userWidgetSettings, "user", { id: state.loggedInUser.id, type: "users" });
                    addRelationship(userWidgetSettings, "client", { id: state.client_id, type: "clients" });
                }
                userWidgetSettings = tslib_1.__assign({}, userWidgetSettings);
                userWidgetSettings.attributes = tslib_1.__assign({}, userWidgetSettings.attributes);
                userWidgetSettings.attributes.get_email_updates = state.toggles.getEmailUpdates;
                effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.createOrUpdateUserWidgetSettings], userWidgetSettings, routes_1.RapiV1UserWidgetSettingsPath));
            }
            if (state.inputs.customerLanguage && state.inputs.customerLanguage !== state.loggedInUser.attributes.language) {
                u.attributes.language = state.inputs.customerLanguage;
            }
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.updateUserDataRequestName], u, routes_1.RapiV1UsersPath + "/" + state.loggedInUser.id));
            break;
        case "go-to-settings-page": {
            state = tslib_1.__assign({}, state);
            state.previousWidgetPages = state.previousWidgetPages.concat(state.currentWidgetPage);
            state.currentWidgetPage = "settings";
            state.currentViewingRoomId = "";
            state.popupMessageIds = [];
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.userEmailInput = state.loggedInUser.attributes.email;
            state.inputs.customerLanguage = state.loggedInUser.attributes.language;
            var userWidgetSettings = bisect_1.Indexer.getFirstMatching(state.stores.userWidgetSettings.byUserIdAndClientId, [state.loggedInUser.id, state.client_id.toString()]);
            if (userWidgetSettings) {
                state.toggles = tslib_1.__assign({}, state.toggles);
                state.toggles.getEmailUpdates = userWidgetSettings.attributes.get_email_updates;
            }
            break;
        }
    }
    return { state: state, effects: effects };
}
exports.reduceSettingsPage = reduceSettingsPage;
function validateWidgetInputErrors(state) {
    var errorMessages = tslib_1.__assign({}, state.errorMessages);
    errorMessages.widgetSettingsPage = tslib_1.__assign({}, errorMessages.widgetSettingsPage);
    errorMessages.widgetSettingsPage.customerNotificationEmail = (state.inputs.userEmailInput && !email_valid_1.emailValid(state.inputs.userEmailInput));
    return errorMessages;
}
exports.validateWidgetInputErrors = validateWidgetInputErrors;
exports.createOrUpdateUserWidgetSettings = "create-user-widget-settings";
exports.updateUserDataRequestName = "update-user-data-request-name";
