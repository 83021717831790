"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var ajax_services_1 = require("../core/services/ajax-services");
var json_resources_1 = require("../core/json-resources");
var array_utils_1 = require("../utils/array-utils");
var messages_page_reducer_1 = require("./messages-page-reducer");
var past_supports_page_reducer_1 = require("./past-supports-page-reducer");
var resource_api_1 = require("../schemas/resource-api");
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var sequence_services_1 = require("../core/services/sequence-services");
var navigation_services_1 = require("../core/services/navigation-services");
var popup_messages_reducer_1 = require("./popup-messages-reducer");
var toast_reducer_1 = require("./toast-reducer");
var I18n_1 = require("../I18n");
var routes_1 = require("../routes");
function searchPage(pageName, queryParams) {
    return {
        type: "search-page",
        pageName: pageName,
        queryParams: queryParams
    };
}
exports.searchPage = searchPage;
function nextPage(pageKey, requestName) {
    return {
        type: "next-page",
        pageKey: pageKey, requestName: requestName
    };
}
exports.nextPage = nextPage;
function goToPageRoom(room) {
    return {
        type: "go-to-page-room",
        room: room
    };
}
exports.goToPageRoom = goToPageRoom;
function goToPageRoomWithMessage(room, message) {
    return {
        type: "go-to-page-room-with-message",
        room: room,
        message: message
    };
}
exports.goToPageRoomWithMessage = goToPageRoomWithMessage;
function setPageFromDate(date, pageKey) {
    return {
        type: "set-page-from-date",
        date: date,
        pageKey: pageKey
    };
}
exports.setPageFromDate = setPageFromDate;
function setPageToDate(date, pageKey) {
    return {
        type: "set-page-to-date",
        date: date,
        pageKey: pageKey
    };
}
exports.setPageToDate = setPageToDate;
function downloadPageReport(queryParams, resourceType) {
    return {
        type: "download-page-report",
        resourceType: resourceType,
        queryParams: queryParams
    };
}
exports.downloadPageReport = downloadPageReport;
function handleRoomRelations(responseDetails, pageData, relationshipKey) {
    pageData = tslib_1.__assign({}, pageData);
    pageData.nextLink = "";
    var apiResponse = responseDetails.apiResponse;
    if (!apiResponse || isErrorResponse(apiResponse))
        return pageData;
    if (apiResponse.links && apiResponse.links.next) {
        pageData.nextLink = apiResponse.links.next;
    }
    pageData.total = apiResponse.meta ? apiResponse.meta.record_count : 0;
    if (responseDetails.resources) {
        pageData.rooms = pageData.rooms.slice();
        pageData[relationshipKey] = pageData[relationshipKey].slice();
        var _loop_1 = function (i) {
            var resource = responseDetails.resources[i];
            if (resource && resource.type === relationshipKey) {
                pageData[relationshipKey].push(resource);
            }
            else if (resource && resource.type === "rooms") {
                var room_1 = resource;
                if (pageData.rooms.filter(function (r) { return r.id === room_1.id; }).length <= 0) {
                    pageData.rooms.push(room_1);
                }
            }
        };
        for (var i = 0; i < responseDetails.resources.length; i++) {
            _loop_1(i);
        }
    }
    return pageData;
}
exports.handleRoomRelations = handleRoomRelations;
function reducePagedSearchRequests(state, action) {
    var _a, _b;
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    switch (action.type) {
        case "complete-request":
            state = tslib_1.__assign({}, state);
            state.loadingPagedRequests = tslib_1.__assign({}, state.loadingPagedRequests);
            delete state.loadingPagedRequests[array_utils_1.stringifyArray(action.name)];
            var responseDetails = json_resources_1.getResourceResponseDetails(action);
            if (action.name[0] === past_supports_page_reducer_1.searchPastSupportsRequestName) {
                state.pastSupportsPage = handleRoomRelations(responseDetails, state.pastSupportsPage, "supports");
            }
            else if (action.name[0] === messages_page_reducer_1.searchMessagesRequestName) {
                state.messagesPage = handleRoomRelations(responseDetails, state.messagesPage, "messages");
            }
            break;
        case "set-page-from-date":
            state = tslib_1.__assign({}, state);
            state[action.pageKey] = tslib_1.__assign({}, state[action.pageKey]);
            state[action.pageKey].fromDate = action.date;
            break;
        case "set-page-to-date":
            state = tslib_1.__assign({}, state);
            state[action.pageKey] = tslib_1.__assign({}, state[action.pageKey]);
            state[action.pageKey].toDate = action.date;
            break;
        case "next-page":
            nextPage("pastSupportsPage", "holla");
            if (!state[action.pageKey].nextLink)
                break;
            state = tslib_1.__assign({}, state);
            state.loadingPagedRequests = tslib_1.__assign({}, state.loadingPagedRequests);
            state.loadingPagedRequests[array_utils_1.stringifyArray([action.requestName])] = true;
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([action.requestName], state[action.pageKey].nextLink));
            break;
        case "search-page":
            var search = navigation_services_1.encodeSearch(ajax_services_1.encodeQueryParts(action.queryParams(state)));
            effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({
                pathname: "/" + action.pageName + "/search",
                search: search
            }));
            break;
        case "go-to-page-room":
            if (action.room && action.room.attributes.kind === "support_room") {
                effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({ pathname: "/supports/" + action.room.id }));
            }
            else {
                (_a = sequence_services_1.sequenceReduction(effects, popup_messages_reducer_1.addPopupRoom(state, action.room.id)), state = _a.state, effects = _a.effects);
            }
            break;
        case "go-to-page-room-with-message":
            if (action.room && action.room.attributes.kind === "support_room") {
                effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.fetchTargetRoomLatestMessageRequestName], routes_1.RapiV1MessagesPath + "/" + action.message.id + "/latest"));
                effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.fetchTargetRoomTargetMessageRequestName], routes_1.RapiV1MessagesPath + "/" + action.message.id));
                effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({ pathname: "/supports/" + action.room.id + "/messages/" + action.message.id }));
            }
            break;
        case "download-page-report":
            var query = action.queryParams(state);
            if (action.resourceType === 'messages' && state.toggles.messagesPageSearchWithRegexp) {
                query.filter['regexp_query'] = query.filter['query'];
                query.filter['query'] = null;
            }
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.downloadCsvRequestName, action.resourceType], "/rapi/v1/" + action.resourceType + "/export_s3.csv", query));
            (_b = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastMessageSearchPageMessagesDownloaded[language])), state = _b.state, effects = _b.effects);
            break;
    }
    return { state: state, effects: effects };
}
exports.reducePagedSearchRequests = reducePagedSearchRequests;
exports.pagedSearchRequestNames = [messages_page_reducer_1.searchMessagesRequestName, past_supports_page_reducer_1.searchPastSupportsRequestName];
exports.downloadCsvRequestName = "download-csv-request-name";
exports.fetchTargetRoomLatestMessageRequestName = "fetch-target-room-latest-message";
exports.fetchTargetRoomTargetMessageRequestName = "fetch-target-room-target-message";
