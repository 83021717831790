"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var radio_button_1 = require("../../components/radio-button");
var two_column_layout_1 = require("../../components/two-column-layout");
var users_util_1 = require("../../utils/users-util");
var full_screen_modal_1 = require("../../components/full-screen-modal");
var resource_page_common_reducer_1 = require("../../reducers/resource-page-common-reducer");
var class_generator_1 = require("../../components/class-generator");
var inputs_reducer_1 = require("../../reducers/subreducers/inputs-reducer");
var operator_page_reducer_1 = require("../../reducers/operator-page-reducer");
var debouncing_inputs_1 = require("../../components/debouncing-inputs");
var toggles_reducer_1 = require("../../reducers/subreducers/toggles-reducer");
var cloudinary_utils_1 = require("../../utils/cloudinary-utils");
var I18n_1 = require("../../I18n");
var select_single_1 = require("../../components/select-single");
var chat_list_message_1 = require("../../components/chat-list-message");
var global_1 = require("../../utils/global");
var timezone_1 = require("../../utils/timezone");
var classNamesForInput = class_generator_1.classNamesGeneratorFor(function (add) {
    add("disabled", React.createElement("div", { className: "b--moon-gray bg-silver" }), React.createElement("div", { className: "b--moon-gray b--light-blue--hover" }));
}, React.createElement("div", { className: "w-100 pa2 o-70 outline-0 b--solid bw1 br3" }));
exports.languageLabeler = function (language) {
    switch (language) {
        case "en":
            return "English";
        case "ja":
            return "日本語";
    }
};
var OperatorsFullScreenModal;
(function (OperatorsFullScreenModal) {
    function render(dispatch) {
        var dispatcher = {
            closeModal: function () { return dispatch(resource_page_common_reducer_1.closeFullScreenResourceModal("users")); },
            save: function () { return dispatch(operator_page_reducer_1.saveUser()); },
            uploadImage: function (e) {
                var files = e.target.files;
                dispatch(operator_page_reducer_1.uploadUserImage(files));
            },
        };
        function NewFooter(state) {
            var isValid = state.editingOperatorValid;
            var language = state.loggedInUser.attributes.language;
            return React.createElement("div", null,
                React.createElement(full_screen_modal_1.FullScreenCancelButton, { onClick: dispatcher.closeModal }, I18n_1.i18n.cancelMessage[language]),
                React.createElement(full_screen_modal_1.FullScreenSaveButton, { disabled: !isValid, onClick: dispatcher.save }, I18n_1.i18n.operatorModalSendAnEmailButton[language]));
        }
        function EditFooter(state) {
            var isValid = state.editingOperatorValid;
            var language = state.loggedInUser.attributes.language;
            return React.createElement("div", null,
                React.createElement(full_screen_modal_1.FullScreenCancelButton, { onClick: dispatcher.closeModal }, I18n_1.i18n.cancelMessage[language]),
                React.createElement(full_screen_modal_1.FullScreenSaveButton, { disabled: !isValid, onClick: dispatcher.save }, I18n_1.i18n.saveMessage[language]));
        }
        return function (state) {
            var imageUrl = cloudinary_utils_1.getCloudinaryResizeUrl(state.inputs.operatorAvatarUrl, 80, 80, 'c_thumb') || require('../../../imgs/thumb_group_default.png');
            var currentEditingIds = state.resourcePageCommonState.currentEditingIds;
            var isNew = currentEditingIds.length === 0;
            var multiSelected = currentEditingIds.length > 1;
            var isNewOrMulti = isNew || multiSelected;
            var editingSelf = currentEditingIds[0] == state.loggedInUser.id;
            var isAdmin = users_util_1.isAtLeastA(state.loggedInUser, "Administrator");
            var isAtLeastSupervisor = users_util_1.isAtLeastA(state.loggedInUser, "Supervisor");
            var language = state.loggedInUser.attributes.language;
            return React.createElement(full_screen_modal_1.FullScreenModal, { isOpen: state.resourcePageCommonState.showResourceModal },
                React.createElement(full_screen_modal_1.FullScreenHeader, { onClose: dispatcher.closeModal },
                    isNew && React.createElement("div", null, I18n_1.i18n.membersPageAddNewMember[language]),
                    !isNew && React.createElement("div", null, I18n_1.i18n.editMessage[language])),
                React.createElement(full_screen_modal_1.FullScreenBody, { isScrollable: true, innerClassNames: "w-60" },
                    React.createElement(two_column_layout_1.TwoColumnLayout, null,
                        !isNewOrMulti && React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null, I18n_1.i18n.operatorModalUniqueUserId[language]),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement(debouncing_inputs_1.DebouncingTextInput, { type: "text", className: classNamesForInput({ disabled: true }), disabled: true, value: state.inputs.operatorUuid }))),
                        React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null,
                                I18n_1.i18n.operatorModalUniqueName[language],
                                " ",
                                isNew &&
                                    React.createElement("span", { className: "red fw2" },
                                        "(",
                                        I18n_1.i18n.requiredMessage[language],
                                        ")"),
                                isNew && state.isOperatorNameDuplicate &&
                                    React.createElement("div", { className: "red" }, I18n_1.i18n.alreadyRegisteredMessage[language])),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement(debouncing_inputs_1.DebouncingTextInput, { type: "text", placeholder: "Name Namename", className: classNamesForInput({ disabled: !isNew }), onChange: inputs_reducer_1.inputChangeDispatcher(dispatch, "operatorName"), value: state.inputs.operatorName, disabled: !isNew }))),
                        React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null,
                                I18n_1.i18n.operatorModalMailAddress[language],
                                " ",
                                isNew &&
                                    React.createElement("span", { className: "red fw2" },
                                        "(",
                                        I18n_1.i18n.requiredMessage[language],
                                        ")"),
                                isNew && state.isOperatorEmailDuplicate &&
                                    React.createElement("div", { className: "red" }, I18n_1.i18n.alreadyRegisteredMessage[language])),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement(debouncing_inputs_1.DebouncingTextInput, { type: "email", placeholder: "aaaaaa@aaaaaaaa.co.jp", className: classNamesForInput({ disabled: !isNew }), onChange: inputs_reducer_1.inputChangeDispatcher(dispatch, "operatorEmail"), value: state.inputs.operatorEmail, disabled: !isNew }))),
                        React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null,
                                I18n_1.i18n.operatorModalFullName[language],
                                " ",
                                state.isOperatorFullNameDuplicate &&
                                    React.createElement("div", { className: "light-gray" },
                                        "(",
                                        I18n_1.i18n.alreadyRegisteredMessage[language],
                                        ")")),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement(debouncing_inputs_1.DebouncingTextInput, { type: "text", placeholder: "Name Namename", className: classNamesForInput({ disabled: false }), onChange: inputs_reducer_1.inputChangeDispatcher(dispatch, "operatorDisplayName"), value: state.inputs.operatorDisplayName }))),
                        !isNew && React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null, I18n_1.i18n.operatorModalIcon[language]),
                            React.createElement(two_column_layout_1.RightColumn, { key: "table-data-image" },
                                React.createElement("div", { className: "w-100" },
                                    React.createElement("div", { className: "dib" },
                                        React.createElement("img", { src: imageUrl, width: 80 })),
                                    React.createElement("div", { className: "dib ml3 h-100" },
                                        React.createElement("input", { type: "file", onChange: dispatcher.uploadImage }))))),
                        !editingSelf && isAdmin &&
                            React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                                React.createElement(two_column_layout_1.LeftColumn, null, I18n_1.i18n.operatorModalRole[language]),
                                React.createElement(two_column_layout_1.RightColumn, null,
                                    React.createElement(radio_button_1.RadioButtonGroup, { labels: [I18n_1.i18n.operatorLabel[language], I18n_1.i18n.supervisorLabel[language], I18n_1.i18n.administratorLabel[language]], values: ["Operator", "Supervisor", "Administrator"], value: state.inputs.operatorUserType, onSelect: inputs_reducer_1.inputChangeDispatcher(dispatch, "operatorUserType") }))),
                        isAtLeastSupervisor && React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null, I18n_1.i18n.operatorModalAssignPriority[language]),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement(select_single_1.SelectSingle, { value: state.inputs.operatorAssignPriority, values: ["1", "2", "3", "4", "5"], onChange: function (priority) { return dispatch(inputs_reducer_1.inputChange("operatorAssignPriority", priority)); } }),
                                React.createElement("span", null, I18n_1.i18n.operatorModalAssignPriorityDescription[language]))),
                        React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null, I18n_1.i18n.operatorModalMaxSupportCount[language]),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement(debouncing_inputs_1.DebouncingTextInput, { placeholder: "10", className: classNamesForInput({ disabled: false }), value: state.inputs.operatorMaxSupportCount, onSelect: inputs_reducer_1.inputChangeDispatcher(dispatch, "operatorMaxSupportCount") }))),
                        !isNew && React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null, I18n_1.i18n.operatorModalTimeZone[language]),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement(select_single_1.SelectSingle, { value: state.inputs.operatorTimezone, values: timezone_1.timezoneNames, onChange: function (tz) { return dispatch(inputs_reducer_1.inputChange("operatorTimezone", tz)); } }))),
                        isAdmin && React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null, I18n_1.i18n.operatorModalInTraining[language]),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement("input", { type: "checkbox", checked: state.toggles.operatorInTraining, onChange: toggles_reducer_1.toggleDispatcher(dispatch, "operatorInTraining") }))),
                        editingSelf && React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null, I18n_1.i18n.operatorModalAccessToken[language]),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement("div", null,
                                    React.createElement("div", { className: "dib" }, global_1.global.access_token),
                                    React.createElement("div", { className: "dib" },
                                        React.createElement(chat_list_message_1.ToolButton, { className: "ma2", onClick: toggles_reducer_1.toggleDispatcher(dispatch, "showRegenerateUserConfirmModal", true) }, I18n_1.i18n.operatorModalRegenerateAccessToken[language]))))),
                        React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null, I18n_1.i18n.operatorModalLanguage[language]),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement(select_single_1.SelectSingle, { labeler: exports.languageLabeler, values: ["en", "ja"], value: state.inputs.operatorLanguage, onChange: function (language) { return dispatch(inputs_reducer_1.inputChange("operatorLanguage", language)); }, hidePlaceholder: true }))),
                        React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null, I18n_1.i18n.operatorModalSoundNotification[language]),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement("input", { type: "checkbox", checked: state.toggles.operatorSoundNotification, onChange: toggles_reducer_1.toggleDispatcher(dispatch, "operatorSoundNotification") }))),
                        state.toggles.operatorSoundNotification && React.createElement(two_column_layout_1.TwoColumnLayoutRow, null,
                            React.createElement(two_column_layout_1.LeftColumn, null, I18n_1.i18n.operatorModalSoundNotificationType[language]),
                            React.createElement(two_column_layout_1.RightColumn, null,
                                React.createElement(radio_button_1.RadioButtonGroup, { value: state.inputs.operatorSoundNotificationType, onSelect: inputs_reducer_1.inputChangeDispatcher(dispatch, "operatorSoundNotificationType"), values: ['1', '2'], labels: [I18n_1.i18n.operatorModalSoundNotificationFollowing[language],
                                        I18n_1.i18n.operatorModalSoundNotificationAll[language]] }))))),
                React.createElement(full_screen_modal_1.FullScreenFooter, null,
                    isNew && NewFooter(state),
                    !isNew && EditFooter(state)));
        };
    }
    OperatorsFullScreenModal.render = render;
})(OperatorsFullScreenModal = exports.OperatorsFullScreenModal || (exports.OperatorsFullScreenModal = {}));
