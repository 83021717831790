"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var array_utils_1 = require("./array-utils");
function mergeChatRows(firstChatRow, secondChatRow) {
    var rows = [];
    var mIdx = 0;
    var rIdx = 0;
    while (mIdx < firstChatRow.length && rIdx < secondChatRow.length) {
        var firstRowCreatedAt = firstChatRow[mIdx].attributes.created_at_unix;
        var secondRowCreatedAt = secondChatRow[rIdx].attributes.created_at_unix;
        if (firstRowCreatedAt < secondRowCreatedAt) {
            rows.push(firstChatRow[mIdx++]);
        }
        else {
            rows.push(secondChatRow[rIdx++]);
        }
    }
    while (mIdx < firstChatRow.length)
        rows.push(firstChatRow[mIdx++]);
    while (rIdx < secondChatRow.length)
        rows.push(secondChatRow[rIdx++]);
    return rows;
}
exports.mergeChatRows = mergeChatRows;
function deleteRoomNotificationById(roomNotifications, id) {
    if (id != null) {
        roomNotifications = roomNotifications.slice();
        roomNotifications.splice(array_utils_1.findIndex(roomNotifications, function (rn) { return rn.id === id; }), 1);
    }
    return roomNotifications;
}
exports.deleteRoomNotificationById = deleteRoomNotificationById;
