"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var json_resources_1 = require("../core/json-resources");
var bisect_1 = require("../utils/bisect");
var toast_reducer_1 = require("./toast-reducer");
var routes_1 = require("../routes");
var users_util_1 = require("../utils/users-util");
var I18n_1 = require("../I18n");
var array_utils_1 = require("../utils/array-utils");
var sequence_services_1 = require("../core/services/sequence-services");
var moment = require('moment');
function setPastSupportStatus(status) {
    return {
        type: "set-past-support-status",
        status: status
    };
}
exports.setPastSupportStatus = setPastSupportStatus;
function setPastSupportSelfEvaluate(selfEvaluate) {
    return {
        type: "set-past-support-self-evaluate",
        selfEvaluate: selfEvaluate
    };
}
exports.setPastSupportSelfEvaluate = setPastSupportSelfEvaluate;
function setPastSupportLastMessageKind(lastMessageKind) {
    return {
        type: "set-past-support-last-message-kind",
        lastMessageKind: lastMessageKind
    };
}
exports.setPastSupportLastMessageKind = setPastSupportLastMessageKind;
function getPastSupportQueryParams(state) {
    var query = { filter: {} };
    if (state.inputs.pastSupportsSearchInput) {
        query.filter["room_or_operator"] = state.inputs.pastSupportsSearchInput;
    }
    if (users_util_1.isAtLeastA(state.loggedInUser, "Supervisor")) {
        var user = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [state.inputs.supportFilteringPanelUserId]);
        if (user) {
            query.filter["user_id"] = user.id;
        }
    }
    else {
        query.filter["user_id"] = state.loggedInUser.id;
    }
    if (state.pastSupportsPage.status !== "both") {
        query.filter["active"] = state.pastSupportsPage.status === "active" ? "1" : "0";
    }
    if (state.pastSupportsPage.selfEvaluate !== "neither") {
        query.filter["self_evaluate"] = state.pastSupportsPage.selfEvaluate;
    }
    if (state.pastSupportsPage.lastMessageKind !== "none") {
        query.filter["last_message_kind"] = state.pastSupportsPage.lastMessageKind;
    }
    query.filter["from"] = (state.pastSupportsPage.fromDate || moment().startOf('month')).unix();
    query.filter["to"] = (state.pastSupportsPage.toDate || moment().endOf('month')).unix();
    return query;
}
exports.getPastSupportQueryParams = getPastSupportQueryParams;
function beginPastSupportsSearch(state, query) {
    var _a;
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    if (!query.filter)
        return { state: state, effects: effects };
    state = tslib_1.__assign({}, state);
    state.pastSupportsPage = tslib_1.__assign({}, state.pastSupportsPage);
    state.pastSupportsPage.rooms = [];
    state.pastSupportsPage.supports = [];
    var requestName = [exports.searchPastSupportsRequestName];
    state.loadingPagedRequests = tslib_1.__assign({}, state.loadingPagedRequests);
    state.loadingPagedRequests[array_utils_1.stringifyArray(requestName)] = true;
    if (query.filter["room_or_operator"]) {
        state.inputs.pastSupportsSearchInput = query.filter["room_or_operator"].toString();
    }
    if (query.filter["from"]) {
        state.pastSupportsPage.fromDate = moment(parseInt(query.filter["from"].toString()) * 1000);
    }
    if (query.filter["to"]) {
        state.pastSupportsPage.toDate = moment(parseInt(query.filter["to"].toString()) * 1000);
    }
    if (query.filter["status"]) {
        state.pastSupportsPage.status = query.filter["status"];
    }
    if (query.filter["self_evaluate"]) {
        state.pastSupportsPage.selfEvaluate = query.filter["self_evaluate"];
    }
    if (query.filter["user_id"] && (query.filter["user_id"] !== state.loggedInUser.id)) {
        state.inputs = tslib_1.__assign({}, state.inputs);
        state.inputs.supportFilteringPanelUserId = query.filter["user_id"].toString();
    }
    query.include = ["room"];
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch(requestName, routes_1.RapiV1SupportsPath, query));
    (_a = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastPastSupportsSearched[language])), state = _a.state, effects = _a.effects);
    return { state: state, effects: effects };
}
exports.beginPastSupportsSearch = beginPastSupportsSearch;
function reducePastSupportsPage(state, action) {
    var effects = [];
    switch (action.type) {
        case "set-past-support-status":
            state = tslib_1.__assign({}, state);
            state.pastSupportsPage = tslib_1.__assign({}, state.pastSupportsPage);
            state.pastSupportsPage.status = action.status;
            break;
        case "set-past-support-self-evaluate":
            state = tslib_1.__assign({}, state);
            state.pastSupportsPage = tslib_1.__assign({}, state.pastSupportsPage);
            state.pastSupportsPage.selfEvaluate = action.selfEvaluate;
            break;
        case "set-past-support-last-message-kind":
            state = tslib_1.__assign({}, state);
            state.pastSupportsPage = tslib_1.__assign({}, state.pastSupportsPage);
            state.pastSupportsPage.lastMessageKind = action.lastMessageKind;
            break;
    }
    return { state: state, effects: effects };
}
exports.reducePastSupportsPage = reducePastSupportsPage;
exports.searchPastSupportsRequestName = "search-past-supports-request-name";
