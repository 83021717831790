"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var DraftMessageStore;
(function (DraftMessageStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    DraftMessageStore.indexer = new bisect_1.Indexer("byId");
    DraftMessageStore.indexer.addIndex("byId", function (draftMessage) { return [assertInnerId(draftMessage)]; });
    DraftMessageStore.indexer.addIndex("byRoomIdAndCreatedAt", function (draftMessage) { return [getRelationshipId(draftMessage, "room"), draftMessage.attributes.created_at_unix]; });
    DraftMessageStore.indexer.addIndex("byStatusAndResolvedTime", function (draftMessage) { return [draftMessage.attributes.status, draftMessage.attributes.resolved_time_unix]; });
    DraftMessageStore.indexer.addIndex("byStatusAndIsRead", function (draftMessage) { return [draftMessage.attributes.status, !!draftMessage.attributes.create_user_resolved_read_time_unix]; });
})(DraftMessageStore = exports.DraftMessageStore || (exports.DraftMessageStore = {}));
