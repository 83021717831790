"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Subject = (function () {
    function Subject() {
        var listeners = {};
        var lastId = -1;
        this.dispatch = function (data) {
            for (var k in listeners) {
                listeners[k](data);
            }
        };
        this.subscribe = function (listener) {
            var id = ++lastId;
            listeners[id] = listener;
            return function () {
                delete listeners[id];
            };
        };
    }
    return Subject;
}());
exports.Subject = Subject;
function delayedValue(timeout, v) {
    return {
        subscribe: function (l) {
            var h = setTimeout(function () {
                l(v);
            }, timeout);
            return function () { return clearTimeout(h); };
        }
    };
}
exports.delayedValue = delayedValue;
var BufferedSubject = (function () {
    function BufferedSubject() {
        var _this = this;
        this.buffering = false;
        this._buffer = [];
        this._stack = [0];
        this.flush$ = new Subject();
        this.dispatch = function (t) {
            _this._buffer.splice(_this._buffer.length - _this.getRightOffset(), 0, t);
            ++_this._stack[_this._stack.length - 1];
            if (!_this.buffering) {
                _this.flushUntilEmpty();
            }
        };
        this.subscribe = function (dispatch) {
            return _this.flush$.subscribe(dispatch);
        };
        this.flushNext = function () {
            if (_this._buffer.length) {
                var next = _this.takeNext();
                _this.executeFlush(next);
                return next;
            }
            return undefined;
        };
        this.flushUntilEmpty = function () {
            var flushed = [];
            while (_this._buffer.length) {
                var next = _this.takeNext();
                flushed.push(next);
                _this.executeFlush(next);
            }
            return flushed;
        };
    }
    Object.defineProperty(BufferedSubject.prototype, "buffer", {
        get: function () {
            return this._buffer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BufferedSubject.prototype, "stack", {
        get: function () {
            return this._stack;
        },
        enumerable: true,
        configurable: true
    });
    BufferedSubject.prototype.clear = function () {
        this._stack = [0];
        this._buffer = [];
    };
    BufferedSubject.prototype.getRightOffset = function () {
        var result = 0;
        for (var i = 0; i < this._stack.length - 1; ++i) {
            result += this._stack[i];
        }
        return result;
    };
    BufferedSubject.prototype.takeNext = function () {
        var i = this._stack.length - 1;
        while (i >= 0 && this._stack[i] <= 0) {
            --i;
        }
        this._stack[i]--;
        return this._buffer.shift();
    };
    BufferedSubject.prototype.executeFlush = function (t) {
        this._stack.push(0);
        this.flush$.dispatch(t);
        var remaining = this._stack.pop();
        this._stack[this._stack.length - 1] += remaining;
    };
    return BufferedSubject;
}());
exports.BufferedSubject = BufferedSubject;
var Subscription = (function () {
    function Subscription() {
        var _this = this;
        this.subscriptions = [];
        this.unsubscribe = function () {
            var subscriptions = _this.subscriptions;
            var errors = [];
            _this.subscriptions = [];
            for (var _i = 0, subscriptions_1 = subscriptions; _i < subscriptions_1.length; _i++) {
                var sub = subscriptions_1[_i];
                try {
                    sub();
                }
                catch (e) {
                    errors.push(e);
                }
            }
            if (errors.length) {
                errors.forEach(function (e) { return console.error(e); });
                throw new Error("Exceptions raised while closing subscription, see above.");
            }
        };
    }
    Subscription.prototype.add = function (cleanup) {
        if (!cleanup)
            return cleanup;
        if (cleanup instanceof Subscription) {
            this.subscriptions.push(cleanup.unsubscribe);
        }
        else {
            this.subscriptions.push(cleanup);
        }
        return cleanup;
    };
    return Subscription;
}());
exports.Subscription = Subscription;
