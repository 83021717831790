"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function withPreventWindowDefaultDrag() {
    return function () {
        var preventDefault = function (ev) {
            var e = ev || event;
            e.preventDefault();
        };
        window.addEventListener("drop", preventDefault);
        window.addEventListener("dragover", preventDefault);
        return function () {
            window.removeEventListener("drop", preventDefault);
            window.removeEventListener("dragover", preventDefault);
        };
    };
}
exports.withPreventWindowDefaultDrag = withPreventWindowDefaultDrag;
