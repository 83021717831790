"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var toggles_reducer_1 = require("../../reducers/subreducers/toggles-reducer");
function openChat() {
    return {
        effectType: "open-chat",
    };
}
exports.openChat = openChat;
function withTrackerChecker(dispatch) {
    return function (effect) {
        switch (effect.effectType) {
            case "open-chat":
                dispatch(toggles_reducer_1.toggle("widgetMaximized", true));
                dispatch(toggles_reducer_1.toggle("widgetHidden", false));
                break;
        }
    };
}
exports.withTrackerChecker = withTrackerChecker;
