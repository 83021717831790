"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var I18n_1 = require("../I18n");
var alert_1 = require("./alert");
var React = require("react");
function flashMessage(flash, language) {
    switch (flash.level) {
        case "success":
            return I18n_1.i18n.alertSuccess[language];
        case "warning":
            return I18n_1.i18n.alertWarning[language];
        case "danger":
            return I18n_1.i18n.alertDanger[language];
    }
}
function Flash(props) {
    var keys = Object.keys(props.flashes);
    return React.createElement("div", null, keys.map(function (key) {
        var flash = props.flashes[key];
        if (!flash)
            return null;
        return React.createElement(alert_1.Alert, { level: flash.level, className: "test--alert-flash", key: key, target: key, onClose: props.dismissFlash }, flash.message || flashMessage(flash, props.language));
    }));
}
exports.Flash = Flash;
