"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var ScenarioTemplatesStore;
(function (ScenarioTemplatesStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    ScenarioTemplatesStore.indexer = new bisect_1.Indexer("byId");
    ScenarioTemplatesStore.indexer.addIndex("byId", function (scenarioTemplate) { return [assertInnerId(scenarioTemplate)]; });
})(ScenarioTemplatesStore = exports.ScenarioTemplatesStore || (exports.ScenarioTemplatesStore = {}));
