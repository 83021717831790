"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var debouncing_inputs_1 = require("./debouncing-inputs");
var svgs_1 = require("./svgs");
var classNamesForSearchInput = class_generator_1.classNamesGeneratorFor(function (add) {
}, React.createElement("div", { className: "ma0 pa0" }));
var classNamesForIcon = class_generator_1.classNamesGeneratorFor(function (add) {
    add("isSearchIcon", React.createElement("div", { className: "fill-gray" }));
    add("isCancelIcon", React.createElement("div", { className: "fill-moon-gray fill-light-silver-hover" }));
}, React.createElement("div", { className: "tc pv2 ph0 flex justify-center items-center" }));
var widths = {
    searchIcon: 28,
    cancelIcon: 23,
};
var styles = {
    searchIcon: {
        width: widths.searchIcon + "px"
    },
    cancelIcon: {
        width: widths.cancelIcon + "px"
    },
};
function SearchInput(props) {
    return React.createElement("div", { className: classNamesForSearchInput(props) },
        React.createElement("label", { className: "bg-white b--solid bw1 b--moon-gray br3 flex pointer b--light-blue--hover" },
            React.createElement("span", { className: classNamesForIcon({ isSearchIcon: true }), style: styles.searchIcon },
                React.createElement(svgs_1.SVGSearch, { width: 15, height: 15 })),
            React.createElement(debouncing_inputs_1.DebouncingInput, { value: props.value, className: "bn pv2 ph0 o-70 f3 outline-0 w-100 " + props.testId, placeholder: props.placeholder, onChange: props.onChange, onKeyDown: props.onKeyDown, autoFocus: props.autoFocus, onFocus: props.onFocus }),
            React.createElement("span", { className: classNamesForIcon({ isCancelIcon: true }), onClick: props.onCancel ? props.onCancel : null, style: styles.cancelIcon }, props.value ? React.createElement(svgs_1.SVGCancel, { className: "test--search-input-cancel", width: 15, height: 15 }) : React.createElement("span", null))));
}
exports.SearchInput = SearchInput;
