"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var bisect_1 = require("../utils/bisect");
var sequence_services_1 = require("../core/services/sequence-services");
var json_resources_1 = require("../core/json-resources");
var routes_1 = require("../routes");
var resource_api_1 = require("../schemas/resource-api");
var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
function focusRoom(roomId) {
    return {
        type: "focus-room",
        roomId: roomId
    };
}
exports.focusRoom = focusRoom;
function reduceLastReadAt(state, action) {
    var _a, _b;
    var effects = [];
    if (Object.keys(state.urgentConnectionErrors).length > 0)
        return { state: state, effects: effects };
    switch (action.type) {
        case "action-cable-connected":
            (_a = sequence_services_1.sequenceReduction(effects, tryToMarkReadOperator(state, state.focusedRoomId)), state = _a.state, effects = _a.effects);
            break;
        case "focus-room":
            if (state.focusedRoomId === action.roomId)
                break;
            state = tslib_1.__assign({}, state);
            state.focusedRoomId = action.roomId;
            (_b = sequence_services_1.sequenceReduction(effects, tryToMarkReadOperator(state, state.focusedRoomId)), state = _b.state, effects = _b.effects);
            break;
        case "complete-request":
            if (action.name[0] === exports.markReadRequestName) {
                var roomId = action.name[1];
                state.isRoomMarkingRead = tslib_1.__assign({}, state.isRoomMarkingRead);
                state.isRoomMarkingRead[roomId] = false;
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceLastReadAt = reduceLastReadAt;
function tryToMarkReadWidget(state) {
    var _a;
    var effects = [];
    if ((state.toggles.widgetMaximized || state.provider_login_mode || state.popupMessageIds.length) && state.toggles.widgetFocused) {
        (_a = sequence_services_1.sequenceReduction(effects, tryMarkRead(state, state.currentViewingRoomId)), state = _a.state, effects = _a.effects);
    }
    return { state: state, effects: effects };
}
exports.tryToMarkReadWidget = tryToMarkReadWidget;
function tryToMarkReadOperator(state, roomId) {
    var _a;
    var effects = [];
    if (state.focusedRoomId && state.focusedRoomId === roomId) {
        (_a = sequence_services_1.sequenceReduction(effects, tryMarkRead(state, roomId)), state = _a.state, effects = _a.effects);
    }
    return { state: state, effects: effects };
}
exports.tryToMarkReadOperator = tryToMarkReadOperator;
function tryMarkRead(state, roomId) {
    var effects = [];
    var lastMessage = bisect_1.Indexer.getLastMatching(state.stores.messages.byRoomIdAndCreatedAtUnix, [roomId]);
    var member = bisect_1.Indexer.getFirstMatching(state.stores.members.byRoomIdAndUserId, [roomId, state.loggedInUser.id]);
    if (!lastMessage)
        return { state: state, effects: effects };
    if (!member)
        return { state: state, effects: effects };
    if (state.isRoomMarkingRead[roomId])
        return { state: state, effects: effects };
    if (getRelationshipId(lastMessage, "user") === state.loggedInUser.id)
        return { state: state, effects: effects };
    if (member.attributes.last_read_at_unix >= lastMessage.attributes.created_at_unix)
        return { state: state, effects: effects };
    state = tslib_1.__assign({}, state);
    state.isRoomMarkingRead = tslib_1.__assign({}, state.isRoomMarkingRead);
    state.isRoomMarkingRead[roomId] = true;
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourcePut([exports.markReadRequestName, roomId], routes_1.RapiV1MembersPath + "/" + member.id + "/mark_read"));
    return { state: state, effects: effects };
}
exports.markReadRequestName = "mark-read-request-name";
