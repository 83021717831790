"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
function TwoColumnLayout(props) {
    return React.createElement("div", { className: props.className || "collapse dt--fixed f3 mt3 gray tl" }, props.children);
}
exports.TwoColumnLayout = TwoColumnLayout;
function RightColumn(props) {
    return React.createElement("div", { className: props.className || "w-80 dib pv4" }, props.children);
}
exports.RightColumn = RightColumn;
function TwoColumnLayoutRow(props) {
    return React.createElement("div", { className: props.className || "bb bb--light-gray" }, props.children);
}
exports.TwoColumnLayoutRow = TwoColumnLayoutRow;
function LeftColumn(props) {
    return React.createElement("div", { className: props.className || "w-20 dib pv4 fw6" }, props.children);
}
exports.LeftColumn = LeftColumn;
