"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var operator_state_1 = require("../operator-state");
var json_resources_1 = require("../../core/json-resources");
var routes_1 = require("../../routes");
var bisect_1 = require("../../utils/bisect");
var resource_api_1 = require("../../schemas/resource-api");
var newResource = resource_api_1.ResourceApi.newResource;
var index_1 = require("../../stores/index");
var removeResource = index_1.Stores.removeResource;
var toast_reducer_1 = require("../toast-reducer");
var I18n_1 = require("../../I18n");
var complete_request_reducer_1 = require("../complete-request-reducer");
var array_utils_1 = require("../../utils/array-utils");
var client_rule_reducer_1 = require("./client-rule-reducer");
var initialState = operator_state_1.OperatorState.initialState;
var memoizers_1 = require("../../utils/memoizers");
var sequence_services_1 = require("../../core/services/sequence-services");
var ajax_services_1 = require("../../core/services/ajax-services");
var navigation_services_1 = require("../../core/services/navigation-services");
function saveClient(saveDesign) {
    return {
        type: "save-client",
        saveDesign: saveDesign
    };
}
exports.saveClient = saveClient;
function deleteClient() {
    return { type: "delete-client" };
}
exports.deleteClient = deleteClient;
function startNewClient() {
    return { type: "start-new-client" };
}
exports.startNewClient = startNewClient;
function loadNextClientData() {
    return {
        type: "load-next-client-data"
    };
}
exports.loadNextClientData = loadNextClientData;
exports.clientsPageIndexRequestName = "clients-page-index";
exports.clientsPageNewRequestName = "clients-page-new";
exports.clientsPageDeleteRequestName = "clients-page-delete";
exports.clientsPageSaveRequestName = "clients-page-save";
exports.clientsPagedRequestName = [exports.clientsPageIndexRequestName];
function beginClientsPageLoading(state) {
    var effects = [];
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.clientsPageIndexRequestName], routes_1.RapiV1ClientsPath));
    return { state: state, effects: effects };
}
exports.beginClientsPageLoading = beginClientsPageLoading;
function checkAwaitingClientLoad(state) {
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    if (!state.clientsPage.awaitingClientLoad)
        return { state: state, effects: effects };
    var client = bisect_1.Indexer.getFirstMatching(state.stores.clients.byId, [state.clientsPage.editingClientId]);
    if (!client)
        return { state: state, effects: effects };
    var settings = client.attributes.settings || {};
    state = tslib_1.__assign({}, state);
    state.clientsPage = tslib_1.__assign({}, state.clientsPage);
    state.clientsPage.awaitingClientLoad = false;
    if (settings.client_origins) {
        state.clientsPage.whiteListUrls = (settings.client_origins.white_list || []).slice();
        state.clientsPage.blackListUrls = (settings.client_origins.black_list || []).slice();
    }
    else {
        state.clientsPage.whiteListUrls = [];
        state.clientsPage.blackListUrls = [];
    }
    state.clientsPage.whiteListRules = (settings.white_list || []).map(foldClientRule).filter(function (l) { return l.length > 1; });
    state.clientsPage.blackListRules = (settings.black_list || []).map(foldClientRule).filter(function (l) { return l.length > 1; });
    state.clientsPage.autoPosts = settings.auto_posts || [];
    state.clientsPage.awaitingPosts = settings.awaiting_posts || [];
    state.clientsPage.awaitingPostsDuringBusinessHour = settings.awaiting_posts_during_business_hour || [];
    state.clientsPage.awaitingPostsAfterBusinessHour = settings.awaiting_posts_after_business_hour || [];
    state.inputs.clientNotificationBeforeClosingBusinessHour = settings.notification_before_closing_business_hour || "";
    state.clientsPage.schedules = settings.schedules || [];
    state.inputs.clientV1EmbeddedCss = settings.embedded_custom_css || "";
    state.inputs.clientV1RoomsCss = settings.custom_css || "";
    state.inputs = tslib_1.__assign({}, state.inputs);
    state.inputs.clientTitle = client.attributes.title;
    state.inputs.clientV1TimeFormat = settings.v1_time_format;
    state.inputs.clientUserRegistration = settings.registration || "";
    state.inputs.clientHideDownloadLink = [true, false].includes(settings.hide_download_link) ? settings.hide_download_link.toString() : "true";
    state.toggles = tslib_1.__assign({}, state.toggles);
    state.toggles.clientDisabled = !!settings.deactivated;
    state.toggles.clientV1EnableHeader = !!settings.custom_header;
    state.inputs.clientDuringOffHours = !!settings.collect_emails_when_not_during_business_hours ? "collect-emails" : "dont-show-widget";
    var v2settings = client.attributes.settings.v2_settings || {};
    state.clientsPage.colors = tslib_1.__assign({}, state.clientsPage.colors, v2settings.colors);
    state.clientsPage.selectedColors = ["widget_button", "widget_button_fill"];
    state.inputs.clientV2TimeFormat = v2settings.v2_time_format;
    state.inputs.clientV2HeaderTitle = v2settings.header_title || state.appSettings.name.toLocaleUpperCase();
    state.inputs.clientV2HeaderDescription = v2settings.header_description || I18n_1.i18n.widgetClientDefaultDescription[language];
    state.inputs.clientV2replyGuidance = v2settings.reply_guidance || I18n_1.i18n.widgetClientDefaultReplyGuidance[language];
    state.inputs.clientV2CreateRoomButtonText = v2settings.create_room_button_text || "";
    state.inputs.clientV2WidgetPosition = v2settings.widget_position || "bottom_right";
    state.inputs.clientV2WidgetBackgroundUrl = v2settings.button_background_url || "";
    if (v2settings.buttonImageStyles) {
        state.toggles.clientV2CollectButtonImageCustomFlg = v2settings.buttonImageStyles.imageCustomFlg || false;
        state.inputs.clientV2CollectButtonImageCustomHeight = v2settings.buttonImageStyles.imageHeight.toString() || "60";
        state.inputs.clientV2CollectButtonImageCustomWidth = v2settings.buttonImageStyles.imageWidth.toString() || "60";
    }
    state.inputs.clientV2CollectEmailsTitle = v2settings.collect_emails_title || "";
    state.inputs.clientV2CollectEmailsDescription = v2settings.collect_emails_description || "";
    state.inputs.clientV2CollectEmailsErrorMessage = v2settings.collect_emails_error || "";
    return { state: state, effects: effects };
}
exports.checkAwaitingClientLoad = checkAwaitingClientLoad;
function reduceClientsPage(state, action) {
    var _a, _b;
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    switch (action.type) {
        case "start-new-client":
            var newClient = newResource("clients");
            var defaultSchedules = [];
            for (var i = 1; i < 6; i++) {
                defaultSchedules.push({ start_time: 0, end_time: 2400, day_of_week: i });
            }
            newClient.attributes.settings = {
                schedules: defaultSchedules,
            };
            newClient.attributes.title = I18n_1.i18n.clientsPageDefaultTitle[language];
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.clientsPageNewRequestName], newClient, routes_1.RapiV1ClientsPath));
            break;
        case "complete-request":
            if (action.name[0] === exports.clientsPageNewRequestName) {
                var resourceResponse = json_resources_1.getResourceResponseDetails(action);
                if (resourceResponse.resources && resourceResponse.resources.length) {
                    effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({ pathname: "/chats/" + resourceResponse.resources[0].id }));
                }
            }
            if (action.name[0] === exports.clientsPageSaveRequestName && action.success) {
                state = tslib_1.__assign({}, state);
                state.clientsPage = tslib_1.__assign({}, state.clientsPage);
                state.clientsPage.saving = false;
                if (action.success) {
                    (_a = checkAwaitingClientLoad(state), state = _a.state, effects = _a.effects);
                }
            }
            if (action.name[0] === exports.clientsPageDeleteRequestName && action.success) {
                state = tslib_1.__assign({}, state);
                state.stores = removeResource({ type: "clients", id: state.clientsPage.editingClientId }, state.stores);
            }
            break;
        case "visit":
            state = tslib_1.__assign({}, state);
            state.clientsPageErrors = {};
            state.clientsPageDuringBusinessHourErrors = {};
            state.clientsPageAfterBusinessHourErrors = {};
            effects = sequence_services_1.sequence(effects, ajax_services_1.abortRequest([exports.clientsPageNewRequestName]));
            effects = sequence_services_1.sequence(effects, ajax_services_1.abortRequest([exports.clientsPageDeleteRequestName]));
            break;
        case "save-client":
            state = tslib_1.__assign({}, state);
            state.clientsPage = tslib_1.__assign({}, state.clientsPage);
            state.clientsPage.saving = true;
            if (action.saveDesign) {
                state.toggles = tslib_1.__assign({}, state.toggles);
                state.toggles.clientViewDesignAccordion = false;
            }
            var clientUpdate = newResource("clients");
            clientUpdate.id = state.clientsPage.editingClientId;
            clientUpdate.attributes.title = state.inputs.clientTitle;
            var settings = clientUpdate.attributes.settings = {};
            settings.auto_posts = state.clientsPage.autoPosts;
            settings.awaiting_posts = state.clientsPage.awaitingPosts;
            settings.awaiting_posts_during_business_hour = state.clientsPage.awaitingPostsDuringBusinessHour;
            settings.awaiting_posts_after_business_hour = state.clientsPage.awaitingPostsAfterBusinessHour;
            settings.notification_before_closing_business_hour = state.inputs.clientNotificationBeforeClosingBusinessHour;
            settings.custom_header = state.toggles.clientV1EnableHeader;
            settings.custom_css = state.inputs.clientV1RoomsCss;
            settings.embedded_custom_css = state.inputs.clientV1EmbeddedCss;
            settings.registration = state.inputs.clientUserRegistration || null;
            settings.deactivated = state.toggles.clientDisabled;
            settings.black_list = unfoldClientRules(state.clientsPage.blackListRules);
            settings.white_list = unfoldClientRules(state.clientsPage.whiteListRules);
            settings.schedules = state.clientsPage.schedules;
            settings.client_origins = {};
            settings.client_origins.white_list = state.clientsPage.whiteListUrls.filter(function (u) { return !!u; });
            settings.client_origins.black_list = state.clientsPage.blackListUrls.filter(function (u) { return !!u; });
            settings.collect_emails_when_not_during_business_hours = state.inputs.clientDuringOffHours === "collect-emails";
            settings.hide_download_link = state.inputs.clientHideDownloadLink === "false" ? false : true;
            settings.v1_time_format = state.inputs.clientV1TimeFormat;
            var v2Settings = clientUpdate.attributes.settings.v2_settings = {};
            v2Settings.v2_time_format = state.inputs.clientV2TimeFormat;
            v2Settings.header_title = state.inputs.clientV2HeaderTitle;
            v2Settings.header_description = state.inputs.clientV2HeaderDescription;
            v2Settings.reply_guidance = state.inputs.clientV2replyGuidance;
            v2Settings.create_room_button_text = state.inputs.clientV2CreateRoomButtonText;
            v2Settings.colors = state.clientsPage.colors;
            v2Settings.widget_position = state.inputs.clientV2WidgetPosition;
            v2Settings.button_background_url = state.inputs.clientV2WidgetBackgroundUrl;
            v2Settings.collect_emails_title = state.inputs.clientV2CollectEmailsTitle;
            v2Settings.collect_emails_description = state.inputs.clientV2CollectEmailsTitle;
            v2Settings.collect_emails_error = state.inputs.clientV2CollectEmailsTitle;
            v2Settings.collect_emails_title = state.inputs.clientV2CollectEmailsTitle;
            v2Settings.collect_emails_description = state.inputs.clientV2CollectEmailsTitle;
            v2Settings.collect_emails_error = state.inputs.clientV2CollectEmailsTitle;
            v2Settings.buttonImageStyles = {};
            v2Settings.buttonImageStyles.imageHeight = parseInt(state.inputs.clientV2CollectButtonImageCustomHeight || "60");
            v2Settings.buttonImageStyles.imageWidth = parseInt(state.inputs.clientV2CollectButtonImageCustomWidth || "60");
            v2Settings.buttonImageStyles.imageCustomFlg = state.toggles.clientV2CollectButtonImageCustomFlg || false;
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.clientsPageSaveRequestName], clientUpdate, routes_1.RapiV1ClientsPath + "/" + clientUpdate.id));
            (_b = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastClientSaved[language])), state = _b.state, effects = _b.effects);
            break;
        case "delete-client":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.showClientDeleteConfirmModal = false;
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceDeleteByUrl([exports.clientsPageDeleteRequestName], routes_1.RapiV1ClientsPath + "/" + state.clientsPage.editingClientId));
            effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({ pathname: "/chats", hash: "", search: "" }));
            break;
        case "load-next-client-data":
            effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, array_utils_1.stringifyArray([exports.clientsPageIndexRequestName])));
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceClientsPage = reduceClientsPage;
function unfoldClientRule(ruleSet) {
    var root = tslib_1.__assign({}, ruleSet[0]);
    var cur = root;
    for (var i = 1; i < ruleSet.length - 1; ++i) {
        cur = cur.and = tslib_1.__assign({}, ruleSet[i]);
    }
    return root;
}
function unfoldClientRules(rules) {
    return rules.filter(function (r) { return r.length > 1; }).map(unfoldClientRule);
}
function foldClientRule(rule) {
    var result = [];
    while (rule) {
        var _a = rule, and = _a.and, copy = tslib_1.__rest(_a, ["and"]);
        result.push(copy);
        rule = and;
    }
    result.push(client_rule_reducer_1.newRule());
    return result;
}
exports.clientTitleErrorKey = "client-title-error-key";
exports.clientAutoPostUserErrorKey = "auto-post-user-error-key";
exports.clientAutoPostKindErrorKey = "auto-post-kind-error-key";
exports.clientAutoPostContentErrorKey = "auto-post-content-error-key";
exports.clientAutoPostErrorKeys = [exports.clientAutoPostUserErrorKey, exports.clientAutoPostKindErrorKey, exports.clientAutoPostContentErrorKey];
exports.clientAwaitingPostUserErrorKey = "awaiting-post-user-error-key";
exports.clientAwaitingPostKindErrorKey = "awaiting-post-kind-error-key";
exports.clientAwaitingPostContentErrorKey = "awaiting-post-content-error-key";
exports.clientAwaitingPostErrorKeys = [exports.clientAwaitingPostUserErrorKey, exports.clientAwaitingPostKindErrorKey, exports.clientAwaitingPostContentErrorKey];
exports.autoPostContentValid = function (autoPost) {
    return !!((autoPost.kind !== "text" && autoPost.settings) || (autoPost.kind === "text" && autoPost.content));
};
exports.awaitingPostContentValid = function (awaitingPost) {
    return !!((awaitingPost.kind !== "text" && awaitingPost.settings) || (awaitingPost.kind === "text" && awaitingPost.content));
};
exports.validateEditClient = memoizers_1.memoizeBySomeProperties({
    clientsPage: initialState.clientsPage,
    inputs: initialState.inputs
}, function (state) {
    var errors = {};
    errors[exports.clientTitleErrorKey] = !state.inputs.clientTitle;
    for (var i = 0; i < state.clientsPage.autoPosts.length; i++) {
        var autoPost = state.clientsPage.autoPosts[i];
        var idx = i.toString();
        errors[array_utils_1.stringifyArray([exports.clientAutoPostContentErrorKey, idx])] = !exports.autoPostContentValid(autoPost);
        errors[array_utils_1.stringifyArray([exports.clientAutoPostKindErrorKey, idx])] = !autoPost.kind;
        errors[array_utils_1.stringifyArray([exports.clientAutoPostUserErrorKey, idx])] = !autoPost.user_id;
    }
    for (var i = 0; i < state.clientsPage.awaitingPosts.length; i++) {
        var awaitingPost = state.clientsPage.awaitingPosts[i];
        var idx = i.toString();
        errors[array_utils_1.stringifyArray([exports.clientAwaitingPostContentErrorKey, idx])] = !exports.awaitingPostContentValid(awaitingPost);
        errors[array_utils_1.stringifyArray([exports.clientAwaitingPostKindErrorKey, idx])] = !awaitingPost.kind;
        errors[array_utils_1.stringifyArray([exports.clientAwaitingPostUserErrorKey, idx])] = !awaitingPost.user_id;
    }
    return errors;
});
exports.validateEditClientAwaitingPostDuringBusinessHour = memoizers_1.memoizeBySomeProperties({
    clientsPage: initialState.clientsPage,
    inputs: initialState.inputs
}, function (state) {
    var errors = {};
    errors[exports.clientTitleErrorKey] = !state.inputs.clientTitle;
    for (var i = 0; i < state.clientsPage.awaitingPostsDuringBusinessHour.length; i++) {
        var awaitingPost = state.clientsPage.awaitingPostsDuringBusinessHour[i];
        var idx = i.toString();
        errors[array_utils_1.stringifyArray([exports.clientAwaitingPostContentErrorKey, idx])] = !exports.awaitingPostContentValid(awaitingPost);
        errors[array_utils_1.stringifyArray([exports.clientAwaitingPostKindErrorKey, idx])] = !awaitingPost.kind;
        errors[array_utils_1.stringifyArray([exports.clientAwaitingPostUserErrorKey, idx])] = !awaitingPost.user_id;
    }
    return errors;
});
exports.validateEditClientAwaitingPostAfterBusinessHour = memoizers_1.memoizeBySomeProperties({
    clientsPage: initialState.clientsPage,
    inputs: initialState.inputs
}, function (state) {
    var errors = {};
    errors[exports.clientTitleErrorKey] = !state.inputs.clientTitle;
    for (var i = 0; i < state.clientsPage.awaitingPostsAfterBusinessHour.length; i++) {
        var awaitingPost = state.clientsPage.awaitingPostsAfterBusinessHour[i];
        var idx = i.toString();
        errors[array_utils_1.stringifyArray([exports.clientAwaitingPostContentErrorKey, idx])] = !exports.awaitingPostContentValid(awaitingPost);
        errors[array_utils_1.stringifyArray([exports.clientAwaitingPostKindErrorKey, idx])] = !awaitingPost.kind;
        errors[array_utils_1.stringifyArray([exports.clientAwaitingPostUserErrorKey, idx])] = !awaitingPost.user_id;
    }
    return errors;
});
exports.widgetButtonImageUploadRequestName = "widget-button-image-upload-request-name";
exports.timeFormats = {
    "yyyy/mm/dd hh:mm": "YYYY/MM/DD HH:mm",
    "yyyy年mm月dd日 hh:mm": "YYYY年MM月DD日 HH時mm分"
};
