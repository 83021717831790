"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var bootstrap_1 = require("../src/core/bootstrap");
var root_reducer_1 = require("../src/widget-v2/reducers/root-reducer");
var root_page_1 = require("../src/widget-v2/views/root-page");
var widget_state_1 = require("../src/widget-v2/reducers/widget-state");
var xhr_1 = require("../src/utils/xhr");
var url_parser_1 = require("../src/utils/url-parser");
var cookie_utils_1 = require("../src/utils/cookie-utils");
var timezone_1 = require("../src/utils/timezone");
var services_1 = require("../src/core/services/services");
var style_utils_1 = require("../src/utils/style-utils");
var complete_request_reducer_1 = require("../src/widget-v2/reducers/complete-request-reducer");
var events_1 = require("../src/widget-v2/events");
var OkskyChat = (function () {
    function OkskyChat(clientCode, rootUrl, options, consoleLog) {
        if (options === void 0) { options = {}; }
        if (consoleLog === void 0) { consoleLog = false; }
        var _this = this;
        this.googleAnalyticsConfig = { ga_client_code: "", mode: "" };
        this.config = {};
        this.defaultHeaders = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
        this.show = function (options) {
            if (options === void 0) { options = {}; }
            var self = _this;
            var conf = {};
            conf.method = "POST";
            var hideButton = options.hideButton || false;
            var hidePopup = options.hidePopup || false;
            var headers = tslib_1.__assign({}, self.defaultHeaders);
            var xdCookieValue = "-";
            if (document.cookie) {
                xdCookieValue = document.cookie.split("; ").filter(function (s) { return s.match(/^xd-/); }).map(function (s) { return s.replace(/^xd-/, ''); }).join("; ") || "-";
            }
            headers = tslib_1.__assign({}, headers, { "Xd-Cookie": xdCookieValue });
            conf.headers = headers;
            conf.data = JSON.stringify(self.loginParams());
            conf.url = url_parser_1.urlJoin(self.rootUrl, "/widget/login_v2?client_code=" + self.clientCode);
            conf.onComplete = function (response) {
                self.showWidget(response, hideButton, hidePopup, self.outputConsoleLog);
            };
            xhr_1.xhr(conf);
        };
        this.rooms = function (f) {
            var self = _this;
            var conf = {};
            conf.method = "GET";
            var headers = tslib_1.__assign({}, self.defaultHeaders);
            var xdCookieValue = "-";
            var xAccessToken = "";
            if (document.cookie) {
                xdCookieValue = document.cookie.split("; ").filter(function (s) { return s.match(/^xd-/); }).map(function (s) { return s.replace(/^xd-/, ''); }).join("; ") || "-";
                var xAccessTokenHash = document.cookie.split("; ").filter(function (s) { return s.match(/^xd-access_token/); }).map(function (s) { return s.replace(/^xd-access_token=/, ''); });
                xAccessToken = xAccessTokenHash[0] || "";
            }
            if (xAccessToken == "") {
                f(null);
            }
            else {
                headers = tslib_1.__assign({}, headers, { "Xd-Cookie": xdCookieValue, "X-Access-Token": xAccessToken });
                conf.headers = headers;
                conf.data = JSON.stringify(self.loginParams());
                conf.url = url_parser_1.urlJoin(self.rootUrl, "/rapi/v1/rooms/related");
                conf.onComplete = function (event) {
                    f(JSON.parse(event.response));
                };
                xhr_1.xhr(conf);
            }
        };
        this.unreadMessageCount = function (roomId, f) {
            var self = _this;
            var conf = {};
            conf.method = "GET";
            var headers = tslib_1.__assign({}, self.defaultHeaders);
            var xdCookieValue = "-";
            var xAccessToken = "";
            if (document.cookie) {
                xdCookieValue = document.cookie.split("; ").filter(function (s) { return s.match(/^xd-/); }).map(function (s) { return s.replace(/^xd-/, ''); }).join("; ") || "-";
                var xAccessTokenHash = document.cookie.split("; ").filter(function (s) { return s.match(/^xd-access_token/); }).map(function (s) { return s.replace(/^xd-access_token=/, ''); });
                xAccessToken = xAccessTokenHash[0] || "";
            }
            headers = tslib_1.__assign({}, headers, { "Xd-Cookie": xdCookieValue, "X-Access-Token": xAccessToken });
            conf.headers = headers;
            conf.data = JSON.stringify(self.loginParams());
            conf.url = url_parser_1.urlJoin(self.rootUrl, "/rapi/v1/rooms/" + roomId + "/unread_message_counts");
            conf.onComplete = function (event) {
                if (f)
                    f(JSON.parse(event.response));
            };
            xhr_1.xhr(conf);
        };
        this.postMessage = function (roomName, message, f) {
            var self = _this;
            var conf = {};
            conf.method = "POST";
            var headers = tslib_1.__assign({}, self.defaultHeaders);
            var xdCookieValue = "-";
            if (document.cookie) {
                xdCookieValue = document.cookie.split("; ").filter(function (s) { return s.match(/^xd-/); }).map(function (s) { return s.replace(/^xd-/, ''); }).join("; ") || "-";
            }
            headers = tslib_1.__assign({}, headers, { "Xd-Cookie": xdCookieValue });
            conf.headers = headers;
            conf.data = JSON.stringify(self.loginParams());
            var urlFixedMessage = encodeURIComponent(message);
            conf.url = url_parser_1.urlJoin(self.rootUrl, "/widget/login_v2?client_code=" + self.clientCode + "&message=" + urlFixedMessage + "&room_name=" + roomName);
            conf.onComplete = function (response) {
                if (f)
                    f(JSON.parse(response.responseText).room);
                self.showWidget(response, false, false, self.outputConsoleLog);
            };
            xhr_1.xhr(conf);
        };
        this.loaderInitialState = function () {
            var self = _this;
            var _a = self.config, should_show = _a.should_show, root_url = _a.root_url, session_cookie_name = _a.session_cookie_name, action_cable_url = _a.action_cable_url, user_uuid = _a.user_uuid, access_token = _a.access_token, widgetMaximized = _a.widgetMaximized, cloudinary_info = _a.cloudinary_info, googleAnalyticsConfig = _a.googleAnalyticsConfig, protoConfig = tslib_1.__rest(_a, ["should_show", "root_url", "session_cookie_name", "action_cable_url", "user_uuid", "access_token", "widgetMaximized", "cloudinary_info", "googleAnalyticsConfig"]);
            var initialState = tslib_1.__assign({}, widget_state_1.WidgetV2State.initialState, protoConfig, { colors: tslib_1.__assign({}, widget_state_1.WidgetV2State.initialState.colors, self.config.colors), toggles: tslib_1.__assign({}, widget_state_1.WidgetV2State.initialState.toggles, { widgetMaximized: self.config.widgetMaximized, widgetFocused: self.config.widgetMaximized }) });
            initialState.clientCode = self.clientCode;
            if (_this.config.tracker_check_get_message_ids !== null && _this.config.tracker_check_get_message_ids !== undefined) {
                if (_this.config.tracker_check_get_message_ids.length > 0) {
                    initialState.tracker_check_get_message_ids = Array.from(_this.config.tracker_check_get_message_ids);
                }
            }
            _this.config.tracker_check_get_message_ids = [];
            return initialState;
        };
        this.beginWidgetSession = function (outputConsoleLog, hideButton, hidePopup) {
            var self = _this;
            if (self.isAlreadyBootstrapped === undefined) {
                if (self.googleAnalyticsConfig.ga_client_code)
                    self.config.googleAnalyticsConfig.ga_client_code = self.googleAnalyticsConfig.ga_client_code;
                if (self.googleAnalyticsConfig.mode)
                    self.config.googleAnalyticsConfig.mode = self.googleAnalyticsConfig.mode;
                if (self.config.googleAnalyticsConfig.mode === "")
                    self.config.googleAnalyticsConfig.mode = "gtag";
                bootstrap_1.bootstrap(self.loaderInitialState(), root_reducer_1.reduce, root_page_1.RootPage.render, services_1.getCoreServices(self.config), outputConsoleLog, 'ok-sky-widget', hideButton, hidePopup);
            }
            if (self.isAlreadyBootstrapped === undefined) {
                self.isAlreadyBootstrapped = true;
            }
        };
        this.showWidget = function (response, hideButton, hidePopup, outputConsoleLog) {
            var self = _this;
            var responseConfig = (response && response.responseText && JSON.parse(response.responseText)) || {};
            self.config = tslib_1.__assign({}, self.config, responseConfig);
            if (!self.config.should_show)
                return;
            if (!(self.config.access_token && self.config.user_uuid))
                throw "Error loading widget";
            cookie_utils_1.setCookies(complete_request_reducer_1.parseXdSetCookie(response), "xd-");
            var okskyWidget = document.createElement('div');
            okskyWidget.setAttribute('id', 'ok-sky-widget');
            style_utils_1.appendOKSKYStyleToHead(document.head);
            document.body.appendChild(okskyWidget);
            if (self.config.provider_login_mode) {
                self.onOpen(false, hideButton, hidePopup, outputConsoleLog);
                style_utils_1.setOKSKYStyleClassToRoot(document.body.parentElement);
                return;
            }
            style_utils_1.setOKSKYStyleClassToRoot(okskyWidget);
            _this.onReadyListener();
            if (hideButton || cookie_utils_1.getCookie("xd-" + self.config.session_cookie_name)) {
                _this.config.tracker_check_get_message_ids = [];
                self.onOpen(false, hideButton, hidePopup, outputConsoleLog);
            }
            else {
                bootstrap_1.bootstrap(self.loaderInitialState(), function (state, action) {
                    switch (action.type) {
                        case "go-widget-maximized-tracker":
                            if (action.widgetMaximized) {
                                events_1.chatCloseTracker(state.currentViewingRoomId, state.client_id, state.clientCode);
                            }
                            else {
                                events_1.chatOpenTracker(state.currentViewingRoomId, state.client_id, state.clientCode);
                            }
                            break;
                        case "go-tracker-w-close":
                            events_1.chatHiddenTracker(state.currentViewingRoomId, state.client_id, state.clientCode);
                            break;
                        case "toggle":
                            switch (action.target) {
                                case "widgetMaximized":
                                    state = _this.toggleWidgetMaximizedOpen(state, hideButton, hidePopup, outputConsoleLog);
                                    break;
                                case "widgetHidden":
                                    state = tslib_1.__assign({}, state);
                                    state.toggles = tslib_1.__assign({}, state.toggles);
                                    state.toggles.widgetHidden = true;
                                    break;
                            }
                            break;
                        case 'set-tracker-checker':
                            if (action.check_id && action.check_id !== null && action.check_id.length > 0) {
                                state.tracker_check_get_message_ids = action.check_id;
                                if (state.toggles.widgetMaximized === undefined || state.toggles.widgetMaximized === false) {
                                    events_1.chatOpenTracker(state.currentViewingRoomId, state.client_id, state.clientCode);
                                }
                                state = _this.toggleWidgetMaximizedOpen(state, hideButton, hidePopup, outputConsoleLog);
                            }
                            break;
                    }
                    return {
                        state: state,
                        effects: []
                    };
                }, root_page_1.RootPage.render, function () { return []; }, outputConsoleLog, 'ok-sky-widget');
            }
            ;
        };
        this.toggleWidgetMaximizedOpen = function (state, hideButton, hidePopup, outputConsoleLog) {
            var self = _this;
            self.onOpen(true, hideButton, hidePopup, outputConsoleLog);
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.widgetMaximized = true;
            return state;
        };
        this.toggleButton = function () {
            var self = _this;
            self.config.widgetMaximized = !self.config.widgetMaximized;
        };
        this.toggleWidget = function () {
            var self = _this;
            self.config.widgetHide = !self.config.widgetHide;
        };
        this.loginParams = function () {
            var self = _this;
            return {
                client_code: self.clientCode,
                login: self.loginKeys(),
                query: self.options.user_infos || null,
                info: self.getInfo()
            };
        };
        this.loginKeys = function () {
            var self = _this;
            if (!self.options.login)
                return null;
            var loginKeys = [];
            var loginValues = [];
            for (var k in self.options.login) {
                var v = self.options.login && self.options.login[k];
                if (k && v) {
                    loginKeys.push(k);
                    loginValues.push(v);
                }
            }
            return { keys: loginKeys, values: loginValues };
        };
        this.getInfo = function () {
            var self = _this;
            var m = JSON.parse(JSON.stringify(self.getTrackingParams()));
            m.width = window.width;
            m.height = window.height;
            return m;
        };
        this.getTrackingParams = function () {
            var timezone = timezone_1.determineTimezone().name();
            return {
                ua: navigator.userAgent,
                p: navigator.platform,
                r: document.referrer,
                l: location.toString(),
                t: Date.now(),
                tz: timezone,
                width: window.top.document.documentElement.offsetWidth,
                height: window.top.document.documentElement.offsetHeight,
                top: window.top.window.pageXOffset,
                left: window.top.window.pageYOffset,
                ptitle: document.title
            };
        };
        this.onReadyListener = function () {
            if (typeof _this.widget.onReady === 'function') {
                _this.widget.onReady();
            }
        };
        this.onReceivedMessageListener = function (event) {
            if (typeof _this.event.onReceivedMessage === 'function') {
                _this.event.onReceivedMessage(event.detail);
            }
        };
        this.onReceivedSomeMessageListener = function (event) {
            if (typeof _this.event.onReceivedSomeMessage === 'function') {
                _this.event.onReceivedSomeMessage(event.detail);
            }
        };
        this.widget = {
            open: function (roomId) {
                if (roomId === void 0) { roomId = null; }
                _this.config.tracker_check_get_message_ids = [];
                events_1.widgetOpen(roomId);
            },
            close: function () {
                _this.config.tracker_check_get_message_ids = [];
                events_1.widgetClose();
            },
            trackerCheckerMessage: function (recommend_check_ids) {
                if (recommend_check_ids === void 0) { recommend_check_ids = []; }
                _this.config.tracker_check_get_message_ids = [];
                var checkElementVisibility = function () {
                    var iframe = document.getElementById('widget-button-iframe');
                    if (iframe) {
                        var iframeWindow = iframe.contentWindow;
                        if (iframeWindow) {
                            var iframeDocument = iframeWindow.document;
                            if (iframeDocument) {
                                var divElement = iframeDocument.querySelector('.test--widget-button');
                                if (divElement && window.getComputedStyle(divElement).display !== 'none') {
                                    if (recommend_check_ids && typeof recommend_check_ids != null && Array.isArray(recommend_check_ids)) {
                                        if (recommend_check_ids.every(function (id) { return typeof id === 'number'; })) {
                                            _this.config.tracker_check_get_message_ids = recommend_check_ids;
                                            events_1.widgetTrackerCheckOpen(recommend_check_ids);
                                            return;
                                        }
                                    }
                                    events_1.widgetOpen();
                                    return;
                                }
                            }
                        }
                    }
                    setTimeout(checkElementVisibility, 100);
                };
                checkElementVisibility();
            },
            onReady: null,
        };
        this.event = {
            onReceivedMessage: null,
            onReceivedSomeMessage: null,
        };
        this.outputConsoleLog = consoleLog;
        var self = this;
        if (!clientCode)
            throw "Please enter a client";
        if (!rootUrl)
            throw "App rootUrl is needed";
        if (options.ga_client_code) {
            this.googleAnalyticsConfig.ga_client_code = options.ga_client_code;
        }
        if (options.ga) {
            this.googleAnalyticsConfig.mode = options.ga;
        }
        window['ga-disable-' + clientCode] = true;
        self.clientCode = clientCode;
        self.rootUrl = rootUrl;
        self.options = options;
        self.config.root_url = rootUrl;
        self.config.provider_login_mode = !!options.provider_login_mode;
        document.addEventListener(events_1.WIDGET_V2_RECEIVED_MESSAGE, self.onReceivedMessageListener, false);
        document.addEventListener(events_1.WIDGET_V2_RECEIVED_SOME_MESSAGE, self.onReceivedSomeMessageListener, false);
    }
    OkskyChat.prototype.onOpen = function (shouldMaximize, shouldHideButton, shouldHidePopup, outputConsoleLog) {
        if (shouldMaximize === void 0) { shouldMaximize = false; }
        if (shouldHideButton === void 0) { shouldHideButton = false; }
        if (shouldHidePopup === void 0) { shouldHidePopup = false; }
        if (outputConsoleLog === void 0) { outputConsoleLog = false; }
        var self = this;
        var conf = {};
        conf.method = "POST";
        conf.headers = tslib_1.__assign({}, self.defaultHeaders, { "Xd-Cookie": cookie_utils_1.getXdCookiesHeaderValue(self.config.xd_cookie_names) });
        conf.data = JSON.stringify(self.loginParams());
        var url = "/widget/on_open?client_code=" + self.clientCode;
        conf.url = url_parser_1.urlJoin(self.rootUrl, url);
        conf.onComplete = function (response) {
            var interactConfig = (response && response.responseText && JSON.parse(response.responseText)) || {};
            self.config = tslib_1.__assign({}, self.config, interactConfig);
            if (shouldMaximize) {
                self.toggleButton();
            }
            ;
            if (shouldHideButton) {
                self.toggleWidget();
            }
            cookie_utils_1.setCookies(complete_request_reducer_1.parseXdSetCookie(response), "xd-");
            self.beginWidgetSession(outputConsoleLog, shouldHideButton, shouldHidePopup);
        };
        xhr_1.xhr(conf);
    };
    return OkskyChat;
}());
window.OkskyChat = OkskyChat;
