"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var initial_loading_reducer_1 = require("./initial-loading-reducer");
var complete_request_reducer_1 = require("./complete-request-reducer");
var json_resources_1 = require("../core/json-resources");
var stores_1 = require("../stores");
var loadResources = stores_1.Stores.loadResources;
var routes_1 = require("../routes");
var sequence_services_1 = require("../core/services/sequence-services");
function loadNextTopNavigationPrivateMessages() {
    return {
        type: "load-next-top-navigation-private-messages"
    };
}
exports.loadNextTopNavigationPrivateMessages = loadNextTopNavigationPrivateMessages;
function beginSearchPrivateRooms(searchString) {
    return {
        type: "begin-search-private-rooms",
        searchString: searchString
    };
}
exports.beginSearchPrivateRooms = beginSearchPrivateRooms;
function reducePrivateMessagesNavigation(state, action) {
    var effects = [];
    switch (action.type) {
        case "load-next-top-navigation-private-messages":
            if (state.inputs.privateMessagesSearch.trim()) {
                effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, exports.loadSearchedPrivateRoomRequestName));
            }
            else {
                effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, initial_loading_reducer_1.loadPrivateRoomsRequestName));
            }
            break;
        case "complete-request":
            if (action.name[0] === exports.loadSearchedPrivateRoomRequestName) {
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                if (!responseDetails.resources)
                    break;
                if (responseDetails.resources.length === 0)
                    break;
                var searchString_1 = action.name[1];
                if (!searchString_1.trim())
                    break;
                state = tslib_1.__assign({}, state);
                state.stores = loadResources(responseDetails.resources, state.stores);
            }
            break;
        case "begin-search-private-rooms":
            var searchString = action.searchString.trim();
            if (!searchString)
                break;
            effects = sequence_services_1.sequence(effects, loadSearchedPrivateRoom(action.searchString.trim(), state.loggedInUser));
            break;
    }
    return { state: state, effects: effects };
}
exports.reducePrivateMessagesNavigation = reducePrivateMessagesNavigation;
function loadSearchedPrivateRoom(searchString, loggedInUser) {
    var query = {};
    query.include = ["current_member", "create_user", "latest_message", "helped_room", "helped_room.current_member"];
    query.filter = { support_room_name_like: searchString, private_rooms_with_active_or_awaiting_support: 1, current_user_is_member: 1 };
    query.page = { size: 5 };
    return json_resources_1.requestResourceFetch([exports.loadSearchedPrivateRoomRequestName, searchString], routes_1.RapiV1RoomsPath, query);
}
exports.loadSearchedPrivateRoom = loadSearchedPrivateRoom;
exports.loadSearchedPrivateRoomRequestName = "load-searched-private-room-resource-data";
exports.privateRoomsPagedRequestNames = [exports.loadSearchedPrivateRoomRequestName];
