"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var customers_page_reducer_1 = require("./customers-page-reducer");
var usage_page_reducer_1 = require("./usage-page-reducer");
var initial_loading_reducer_1 = require("./initial-loading-reducer");
var json_resources_1 = require("../core/json-resources");
var stores_1 = require("../stores");
var loadResources = stores_1.Stores.loadResources;
var resource_api_1 = require("../schemas/resource-api");
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var supports_left_filtering_panel_reducer_1 = require("./supports-left-filtering-panel-reducer");
var array_utils_1 = require("../utils/array-utils");
var draft_messages_reducer_1 = require("./draft-messages-reducer");
var template_responses_reducer_1 = require("./template-responses-reducer");
var clients_page_reducer_1 = require("./clients-page/clients-page-reducer");
var chat_history_reducer_1 = require("./chat-history-reducer");
var private_messages_navigation_reducer_1 = require("./private-messages-navigation-reducer");
var operator_page_reducer_1 = require("./operator-page-reducer");
var sequence_services_1 = require("../core/services/sequence-services");
var reports_page_reducer_1 = require("./reports-page-reducer");
var surveys_reducer_1 = require("./surveys-reducer");
var paged_search_requests_reducer_1 = require("./paged-search-requests-reducer");
function areRequestsStillLoading(loadingRequests, expectedStringifiedRequestNames) {
    for (var _i = 0, expectedStringifiedRequestNames_1 = expectedStringifiedRequestNames; _i < expectedStringifiedRequestNames_1.length; _i++) {
        var expectedStringifiedRequestName = expectedStringifiedRequestNames_1[_i];
        if (loadingRequests[expectedStringifiedRequestName])
            return true;
    }
    return false;
}
exports.areRequestsStillLoading = areRequestsStillLoading;
function loadNextPageIfNeeded(state, stringifiedRequestName) {
    var effects = [];
    if (!stringifiedRequestName)
        return effects;
    var nextRequest = state.nextPagedRequests[stringifiedRequestName];
    if (!nextRequest)
        return effects;
    if (state.loadingPagedRequests[stringifiedRequestName])
        return effects;
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch(array_utils_1.parseStringifiedArray(stringifiedRequestName), nextRequest));
    return effects;
}
exports.loadNextPageIfNeeded = loadNextPageIfNeeded;
function reduceCompleteRequests(state, action) {
    var effects = [];
    var doNotLoadRequests = paged_search_requests_reducer_1.pagedSearchRequestNames.concat([initial_loading_reducer_1.getRoomCountsRequestName, usage_page_reducer_1.usageLoadingRequestName]).concat(reports_page_reducer_1.searchReportsRequestNames);
    var paginatedRequests = initial_loading_reducer_1.initialLoadingPagedRequestNames
        .concat(operator_page_reducer_1.operatorsPagePagedRequestNames)
        .concat(private_messages_navigation_reducer_1.privateRoomsPagedRequestNames)
        .concat(template_responses_reducer_1.templateResponsesPagedRequestNames)
        .concat(supports_left_filtering_panel_reducer_1.supportLeftFilteringPanelPagedRequestName)
        .concat(customers_page_reducer_1.customersPagedRequestName)
        .concat(clients_page_reducer_1.clientsPagedRequestName)
        .concat(chat_history_reducer_1.chatHistoryPagedRequestNames)
        .concat(draft_messages_reducer_1.draftMessagesPagedRequestNames)
        .concat(surveys_reducer_1.surveysPagedRequestNames);
    switch (action.type) {
        case "complete-request":
            state = tslib_1.__assign({}, state);
            if (paginatedRequests.indexOf(action.name[0]) !== -1) {
                state.loadingPagedRequests = tslib_1.__assign({}, state.loadingPagedRequests);
                delete state.loadingPagedRequests[array_utils_1.stringifyArray(action.name)];
            }
            var responseDetails = json_resources_1.getResourceResponseDetails(action);
            if (!responseDetails.resources)
                break;
            if (!responseDetails.resources.length)
                break;
            state.completedRequests = tslib_1.__assign({}, state.completedRequests);
            state.completedRequests[array_utils_1.stringifyArray(action.name)] = true;
            if (doNotLoadRequests.indexOf(action.name[0]) !== -1)
                break;
            state.stores = loadResources(responseDetails.resources, state.stores);
            var apiResponse = responseDetails.apiResponse;
            if (!apiResponse || isErrorResponse(apiResponse))
                break;
            if (paginatedRequests.indexOf(action.name[0]) !== -1) {
                if (apiResponse.links && apiResponse.links.next) {
                    state.nextPagedRequests = tslib_1.__assign({}, state.nextPagedRequests);
                    state.nextPagedRequests[array_utils_1.stringifyArray(action.name)] = apiResponse.links.next;
                }
                else {
                    state.nextPagedRequests = tslib_1.__assign({}, state.nextPagedRequests);
                    delete state.nextPagedRequests[array_utils_1.stringifyArray(action.name)];
                }
            }
            else {
                var resourceType = responseDetails.resources[0].type;
                if (!resourceType)
                    break;
                var indexerName = stores_1.stateNameByModelType[resourceType];
                if (!indexerName)
                    break;
                var loadedCount = state.stores[indexerName][Object.keys(state.stores[indexerName])[0]].length;
                if (apiResponse.meta && apiResponse.meta.record_count && loadedCount >= apiResponse.meta.record_count)
                    break;
                effects = sequence_services_1.sequence(effects, json_resources_1.requestNextIfAvailable(action.name, responseDetails));
            }
            break;
        case "loading-request":
            if (paginatedRequests.indexOf(action.name[0]) !== -1) {
                state = tslib_1.__assign({}, state);
                state.loadingPagedRequests = tslib_1.__assign({}, state.loadingPagedRequests);
                state.loadingPagedRequests[array_utils_1.stringifyArray(action.name)] = true;
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceCompleteRequests = reduceCompleteRequests;
