"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var memoizers_1 = require("../../utils/memoizers");
var layouts_1 = require("../../components/layouts");
var chat_room_1 = require("./chat-room");
var room_list_1 = require("./room-list");
var header_1 = require("./header");
var color_editable_1 = require("../../components/color-editable");
var flash_1 = require("../../components/flash");
var flash_reducer_1 = require("../../reducers/subreducers/flash-reducer");
var settings_page_1 = require("./settings-page");
var WidgetCanvas;
(function (WidgetCanvas) {
    function render(dispatch) {
        var dispatcher = {
            dismissFlash: function (key) { return dispatch(flash_reducer_1.dismissFlash(key)); },
        };
        var ChatRoomContent = chat_room_1.ChatRoom.render(dispatch);
        var RoomListContent = room_list_1.RoomList.render(dispatch);
        var HeaderContent = header_1.Header.render(dispatch);
        var SettingsPageContent = settings_page_1.SettingsPage.render(dispatch);
        return memoizers_1.memoizeByEachArgument(function (state) {
            var language = state.loggedInUser.attributes.language;
            var borderClass = "";
            if (state.provider_login_mode) {
                borderClass = "br0";
            }
            else if (state.currentWidgetPage !== "room") {
                borderClass = "bblr3 bbrr3";
            }
            else {
                borderClass = "bblr4 bbrr4";
            }
            return React.createElement(layouts_1.Column, { stretchColumn: true, className: "w-100 h-100 fixed" },
                React.createElement(layouts_1.Row, { fixedRow: true }, HeaderContent(state)),
                React.createElement(flash_1.Flash, { flashes: tslib_1.__assign({}, state.flashes), dismissFlash: dispatcher.dismissFlash, language: language }),
                React.createElement(color_editable_1.ColorEditable, { className: borderClass + " flex flex-row items-stretch flex-auto br0-s h-100", colors: state.colors, editedColors: { backgroundColor: "background", color: "background_text" } }, (function () {
                    switch (state.currentWidgetPage) {
                        case "roomList":
                            return RoomListContent(state);
                        case "room":
                            return ChatRoomContent(state);
                        case "settings":
                            return SettingsPageContent(state);
                    }
                })()));
        });
    }
    WidgetCanvas.render = render;
})(WidgetCanvas = exports.WidgetCanvas || (exports.WidgetCanvas = {}));
