"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var UserEventsStore;
(function (UserEventsStore) {
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    UserEventsStore.indexer = new bisect_1.Indexer("byId");
    UserEventsStore.indexer.addIndex("byId", function (t) { return [t.id]; });
    UserEventsStore.indexer.addIndex("byUserIdResourceTypeKindAndResourceId", function (t) { return [
        getRelationshipId(t, "user"),
        t.attributes.resource_type,
        t.attributes.kind,
        t.attributes.resource_id
    ]; });
})(UserEventsStore = exports.UserEventsStore || (exports.UserEventsStore = {}));
