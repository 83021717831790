"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var tracker_events_1 = require("../core/tracker-events");
function formatLineBreak(str, resourceId, color) {
    if (!str)
        return [React.createElement("span", null)];
    var splitted = str.split("\n");
    if (splitted.length <= 1)
        return [React.createElement("span", { key: str + "-" + resourceId + "-linebreak-content" }, linkify(str, resourceId, color))];
    var result = [];
    for (var i = 0; i < splitted.length - 1; ++i) {
        if (splitted[i]) {
            result.push(React.createElement("span", { key: splitted[i] + "-" + resourceId + "-" + i + "-linebreak-content" }, linkify(splitted[i], resourceId, color)));
        }
        result.push(React.createElement("br", { key: resourceId + "-" + i + "-linebreak-br" }));
    }
    if (splitted[splitted.length - 1]) {
        result.push(React.createElement("span", { key: splitted[splitted.length - 1] + "-" + resourceId + "-" + (splitted.length - 1) + "-linebreak-content" }, linkify(splitted[splitted.length - 1], resourceId, color)));
    }
    return result;
}
exports.formatLineBreak = formatLineBreak;
exports.addLineBreaks = function (str) {
    return str.split(/\r\n|\r|\n/g).map(function (s, i) {
        return React.createElement("div", { key: "string-prototype-" + i },
            React.createElement("span", null, s),
            React.createElement("br", null));
    });
};
exports.addLineBreaks2 = function (str) {
    return str.split(/\r\n|\r|\n/g).map(function (s, i) {
        return React.createElement("span", { key: "string-prototype-" + i },
            React.createElement("span", null, s),
            React.createElement("br", null));
    });
};
function highlightString(str, highlight, classNames, resourceId, color) {
    if (!str)
        return [React.createElement("span", null)];
    if (!highlight)
        return formatLineBreak(str, resourceId, color);
    var re = new RegExp(sanitizeRegex(highlight));
    var splitted = str.trim().split(re);
    if (splitted.length <= 1)
        return formatLineBreak(str, resourceId, color);
    var highlightedResult = [];
    for (var i = 0; i < splitted.length - 1; ++i) {
        if (splitted[i]) {
            highlightedResult = highlightedResult.concat(formatLineBreak(splitted[i], resourceId + "-" + i, color));
        }
        highlightedResult.push(React.createElement("span", { key: highlight + "-" + resourceId + "-" + i + "-highlight-content", className: classNames }, linkify(highlight, resourceId, color)));
    }
    if (splitted[splitted.length - 1]) {
        highlightedResult = highlightedResult.concat(formatLineBreak(splitted[splitted.length - 1], resourceId + "-" + (splitted.length - 1), color));
    }
    return highlightedResult;
}
exports.highlightString = highlightString;
function highlightStringWithMultipleKeywords(str, highlight, classNames, resourceId, color) {
    if (!str)
        return [React.createElement("span", null)];
    if (!highlight)
        return formatLineBreak(str, resourceId, color);
    var keywords = highlight.split(' ').filter(function (e) { return e !== ""; });
    var sub = String.fromCharCode(0x1a);
    var us = String.fromCharCode(0x1f);
    var replacedStr = str;
    for (var k = 0; k < keywords.length; k++) {
        replacedStr = replacedStr.split(keywords[k]).join(sub + k.toString() + us);
    }
    var splitted = replacedStr.trim().split(/((?:[\x1a][0-9]+[\x1f])|(?=[\x1a][0-9]+[\x1f]))/);
    splitted = splitted.filter(function (e) { return e !== ""; });
    if (splitted.length < 1)
        return formatLineBreak(str, resourceId, color);
    var highlightedResult = [];
    for (var i = 0; i < splitted.length; i++) {
        if (splitted[i]) {
            if (/[\x1a][0-9]+[\x1f]/.test(splitted[i])) {
                var index = parseInt(splitted[i].split(sub).join('').split(us).join(''));
                var keyword = keywords[index];
                highlightedResult = highlightedResult.concat(React.createElement("span", { key: keyword + "-" + resourceId + "-" + i + "-highlight-content", className: classNames }, keyword));
            }
            else {
                highlightedResult = highlightedResult.concat(formatLineBreak(splitted[i], resourceId + "-" + i, color));
            }
        }
    }
    return highlightedResult;
}
exports.highlightStringWithMultipleKeywords = highlightStringWithMultipleKeywords;
function sanitizeRegex(str) {
    return str.replace(/[#-}]/g, '\\$&');
}
exports.sanitizeRegex = sanitizeRegex;
function sanitizeRegexWithMultipleKeywords(str) {
    var keywords = str.split(' ');
    var regex = keywords.filter(function (e) { return e !== ""; }).join('|');
    return regex;
}
exports.sanitizeRegexWithMultipleKeywords = sanitizeRegexWithMultipleKeywords;
function linkify(str, resourceId, color) {
    var exp = /(\b(https?):\/\/[-A-Z0-9+&@\[\]#\/%?=~_|!:,.;]*[-A-Z0-9+&@\[\]#\/%=~_|])/ig;
    var result = [];
    var matches = str.match(exp);
    if (!matches) {
        return telephoneLinkify(str, color);
    }
    else {
        var prefix_1;
        var suffix_1 = str;
        matches.forEach(function (link, i) {
            var _a;
            _a = (suffix_1 || "").split(link, 2), prefix_1 = _a[0], suffix_1 = _a[1];
            var linkName = link;
            if (prefix_1) {
                var regex = /\[l:(.+?)\]$/;
                var match = prefix_1.match(regex);
                if (match) {
                    linkName = match[1];
                    prefix_1 = prefix_1.replace(match[0], "");
                }
                result.push(React.createElement("span", { key: prefix_1.trim() + "-" + i }, telephoneLinkify(prefix_1, color)));
            }
            if (link) {
                var dataActionId = "oksky-link-ms-" + resourceId;
                result.push(React.createElement("a", { key: link + "-" + i, style: { color: color }, href: link, target: "_blank", "data-link-action-id": dataActionId, onClick: function (event) { return tracker_events_1.eventClickHandler(event); }, onKeyDown: function (e) { return tracker_events_1.eventKeyDownHandler(e); } }, linkName));
            }
        });
        if (suffix_1) {
            result.push(React.createElement("span", { key: suffix_1.trim() }, telephoneLinkify(suffix_1, color)));
        }
    }
    return result;
}
exports.linkify = linkify;
function telephoneLinkify(str, color) {
    var exp = /\b(0\d{1,3}-?\(? ?\d{2,4}-?\)? ?\d{4}|(0{1}\d{9,10})|0[5789]0-?\d{4}-?\d{4}|0120-?\d{3}-?\d{3})\b/ig;
    var matchers = str.match(exp);
    if (!matchers)
        return str;
    var index = 0;
    var replacedHtml = str;
    var preReplaceMatchers = matchers.concat();
    do {
        var matcher = preReplaceMatchers.shift();
        replacedHtml = replacedHtml.replace(matcher, "<tel>" + index + "</tel>");
        index++;
    } while (preReplaceMatchers.length != 0);
    for (var i = 0; i < matchers.length; i++) {
        var replacedTel = "<a\n            key=\"" + matchers[i] + "-" + i + "\"\n            style=\"color: " + color + "\"\n            href=\"tel:" + matchers[i] + "\"\n            target=\"_blank\">" + matchers[i] + "</a>";
        replacedHtml = replacedHtml.replace("<tel>" + i + "</tel>", replacedTel);
    }
    return [React.createElement("span", { dangerouslySetInnerHTML: { __html: replacedHtml } })];
}
exports.telephoneLinkify = telephoneLinkify;
