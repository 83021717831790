"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearInputDebouncingEventName = "clear-input-debouncing";
exports.flushInputDebouncingEventName = "flush-input-debouncing";
function clearInputDebouncing() {
    return { effectType: "clear-input-debouncing" };
}
exports.clearInputDebouncing = clearInputDebouncing;
function flushInputDebouncing() {
    return { effectType: "flush-input-debouncing" };
}
exports.flushInputDebouncing = flushInputDebouncing;
function withInputDebouncing(dispatch) {
    return function (effect) {
        switch (effect.effectType) {
            case "clear-input-debouncing":
                document.dispatchEvent(new Event(exports.clearInputDebouncingEventName));
                break;
            case "flush-input-debouncing":
                document.dispatchEvent(new Event(exports.flushInputDebouncingEventName));
                break;
        }
    };
}
exports.withInputDebouncing = withInputDebouncing;
