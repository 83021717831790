"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var classNamesForSendMessageButton = class_generator_1.classNamesGeneratorFor(function (add) {
    add("disabled", React.createElement("div", { className: "bg-moon-gray" }), React.createElement("div", { className: "glow o-90 bg-animate bg-light-blue" }));
}, React.createElement("div", { className: "b--none" }));
function RequestButton(props) {
    return React.createElement("button", { onClick: props.onClick, disabled: props.disabled, className: classNamesForSendMessageButton(props) + " " + props.className }, props.children);
}
exports.RequestButton = RequestButton;
