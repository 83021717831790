"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bisect_1 = require("../utils/bisect");
var StripePlansStore;
(function (StripePlansStore) {
    StripePlansStore.indexer = new bisect_1.Indexer("byId");
    StripePlansStore.indexer.addIndex("byId", function (stripePlan) { return [stripePlan.id]; });
    StripePlansStore.indexer.addIndex("withGeneral", function (stripePlan) { return [stripePlan.attributes.access === "general"]; });
    StripePlansStore.indexer.addIndex("withHidden", function (stripePlan) { return [stripePlan.attributes.access === "hidden"]; });
})(StripePlansStore = exports.StripePlansStore || (exports.StripePlansStore = {}));
