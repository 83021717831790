"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var generators_1 = require("../../utils/generators");
function requestUpload(name, files) {
    return {
        effectType: "request-upload",
        name: name,
        files: files
    };
}
exports.requestUpload = requestUpload;
function beginUpload(name) {
    return {
        type: "begin-upload",
        name: name
    };
}
exports.beginUpload = beginUpload;
function uploadSuccess(cloudinaryResponse, name) {
    return {
        type: "upload-success",
        name: name,
        cloudinaryResponse: cloudinaryResponse
    };
}
exports.uploadSuccess = uploadSuccess;
function uploadFailure(name, when) {
    if (when === void 0) { when = Date.now(); }
    return {
        type: "upload-failure",
        name: name,
        when: when
    };
}
exports.uploadFailure = uploadFailure;
function withUpload(dispatch, cloudinaryConfig) {
    return function (request) {
        switch (request.effectType) {
            case "request-upload":
                if (cloudinaryConfig == null) {
                    console.error("global.cloudinary_info is not defined");
                    dispatch(uploadFailure(request.name));
                    return;
                }
                var _loop_1 = function (file) {
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append("api_key", cloudinaryConfig.api_key);
                    formData.append("upload_preset", cloudinaryConfig.upload_preset);
                    var xhr = new XMLHttpRequest();
                    var fileType = file.type.split("/")[0];
                    var urlType = "raw";
                    var fileRequestName = request.name.concat(generators_1.genLocalId());
                    if (fileType == "image") {
                        urlType = "image";
                    }
                    else if (fileType == "video" || fileType == "audio") {
                        urlType = "video";
                    }
                    xhr.open('POST', "https://api.cloudinary.com/v1_1/" + cloudinaryConfig.cloud_name + "/" + urlType + "/upload", true);
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState === xhr.DONE) {
                            if (xhr.status === 200) {
                                dispatch(uploadSuccess(JSON.parse(xhr.responseText), fileRequestName));
                            }
                            else {
                                console.error("error: Unable to upload image to cloudinary", xhr.responseText);
                                dispatch(uploadFailure(fileRequestName));
                            }
                        }
                    };
                    dispatch(beginUpload(fileRequestName));
                    xhr.send(formData);
                };
                for (var _i = 0, _a = request.files; _i < _a.length; _i++) {
                    var file = _a[_i];
                    _loop_1(file);
                }
        }
    };
}
exports.withUpload = withUpload;
