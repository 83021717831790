"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function scrollTo(targetSelector) {
    return { effectType: 'scroll-to', targetSelector: targetSelector };
}
exports.scrollTo = scrollTo;
function withScrollTo() {
    return function (effect) {
        switch (effect.effectType) {
            case 'scroll-to':
                var element = document.querySelector(effect.targetSelector);
                if (element != null) {
                    element.scrollIntoView();
                }
        }
    };
}
exports.withScrollTo = withScrollTo;
