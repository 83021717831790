"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var svgs_1 = require("./svgs");
function VideoMessageContent(props) {
    var video = props.message.attributes.settings;
    if (!video)
        return null;
    var videoWidth = (props.isSmall ? 200 : 350);
    var videoHeight = videoWidth * .75;
    var playSize = 50;
    var bottomPositioning = (videoHeight / 2 - (playSize / 2.0));
    var leftPositioning = (videoWidth / 2 - playSize / 2.0);
    return React.createElement("div", { className: "relative" },
        React.createElement("a", { href: video.src_url, className: "absolute pointer z-2 bg-light-blue br-100 fill-white ba b--white bw3", style: { bottom: bottomPositioning, left: leftPositioning }, target: "_blank" },
            React.createElement(svgs_1.SVGPlay, { width: playSize, height: playSize, className: "relative db" })),
        React.createElement("video", { height: videoHeight, width: videoWidth, muted: true, src: video.src_url }));
}
exports.VideoMessageContent = VideoMessageContent;
