"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var memoizers_1 = require("../utils/memoizers");
var I18n_1 = require("../I18n");
function doubleDigits(n) {
    if (n < 10)
        return "0" + n;
    return n + "";
}
exports.formatTime = memoizers_1.memoizeByEachArgument(function (unixTime) {
    var date = new Date(unixTime * 1000);
    return doubleDigits(date.getHours()) + ":" + doubleDigits(date.getMinutes()) + ":" + doubleDigits(date.getSeconds());
});
function FormatTime(props) {
    return React.createElement("span", null, exports.formatTime(props.unixTime));
}
exports.FormatTime = FormatTime;
exports.formatDate = memoizers_1.memoizeByEachArgument(function (unixTime) {
    var date = new Date(unixTime * 1000);
    return date.toLocaleDateString();
});
function FormatDate(props) {
    return React.createElement("span", null, exports.formatDate(props.unixTime));
}
exports.FormatDate = FormatDate;
function formatTimeOrDate(unixTime, now) {
    var date = new Date(unixTime * 1000);
    var nowDate = new Date(now);
    if (nowDate.toDateString() === date.toDateString()) {
        return exports.formatTime(unixTime);
    }
    return exports.formatDate(unixTime);
}
exports.formatTimeOrDate = formatTimeOrDate;
function FormatTimeOrDate(props) {
    return React.createElement("span", { className: props.className }, formatTimeOrDate(props.unixTime, props.now));
}
exports.FormatTimeOrDate = FormatTimeOrDate;
function getUsername(user) {
    if (user.attributes.full_name && user.attributes.name) {
        return user.attributes.full_name + "(" + user.attributes.name + ")";
    }
    else {
        return user.attributes.full_name || user.attributes.name;
    }
}
exports.getUsername = getUsername;
function getUsernameWithoutUniqueName(user) {
    if (user.attributes.full_name) {
        return user.attributes.full_name;
    }
    else {
        return null;
    }
}
exports.getUsernameWithoutUniqueName = getUsernameWithoutUniqueName;
function getMasterKeywordTitle(masterkeyword) {
    return masterkeyword.attributes.key_title || masterkeyword.attributes.key_name;
}
exports.getMasterKeywordTitle = getMasterKeywordTitle;
function formatName(user) {
    if (user && getUsername(user)) {
        return getUsername(user) + (!!user.attributes.is_active ? '' : I18n_1.i18n.error[user.attributes.language]);
    }
    else {
        return "オペレーター";
    }
}
exports.formatName = formatName;
function formatNameWithoutUniqueName(user) {
    if (user && getUsernameWithoutUniqueName(user)) {
        return getUsernameWithoutUniqueName(user) + (!!user.attributes.is_active ? '' : I18n_1.i18n.error[user.attributes.language]);
    }
    else {
        return "オペレーター";
    }
}
exports.formatNameWithoutUniqueName = formatNameWithoutUniqueName;
function formatEmail(user) {
    if (user && user.attributes.user_type !== "Customer") {
        return user.attributes.email;
    }
    return "";
}
exports.formatEmail = formatEmail;
function FormatName(p) {
    return React.createElement("span", null, formatName(p.user));
}
exports.FormatName = FormatName;
function FormatEmail(p) {
    return React.createElement("span", null, formatEmail(p.user));
}
exports.FormatEmail = FormatEmail;
function humanFileSize(size) {
    if (size === 0)
        return '0 B';
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return ((size / Math.pow(1024, i)).toFixed(0)) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}
exports.humanFileSize = humanFileSize;
