"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var HooksStore;
(function (HooksStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    HooksStore.webhookContentTypes = ["json", "form", "www_form", "vnd_api_json"];
    HooksStore.indexer = new bisect_1.Indexer("byId");
    HooksStore.indexer.addIndex("byId", function (hook) { return [assertInnerId(hook)]; });
    HooksStore.indexer.addIndex("byEvents", function (hook) { return [hook.attributes.events]; });
    HooksStore.indexer.addIndex("byIsActive", function (hook) { return [hook.attributes.is_active]; });
    HooksStore.indexer.addIndex("byPayloadUrl", function (hook) { return [hook.attributes.payload_url]; });
    HooksStore.indexer.addIndex("byCreatedAtUnix", function (hook) { return [hook.attributes.created_at_unix]; });
    HooksStore.indexer.addIndex("byUpdatedAtUnix", function (hook) { return [hook.attributes.updated_at_unix]; });
})(HooksStore = exports.HooksStore || (exports.HooksStore = {}));
exports.HookEvents = [
    "Message",
    "Room",
    "MessageEvent",
    "Customer",
    "User",
    "Support",
];
