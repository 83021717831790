"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var reducers_1 = require("../core/reducers");
var json_resources_1 = require("../core/json-resources");
var routes_1 = require("../routes");
var toggles_reducer_1 = require("./subreducers/toggles-reducer");
var bisect_1 = require("../utils/bisect");
var resource_api_1 = require("../schemas/resource-api");
var newResource = resource_api_1.ResourceApi.newResource;
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var inputs_reducer_1 = require("./subreducers/inputs-reducer");
var complete_request_reducer_1 = require("./complete-request-reducer");
var array_utils_1 = require("../utils/array-utils");
var I18n_1 = require("../I18n");
var toast_reducer_1 = require("./toast-reducer");
var sequence_services_1 = require("../core/services/sequence-services");
var navigation_services_1 = require("../core/services/navigation-services");
var action_cable_services_1 = require("../core/services/action-cable-services");
var settings_page_reducer_1 = require("../widget-v2/reducers/settings-page-reducer");
var csv_service_1 = require("../core/services/csv-service");
exports.customersLoadingRequestName = "customer-loading";
exports.customersPageMemosLoadingRequestName = "customers-page-memos-loading";
exports.customersPageMemoWriteRequestName = "customers-page-memo-write";
exports.customersSpecificLoadingRequestName = "customers-specific-loading";
exports.customersTrackerEntryLoadingRequestName = "customers-tracker-entry-loading";
exports.customersPagedRequestName = [exports.customersLoadingRequestName];
function submitCustomerNote() {
    return { type: "submit-customer-note" };
}
exports.submitCustomerNote = submitCustomerNote;
function customersPageToggle(toggle) {
    return {
        type: "customers-page-toggle",
        toggle: toggle
    };
}
exports.customersPageToggle = customersPageToggle;
function customersPageBeginEditSearch() {
    return {
        type: "customers-page-begin-edit-search"
    };
}
exports.customersPageBeginEditSearch = customersPageBeginEditSearch;
function customersPageSaveSearch() {
    return {
        type: "customers-page-save-search"
    };
}
exports.customersPageSaveSearch = customersPageSaveSearch;
function customersPageCancelEditSearch() {
    return {
        type: "customers-page-cancel-edit-search"
    };
}
exports.customersPageCancelEditSearch = customersPageCancelEditSearch;
function customersPagePagination() {
    return { type: "customers-page-pagination" };
}
exports.customersPagePagination = customersPagePagination;
function customerFreeSearchInputChange(inputChange) {
    return {
        type: "customer-free-search-input-change",
        inputChange: inputChange
    };
}
exports.customerFreeSearchInputChange = customerFreeSearchInputChange;
function startEditUserInfoValue(userId, userInfoKey, userInfoValue) {
    return {
        type: 'start-edit-user-info-value',
        userId: userId,
        userInfoKey: userInfoKey,
        userInfoValue: userInfoValue,
    };
}
exports.startEditUserInfoValue = startEditUserInfoValue;
function spVisitTargetRoom(roomId) {
    return {
        type: 'sp-visit-target-room',
        roomId: roomId
    };
}
exports.spVisitTargetRoom = spVisitTargetRoom;
function cancelEditUserInfoValue() {
    return {
        type: 'cancel-edit-user-info-value'
    };
}
exports.cancelEditUserInfoValue = cancelEditUserInfoValue;
function finishEditUserInfoValue() {
    return {
        type: 'finish-edit-user-info-value'
    };
}
exports.finishEditUserInfoValue = finishEditUserInfoValue;
function cancelNewUserInfoValue() {
    return {
        type: 'cancel-new-user-info-value'
    };
}
exports.cancelNewUserInfoValue = cancelNewUserInfoValue;
function saveNewUserInfoValue() {
    return {
        type: 'save-new-user-info-value',
    };
}
exports.saveNewUserInfoValue = saveNewUserInfoValue;
function archiveCustomer() {
    return {
        type: 'archive-customer',
    };
}
exports.archiveCustomer = archiveCustomer;
function uploadCustomerReport(event) {
    return {
        type: "upload-customer-report",
        event: event,
    };
}
exports.uploadCustomerReport = uploadCustomerReport;
function beginEditCustomer(customerId) {
    return {
        type: "begin-edit-customer",
        customerId: customerId
    };
}
exports.beginEditCustomer = beginEditCustomer;
function closeFullScreenCustomerModal() {
    return {
        type: "close-full-screen-customer-modal"
    };
}
exports.closeFullScreenCustomerModal = closeFullScreenCustomerModal;
exports.uploadCustomerReportRequestName = "upload-customer-report";
exports.USER_INFO_SEPARATOR = ":";
function getUserInfoToggleKey(keyName, value) {
    return keyName + exports.USER_INFO_SEPARATOR + value;
}
exports.getUserInfoToggleKey = getUserInfoToggleKey;
function allSuggestedToggleKeys(mks) {
    var keys = [];
    for (var i = 0; i < mks.length; ++i) {
        if (mks[i].attributes.suggestions.length == 0)
            continue;
        for (var j = 0; j < mks[i].attributes.suggestions.length; ++j) {
            keys.push(getUserInfoToggleKey(mks[i].attributes.key_name, mks[i].attributes.suggestions[j]));
        }
    }
    return keys;
}
exports.allSuggestedToggleKeys = allSuggestedToggleKeys;
function computeCustomerFilterProperties(toggles, freeSearchInputs) {
    var userInfo = {};
    var addInfo = function (k, v) {
        if (userInfo[k]) {
            if (userInfo[k].indexOf(v) === -1)
                userInfo[k].push(v);
        }
        else {
            userInfo[k] = [v];
        }
    };
    for (var toggleKey in toggles) {
        if (!toggles[toggleKey])
            continue;
        var _a = toggleKey.split(exports.USER_INFO_SEPARATOR), key = _a[0], value = _a[1];
        addInfo(key, value);
    }
    for (var key in freeSearchInputs) {
        var value = freeSearchInputs[key];
        if (!value)
            continue;
        addInfo(key, value);
    }
    return userInfo;
}
exports.computeCustomerFilterProperties = computeCustomerFilterProperties;
function getCustomerQueryParams(state) {
    var filterQuery = { filter: {} };
    var name = state.inputs.searchCustomerNickName;
    var toggles = state.customersPage.toggles;
    var freeSearchInputs = state.customersPage.freeSearchInputs;
    var roomName = state.inputs.searchCustomerRoomName;
    if (name) {
        filterQuery.filter.username = name;
    }
    if (roomName) {
        filterQuery.filter.room_name = roomName;
    }
    filterQuery.filter.type = "Customer";
    var userInfo = computeCustomerFilterProperties(toggles, freeSearchInputs);
    if (Object.keys(userInfo).length > 0) {
        filterQuery.filter.user_info = JSON.stringify(userInfo);
    }
    return filterQuery;
}
exports.getCustomerQueryParams = getCustomerQueryParams;
function isValidJSON(str) {
    try {
        JSON.parse(str);
    }
    catch (e) {
        return false;
    }
    return true;
}
function beginCustomerSearchByQuery(state, query) {
    var _a;
    var effects = [];
    var toggles = null;
    var freeSearchInputs = null;
    var name = query["name"];
    var queryToggles = query["toggles"];
    var queryInputs = query["inputs"];
    if (queryToggles && isValidJSON(queryToggles)) {
        toggles = JSON.parse(queryToggles);
    }
    if (queryInputs && isValidJSON(queryInputs)) {
        freeSearchInputs = JSON.parse(queryInputs);
    }
    if (name || toggles || freeSearchInputs) {
        state = tslib_1.__assign({}, state);
        if (name) {
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.searchCustomerNickName = name;
        }
        if (toggles) {
            state.customersPage = tslib_1.__assign({}, state.customersPage);
            state.customersPage.toggles = toggles;
        }
        if (freeSearchInputs) {
            state.customersPage = tslib_1.__assign({}, state.customersPage);
            state.customersPage.freeSearchInputs = freeSearchInputs;
        }
        (_a = sequence_services_1.sequenceReduction(effects, beginCustomerSearch(state)), state = _a.state, effects = _a.effects);
    }
    return { state: state, effects: effects };
}
exports.beginCustomerSearchByQuery = beginCustomerSearchByQuery;
function beginCustomerSearch(state) {
    var effects = [];
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.customersLoadingRequestName], routes_1.RapiV1UsersPath, getCustomerQueryParams(state)));
    state = tslib_1.__assign({}, state);
    state.customersPage = tslib_1.__assign({}, state.customersPage);
    state.customersPage.filteredCustomers = [];
    return { state: state, effects: effects };
}
exports.beginCustomerSearch = beginCustomerSearch;
var stateSubReducer = reducers_1.subReducersFor();
var customersPageReducer = reducers_1.subReducersFor();
var reduceCustomersToggle = stateSubReducer("customersPage", customersPageReducer("toggles", toggles_reducer_1.reduceToggle));
var reduceFreeSearchInput = stateSubReducer("customersPage", customersPageReducer("freeSearchInputs", inputs_reducer_1.reduceInputs));
function reduceCustomersPage(state, action) {
    var _a, _b, _c, _d, _e;
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.customersLoadingRequestName) {
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                state.customersPage.numberOfCustomers = apiResponse.meta ? apiResponse.meta.record_count : 0;
                if (responseDetails.resources && responseDetails.resources.length) {
                    state = tslib_1.__assign({}, state);
                    state.customersPage = tslib_1.__assign({}, state.customersPage);
                    state.customersPage.filteredCustomers = state.customersPage.filteredCustomers.slice();
                    for (var i = 0; i < responseDetails.resources.length; i++) {
                        var customer_1 = responseDetails.resources[i];
                        if (customer_1 && customer_1.type === "users") {
                            state.customersPage.filteredCustomers = state.customersPage.filteredCustomers.concat(customer_1);
                        }
                    }
                }
            }
            break;
        case "customers-page-pagination":
            effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, array_utils_1.stringifyArray([exports.customersLoadingRequestName])));
            break;
        case "customer-free-search-input-change":
            (_a = sequence_services_1.sequenceReduction(effects, reduceFreeSearchInput(state, action.inputChange)), state = _a.state, effects = _a.effects);
            break;
        case "customers-page-toggle":
            (_b = sequence_services_1.sequenceReduction(effects, reduceCustomersToggle(state, action.toggle)), state = _b.state, effects = _b.effects);
            if (action.toggle.type === "disable-all") {
                state.customersPage = tslib_1.__assign({}, state.customersPage);
                state.customersPage.freeSearchInputs = {};
                state.inputs = tslib_1.__assign({}, state.inputs);
                state.inputs.searchCustomerNickName = "";
            }
            break;
        case "customers-page-begin-edit-search":
            state = tslib_1.__assign({}, state);
            state.customersPage = tslib_1.__assign({}, state.customersPage);
            state.customersPage.originalToggles = tslib_1.__assign({}, state.customersPage.originalToggles);
            state.customersPage.originalToggles = state.customersPage.toggles;
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.customersSearchModal = true;
            break;
        case "customers-page-save-search":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.customersSearchModal = false;
            state.customersPage = tslib_1.__assign({}, state.customersPage);
            state.customersPage.filteredCustomers = [];
            effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({
                pathname: "/" + state.pathParts.join("/"),
                hash: "",
                search: navigation_services_1.encodeSearch({
                    name: state.inputs.searchCustomerNickName,
                    inputs: JSON.stringify(state.customersPage.freeSearchInputs),
                    toggles: JSON.stringify(state.customersPage.toggles)
                })
            }));
            (_c = sequence_services_1.sequenceReduction(effects, beginCustomerSearch(state)), state = _c.state, effects = _c.effects);
            break;
        case "customers-page-cancel-edit-search":
            state = tslib_1.__assign({}, state);
            state.customersPage = tslib_1.__assign({}, state.customersPage);
            state.customersPage.toggles = tslib_1.__assign({}, state.customersPage.toggles);
            state.customersPage.toggles = state.customersPage.originalToggles;
            state.customersPage.originalToggles = {};
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.customersSearchModal = false;
            break;
        case "submit-customer-note":
            var memo = newResource("memos");
            memo.attributes.text = state.inputs.customerNewNote;
            memo.attributes.resource_type = "User";
            memo.attributes.resource_id = parseInt(state.customersPage.editingCustomerId, 10);
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.customersPageMemoWriteRequestName], memo, routes_1.RapiV1MemosPath));
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.customerNewNote = "";
            break;
        case "begin-edit-customer":
            var customerId_1 = action.customerId;
            state = tslib_1.__assign({}, state);
            state.customersPage = tslib_1.__assign({}, state.customersPage);
            state.customersPage.editingCustomerId = customerId_1;
            state.customersPage.showResourceModal = true;
            var query = {};
            query.include = ["present_rooms", "members", "user_events"];
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.customersSpecificLoadingRequestName], routes_1.RapiV1UsersPath + "/" + customerId_1, query));
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.customersPageMemosLoadingRequestName], routes_1.RapiV1MemosPath + "?filter[resource_id]=" + customerId_1));
            var user = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [customerId_1]);
            if (user) {
                var query_1 = {};
                query_1.filter = { user_uuid: user.attributes.uuid };
                effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.customersTrackerEntryLoadingRequestName], routes_1.RapiV1TrackerEntriesPath, query_1));
            }
            var customer = state.customersPage.filteredCustomers.filter(function (customer) { return customer.id === customerId_1; });
            if (customer.length) {
                effects = sequence_services_1.sequence(effects, action_cable_services_1.actionCableSendMessage("CustomerWatchChannel", "follow_customer_changes", { customer_uuid: customer[0].attributes.uuid }));
            }
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.customerNewNote = "";
            break;
        case 'start-edit-user-info-value':
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.editingUserId = action.userId;
            state.inputs.editingUserInfoKey = action.userInfoKey;
            state.inputs.editingUserInfoValue = action.userInfoValue;
            break;
        case "sp-visit-target-room":
            var roomId = action.roomId;
            effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({ pathname: "/supports/" + roomId }));
            state = tslib_1.__assign({}, state);
            state.currentViewingRoomId = roomId;
            state.sp = tslib_1.__assign({}, state.sp);
            state.sp.currentRender = "spSupportsMessagesList";
            state.sp.tab1OverlapRender = "";
            state.sp.tab2OverlapRender = "";
            state.sp.tab3OverlapRender = "";
            break;
        case "cancel-edit-user-info-value":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.editingUserInfoKey = "";
            state.inputs.editingUserInfoValue = "";
            break;
        case "finish-edit-user-info-value": {
            var id = state.inputs.editingUserId;
            var userData = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [id]);
            var userInfo = userData.attributes.info;
            for (var key in userInfo) {
                if (key === state.inputs.editingUserInfoKey) {
                    userInfo[key] = state.inputs.editingUserInfoValue;
                }
            }
            var newUserData = newResource("users");
            newUserData.id = id;
            newUserData.attributes.info = userInfo;
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([settings_page_reducer_1.updateUserDataRequestName, id], newUserData, routes_1.RapiV1UsersPath + "/" + id));
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.editingUserInfoKey = "";
            state.inputs.editingUserInfoValue = "";
            break;
        }
        case 'save-new-user-info-value': {
            var newUserInfoValue = state.inputs.newUserInfoValue;
            var masterKeyword = bisect_1.Indexer.getFirstMatching(state.stores.masterKeywords.byId, [state.inputs.newUserInfoMasterKeywordId]);
            if (!masterKeyword)
                return;
            var customer_2 = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [state.customersPage.editingCustomerId]);
            if (!customer_2)
                return;
            var newUserInfo = customer_2.attributes.info;
            newUserInfo[masterKeyword.attributes.key_name] = newUserInfoValue;
            var newUserData = newResource("users");
            newUserData.id = customer_2.id;
            newUserData.attributes.info = newUserInfo;
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([settings_page_reducer_1.updateUserDataRequestName, customer_2.id], newUserData, routes_1.RapiV1UsersPath + "/" + customer_2.id));
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.newUserInfoValue = "";
            state.inputs.newUserInfoMasterKeywordId = "";
            break;
        }
        case "cancel-new-user-info-value":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.newUserInfoValue = "";
            state.inputs.newUserInfoMasterKeywordId = "";
            break;
        case "archive-customer":
            state = tslib_1.__assign({}, state);
            state.customersPage = tslib_1.__assign({}, state.customersPage);
            state.customersPage.showResourceModal = false;
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourcePut([exports.archiveCustomerRequestName], routes_1.RapiV1UsersPath + "/" + state.customersPage.editingCustomerId + "/archive"));
            break;
        case "upload-customer-report":
            var files = action.event.target.files;
            effects = sequence_services_1.sequence(effects, csv_service_1.uploadCSV([exports.uploadCustomerReportRequestName], '/rapi/v1/users/import.csv', files));
            break;
        case "csv-upload-success":
            if (action.name[0] == exports.uploadCustomerReportRequestName) {
                (_d = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.uploadSuccessMessage[language] + " " + action.filename)), state = _d.state, effects = _d.effects);
            }
            break;
        case "csv-upload-failure":
            if (action.name[0] == exports.uploadCustomerReportRequestName) {
                (_e = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.uploadFailureMessage[language] + " " + action.filename)), state = _e.state, effects = _e.effects);
            }
            break;
        case "close-full-screen-customer-modal":
            state = tslib_1.__assign({}, state);
            state.customersPage = tslib_1.__assign({}, state.customersPage);
            state.customersPage.editingCustomerId = "";
            state.customersPage.showResourceModal = false;
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceCustomersPage = reduceCustomersPage;
exports.saveUserInfoRequestName = "save-user-info-request-name";
exports.archiveCustomerRequestName = "archive-customer";
