"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var memoizers_1 = require("../../utils/memoizers");
var widget_button_1 = require("./widget-button");
var popup_messages_1 = require("./popup-messages");
var toggles_reducer_1 = require("../../reducers/subreducers/toggles-reducer");
var widget_frame_reducer_1 = require("../reducers/widget-frame-reducer");
var room_list_reducer_1 = require("../reducers/room-list-reducer");
var flash_reducer_1 = require("../../reducers/subreducers/flash-reducer");
var widget_canvas_1 = require("./widget-canvas");
var key_navigation_utils_1 = require("../../utils/key-navigation-utils");
var widget_space_1 = require("../../components/widget-space");
var events_1 = require("../events");
var widget_close_button_1 = require("./widget-close-button");
var tracker_checker_reducer_1 = require("../reducers/tracker-checker-reducer");
var RootPage;
(function (RootPage) {
    function render(dispatch, hideButton, hidePopup) {
        var dispatcher = {
            focusWidget: function (e) {
                e.preventDefault();
                dispatch(toggles_reducer_1.toggle("widgetFocused", true));
            },
            blurWidget: function (e) {
                e.preventDefault();
                dispatch(toggles_reducer_1.toggle("widgetFocused", false));
            },
            selectColors: function (e) {
                e.preventDefault();
                dispatch(widget_frame_reducer_1.captureAndEditWidgetColors(e));
            },
            dismissFlash: function (key) { return dispatch(flash_reducer_1.dismissFlash(key)); },
            toggleWidgetMaximizedOnKeyDown: function (e) {
                if (key_navigation_utils_1.isEnterOrSpace(e)) {
                    dispatch(toggles_reducer_1.toggle("widgetMaximized"));
                }
            },
            toggleWidgetMaximizedOnClick: function () {
                dispatch(toggles_reducer_1.toggle("widgetMaximized"));
            },
            toggleWidgetHideOnClick: function () {
                dispatch(toggles_reducer_1.toggle("widgetHidden"));
            },
        };
        var WidgetButtonContent = widget_button_1.WidgetButton.render(dispatch);
        var PopupMessagesContent = popup_messages_1.PopupMessages.render(dispatch);
        var WidgetCanvasContent = widget_canvas_1.WidgetCanvas.render(dispatch);
        var WidgetCloseButtonContent = widget_close_button_1.WidgetCloseButton.render(dispatch);
        function widgetOpen(event) {
            if (typeof event.detail === 'string')
                dispatch(room_list_reducer_1.loadRoom(event.detail));
            dispatch(toggles_reducer_1.toggle("widgetMaximized", true));
            dispatch(toggles_reducer_1.toggle("widgetHidden", false));
        }
        function widgetClose() {
            dispatch(toggles_reducer_1.toggle("widgetMaximized", false));
        }
        function checkerCheckMessages(event) {
            if (typeof event.detail === 'object' && event.detail !== null
                && event.detail.hasOwnProperty('ids') && Array.isArray(event.detail.ids)) {
                dispatch(tracker_checker_reducer_1.setTrackerChecker(event.detail.ids));
            }
        }
        document.addEventListener(events_1.WIDGET_V2_OPEN, widgetOpen, false);
        document.addEventListener(events_1.WIDGET_V2_CLOSE, widgetClose, false);
        document.addEventListener(events_1.WIDGET_V2_TRACKER_CHECK_OPEN, checkerCheckMessages, false);
        dispatch(toggles_reducer_1.toggle("widgetHidden", hideButton));
        dispatch(toggles_reducer_1.toggle("forceHidePopup", hidePopup));
        dispatch(tracker_checker_reducer_1.setTrackerChecker());
        return memoizers_1.memoizeByEachArgument(function (state) {
            var maximizeWidget = state.toggles.widgetMaximized || state.provider_login_mode;
            var buttonImageStyles = {};
            if (maximizeWidget && state.buttonImageStyles.imageCustomFlg) {
                buttonImageStyles.imageCustomFlg = false;
                buttonImageStyles.imageHeight = 60;
                buttonImageStyles.imageWidth = 60;
            }
            else {
                buttonImageStyles = state.buttonImageStyles;
            }
            if (state.provider_login_mode) {
                return WidgetCanvasContent(state);
            }
            return React.createElement("div", { "data-namespace": true, onClickCapture: function (e) {
                    if (state.fakeWidget.inEditMode) {
                        dispatcher.selectColors(e);
                    }
                }, "data-widgetroot": true, onFocus: dispatcher.focusWidget, onBlur: dispatcher.blurWidget, style: {
                    display: state.toggles.widgetHidden ? "none" : ""
                }, tabIndex: -1 },
                React.createElement("div", { style: maximizeWidget ? {} : { display: "none" } },
                    React.createElement(widget_space_1.WidgetSpace, { maximized: true, providerLoginMode: state.provider_login_mode, widgetCanvasMode: true, className: "pea gpu test--widget-box z-max", widgetPosition: state.widget_position, isButtonHide: hideButton, buttonImageStyles: buttonImageStyles }, WidgetCanvasContent(state))),
                React.createElement("div", { style: (maximizeWidget || state.popupMessageIds.length === 0) || state.toggles.forceHidePopup ? { display: "none" } : {} },
                    React.createElement(widget_space_1.WidgetSpace, { maximized: false, popupMessagesMode: true, widgetPosition: state.widget_position, isButtonHide: hideButton, buttonImageStyles: buttonImageStyles }, PopupMessagesContent(state))),
                React.createElement(widget_space_1.WidgetSpace, { maximized: false, widgetButtonMode: true, toggleWidgetMaximizedOnClick: dispatcher.toggleWidgetMaximizedOnClick, widgetPosition: state.widget_position, isButtonHide: hideButton, buttonImageStyles: buttonImageStyles }, WidgetButtonContent(state)),
                React.createElement(widget_space_1.WidgetSpace, { maximized: false, closeButtonMode: true, toggleWidgetHideOnClick: dispatcher.toggleWidgetHideOnClick, widgetPosition: state.widget_position, isButtonHide: hideButton, buttonImageStyles: buttonImageStyles }, WidgetCloseButtonContent(state)));
        });
    }
    RootPage.render = render;
})(RootPage = exports.RootPage || (exports.RootPage = {}));
