"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var SuggestionsStore;
(function (SuggestionsStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    SuggestionsStore.indexer = new bisect_1.Indexer("byId");
    SuggestionsStore.indexer.addIndex("byId", function (s) { return [assertInnerId(s)]; });
    SuggestionsStore.indexer.addIndex("byRoomId", function (s) { return [getRelationshipId(s, "room")]; });
})(SuggestionsStore = exports.SuggestionsStore || (exports.SuggestionsStore = {}));
