"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var operator_state_1 = require("./operator-state");
var json_resources_1 = require("../core/json-resources");
var routes_1 = require("../routes");
var toast_reducer_1 = require("./toast-reducer");
var I18n_1 = require("../I18n");
var users_util_1 = require("../utils/users-util");
var complete_request_reducer_1 = require("./complete-request-reducer");
var stores_1 = require("../stores");
var removeResource = stores_1.Stores.removeResource;
var memoizers_1 = require("../utils/memoizers");
var object_helpers_1 = require("../utils/object-helpers");
var initialState = operator_state_1.OperatorState.initialState;
var array_utils_1 = require("../utils/array-utils");
var bisect_1 = require("../utils/bisect");
var resource_api_1 = require("../schemas/resource-api");
var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
var sequence_services_1 = require("../core/services/sequence-services");
var input_debouncing_services_1 = require("../core/services/input-debouncing-services");
function draftMessagesResolveToggle(status, resource) {
    return {
        type: "draft-messages-resolve-toggle",
        resource: resource,
        status: status
    };
}
exports.draftMessagesResolveToggle = draftMessagesResolveToggle;
function resolveDraftMessages(status, resource) {
    return {
        type: "resolve-draft-messages",
        resource: resource,
        status: status
    };
}
exports.resolveDraftMessages = resolveDraftMessages;
function markReadDraftMessagesResolved(on) {
    return {
        type: "mark-read-resolved-draft-messages",
        on: on
    };
}
exports.markReadDraftMessagesResolved = markReadDraftMessagesResolved;
function loadNextDraftMessages() {
    return {
        type: "load-next-draft-messages"
    };
}
exports.loadNextDraftMessages = loadNextDraftMessages;
function loadFilterResolvedMessages() {
    return {
        type: "load-filter-resolved-messages"
    };
}
exports.loadFilterResolvedMessages = loadFilterResolvedMessages;
function setDraftMessagesDate(fromDate, toDate) {
    return {
        type: "set-draft-messages-date",
        fromDate: fromDate,
        toDate: toDate
    };
}
exports.setDraftMessagesDate = setDraftMessagesDate;
function loadSearchInput(searchInput) {
    return {
        type: "load-search-input",
        searchInput: searchInput
    };
}
exports.loadSearchInput = loadSearchInput;
function loadDropdownStatus(resolvedStatus) {
    return {
        type: "load-dropdown-status",
        resolvedStatus: resolvedStatus
    };
}
exports.loadDropdownStatus = loadDropdownStatus;
function loadOperatorDropdown(user) {
    return {
        type: "load-operator-dropdown",
        user: user
    };
}
exports.loadOperatorDropdown = loadOperatorDropdown;
function draftMessagesUrl(user) {
    if (users_util_1.isAtLeastA(user, "Supervisor")) {
        return routes_1.RapiV1DraftMessagesPath;
    }
    else {
        return routes_1.RapiV1UsersPath + "/" + user.id + "/draft_messages";
    }
}
exports.beginDraftMessagesRoomLoad = function (state) {
    var effects = [];
    effects = sequence_services_1.sequence(effects, loadInReviewDraftMessages(state.loggedInUser));
    effects = sequence_services_1.sequence(effects, loadUnreadResolvedDraftMessages(state.loggedInUser));
    return effects;
};
function loadInReviewDraftMessages(user) {
    var query = {};
    query.filter = { in_review: 1 };
    query.include = ["room"];
    return json_resources_1.requestResourceFetch([exports.loadInReviewDraftMessagesRequest, user.id], draftMessagesUrl(user), query);
}
exports.loadInReviewDraftMessages = loadInReviewDraftMessages;
function loadUnreadResolvedDraftMessages(user) {
    var query = {};
    query.filter = { read: 0 };
    query.include = ["room", "resolved_user"];
    return json_resources_1.requestResourceFetch([exports.loadUnreadResolvedDraftMessagesRequest, user.id], draftMessagesUrl(user), query);
}
exports.loadUnreadResolvedDraftMessages = loadUnreadResolvedDraftMessages;
exports.loadFilteredResolvedDraftMessages = memoizers_1.memoizeBySomeProperties({
    loggedInUser: initialState.loggedInUser,
    inputs: object_helpers_1.pick(initialState.inputs, "draftMessageSearchInput"),
    draftMessagesPage: initialState.draftMessagesPage,
}, function (state) {
    var query = {};
    query.include = ["room", "resolved_user"];
    query.sort = ["-created_at"];
    query.filter = {};
    query.filter[state.draftMessagesPage.draftMessageResolvedDropdown] = 1;
    if (state.inputs.draftMessageSearchInput) {
        query.filter.content_or_room_name_like = state.inputs.draftMessageSearchInput;
    }
    if (state.draftMessagesPage.fromDate) {
        query.filter.from_created_at = state.draftMessagesPage.fromDate.unix();
    }
    if (state.draftMessagesPage.toDate) {
        query.filter.to_created_at = state.draftMessagesPage.toDate.unix();
    }
    if (users_util_1.isAtLeastA(state.loggedInUser, "Supervisor")) {
        if (state.draftMessagesPage.draftMessageCreateUserId) {
            query.filter.created_user_id = state.draftMessagesPage.draftMessageCreateUserId;
        }
    }
    else {
        query.filter.created_user_id = state.loggedInUser.id;
    }
    query.include = ["room", "resolved_user"];
    query.sort = ["-created_at"];
    return json_resources_1.requestResourceFetch([exports.loadFilteredResolvedDraftMessagesRequest], draftMessagesUrl(state.loggedInUser), query);
});
function filterDraftMessages(draftMessages, state) {
    return draftMessages.filter(function (sdm) {
        if (!sdm && !sdm.attributes)
            return false;
        if (state.loggedInUser.attributes.user_type === "Operator" && getRelationshipId(sdm, "created_user") !== state.loggedInUser.id)
            return false;
        if (state.toggles.draftMessageInReview) {
            return sdm.attributes.status === "in_review";
        }
        if (sdm.attributes.status === "in_review")
            return false;
        var statusDropdown = state.draftMessagesPage.draftMessageResolvedDropdown;
        if (statusDropdown !== sdm.attributes.status && statusDropdown !== "resolved")
            return false;
        var fromDate = state.draftMessagesPage.fromDate;
        var toDate = state.draftMessagesPage.toDate;
        var createdAtUnix = sdm.attributes.created_at_unix;
        if (createdAtUnix && fromDate && createdAtUnix < fromDate.unix())
            return false;
        if (createdAtUnix && toDate && createdAtUnix > toDate.unix())
            return false;
        if (state.draftMessagesPage.draftMessageCreateUserId && getRelationshipId(sdm, "created_user") !== state.draftMessagesPage.draftMessageCreateUserId)
            return false;
        var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [getRelationshipId(sdm, "room")]);
        if (!room)
            return false;
        var searchInput = state.inputs.draftMessageSearchInput;
        return (!searchInput || (searchInput && sdm.attributes.message_content && sdm.attributes.message_content.indexOf(searchInput) !== -1) ||
            (searchInput && sdm.attributes.comment && sdm.attributes.comment.indexOf(searchInput) !== -1) ||
            (room.attributes && room.attributes.name && room.attributes.name.indexOf(searchInput) !== -1));
    });
}
exports.filterDraftMessages = filterDraftMessages;
function reduceDraftMessages(state, action) {
    var _a;
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.resolveDraftMessagesRequestName && action.success) {
                var resourceResponse = json_resources_1.getResourceResponseDetails(action);
                if (resourceResponse.resources && resourceResponse.resources.length > 0) {
                    var draftMessages = resourceResponse.resources;
                    state = tslib_1.__assign({}, state);
                    draftMessages.map(function (sdm) {
                        state.stores = removeResource({ type: "draft_messages", id: sdm.id }, state.stores);
                    });
                }
            }
            break;
        case "resolve-draft-messages":
            effects = sequence_services_1.sequence(effects, input_debouncing_services_1.clearInputDebouncing());
            var url = "";
            if (action.resource.type === "draft_messages") {
                url = routes_1.RapiV1DraftMessagesPath + "/" + action.resource.id + "/" + action.status;
            }
            else if (action.resource.type === "rooms") {
                url = routes_1.RapiV1RoomsPath + "/" + action.resource.id + "/" + action.status + "_draft_messages";
            }
            var comment = state.inputs.draftMessageComment;
            url += "" + (comment ? "?comment=" + comment : "");
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourcePut([exports.resolveDraftMessagesRequestName, action.resource.id, action.status], url));
            var toastMessage = "";
            if (action.status === "reject") {
                toastMessage = I18n_1.i18n.feedbackToastDraftMessageRejected[language];
            }
            else if (action.status === "approve") {
                toastMessage = I18n_1.i18n.feedbackToastDraftMessageApproved[language];
            }
            (_a = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, toastMessage)), state = _a.state, effects = _a.effects);
            state = tslib_1.__assign({}, state);
            state.draftMessagesPage = tslib_1.__assign({}, state.draftMessagesPage);
            state.draftMessagesPage.draftMessageResolveToggle = tslib_1.__assign({}, state.draftMessagesPage.draftMessageResolveToggle);
            state.draftMessagesPage.draftMessageResolveToggle[action.resource.id] = false;
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.draftMessageComment = "";
            break;
        case "mark-read-resolved-draft-messages":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.draftMessageInReview = action.on;
            if (!action.on) {
                effects = sequence_services_1.sequence(effects, json_resources_1.requestResourcePut([exports.markReadResolvedDraftMessagesRequestName], "/rapi/v1/draft_messages/mark_read_all_resolved"));
            }
            break;
        case "load-search-input":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.draftMessageSearchInput = action.searchInput;
            effects = sequence_services_1.sequence(effects, exports.loadFilteredResolvedDraftMessages(state));
            break;
        case "set-draft-messages-date":
            state = tslib_1.__assign({}, state);
            state.draftMessagesPage = tslib_1.__assign({}, state.draftMessagesPage);
            if (action.fromDate) {
                state.draftMessagesPage.fromDate = action.fromDate;
            }
            else {
                state.draftMessagesPage.toDate = action.toDate;
            }
            effects = sequence_services_1.sequence(effects, exports.loadFilteredResolvedDraftMessages(state));
            break;
        case "load-filter-resolved-messages":
            effects = sequence_services_1.sequence(effects, exports.loadFilteredResolvedDraftMessages(state));
            break;
        case "load-dropdown-status":
            state = tslib_1.__assign({}, state);
            state.draftMessagesPage = tslib_1.__assign({}, state.draftMessagesPage);
            state.draftMessagesPage.draftMessageResolvedDropdown = action.resolvedStatus;
            effects = sequence_services_1.sequence(effects, exports.loadFilteredResolvedDraftMessages(state));
            break;
        case "load-operator-dropdown":
            state = tslib_1.__assign({}, state);
            state.draftMessagesPage = tslib_1.__assign({}, state.draftMessagesPage);
            state.draftMessagesPage.draftMessageCreateUserId = action.user ? action.user.id : "";
            effects = sequence_services_1.sequence(effects, exports.loadFilteredResolvedDraftMessages(state));
            break;
        case "load-next-draft-messages":
            if (state.toggles.draftMessageInReview) {
                effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, array_utils_1.stringifyArray([exports.loadInReviewDraftMessagesRequest, state.loggedInUser.id])));
            }
            else {
                effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, array_utils_1.stringifyArray([exports.loadFilteredResolvedDraftMessagesRequest])));
                effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, array_utils_1.stringifyArray([exports.loadUnreadResolvedDraftMessagesRequest, state.loggedInUser.id])));
            }
            break;
        case "draft-messages-resolve-toggle":
            state = tslib_1.__assign({}, state);
            state.draftMessagesPage = tslib_1.__assign({}, state.draftMessagesPage);
            state.draftMessagesPage.draftMessageResolveToggle = tslib_1.__assign({}, state.draftMessagesPage.draftMessageResolveToggle);
            if (action.status === "show") {
                state.draftMessagesPage.draftMessageResolveToggle[action.resource.id] = true;
            }
            else {
                state.draftMessagesPage.draftMessageResolveToggle[action.resource.id] = false;
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceDraftMessages = reduceDraftMessages;
exports.resolveDraftMessagesRequestName = "resolve-draft-messages-request";
exports.loadUnreadResolvedDraftMessagesRequest = "load-unread-resolved-draft-messages-request-name";
exports.loadInReviewDraftMessagesRequest = "load-in-review-draft-messages-request-name";
exports.loadFilteredResolvedDraftMessagesRequest = "load-filtered-resolved-draft-messages-request-name";
exports.markReadResolvedDraftMessagesRequestName = "mark-read-draft-messages-by-status-request-name";
exports.draftMessagesPagedRequestNames = [exports.loadFilteredResolvedDraftMessagesRequest, exports.loadInReviewDraftMessagesRequest, exports.loadUnreadResolvedDraftMessagesRequest];
