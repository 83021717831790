"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var stores_1 = require("../../stores");
var loadResources = stores_1.Stores.loadResources;
var removeResource = stores_1.Stores.removeResource;
var resource_api_1 = require("../../schemas/resource-api");
var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
var action_cable_services_1 = require("../../core/services/action-cable-services");
var sequence_services_1 = require("../../core/services/sequence-services");
var bisect_1 = require("../../utils/bisect");
var last_read_at_reducer_1 = require("../../reducers/last-read-at-reducer");
var events_1 = require("../events");
function reduceActionCable(state, action) {
    var _a, _b;
    var effects = [];
    switch (action.type) {
        case "action-cable-received-message":
            var data = action.data;
            if (data.resources || data.resource_update) {
                var resources = data.resources || data.resource_update && [data.resource_update] || [];
                state = tslib_1.__assign({}, state);
                state.stores = loadResources(resources, state.stores);
                (_a = exports.handleNewResources(state, data), state = _a.state, effects = _a.effects);
            }
            if (data.status_update) {
                var statusUpdate = data.status_update;
                var user = bisect_1.Indexer.getFirstMatching(state.stores.users.byUuid, [statusUpdate.user_uuid]);
                if (user) {
                    user = tslib_1.__assign({}, user);
                    user.attributes = tslib_1.__assign({}, user.attributes);
                    user.attributes.status = statusUpdate.status;
                    state = tslib_1.__assign({}, state);
                    state.stores = loadResources([user], state.stores);
                }
            }
            if (data.resource_delete) {
                state = tslib_1.__assign({}, state);
                state.stores = removeResource(data.resource_delete, state.stores);
                (_b = exports.handleDeletedMessage(state, data), state = _b.state, effects = _b.effects);
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceActionCable = reduceActionCable;
exports.handleNewResources = function (state, data) {
    var effects = [];
    if (!data.resources)
        return { state: state, effects: effects };
    data.resources.map(function (resource) {
        var _a;
        if (resource.type === "messages") {
            var message = resource;
            if (state.currentViewingRoomId === getRelationshipId(message, "room")) {
                if (state.toggles.widgetMaximized || state.provider_login_mode) {
                    (_a = sequence_services_1.sequenceReduction(effects, last_read_at_reducer_1.tryToMarkReadWidget(state)), state = _a.state, effects = _a.effects);
                }
                else if (state.popupMessageIds.indexOf(message.id) === -1) {
                    state = tslib_1.__assign({}, state);
                    state.popupMessageIds = state.popupMessageIds.concat(message.id);
                }
                events_1.receivedMessage(message);
            }
            events_1.receivedSomeMessage(message);
        }
        else if (resource.type === "users") {
            effects = sequence_services_1.sequence(effects, action_cable_services_1.actionCableSendMessage("UserStatusChannel", "follow_user_status_channel", { user_id: resource.id }));
        }
    });
    return { state: state, effects: effects };
};
exports.handleDeletedMessage = function (state, data) {
    var effects = [];
    if (data.resource_delete && data.resource_delete.type === "messages") {
        var id_1 = data.resource_delete.id;
        if (!id_1)
            return { state: state, effects: effects };
        state = tslib_1.__assign({}, state);
        state.popupMessageIds = state.popupMessageIds.filter(function (pm) { return pm !== id_1; });
    }
    return { state: state, effects: effects };
};
