"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var reducers_1 = require("../reducers");
window.ActionCable = require("action-cable");
function actionCableSubscribe(channel) {
    return {
        effectType: "action-cable-subscribe",
        channel: channel,
    };
}
exports.actionCableSubscribe = actionCableSubscribe;
function actionCableSendMessage(channel, action, data) {
    return {
        effectType: "action-cable-send-message",
        channel: channel,
        action: action,
        data: data
    };
}
exports.actionCableSendMessage = actionCableSendMessage;
function actionCableReceivedMessage(channel, data) {
    return {
        type: "action-cable-received-message",
        channel: channel,
        data: data
    };
}
exports.actionCableReceivedMessage = actionCableReceivedMessage;
function actionCableConnected(channel, when) {
    if (when === void 0) { when = Date.now(); }
    return {
        type: "action-cable-connected",
        channel: channel,
        when: when
    };
}
exports.actionCableConnected = actionCableConnected;
function actionCableDisconnected(channel, when) {
    if (when === void 0) { when = Date.now(); }
    return {
        type: "action-cable-disconnected",
        channel: channel,
        when: when
    };
}
exports.actionCableDisconnected = actionCableDisconnected;
function actionCableRejected(channel, when) {
    if (when === void 0) { when = Date.now(); }
    return {
        type: "action-cable-rejected",
        channel: channel,
        when: when
    };
}
exports.actionCableRejected = actionCableRejected;
function withActionCable(dispatch, actionCableUrl, accessToken, tenant) {
    var cable = null;
    var cableSubscriptions = {};
    var debouncedHandle = null;
    var queuedData = [];
    return function (effect) {
        switch (effect.effectType) {
            case "action-cable-subscribe":
                if (cable == null) {
                    var url = actionCableUrl ? (actionCableUrl + ("?" + (accessToken ? 'access_token=' + accessToken : '') + "&" + (tenant ? 'tenant=' + tenant : ''))) : null;
                    cable = ActionCable.createConsumer(url);
                }
                if (cableSubscriptions[effect.channel] == null) {
                    cableSubscriptions[effect.channel] = cable.subscriptions.create(effect.channel, {
                        disconnected: function () {
                            dispatch(actionCableDisconnected(effect.channel));
                        },
                        connected: function () {
                            dispatch(actionCableConnected(effect.channel));
                        },
                        rejected: function () {
                            dispatch(actionCableRejected(effect.channel));
                        },
                        received: function (data) {
                            if (debouncedHandle)
                                clearTimeout(debouncedHandle);
                            queuedData.push(data);
                            debouncedHandle = setTimeout(function () {
                                var executing = queuedData;
                                queuedData = [];
                                reducers_1.whileBatchingDispatches(dispatch, function () {
                                    executing.forEach(function (data) { return dispatch(actionCableReceivedMessage(effect.channel, data)); });
                                });
                            }, 200);
                        }
                    });
                }
                break;
            case "action-cable-send-message":
                if (cableSubscriptions[effect.channel] != null) {
                    cableSubscriptions[effect.channel].perform(effect.action, tslib_1.__assign({}, effect.data));
                }
                break;
        }
    };
}
exports.withActionCable = withActionCable;
