"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var layouts_1 = require("./layouts");
var svgs_1 = require("./svgs");
function SelectInput(props) {
    var hasBeenAnswered = (props.question && props.question.attributes.answered_at_unix && !!props.question.attributes.answer);
    var choiceDisabled = !props.answerQuestion || hasBeenAnswered;
    return (React.createElement("div", { className: "db bg-white br3 pv2 mv2  " + (choiceDisabled ? "ba b--light-gray mr4 ml2" : " w-100 mh5") },
        React.createElement(layouts_1.Row, null, props.survey.attributes.choices.map(function (choice, i) {
            return choice.kind === "select_input" &&
                React.createElement(layouts_1.Column, { className: "w-50" },
                    React.createElement("button", { className: "w-50 h3 center ba br3  tc f4 fw3 pa2\n                " + (choiceDisabled ? "b--light-gray b--gray gray " : "b--light-blue pointer light-blue bg--light-blue--hover hover-white"), key: "select-input-text-" + props.survey.id + "-" + i, disabled: choiceDisabled, style: {
                            backgroundColor: "bottom_action_button_background",
                            fill: "bottom_action_button_fill",
                            color: "bottom_action_button_fill",
                            outlineColor: "outline",
                        }, onClick: function () { return props.answerQuestion(choice.text); } },
                        hasBeenAnswered && choice.text === props.question.attributes.answer &&
                            React.createElement("div", { className: "fill-gray stroke--gray fl" },
                                React.createElement(svgs_1.SVGChecked, { width: 12, height: 12 })),
                        React.createElement("div", { tabIndex: -1, className: "no-focus-glow" }, choice.text)));
        }))));
}
exports.SelectInput = SelectInput;
