"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var events_1 = require("../events");
function goWidgetMaximizedTracker(widgetMaximized) {
    return {
        type: "go-widget-maximized-tracker",
        widgetMaximized: widgetMaximized,
    };
}
exports.goWidgetMaximizedTracker = goWidgetMaximizedTracker;
function goTrackerWidgetRemove() {
    return {
        type: "go-tracker-w-close",
    };
}
exports.goTrackerWidgetRemove = goTrackerWidgetRemove;
function reduceTrackerEntry(state, action) {
    var effects = [];
    switch (action.type) {
        case "go-tracker-w-close":
            events_1.chatHiddenTracker(state.currentViewingRoomId, state.client_id, state.clientCode);
            break;
        case "go-widget-maximized-tracker":
            if (action.widgetMaximized) {
                events_1.chatCloseTracker(state.currentViewingRoomId, state.client_id, state.clientCode);
            }
            else {
                events_1.chatOpenTracker(state.currentViewingRoomId, state.client_id, state.clientCode);
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceTrackerEntry = reduceTrackerEntry;
