"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var VisitsStore;
(function (VisitsStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    VisitsStore.indexer = new bisect_1.Indexer("byId");
    VisitsStore.indexer.addIndex("byId", function (visit) { return [assertInnerId(visit)]; });
    VisitsStore.indexer.addIndex("byUserId", function (visit) { return [getRelationshipId(visit, "user")]; });
    VisitsStore.indexer.addIndex("byLocation", function (visit) { return [visit.attributes.location]; });
    VisitsStore.indexer.addIndex("byCreatedAtUnix", function (visit) { return [visit.attributes.created_at_unix]; });
})(VisitsStore = exports.VisitsStore || (exports.VisitsStore = {}));
