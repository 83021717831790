"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var react_1 = require("react");
var tracker_events_1 = require("../core/tracker-events");
var YoutubeIframe = function (props) {
    return React.createElement("iframe", tslib_1.__assign({}, props));
};
function YoutubeMessageContent(props) {
    var youtubeData = props.message.attributes.settings;
    if (!youtubeData)
        return null;
    if (!youtubeData.youtube_id || youtubeData.youtube_id === null || youtubeData.youtube_id === "")
        return null;
    var youtubeId = youtubeData.youtube_id;
    var preview = props.preview || false;
    var dataActionId = null;
    if (props.message && props.message.id && props.message.id !== null && props.message.id !== "") {
        dataActionId = "oksky-youtube-ms-" + props.message.id;
    }
    var playerRef = react_1.useRef(null);
    var onPlayerStateChange = react_1.useCallback(function (event) {
        tracker_events_1.handlePlayerStateChange(event, dataActionId);
    }, [props.message.id, dataActionId]);
    react_1.useEffect(function () {
        tracker_events_1.loadYouTubeApi().then(function () {
            var iframeWindow = tracker_events_1.getIframeWindow();
            if (!iframeWindow || iframeWindow === null) {
                return;
            }
            playerRef.current = new iframeWindow.YT.Player("youtube-player-" + props.message.id, {
                events: {
                    'onStateChange': onPlayerStateChange
                }
            });
        });
        return function () {
            if (playerRef.current && playerRef.current.destroy) {
                playerRef.current.destroy();
            }
        };
    }, [props.message.id, onPlayerStateChange]);
    return (React.createElement("div", { className: "relative", style: preview ? { position: "relative", paddingTop: "56.25%" } : {} },
        React.createElement(YoutubeIframe, { id: "youtube-player-" + props.message.id, width: "100%", height: preview ? "100%" : undefined, src: "https://www.youtube-nocookie.com/embed/" + youtubeId + "?&playlist=" + youtubeId + "&mute=1&playsinline=1&modestbranding=1&enablejsapi=1", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", referrerPolicy: "strict-origin-when-cross-origin", allowFullScreen: true, style: preview ? { position: "absolute", top: 0, right: 0, width: "100%", height: "100%" } : {}, "data-youtube-action-id": dataActionId })));
}
exports.YoutubeMessageContent = YoutubeMessageContent;
