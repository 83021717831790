"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var json_resources_1 = require("../../core/json-resources");
var routes_1 = require("../../routes");
var stores_1 = require("../../stores");
var loadResources = stores_1.Stores.loadResources;
var sequence_services_1 = require("../../core/services/sequence-services");
var google_analytics_services_1 = require("../../core/services/google-analytics-services");
var action_cable_services_1 = require("../../core/services/action-cable-services");
function reduceInitialLoading(state, action) {
    var effects = [];
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.loadCustomerRoomsRequestName) {
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                if (!responseDetails.resources || responseDetails.resources.length === 0)
                    break;
                responseDetails.resources.map(function (resource) {
                    if (resource.type === "users") {
                        effects = sequence_services_1.sequence(effects, action_cable_services_1.actionCableSendMessage("UserStatusChannel", "follow_user_status_channel", { user_id: resource.id }));
                    }
                });
            }
            break;
        case "send-online-status-action":
            effects = sequence_services_1.sequence(effects, action_cable_services_1.actionCableSendMessage("UserStatusChannel", "indicate_online", {}));
            break;
        case "visit":
            if (!state.initialized) {
                state = tslib_1.__assign({}, state);
                state.initialized = true;
                state.stores = loadResources([state.loggedInUser], state.stores);
                effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.loadUserWidgetSettingsRequestName], routes_1.RapiV1UsersPath + "/" + state.loggedInUser.id + "/user_widget_settings"));
                effects = sequence_services_1.sequence(effects, google_analytics_services_1.includeGoogleAnalytics());
                effects = sequence_services_1.sequence(effects, action_cable_services_1.actionCableSubscribe("MemberChannel"));
                effects = sequence_services_1.sequence(effects, action_cable_services_1.actionCableSubscribe("UserStatusChannel"));
                effects = sequence_services_1.sequence(effects, loadAllAvailableOperators(state.numberOfOperatorsShownOnHeader));
                effects = sequence_services_1.sequence(effects, loadAutoPostMessageUsers(state.client_id));
                effects = sequence_services_1.sequence(effects, loadCustomerRooms(state.loggedInUser.id));
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceInitialLoading = reduceInitialLoading;
function loadCustomerRooms(userId) {
    var query = {};
    query.include = ["latest_message", "current_member", "latest_support", "latest_support.user"];
    query.filter = { sort_unread_read_for_user_id: userId };
    return json_resources_1.requestResourceFetch([exports.loadCustomerRoomsRequestName], routes_1.RapiV1UsersPath + "/" + userId + "/rooms", query);
}
exports.loadCustomerRooms = loadCustomerRooms;
function loadAllAvailableOperators(numOperators) {
    var query = {};
    query.filter = { is_operator_and_available: 1 };
    query.page = { size: numOperators };
    return json_resources_1.requestResourceFetch([exports.loadAllAvailableOperatorsRequestName], routes_1.RapiV1UsersPath, query);
}
function loadAutoPostMessageUsers(clientId) {
    return json_resources_1.requestResourceFetch([exports.loadAutoPostMessageUsersRequestName], routes_1.RapiV1ClientsPath + '/' + clientId + '/auto_post_users');
}
exports.loadUserWidgetSettingsRequestName = "load-user-widget-settings-request-name";
exports.loadCustomerRoomsRequestName = "load-customer-rooms-request-name";
exports.loadAutoPostMessageUsersRequestName = "load-auto-post-message-user-request-name";
exports.loadAllAvailableOperatorsRequestName = "load-all-available-operators-request-name";
exports.initialLoadingPagedRequestNames = [exports.loadCustomerRoomsRequestName, exports.loadAllAvailableOperatorsRequestName];
