"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
function toggle(target, on) {
    if (on === void 0) { on = undefined; }
    return { type: "toggle", on: on, target: target };
}
exports.toggle = toggle;
function enableAll(targets) {
    return {
        type: 'enable-all',
        targets: targets
    };
}
exports.enableAll = enableAll;
function disableAll() {
    return { type: 'disable-all' };
}
exports.disableAll = disableAll;
function toggleDispatcher(dispatch, target, on) {
    if (on === void 0) { on = undefined; }
    return function (e) {
        if (e) {
            e.stopPropagation();
        }
        dispatch(toggle(target, on));
    };
}
exports.toggleDispatcher = toggleDispatcher;
function reduceToggle(state, action) {
    var effects = [];
    switch (action.type) {
        case 'toggle':
            var result = void 0;
            if (action.on != null) {
                result = !!action.on;
            }
            else {
                result = !state[action.target];
            }
            if (result !== !!state[action.target]) {
                state = tslib_1.__assign({}, state);
                state[action.target] = result;
            }
            break;
        case 'enable-all':
            state = tslib_1.__assign({}, state);
            for (var _i = 0, _a = action.targets; _i < _a.length; _i++) {
                var target = _a[_i];
                state[target] = true;
            }
            break;
        case 'disable-all':
            state = {};
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceToggle = reduceToggle;
