"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var routes_1 = require("../../routes");
var cookie_utils_1 = require("../../utils/cookie-utils");
var generators_1 = require("../../utils/generators");
exports.uploadNormalFileKindVideoTag = "video_tag";
exports.uploadNormalFileKindVideoTagThumbnail = "video_tag_thumbnail";
function requestNormalUpload(name, files, kind, fileId) {
    if (fileId === void 0) { fileId = null; }
    return {
        effectType: "request-normal-upload",
        name: name,
        files: files,
        kind: kind,
        fileId: fileId
    };
}
exports.requestNormalUpload = requestNormalUpload;
function beginNormalUpload(name) {
    return {
        type: "begin-normal-upload",
        name: name
    };
}
exports.beginNormalUpload = beginNormalUpload;
function uploadNormalSuccess(uploadedResponse, name, uploadEnd) {
    return {
        type: "upload-normal-success",
        uploadedResponse: uploadedResponse,
        name: name,
        uploadEnd: uploadEnd,
    };
}
exports.uploadNormalSuccess = uploadNormalSuccess;
function uploadNormalFailure(name, uploadEnd, when) {
    if (when === void 0) { when = Date.now(); }
    return {
        type: "upload-normal-failure",
        name: name,
        when: when,
        uploadEnd: uploadEnd,
    };
}
exports.uploadNormalFailure = uploadNormalFailure;
function withNormalUpload(dispatch, token, xdCookieNames) {
    if (xdCookieNames === void 0) { xdCookieNames = []; }
    return function (request) {
        switch (request.effectType) {
            case "request-normal-upload":
                var validateFlg = true;
                for (var i = 0; i < request.files.length; i++) {
                    var file = request.files[i];
                    var uploadEnd = i === request.files.length - 1;
                    var fileName = file.name;
                    var fileExtension = fileName.split('.').pop().toLowerCase();
                    var fileRequestName = request.name.concat(generators_1.genLocalId());
                    if (request.kind === exports.uploadNormalFileKindVideoTag) {
                        if (!["3g2", "3gp", "avi", "flv", "m3u8", "ts", "m2ts", "mts", "mov", "mkv", "mp4", "mpeg", "mpg", "mpd", "mxf", "ogv", "webm", "wmv"].includes(fileExtension)) {
                            console.log('Unsupported file type:', fileExtension);
                            dispatch(uploadNormalFailure(fileRequestName, uploadEnd));
                            validateFlg = false;
                            return;
                        }
                    }
                    else if (request.kind === exports.uploadNormalFileKindVideoTagThumbnail) {
                        if (!["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
                            console.log('Unsupported file type:', fileExtension);
                            dispatch(uploadNormalFailure(fileRequestName, uploadEnd));
                            validateFlg = false;
                            return;
                        }
                    }
                    else {
                        console.log('upload normal file kind not found!:', fileExtension);
                        dispatch(uploadNormalFailure(fileRequestName, uploadEnd));
                        validateFlg = false;
                        return;
                    }
                }
                if (!validateFlg) {
                    return;
                }
                var _loop_1 = function (i) {
                    var file = request.files[i];
                    var uploadEnd = i === request.files.length - 1;
                    var fileRequestName = request.name.concat(generators_1.genLocalId());
                    uploadUrl = "";
                    if (request.kind === exports.uploadNormalFileKindVideoTag) {
                        uploadUrl = routes_1.RapiV1ShowContentsPath + "/upload_video_tag";
                    }
                    else if (request.kind === exports.uploadNormalFileKindVideoTagThumbnail) {
                        uploadUrl = routes_1.RapiV1ShowContentsPath + "/upload_video_tag_thumbnail";
                    }
                    var formData = new FormData();
                    formData.append('file', file);
                    if (request.fileId && request.fileId !== null && request.fileId !== "") {
                        formData.append('fileId', request.fileId);
                    }
                    else {
                        formData.append('fileId', getUniqueId());
                    }
                    var xhr = new XMLHttpRequest();
                    xhr.open('POST', uploadUrl, true);
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState === xhr.DONE) {
                            if (xhr.status === 200) {
                                dispatch(uploadNormalSuccess(JSON.parse(xhr.responseText), fileRequestName, uploadEnd));
                            }
                            else {
                                console.error("error: Unable to upload", xhr.responseText);
                                dispatch(uploadNormalFailure(fileRequestName, uploadEnd));
                            }
                        }
                    };
                    xhr.setRequestHeader('X-Access-Token', token);
                    xhr.setRequestHeader("Xd-Cookie", cookie_utils_1.getXdCookiesHeaderValue(xdCookieNames));
                    dispatch(beginNormalUpload(fileRequestName));
                    xhr.send(formData);
                };
                var uploadUrl;
                for (var i = 0; i < request.files.length; i++) {
                    _loop_1(i);
                }
                break;
        }
    };
}
exports.withNormalUpload = withNormalUpload;
function getUniqueId(strongNo) {
    var strong = 1000;
    if (strongNo)
        strong = strongNo;
    return (new Date().getTime().toString(16) +
        Math.floor(strong * Math.random()).toString(16));
}
exports.getUniqueId = getUniqueId;
