"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formats_1 = require("./formats");
function DateTimeFormat(props) {
    return typeof props.unixTime === "number" ? React.createElement("span", { className: props.className },
        React.createElement("span", { className: "mr2" }, formats_1.formatDate(props.unixTime)),
        formats_1.formatTime(props.unixTime))
        : React.createElement("span", null);
}
exports.DateTimeFormat = DateTimeFormat;
