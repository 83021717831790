"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sizing_reducer_1 = require("../../reducers/subreducers/sizing-reducer");
function calculateSize(name, id, calculationType) {
    if (calculationType === void 0) { calculationType = "as-is"; }
    return {
        effectType: 'calculate-size',
        targetSelector: "#" + id,
        name: name,
        calculationType: calculationType
    };
}
exports.calculateSize = calculateSize;
function withSizeCalculator(dispatch) {
    return function (e) {
        switch (e.effectType) {
            case 'calculate-size':
                var element = document.querySelector(e.targetSelector);
                if (element) {
                    dispatch(sizing_reducer_1.sizeUpdate(e.name, getSize(element, e.calculationType)));
                }
        }
    };
}
exports.withSizeCalculator = withSizeCalculator;
function getSize(source, type) {
    if (type === "as-is") {
        var bounding = source.getBoundingClientRect();
        return [bounding.width, bounding.height];
    }
    var calculator;
    switch (type) {
        case "expand-height":
            calculator = document.createElement("DIV");
            copyComputedStyles(source, calculator);
            calculator.style.wordWrap = "break-word";
            calculator.style.whiteSpace = "pre-wrap";
            calculator.style.overflow = "show";
            calculator.style.height = "";
            break;
        case "expand-width":
            calculator = document.createElement("DIV");
            copyComputedStyles(source, calculator);
            calculator.style.whiteSpace = "no-wrap";
            calculator.style.overflow = "show";
            calculator.style.width = "";
            break;
    }
    if (source instanceof HTMLInputElement || source instanceof HTMLTextAreaElement) {
        calculator.textContent = source.value;
    }
    else {
        calculator.innerHTML = source.innerHTML;
    }
    document.body.appendChild(calculator);
    try {
        var bounding = calculator.getBoundingClientRect();
        return [bounding.width, bounding.height];
    }
    finally {
        calculator.remove();
    }
}
function copyComputedStyles(from, to) {
    var computedStyleObject = document.defaultView.getComputedStyle(from, null);
    if (!computedStyleObject)
        return null;
    var stylePropertyValid = function (value, prop) {
        return value !== undefined &&
            prop !== "length" &&
            typeof value !== 'object' &&
            typeof value !== 'function';
    };
    for (var i = 0; i < computedStyleObject.length; i++) {
        var property = computedStyleObject[i];
        if (stylePropertyValid(computedStyleObject[property], property)) {
            to.style[property] = computedStyleObject[property];
        }
    }
}
