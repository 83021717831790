"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var chat_list_message_1 = require("./chat-list-message");
var svgs_1 = require("./svgs");
var formats_1 = require("./formats");
function WidgetFileMessageContent(props) {
    var file = props.message.attributes.settings;
    return React.createElement("div", { className: "pa3 br2 dib w-100" },
        React.createElement("div", { className: "fl" },
            React.createElement("a", { href: file.url, download: true },
                React.createElement(chat_list_message_1.ToolButton, { className: "ma2" },
                    React.createElement(svgs_1.SVGDownload, { width: 16, height: 16 })))),
        React.createElement("div", { className: "dib pl2" },
            React.createElement("div", { className: "fw7 f3 pa1 mw7_5rem" }, file.filename + "." + file.type + ("(" + formats_1.humanFileSize(file.size) + ")"))));
}
exports.WidgetFileMessageContent = WidgetFileMessageContent;
