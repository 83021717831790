"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var initial_loading_reducer_1 = require("./initial-loading-reducer");
var json_resources_1 = require("../core/json-resources");
var bisect_1 = require("../utils/bisect");
var users_util_1 = require("../utils/users-util");
var array_utils_1 = require("../utils/array-utils");
var sequence_services_1 = require("../core/services/sequence-services");
var routes_1 = require("../routes");
var navigation_services_1 = require("../core/services/navigation-services");
var complete_request_reducer_1 = require("./complete-request-reducer");
function loadNextSupportData(supportSearchType, supportReplyType, userId) {
    return {
        type: "load-next-support-data",
        supportSearchType: supportSearchType,
        supportReplyType: supportReplyType,
        userId: userId
    };
}
exports.loadNextSupportData = loadNextSupportData;
function writeScrollPosition(currentScrollPosition, name) {
    return {
        type: "write-scroll-position",
        currentScrollPosition: currentScrollPosition,
        name: name
    };
}
exports.writeScrollPosition = writeScrollPosition;
function goToSearchedSupportRoom(roomId) {
    return {
        type: "go-to-searched-support-room",
        roomId: roomId
    };
}
exports.goToSearchedSupportRoom = goToSearchedSupportRoom;
function selectFilteringRoomsByUser(userId) {
    return {
        type: "select-filtering-rooms-by-user",
        userId: userId
    };
}
exports.selectFilteringRoomsByUser = selectFilteringRoomsByUser;
function setSortType(sortType) {
    return {
        type: "set-sort-type",
        sortType: sortType
    };
}
exports.setSortType = setSortType;
function reduceSupportsLeftFilteringPanel(state, action) {
    var _a, _b;
    var effects = [];
    switch (action.type) {
        case "select-filtering-rooms-by-user":
            var userId = action.userId;
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.supportFilteringPanelUserId = userId || "";
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.showSupportUserFilter = false;
            var filterNames = [state.inputs.supportSearchType, userId || ""].filter(function (a) { return a; });
            state.inputs.scrollPositionToUpdate = array_utils_1.stringifyArray(filterNames);
            if (userId && !(state.completedRequests[array_utils_1.stringifyArray([initial_loading_reducer_1.loadUnRepliedOperatorsSupportsRequestName, userId])] || state.completedRequests[array_utils_1.stringifyArray([initial_loading_reducer_1.loadRepliedOperatorsSupportsRequestName, userId])])) {
                effects = sequence_services_1.sequence(effects, initial_loading_reducer_1.loadUnRepliedOperatorsSupports(userId));
                effects = sequence_services_1.sequence(effects, initial_loading_reducer_1.loadRepliedOperatorsSupports(userId));
            }
            break;
        case "go-to-searched-support-room":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.supportLeftFilteringSupportRoomsSearch = "";
            effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({ pathname: "/supports/" + action.roomId }));
            break;
        case "input-change":
            var searchString = action.text;
            if (action.target === "supportLeftFilteringSupportRoomsSearch") {
                state = tslib_1.__assign({}, state);
                state.toggles = tslib_1.__assign({}, state.toggles);
                state.toggles.showSupportRoomFilter = !!searchString;
                effects = sequence_services_1.sequence(effects, loadAllSearchedSupportRoom(searchString, state.loggedInUser));
            }
            else if (action.target === "supportLeftFilteringUsersSearch") {
                (_a = sequence_services_1.sequenceReduction(effects, filterSupportsByOperator(state, searchString)), state = _a.state, effects = _a.effects);
            }
            break;
        case "toggle":
            if (action.target === "showSupportUserFilter") {
                (_b = sequence_services_1.sequenceReduction(effects, filterSupportsByOperator(state, "")), state = _b.state, effects = _b.effects);
            }
            break;
        case "load-next-support-data":
            var stringifiedRequestName = "";
            if (state.roomCounts) {
                switch (action.supportSearchType) {
                    case "supporting":
                        var user = users_util_1.isAtLeastA(state.loggedInUser, "Supervisor") ?
                            bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [state.inputs.supportFilteringPanelUserId])
                            : state.loggedInUser;
                        if (user) {
                            if (state.inputs.supportSearchReplyType === "unreplied") {
                                if (state.supportDetailsRows.length < (state.roomCounts.read_only.unreplied_support_count_by_user && state.roomCounts.read_only.unreplied_support_count_by_user[user.id] || 0)) {
                                    stringifiedRequestName = array_utils_1.stringifyArray([initial_loading_reducer_1.loadUnRepliedOperatorsSupportsRequestName, user.id]);
                                    if (!state.nextPagedRequests[stringifiedRequestName]) {
                                        effects = sequence_services_1.sequence(effects, initial_loading_reducer_1.loadUnRepliedOperatorsSupports(user.id));
                                    }
                                }
                            }
                            else if (state.inputs.supportSearchReplyType === "replied") {
                                if (state.supportDetailsRows.length < (state.roomCounts.read_only.replied_support_count_by_user && state.roomCounts.read_only.replied_support_count_by_user[user.id] || 0)) {
                                    stringifiedRequestName = array_utils_1.stringifyArray([initial_loading_reducer_1.loadRepliedOperatorsSupportsRequestName, user.id]);
                                    if (!state.nextPagedRequests[stringifiedRequestName]) {
                                        effects = sequence_services_1.sequence(effects, initial_loading_reducer_1.loadRepliedOperatorsSupports(user.id));
                                    }
                                }
                            }
                        }
                        else {
                            if (state.inputs.supportSearchReplyType === "unreplied") {
                                if (state.supportDetailsRows.length < state.roomCounts.read_only.total_unreplied_support_count) {
                                    stringifiedRequestName = array_utils_1.stringifyArray([initial_loading_reducer_1.loadAllUnRepliedSupportsRequestName]);
                                    if (!state.nextPagedRequests[stringifiedRequestName]) {
                                        effects = sequence_services_1.sequence(effects, initial_loading_reducer_1.loadAllUnRepliedSupports());
                                    }
                                }
                            }
                            else if (state.inputs.supportSearchReplyType === "replied") {
                                if (state.supportDetailsRows.length < state.roomCounts.read_only.total_replied_support_count) {
                                    stringifiedRequestName = array_utils_1.stringifyArray([initial_loading_reducer_1.loadAllRepliedSupportsRequestName]);
                                    if (!state.nextPagedRequests[stringifiedRequestName]) {
                                        effects = sequence_services_1.sequence(effects, initial_loading_reducer_1.loadAllRepliedSupports());
                                    }
                                }
                            }
                        }
                        break;
                    case "awaiting":
                        if (state.supportDetailsRows.length < state.roomCounts.read_only.awaiting_support_count) {
                            stringifiedRequestName = initial_loading_reducer_1.loadAwaitingSupportRoomsRequestName;
                            if (!state.nextPagedRequests[initial_loading_reducer_1.loadAwaitingSupportRoomsRequestName]) {
                                effects = sequence_services_1.sequence(effects, initial_loading_reducer_1.loadAwaitingSupportRooms());
                            }
                        }
                        break;
                }
            }
            effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, stringifiedRequestName));
            break;
        case "write-scroll-position":
            state = tslib_1.__assign({}, state);
            state.scrollPositions = tslib_1.__assign({}, state.scrollPositions);
            state.scrollPositions[array_utils_1.stringifyArray(action.name)] = action.currentScrollPosition;
            break;
        case "set-sort-type":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.supportSortType = action.sortType;
            var supportDetailRows = state.supportDetailsRows;
            switch (state.inputs.supportSortType) {
                case "room-asc":
                    supportDetailRows.sort(sortBy([{ key: "room", subKey: "created_at", reverse: false }]));
                    break;
                case "room-desc":
                    supportDetailRows.sort(sortBy([{ key: "room", subKey: "created_at", reverse: true }]));
                    break;
                case "last-message-asc":
                    supportDetailRows.sort(sortBy([{ key: "lastMessage", subKey: "created_at", reverse: false }]));
                    break;
                case "last-message-desc":
                    supportDetailRows.sort(sortBy([{ key: "lastMessage", subKey: "created_at", reverse: true }]));
                    break;
            }
            state.supportDetailsRows = supportDetailRows;
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceSupportsLeftFilteringPanel = reduceSupportsLeftFilteringPanel;
function sortBy(list) {
    return function (a, b) {
        for (var i = 0; i < list.length; i++) {
            var orderBy = list[i].reverse ? 1 : -1;
            if (a[list[i].key]['attributes'][list[i].subKey] < b[list[i].key]['attributes'][list[i].subKey]) {
                return orderBy;
            }
            else if (a[list[i].key]['attributes'][list[i].subKey] > b[list[i].key]['attributes'][list[i].subKey]) {
                return orderBy * -1;
            }
        }
        return 0;
    };
}
function filterSupportsByOperator(state, searchString) {
    var effects = [];
    if (!state.toggles.showSupportUserFilter)
        return { state: state, effects: effects };
    if (!users_util_1.isAtLeastA(state.loggedInUser, "Supervisor"))
        return { state: state, effects: effects };
    effects = sequence_services_1.sequence(effects, loadSearchedOperators(searchString));
    return { state: state, effects: effects };
}
function loadSearchedOperators(searchString, sort, reverse) {
    var query = {};
    query.filter = { name_like: searchString, is_customer: 0 };
    var sortParam = (reverse ? "-" : "") + (sort || "name");
    query.sort = [sortParam];
    return json_resources_1.requestResourceFetch([exports.loadSearchedUsersRequestName], routes_1.RapiV1UsersPath, query);
}
exports.loadSearchedOperators = loadSearchedOperators;
function loadAllSearchedSupportRoom(searchString, loggedInUser) {
    if (!searchString)
        return;
    var query = {};
    query.filter = { keyword: searchString };
    query.include = ["members"];
    query.page = { size: 5 };
    query.sort = ["name"];
    if (users_util_1.isAtLeastA(loggedInUser, "Supervisor")) {
        query.filter["support_rooms_with_active_or_awaiting_support"] = 1;
    }
    else {
        query.filter["current_users_support_rooms_with_active_or_awaiting_support"] = 1;
    }
    return json_resources_1.requestResourceFetch([exports.loadSearchedSupportRoomsRequestName], routes_1.RapiV1RoomsPath, query);
}
exports.loadAllSearchedSupportRoom = loadAllSearchedSupportRoom;
exports.loadSearchedUsersRequestName = "load-searched-users-request-name";
exports.loadSearchedSupportRoomsRequestName = "load-searched-supports-rooms-resource-data";
exports.supportLeftFilteringPanelPagedRequestName = [exports.loadSearchedSupportRoomsRequestName, exports.loadSearchedUsersRequestName];
