"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function pick(o) {
    var keys = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        keys[_i - 1] = arguments[_i];
    }
    var result = {};
    for (var _a = 0, keys_1 = keys; _a < keys_1.length; _a++) {
        var k = keys_1[_a];
        result[k] = o[k];
    }
    return result;
}
exports.pick = pick;
function deepEqual(actual, expected) {
    if (actual === expected) {
        return true;
    }
    else if (actual instanceof Date && expected instanceof Date) {
        return actual.getTime() === expected.getTime();
    }
    else if (!actual || !expected || typeof actual != 'object' && typeof expected != 'object') {
        return actual == expected;
    }
    else {
        var i, key;
        if (actual === null || actual === undefined || expected === null || expected === undefined)
            return false;
        if (actual.prototype !== expected.prototype)
            return false;
        if (Object.prototype.toString.call(actual) == '[object Arguments]') {
            if (!(Object.prototype.toString.call(expected) == '[object Arguments]')) {
                return false;
            }
            actual = Array.prototype.slice.call(actual);
            expected = Array.prototype.slice.call(expected);
            return deepEqual(actual, expected);
        }
        if (isBuffer(actual)) {
            if (!isBuffer(expected)) {
                return false;
            }
            if (actual.length !== expected.length)
                return false;
            for (i = 0; i < actual.length; i++) {
                if (actual[i] !== expected[i])
                    return false;
            }
            return true;
        }
        try {
            var ka = Object.keys(actual), kb = Object.keys(expected);
        }
        catch (e) {
            return false;
        }
        if (ka.length != kb.length)
            return false;
        ka.sort();
        kb.sort();
        for (i = ka.length - 1; i >= 0; i--) {
            if (ka[i] != kb[i])
                return false;
        }
        for (i = ka.length - 1; i >= 0; i--) {
            key = ka[i];
            if (!deepEqual(actual[key], expected[key]))
                return false;
        }
        return typeof actual === typeof expected;
    }
}
exports.deepEqual = deepEqual;
function isBuffer(x) {
    if (!x || typeof x !== 'object' || typeof x.length !== 'number')
        return false;
    if (typeof x.copy !== 'function' || typeof x.slice !== 'function') {
        return false;
    }
    if (x.length > 0 && typeof x[0] !== 'number')
        return false;
    return true;
}
