"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var logout_services_1 = require("./logout-services");
var sequence_services_1 = require("./sequence-services");
function wrapWithAuthHeaders(withAjax, token) {
    return function (effect) {
        switch (effect.effectType) {
            case "request-ajax":
                effect = tslib_1.__assign({}, effect);
                effect.config = tslib_1.__assign({}, effect.config);
                effect.config.headers = effect.config.headers ? tslib_1.__assign({}, effect.config.headers) : {};
                effect.config.headers['X-Access-Token'] = token;
                withAjax(effect);
                break;
        }
    };
}
exports.wrapWithAuthHeaders = wrapWithAuthHeaders;
function reduceAjaxLogout(state, action) {
    var effects = [];
    switch (action.type) {
        case "complete-request":
            if (action.status === 401 || action.status === 403) {
                effects = sequence_services_1.sequence(effects, logout_services_1.logout());
            }
    }
    return { state: state, effects: effects };
}
exports.reduceAjaxLogout = reduceAjaxLogout;
