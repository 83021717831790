"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var SurveysStore;
(function (SurveysStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    SurveysStore.indexer = new bisect_1.Indexer("byId");
    SurveysStore.indexer.addIndex("byId", function (survey) { return [assertInnerId(survey)]; });
    SurveysStore.indexer.addIndex("byTitle", function (survey) { return [survey.attributes.title]; });
    SurveysStore.indexer.addIndex("byDescription", function (survey) { return [survey.attributes.description]; });
})(SurveysStore = exports.SurveysStore || (exports.SurveysStore = {}));
