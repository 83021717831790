"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function stringifyArray(array, delimiter) {
    return array.join(delimiter || ":");
}
exports.stringifyArray = stringifyArray;
function parseStringifiedArray(stringifiedArray, delimiter) {
    return stringifiedArray.split(delimiter || ":");
}
exports.parseStringifiedArray = parseStringifiedArray;
function findIndex(arr, f) {
    for (var i = 0; i < arr.length; i++) {
        if (f(arr[i], i, arr)) {
            return i;
        }
    }
    return -1;
}
exports.findIndex = findIndex;
function find(arr, f) {
    for (var i = 0; i < arr.length; i++) {
        if (f(arr[i], i, arr)) {
            return arr[i];
        }
    }
    return undefined;
}
exports.find = find;
