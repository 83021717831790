"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var UserWidgetSettingsStore;
(function (UserWidgetSettingsStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    UserWidgetSettingsStore.indexer = new bisect_1.Indexer("byId");
    UserWidgetSettingsStore.indexer.addIndex("byId", function (uws) { return [assertInnerId(uws)]; });
    UserWidgetSettingsStore.indexer.addIndex("byUserIdAndClientId", function (uws) { return [getRelationshipId(uws, "user"), getRelationshipId(uws, "client")]; });
})(UserWidgetSettingsStore = exports.UserWidgetSettingsStore || (exports.UserWidgetSettingsStore = {}));
