"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function showBrowserNotification(name, title, body) {
    return {
        effectType: "show-browser-notification",
        name: name,
        title: title,
        body: body
    };
}
exports.showBrowserNotification = showBrowserNotification;
function browserNotificationClicked(name) {
    return {
        type: "browser-notification-clicked",
        name: name
    };
}
exports.browserNotificationClicked = browserNotificationClicked;
var permissionGranted = false;
function checkPermission(force) {
    if (force === void 0) { force = false; }
    if (!("Notification" in window))
        return false;
    if (Notification.permission === "denied") {
        permissionGranted = false;
    }
    if (Notification.permission === "granted") {
        permissionGranted = true;
    }
    Notification.requestPermission(function (status) {
        permissionGranted = status === "granted";
    });
    return permissionGranted || force;
}
function withBrowserNotifications(dispatch) {
    var notifications = [];
    checkPermission();
    return function (effect) {
        switch (effect.effectType) {
            case "show-browser-notification":
                if (permissionGranted) {
                    var notification_1 = new Notification(effect.title, { body: effect.body });
                    notification_1.onclick = function () {
                        window.focus();
                        notification_1.close();
                        dispatch(browserNotificationClicked(effect.name));
                    };
                    notifications.push(notification_1);
                }
                break;
        }
    };
}
exports.withBrowserNotifications = withBrowserNotifications;
