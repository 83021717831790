"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
function addNewClientAccessPath(pathType) {
    return { type: "add-new-client-access-path", pathType: pathType };
}
exports.addNewClientAccessPath = addNewClientAccessPath;
function changeClientAccessPath(pathType, idx, value) {
    return { type: "change-client-access-path", pathType: pathType, idx: idx, value: value };
}
exports.changeClientAccessPath = changeClientAccessPath;
function removeClientAccessPath(pathType, idx) {
    return { type: "remove-client-access-path", pathType: pathType, idx: idx };
}
exports.removeClientAccessPath = removeClientAccessPath;
function addClientRule(ruleType) {
    return { type: "add-client-rule", ruleType: ruleType };
}
exports.addClientRule = addClientRule;
function changeClientRule(ruleType, orIdx, andIdx, rule) {
    return {
        type: "change-client-rule", ruleType: ruleType, orIdx: orIdx, andIdx: andIdx, rule: rule
    };
}
exports.changeClientRule = changeClientRule;
function removeClientRule(ruleType, ordIdx) {
    return {
        type: "remove-client-rule",
        ruleType: ruleType,
        ordIdx: ordIdx
    };
}
exports.removeClientRule = removeClientRule;
function reduceClientRule(state, action) {
    var effects = [];
    switch (action.type) {
        case "add-new-client-access-path":
            state = tslib_1.__assign({}, state);
            state.clientsPage = tslib_1.__assign({}, state.clientsPage);
            var urlsKey = getClientUrlsKey(action.pathType);
            state.clientsPage[urlsKey] = state.clientsPage[urlsKey].concat([""]);
            break;
        case "remove-client-access-path":
            state = tslib_1.__assign({}, state);
            state.clientsPage = tslib_1.__assign({}, state.clientsPage);
            var urlsKey = getClientUrlsKey(action.pathType);
            state.clientsPage[urlsKey] = state.clientsPage[urlsKey].slice();
            state.clientsPage[urlsKey].splice(action.idx, 1);
            break;
        case "change-client-access-path":
            state = tslib_1.__assign({}, state);
            state.clientsPage = tslib_1.__assign({}, state.clientsPage);
            var urlsKey = getClientUrlsKey(action.pathType);
            state.clientsPage[urlsKey] = state.clientsPage[urlsKey].slice();
            state.clientsPage[urlsKey][action.idx] = action.value;
            break;
        case "add-client-rule":
            state = tslib_1.__assign({}, state);
            state.clientsPage = tslib_1.__assign({}, state.clientsPage);
            var rulesKey = getClientRulesKey(action.ruleType);
            state.clientsPage[rulesKey] = state.clientsPage[rulesKey].concat([[newRule()]]);
            break;
        case "remove-client-rule":
            state = tslib_1.__assign({}, state);
            state.clientsPage = tslib_1.__assign({}, state.clientsPage);
            var rulesKey = getClientRulesKey(action.ruleType);
            state.clientsPage[rulesKey] = state.clientsPage[rulesKey].slice();
            state.clientsPage[rulesKey].splice(action.ordIdx, 1);
            break;
        case "change-client-rule":
            state = tslib_1.__assign({}, state);
            state.clientsPage = tslib_1.__assign({}, state.clientsPage);
            var rulesKey = getClientRulesKey(action.ruleType);
            state.clientsPage[rulesKey] = state.clientsPage[rulesKey].slice();
            var ands = state.clientsPage[rulesKey][action.orIdx] = state.clientsPage[rulesKey][action.orIdx].slice();
            ands[action.andIdx] = action.rule;
            var isLastAnd = action.andIdx === ands.length - 1;
            if (isLastAnd) {
                if (isRuleComplete(action.rule)) {
                    ands.push(newRule());
                }
            }
            else if (!isRuleComplete(action.rule)) {
                ands.splice(action.andIdx, 1);
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceClientRule = reduceClientRule;
function getClientUrlsKey(pathType) {
    if (pathType == "black") {
        return "blackListUrls";
    }
    return "whiteListUrls";
}
function getClientRulesKey(pathType) {
    if (pathType == "black") {
        return "blackListRules";
    }
    return "whiteListRules";
}
function isRuleComplete(rule) {
    return rule.selector && rule.attribute;
}
function newRule() {
    return { attribute: "", selector: "" };
}
exports.newRule = newRule;
