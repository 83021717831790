"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var ReportsStore;
(function (ReportsStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    ReportsStore.indexer = new bisect_1.Indexer("byId");
    ReportsStore.indexer.addIndex("byId", function (report) { return [assertInnerId(report)]; });
})(ReportsStore = exports.ReportsStore || (exports.ReportsStore = {}));
