"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var chat_room_reducer_1 = require("./chat-room-reducer");
var room_notification_utils_1 = require("../../utils/room-notification-utils");
var file_upload_utils_1 = require("../../utils/file-upload-utils");
var generators_1 = require("../../utils/generators");
var I18n_1 = require("../../I18n");
var upload_services_1 = require("../../core/services/upload-services");
var sequence_services_1 = require("../../core/services/sequence-services");
var google_analytics_services_1 = require("../../core/services/google-analytics-services");
function uploadFile(files) {
    return {
        type: "upload-file",
        files: files
    };
}
exports.uploadFile = uploadFile;
function closeFailureMessage(notificationId) {
    return {
        type: "close-failure-message",
        notificationId: notificationId
    };
}
exports.closeFailureMessage = closeFailureMessage;
function reduceFileUpload(state, action) {
    var _a;
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    switch (action.type) {
        case "upload-success":
            if (action.name[0] == exports.fileUploadRequestName) {
                var roomId = action.name[1];
                var roomNotificationId = action.name[2];
                (_a = sequence_services_1.sequenceReduction(effects, chat_room_reducer_1.sendMessage(state, file_upload_utils_1.createNewMessageFromUpload(action.cloudinaryResponse), roomId, state.client_id)), state = _a.state, effects = _a.effects);
                effects = sequence_services_1.sequence(effects, google_analytics_services_1.sendGoogleAnalyticsEvent("Send Message by Customer", 'ok-sky_chat', state.clientCode));
                effects = sequence_services_1.sequence(effects, chat_room_reducer_1.notifyTyping(state.currentViewingRoomId, ""));
                state.roomNotifications = room_notification_utils_1.deleteRoomNotificationById(state.roomNotifications, roomNotificationId);
                if (roomId) {
                    state.isMessageSendingByRoom = tslib_1.__assign({}, state.isMessageSendingByRoom);
                    delete state.isMessageSendingByRoom[roomId];
                }
            }
            break;
        case "upload-failure":
            if (action.name[0] == exports.fileUploadRequestName) {
                var roomId = action.name[1];
                var roomNotification_1 = {
                    id: action.name[0] + ":" + generators_1.genUuid(),
                    type: "room_notifications",
                    attributes: {
                        roomId: roomId,
                        body: I18n_1.i18n.uploadFailureMessage[language],
                        created_at_unix: Date.now() / 1000,
                        kind: "error"
                    },
                };
                state = tslib_1.__assign({}, state);
                state.roomNotifications = state.roomNotifications.concat([roomNotification_1]);
                state.roomNotifications = room_notification_utils_1.deleteRoomNotificationById(state.roomNotifications, action.name[2]);
                if (roomId) {
                    state.isMessageSendingByRoom = tslib_1.__assign({}, state.isMessageSendingByRoom);
                    delete state.isMessageSendingByRoom[roomId];
                }
            }
            break;
        case "close-failure-message":
            state = tslib_1.__assign({}, state);
            state.roomNotifications = state.roomNotifications.filter(function (notification) {
                return notification.id !== action.notificationId;
            });
            break;
        case "begin-upload":
            var roomNotification = {
                id: action.name[2],
                type: "room_notifications",
                attributes: {
                    roomId: action.name[1],
                    created_at_unix: Date.now() / 1000,
                    kind: "progress"
                },
            };
            state = tslib_1.__assign({}, state);
            state.roomNotifications = state.roomNotifications.concat([roomNotification]);
            break;
        case "upload-file":
            effects = sequence_services_1.sequence(effects, upload_services_1.requestUpload([exports.fileUploadRequestName, state.currentViewingRoomId], action.files));
            state = tslib_1.__assign({}, state);
            state.isMessageSendingByRoom = tslib_1.__assign({}, state.isMessageSendingByRoom);
            state.isMessageSendingByRoom[state.currentViewingRoomId] = true;
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceFileUpload = reduceFileUpload;
exports.createFileMessageRequestName = "create-file-message-request-name";
exports.fileUploadRequestName = "file-upload-request-name";
