"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function flushDebounce(name) {
    return { effectType: "flush-debounce", name: name };
}
exports.flushDebounce = flushDebounce;
function clearDebounce(name) {
    return { effectType: "clear-debounce", name: name };
}
exports.clearDebounce = clearDebounce;
function debounce(action, name, debounceMs) {
    if (debounceMs === void 0) { debounceMs = 300; }
    return {
        effectType: "debounce",
        action: action, debounceMs: debounceMs, name: name
    };
}
exports.debounce = debounce;
function withDebounce(dispatch) {
    var timeouts = {};
    return function (e) {
        switch (e.effectType) {
            case "flush-debounce":
                var timeout = timeouts[e.name];
                if (timeout) {
                    delete timeouts[e.name];
                    clearTimeout(timeout.handle);
                    dispatch(timeout.action);
                }
                break;
            case "clear-debounce":
                var timeout = timeouts[e.name];
                if (timeout) {
                    delete timeouts[e.name];
                    clearTimeout(timeout.handle);
                }
                break;
            case "debounce":
                var timeout = timeouts[e.name];
                if (timeout) {
                    delete timeouts[e.name];
                    clearTimeout(timeout.handle);
                }
                var run = function () {
                    delete timeouts[e.name];
                    dispatch(e.action);
                };
                timeouts[e.name] = { handle: setTimeout(run, e.debounceMs), action: e.action };
                break;
        }
    };
}
exports.withDebounce = withDebounce;
