"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var SupportsStore;
(function (SupportsStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    SupportsStore.indexer = new bisect_1.Indexer("byId");
    SupportsStore.indexer.addIndex("byId", function (support) { return [assertInnerId(support)]; });
    SupportsStore.indexer.addIndex("byUserIdAndIsEnded", function (support) { return [assertInnerId(support.relationships["user"].data),
        !!support.attributes.end_time_unix]; });
    SupportsStore.indexer.addIndex("byRoomId", function (support) { return [assertInnerId(support.relationships["room"].data)]; });
    SupportsStore.indexer.addIndex("byRoomIdAndIsEnded", function (support) { return [assertInnerId(support.relationships["room"].data), !!support.attributes.end_time_unix]; });
    SupportsStore.indexer.addIndex("byRoomIdAndUserId", function (support) { return [assertInnerId(support.relationships["room"].data),
        assertInnerId(support.relationships["user"].data)]; });
    SupportsStore.indexer.addIndex("byRoomIdAndStartTimeUnix", function (support) { return [getRelationshipId(support, "room"), support.attributes.start_time_unix]; });
})(SupportsStore = exports.SupportsStore || (exports.SupportsStore = {}));
