"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RapiV1UsersPath = "/rapi/v1/users";
exports.RapiV1ClientsPath = "/rapi/v1/clients";
exports.RapiV1SupportsPath = "/rapi/v1/supports";
exports.RapiV1RoomsPath = "/rapi/v1/rooms";
exports.RapiV1MessagesPath = "/rapi/v1/messages";
exports.RapiV1ReportsPath = "/rapi/v1/reports";
exports.RapiV1MasterKeywordsPath = "/rapi/v1/master_keywords";
exports.RapiV1UserInfosPath = "/rapi/v1/user_infos";
exports.RapiV1TemplateResponsesPath = "/rapi/v1/template_responses";
exports.DestroyUserSessionPath = "/users/sign_out";
exports.RapiV1MemosPath = "/rapi/v1/memos";
exports.RapiV1MembersPath = "/rapi/v1/members";
exports.RapiV1WebhookPath = "/rapi/v1/hooks";
exports.RapiV1MarketingDatasPath = "/rapi/v1/marketing_datas";
exports.RapiV1ExternalServicesPath = "/rapi/v1/external_services";
exports.RapiV1TenantsPath = "/rapi/v1/tenants";
exports.RapiV1DraftMessagesPath = "/rapi/v1/draft_messages";
exports.RapiV1SurveysPath = "/rapi/v1/surveys";
exports.RapiV1QuestionsPath = "/rapi/v1/questions";
exports.RapiV1VisitsPath = "/rapi/v1/visits";
exports.RapiV1TrackerEntriesPath = "/rapi/v1/tracker_entries";
exports.RapiV1UserWidgetSettingsPath = "/rapi/v1/user_widget_settings";
exports.RapiV1StripePlansPath = "/rapi/v1/stripe_plans";
exports.RapiV1StripeProductsPath = "/rapi/v1/stripe_products";
exports.RapiV1StripeCardsPath = "/rapi/v1/stripe_cards";
exports.RapiV1StripeCustomersPath = "/rapi/v1/stripe_customers";
exports.RapiV1StripeSubscriptionsPath = "/rapi/v1/stripe_subscriptions";
exports.RapiV1WidgetChangeHuman = "/widget/change_human";
exports.RapiV1FileDownloadsPath = "/rapi/v1/file_downloads";
exports.RapiV1ScenarioTemplatesPath = "/rapi/v1/scenario_templates";
exports.RapiV1ScenarioSurveyTemplatesPath = "/rapi/v1/scenario_survey_templates";
exports.RapiV1ScenarioSurveyAnswersPath = "/rapi/v1/scenario_survey_answers";
exports.RapiV1ShowContentsPath = "/rapi/v1/show_contents";
