"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bisect_1 = require("../utils/bisect");
var MasterKeywordsStore;
(function (MasterKeywordsStore) {
    MasterKeywordsStore.indexer = new bisect_1.Indexer("byId");
    MasterKeywordsStore.indexer.addIndex("byId", function (mk) { return [mk.id]; });
    MasterKeywordsStore.indexer.addIndex("byKeyTitle", function (mk) { return [mk.attributes.key_title]; });
    MasterKeywordsStore.indexer.addIndex("byKeyName", function (mk) { return [mk.attributes.key_name]; });
    MasterKeywordsStore.indexer.addIndex("byRank", function (mk) { return [mk.attributes.rank]; });
})(MasterKeywordsStore = exports.MasterKeywordsStore || (exports.MasterKeywordsStore = {}));
