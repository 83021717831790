"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bisect_1 = require("../utils/bisect");
var I18n_1 = require("../I18n");
var array_utils_1 = require("../utils/array-utils");
var ExternalServicesStore;
(function (ExternalServicesStore) {
    ExternalServicesStore.indexer = new bisect_1.Indexer("byId");
    ExternalServicesStore.indexer.addIndex("byId", function (externalService) { return [externalService.id]; });
    ExternalServicesStore.indexer.addIndex("byName", function (externalService) { return [externalService.attributes.name]; });
    ExternalServicesStore.indexer.addIndex("byKind", function (externalService) { return [externalService.attributes.kind]; });
    ExternalServicesStore.indexer.addIndex("byIsActive", function (externalService) { return [externalService.attributes.is_active]; });
})(ExternalServicesStore = exports.ExternalServicesStore || (exports.ExternalServicesStore = {}));
function kindKey2Label(key, language) {
    var kind = array_utils_1.find(externalServiceKinds(language), function (e) { return e.key === key; });
    return kind ? kind.label : null;
}
exports.kindKey2Label = kindKey2Label;
function externalServiceKinds(language) {
    return [
        {
            key: "google",
            label: I18n_1.i18n.connectionLabelGoogle[language],
            defaultSettings: {},
            permitCreate: false,
        },
        {
            key: "facebook",
            label: I18n_1.i18n.connectionLabelFacebook[language],
            defaultSettings: {},
            permitCreate: false,
        },
        {
            key: "twitter",
            label: I18n_1.i18n.connectionLabelTwitter[language],
            defaultSettings: {},
            permitCreate: false,
        },
        {
            key: "line",
            label: I18n_1.i18n.connectionLabelLine[language],
            defaultSettings: {},
            permitCreate: false,
        },
        {
            key: "gline",
            label: I18n_1.i18n.connectionLabelGlobalLine[language],
            defaultSettings: {
                ec_site_id: "",
                ec_site_secret: ""
            },
            permitCreate: false,
        },
        {
            key: "spiralec",
            label: I18n_1.i18n.connectionLabelSpiralEC[language],
            defaultSettings: {
                content_type: 'application/json',
                client_id: '',
                client_secret: '',
                code: '',
                access_info: {
                    "access_token": '',
                    "expires_in": 3600,
                    "token_type": "Bearer",
                    "scope": "member order coordination item",
                    "refresh_token": ""
                }
            },
            permitCreate: false,
        },
        {
            key: "mailgun",
            label: I18n_1.i18n.connectionLabelMailgun[language],
            defaultSettings: {},
            permitCreate: false,
        },
        {
            key: "hipchat",
            label: I18n_1.i18n.connectionLabelHipchat[language],
            defaultSettings: {},
            permitCreate: false,
        },
        {
            key: "twilio",
            label: I18n_1.i18n.connectionLabelTwilio[language],
            defaultSettings: {},
            permitCreate: false,
        },
        {
            key: "wnlc",
            label: I18n_1.i18n.connectionLabelIBMWatson[language],
            defaultSettings: {
                access_info: {
                    "credentials": {
                        "url": "",
                        "password": "",
                        "username": ""
                    }
                },
                class_id: ''
            },
            permitCreate: false,
        },
        {
            key: "wdialog",
            label: I18n_1.i18n.connectionLabelIBMWatsonDialog[language],
            defaultSettings: {},
            permitCreate: false,
        },
        {
            key: "yahoodev",
            label: I18n_1.i18n.connectionLabelYahoo[language],
            defaultSettings: {
                access_info: {
                    "appid": "",
                    "appsecret": ""
                }
            },
            permitCreate: false,
        },
        {
            key: "nlccf",
            label: I18n_1.i18n.connectionLabelOkSkyNLCCF[language],
            defaultSettings: {
                access_info: {
                    url: 'http://nlccf.herokuapp.com',
                    accesskey: ""
                }
            },
            permitCreate: false,
        },
        {
            key: "pusher",
            label: I18n_1.i18n.connectionLabelPusher[language],
            defaultSettings: {
                access_info: {
                    app_id: '',
                    key: '',
                    secret: ''
                }
            },
            permitCreate: false,
        },
        {
            key: "nu_visionary_api",
            label: I18n_1.i18n.connectionLabelVisionaryAPI[language],
            defaultSettings: {
                access_info: {
                    client_id: '',
                    fqdn: ''
                }
            },
            permitCreate: false,
        },
        {
            key: "aws_sns_ios",
            label: I18n_1.i18n.connectionLabelIosDevicePush[language],
            defaultSettings: {
                access_info: {
                    access_key_id: '',
                    secret_access_key: '',
                    region: '',
                    platform_application_arn: ''
                }
            },
            permitCreate: false,
        },
        {
            key: "aws_sns_ios_sandbox",
            label: I18n_1.i18n.connectionLabelIOSSandboxDevicePush[language],
            defaultSettings: {
                access_info: {
                    access_key_id: '',
                    secret_access_key: '',
                    region: '',
                    platform_application_arn: ''
                }
            },
            permitCreate: false,
        },
        {
            key: "aws_sns_android",
            label: I18n_1.i18n.connectionLabelAndroidDevicePush[language],
            defaultSettings: {
                access_info: {
                    access_key_id: '',
                    secret_access_key: '',
                    region: '',
                    platform_application_arn: ''
                }
            },
            permitCreate: false,
        },
        {
            key: "custom_user",
            label: I18n_1.i18n.connectionLabelUserCustom[language],
            defaultSettings: {
                copy_master_keywords: []
            },
            permitCreate: false,
        },
        {
            key: "line_bot_api",
            label: I18n_1.i18n.connectionLabelLineBotApi[language],
            defaultSettings: {
                access_info: {
                    line_channel_secret: '',
                    line_channel_token: '',
                    channel_id: ''
                },
                join_messages: [{ content: '', kind: 'text' }],
                follow_messages: [{ content: '', kind: 'text' }],
                beacon_banner_messages: [{ content: '', kind: 'text' }],
                beacon_enter_messages: [{ content: '', kind: 'text' }],
                beacon_leave_messages: [{ content: '', kind: 'text' }]
            },
            permitCreate: true,
        },
        {
            key: "ccqa",
            label: I18n_1.i18n.connectionLabelNTTDataCCQA[language],
            defaultSettings: {
                access_info: {
                    url: ''
                },
                searcher_id: '',
                message_create_user_id: 1,
                min_cos_similarity: 0.5,
                incorrect_answer_messages: [
                    {
                        content: '',
                        kind: 'text'
                    }
                ]
            },
            permitCreate: true,
        },
        {
            key: "twilio_text_messaging",
            label: I18n_1.i18n.connectionLabelTwilioTextMessaging[language],
            defaultSettings: {
                access_info: {
                    account_sid: '',
                    account_token: '',
                    phone_number: '',
                }
            },
            permitCreate: true,
        },
        {
            key: "messenger_api",
            label: I18n_1.i18n.connectionLabelMessengerApi[language],
            defaultSettings: {
                access_info: {
                    webhook_token: '',
                    facebook_page_id: '',
                    page_access_token: '',
                }
            },
            permitCreate: true,
        },
        {
            key: "line_messaging_api",
            label: I18n_1.i18n.connectionLabelLineMessagingApi[language],
            defaultSettings: {
                access_info: {
                    channel_id: '',
                    channel_secret: '',
                    destination_id: '',
                    switcher_secret: '',
                    line_channel_token: '',
                },
                user_not_send_message: 1,
            },
            permitCreate: true,
        },
        {
            key: "salesforce_api",
            label: I18n_1.i18n.connectionLabelSalesforceApi[language],
            defaultSettings: {
                access_info: {
                    username: '',
                    password: '',
                    security_token: '',
                    client_id: '',
                    client_secret: '',
                    api_version: '',
                },
            },
            permitCreate: true,
        },
        {
            key: "opengpt35",
            label: I18n_1.i18n.connectionLabelOpenAiApi[language],
            defaultSettings: {
                access_info: {
                    url: ''
                },
                message_create_user_id: 1,
                incorrect_answer_messages: [
                    {
                        content: '',
                        kind: 'text'
                    }
                ]
            },
            permitCreate: false,
        },
    ];
}
exports.externalServiceKinds = externalServiceKinds;
