"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var RoomsStore;
(function (RoomsStore) {
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    RoomsStore.indexer = new bisect_1.Indexer("byId");
    RoomsStore.indexer.addIndex("byId", function (rd) { return [rd.id]; });
    RoomsStore.indexer.addIndex("byKind", function (rd) { return [rd.attributes.kind]; });
    RoomsStore.indexer.addIndex("byKindAndName", function (rd) { return [rd.attributes.kind, rd.attributes.name]; });
    RoomsStore.indexer.addIndex("byPrivate", function (rd) { return [rd.attributes.is_private]; });
    RoomsStore.indexer.addIndex("byCreateUserId", function (rd) { return [getRelationshipId(rd, "create_user")]; });
    RoomsStore.indexer.addIndex("byNotSupportRoom", function (rd) { return [rd.attributes.kind !== "support_room"]; });
    RoomsStore.indexer.addIndex("byWaitingAndReverseCreatedAt", function (rd) { return [rd.attributes.awaiting_support, -rd.attributes.created_at_unix]; });
})(RoomsStore = exports.RoomsStore || (exports.RoomsStore = {}));
