"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var class_generator_1 = require("./class-generator");
var classNamesForBadge = class_generator_1.classNamesGeneratorFor(function (add) {
    add("online", React.createElement("div", { className: "bg-light-green" }));
    add("busy", React.createElement("div", { className: "bg-red" }));
    add("offline", React.createElement("div", { className: "bg-light-gray" }));
    add("away", React.createElement("div", { className: "bg-yellow" }));
}, React.createElement("div", { className: "br-100 wtiny htiny dib" }));
function StatusBadge(props) {
    switch (props.user && props.user.attributes.status) {
        case "available":
            props = tslib_1.__assign({}, props, { online: true });
            break;
        case "busy":
            props = tslib_1.__assign({}, props, { busy: true });
            break;
        case "away":
            props = tslib_1.__assign({}, props, { away: true });
            break;
        case "offline":
        case "hidden":
        default:
            props = tslib_1.__assign({}, props, { offline: true });
            break;
    }
    return React.createElement("div", { className: classNamesForBadge(props) });
}
exports.StatusBadge = StatusBadge;
