"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var index_1 = require("../stores/index");
var removeResource = index_1.Stores.removeResource;
var json_resources_1 = require("../core/json-resources");
var routes_1 = require("../routes");
var bisect_1 = require("../utils/bisect");
var chat_input_reducer_1 = require("./chat-input-reducer");
var resource_api_1 = require("../schemas/resource-api");
var newResource = resource_api_1.ResourceApi.newResource;
var addRelationship = resource_api_1.ResourceApi.addRelationship;
var toast_reducer_1 = require("./toast-reducer");
var I18n_1 = require("../I18n");
var ajax_services_1 = require("../core/services/ajax-services");
var sequence_services_1 = require("../core/services/sequence-services");
var copy_clipboard_services_1 = require("../core/services/copy-clipboard-services");
var sounds_1 = require("../components/sounds");
function alertDeleteMessage(messageId) {
    return {
        type: "alert-delete-message",
        messageId: messageId
    };
}
exports.alertDeleteMessage = alertDeleteMessage;
function deleteMessage(messageId) {
    return {
        type: "delete-message",
        messageId: messageId
    };
}
exports.deleteMessage = deleteMessage;
function startMessageEdit(messageId) {
    return {
        type: "start-message-edit",
        messageId: messageId
    };
}
exports.startMessageEdit = startMessageEdit;
function cancelMessageEdit(messageId) {
    return {
        type: "cancel-message-edit",
        messageId: messageId
    };
}
exports.cancelMessageEdit = cancelMessageEdit;
function finishMessageEdit(messageId, content) {
    return {
        type: "finish-message-edit",
        messageId: messageId,
        content: content
    };
}
exports.finishMessageEdit = finishMessageEdit;
function copyMessageTextToClipboard(messageId, messageResourceType) {
    return {
        type: "copy-message-text-to-clipboard",
        messageId: messageId,
        messageResourceType: messageResourceType,
    };
}
exports.copyMessageTextToClipboard = copyMessageTextToClipboard;
function copyTextToClipboard(text) {
    return {
        type: "copy-text-to-clipboard",
        text: text,
    };
}
exports.copyTextToClipboard = copyTextToClipboard;
function startFileMessageShare(messageId) {
    return {
        type: "start-file-message-share",
        messageId: messageId
    };
}
exports.startFileMessageShare = startFileMessageShare;
function cancelFileMessageShare() {
    return {
        type: "cancel-file-message-share",
    };
}
exports.cancelFileMessageShare = cancelFileMessageShare;
function finishFileMessageShare(messageId, roomId) {
    return {
        type: "finish-file-message-share",
        messageId: messageId,
        roomId: roomId
    };
}
exports.finishFileMessageShare = finishFileMessageShare;
function selectFileMessageShareRoom(roomId) {
    return {
        type: "select-file-message-share-room",
        roomId: roomId
    };
}
exports.selectFileMessageShareRoom = selectFileMessageShareRoom;
function batchingEnd() {
    return {
        type: "batching-end"
    };
}
exports.batchingEnd = batchingEnd;
exports.deleteMessageRequestName = "delete-message-request-name";
exports.editMessageRequestName = "edit-message-request-name";
function reduceEditDeleteMessages(state, action) {
    var _a, _b, _c, _d;
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.deleteMessageRequestName) {
                if (action.success) {
                    state = tslib_1.__assign({}, state);
                    state.stores = removeResource({ type: "messages", id: action.name[1] }, state.stores);
                }
            }
            if (action.name[0] === exports.editMessageRequestName) {
                state.curEditingMessageId = null;
            }
            break;
        case "start-message-edit":
            state = tslib_1.__assign({}, state);
            state.curEditingMessageId = action.messageId;
            var message = bisect_1.Indexer.getFirstMatching(state.stores.messages.byId, [action.messageId]);
            if (message) {
                state.inputs = tslib_1.__assign({}, state.inputs);
                state.inputs.curEditingMessageText = message.attributes.content;
            }
            break;
        case "cancel-message-edit":
            state = tslib_1.__assign({}, state);
            state.curEditingMessageId = null;
            break;
        case "finish-message-edit":
            effects = sequence_services_1.sequence(effects, requestEditMessage(action.messageId, action.content));
            (_a = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastMessageEdited[language])), state = _a.state, effects = _a.effects);
            break;
        case "alert-delete-message":
            state = tslib_1.__assign({}, state);
            state.supportsPage = tslib_1.__assign({}, state.supportsPage);
            state.supportsPage.supportsMessageIdToBeDeleted = action.messageId;
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.supportsMessageDeleteModal = true;
            break;
        case "delete-message":
            effects = sequence_services_1.sequence(effects, requestDeleteMessage(action.messageId));
            (_b = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastMessageDeleted[language])), state = _b.state, effects = _b.effects);
            state.toggles.supportsMessageDeleteModal = false;
            break;
        case "copy-message-text-to-clipboard":
            var message;
            var draftMessage = void 0;
            var messageKind = void 0;
            var messageContent = void 0;
            var messageUrl = void 0;
            var messageResourceType = action.messageResourceType;
            var toastContent = "";
            switch (messageResourceType) {
                case "messages":
                    message = bisect_1.Indexer.getFirstMatching(state.stores.messages.byId, [action.messageId]);
                    if (!message)
                        break;
                    messageKind = message.attributes.kind;
                    messageContent = message.attributes.content;
                    messageUrl = message.attributes.settings && message.attributes.settings.url;
                    toastContent = I18n_1.i18n.feedbackToastMessageCopied[language];
                    break;
                case "draft_messages":
                    draftMessage = bisect_1.Indexer.getFirstMatching(state.stores.draftMessageResources.byId, [action.messageId]);
                    if (!draftMessage)
                        break;
                    messageKind = draftMessage.attributes.kind;
                    messageContent = draftMessage.attributes.message_content;
                    messageUrl = draftMessage.attributes.settings && draftMessage.attributes.settings.url;
                    toastContent = I18n_1.i18n.feedbackToastDraftMessageCopied[language];
                    break;
            }
            var textToCopy = void 0;
            switch (messageKind) {
                case "text":
                    textToCopy = messageContent;
                    break;
                case "link":
                    textToCopy = messageUrl;
                    break;
            }
            effects = sequence_services_1.sequence(effects, copy_clipboard_services_1.copyToClipboard(textToCopy));
            (_c = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, toastContent)), state = _c.state, effects = _c.effects);
            break;
        case "copy-text-to-clipboard":
            effects = sequence_services_1.sequence(effects, copy_clipboard_services_1.copyToClipboard(action.text));
            (_d = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastTextCopied[language])), state = _d.state, effects = _d.effects);
            break;
        case "start-file-message-share":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.showFileShareModal = true;
            state.supportsPage = tslib_1.__assign({}, state.supportsPage);
            state.supportsPage.fileShareMessageId = action.messageId;
            break;
        case "cancel-file-message-share":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.showFileShareModal = false;
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.fileShareSearch = null;
            state.supportsPage = tslib_1.__assign({}, state.supportsPage);
            state.supportsPage.fileShareMessageId = null;
            state.supportsPage.fileShareRoomId = null;
            break;
        case "finish-file-message-share":
            var message = bisect_1.Indexer.getFirstMatching(state.stores.messages.byId, [action.messageId]);
            var selectedRoom = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [action.roomId]);
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.showFileShareModal = false;
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.fileShareSearch = null;
            state.supportsPage = tslib_1.__assign({}, state.supportsPage);
            state.supportsPage.fileShareMessageId = null;
            state.supportsPage.fileShareRoomId = null;
            if (!selectedRoom)
                break;
            if (!message)
                break;
            if (message.attributes.kind !== "file")
                break;
            var newMessage = newResource("messages");
            newMessage.attributes.kind = "file";
            newMessage.attributes.settings = tslib_1.__assign({}, message.attributes.settings);
            addRelationship(newMessage, "room", selectedRoom);
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([chat_input_reducer_1.sendMessageRequestName, action.roomId], newMessage, routes_1.RapiV1MessagesPath));
            break;
        case "select-file-message-share-room":
            state = tslib_1.__assign({}, state);
            state.supportsPage = tslib_1.__assign({}, state.supportsPage);
            state.supportsPage.fileShareRoomId = action.roomId;
            break;
        case "batching-end":
            state = tslib_1.__assign({}, state);
            if (state.ringANotifySoundAtBatchingEnd) {
                var audio = new Audio(sounds_1.UserSound());
                var playPromise = audio.play();
                if (playPromise !== undefined) {
                    playPromise.then(function (_) { }).catch(function (error) { });
                }
                if (process.env.NODE_ENV !== "production") {
                    console.warn("Ring a notify sound");
                }
                state.sp = tslib_1.__assign({}, state.sp);
            }
            state.ringANotifySoundAtBatchingEnd = false;
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceEditDeleteMessages = reduceEditDeleteMessages;
function requestDeleteMessage(messageId) {
    var ajaxConfig = {};
    ajaxConfig.headers = json_resources_1.jsonResourceHeaders;
    ajaxConfig.url = routes_1.RapiV1MessagesPath + "/" + messageId;
    ajaxConfig.method = "DELETE";
    return ajax_services_1.requestAjax([exports.deleteMessageRequestName, messageId], ajaxConfig);
}
exports.requestDeleteMessage = requestDeleteMessage;
function requestEditMessage(messageId, content) {
    var ajaxConfig = {};
    ajaxConfig.headers = json_resources_1.jsonResourceHeaders;
    ajaxConfig.url = routes_1.RapiV1MessagesPath + ("/" + messageId);
    ajaxConfig.method = "PATCH";
    ajaxConfig.json = {
        data: { type: "messages", id: messageId, attributes: { content: content } }
    };
    return ajax_services_1.requestAjax([exports.editMessageRequestName, messageId], ajaxConfig);
}
exports.requestEditMessage = requestEditMessage;
