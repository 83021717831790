"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var classesForProgressBar = class_generator_1.classNamesGeneratorFor(function (add) {
    add("percent", React.createElement("div", { className: "bg-light-blue" }));
}, React.createElement("div", { className: "h0_5 br-pill" }));
function ProgressBar(props) {
    var progressStyle = {};
    progressStyle.width = props.percent + "%";
    return React.createElement("div", { className: "bg-light-gray w-100 h0_5 br-pill dib" },
        React.createElement("div", { style: progressStyle, className: classesForProgressBar(props) }));
}
exports.ProgressBar = ProgressBar;
