"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var color_editable_1 = require("./color-editable");
var scroll_utils_1 = require("../utils/scroll-utils");
var react_1 = require("react");
var MOUSE_LEFT_CLICK = 1;
var MIN_SCROLL_BAR_HEIGHT_PX = 10;
function ScrollBarContainer(props) {
    var scrollContainerParentRef;
    var scrollBarRef;
    var isDragging;
    var prevY;
    var prevTouchY;
    var body;
    var realScrollBarHeight;
    var realScrollBarPosition;
    var updateScrollBarPosition = function () {
        var scrollHeight = scrollContainerParentRef.children[0].scrollHeight;
        var scrollTop = scrollContainerParentRef.children[0].scrollTop;
        var clientHeight = scrollContainerParentRef.children[0].clientHeight;
        var scrollBarBackgroundHeight = clientHeight;
        var deltaHeight = scrollBarBackgroundHeight / scrollHeight;
        scrollBarRef.parentElement.style.height = scrollBarBackgroundHeight + 'px';
        if (scrollHeight <= clientHeight) {
            scrollBarRef.style.height = "0px";
            scrollBarRef.parentElement.style.backgroundColor = "transparent";
        }
        else {
            scrollBarRef.parentElement.style.backgroundColor = props.colors["scrollbar_background"];
            var scrollBarHeight = clientHeight * deltaHeight;
            var scrollBarPosition = scrollTop * deltaHeight;
            realScrollBarHeight = scrollBarHeight;
            realScrollBarPosition = scrollBarPosition;
            if (scrollBarHeight < MIN_SCROLL_BAR_HEIGHT_PX) {
                scrollBarPosition -= (scrollTop / scrollHeight) * (MIN_SCROLL_BAR_HEIGHT_PX - scrollBarHeight);
                scrollBarHeight = MIN_SCROLL_BAR_HEIGHT_PX;
            }
            scrollBarRef.style.height = scrollBarHeight + "px";
            scrollBarRef.style.marginTop = scrollBarPosition + "px";
        }
    };
    var moveScrollBarPosition = function (delta) {
        var clientHeight = scrollContainerParentRef.children[0].clientHeight;
        var scrollHeight = scrollContainerParentRef.children[0].scrollHeight;
        var deltaHeight = clientHeight / scrollHeight;
        var scrollBarPosition = realScrollBarPosition + delta;
        var scrollTop = scrollBarPosition / deltaHeight;
        if (scrollBarPosition >= clientHeight - realScrollBarHeight) {
            scrollBarPosition = clientHeight - realScrollBarHeight;
            scrollTop = scrollBarPosition / deltaHeight;
        }
        if (scrollBarPosition <= 0) {
            scrollTop = 0;
        }
        scrollContainerParentRef.children[0].scrollTop = scrollTop;
        updateScrollBarPosition();
    };
    var onDragEnd = function () {
        isDragging = false;
        scrollContainerParentRef.children[0].classList.remove('user-select-disabled');
        body.removeEventListener('mousemove', function (e) { return onDrag(e); });
        body.removeEventListener('mouseup', onDragEnd);
        body.removeEventListener('touchmove', function (e) { return onMobileDrag(e); });
        body.removeEventListener('touchend', onDragEnd);
    };
    var onDragStart = function (e) {
        if (e.buttons !== MOUSE_LEFT_CLICK)
            return onDragEnd;
        isDragging = true;
        var mouseY = e.clientY;
        var scrollBarY = scrollBarRef.getBoundingClientRect().top;
        var scrollBarHeight = scrollBarRef.getBoundingClientRect().height;
        if (mouseY < scrollBarY || mouseY > scrollBarY + scrollBarHeight) {
            var delta = mouseY - (scrollBarY + scrollBarHeight / 2);
            moveScrollBarPosition(delta);
        }
        scrollContainerParentRef.children[0].classList.add('user-select-disabled');
        prevY = e.clientY;
        body.addEventListener('mousemove', function (e) { return onDrag(e); });
        body.addEventListener('mouseup', onDragEnd);
        body.addEventListener('touchmove', function (e) { return onMobileDrag(e); });
        body.addEventListener('touchend', onDragEnd);
    };
    var onDrag = function (e) {
        if (e.buttons !== MOUSE_LEFT_CLICK)
            return onDragEnd;
        if (!isDragging)
            return;
        e.preventDefault();
        var delta = e.clientY - prevY;
        prevY = e.clientY;
        moveScrollBarPosition(delta);
    };
    var onMobileDragStart = function (e) {
        e.buttons = e.targetTouches.length;
        e.clientY = e.targetTouches[0].clientY;
        onDragStart(e);
        prevTouchY = e.targetTouches[0].pageY;
    };
    var onMobileDrag = function (e) {
        e.buttons = e.targetTouches.length;
        e.clientY = e.targetTouches[0].clientY;
        onDrag(e);
        e.currentTarget = scrollContainerParentRef.children[0];
        e.deltaY = e.targetTouches[0].pageY - prevTouchY;
        preventMobileOverScroll(e);
    };
    var preventMobileOverScroll = function (e) {
        e.deltaY = e.deltaY || prevTouchY - e.targetTouches[0].pageY;
        prevTouchY = e.targetTouches[0].pageY;
        scroll_utils_1.preventOverScroll(e);
    };
    var updateScrollContainerClasses = function () {
        scrollContainerParentRef.children[0].classList.add('no-scrollbar', 'overflow-y-scroll', 'no-overscroll', 'scroll-auto', "gpu");
        scrollContainerParentRef.children[0].addEventListener("wheel", function (e) { return scroll_utils_1.preventOverScroll(e); }, { passive: false });
        scrollContainerParentRef.children[0].addEventListener("touchstart", function (e) { return prevTouchY = e.targetTouches[0].pageY; }, { passive: false });
        scrollContainerParentRef.children[0].addEventListener("touchmove", preventMobileOverScroll, { passive: false });
    };
    var onScroll = function () {
        updateScrollBarPosition();
    };
    react_1.useEffect(function () {
        body = scrollContainerParentRef;
        while (body.nodeName !== "BODY") {
            body = body.parentElement;
        }
        if (props.inEditMode) {
            scrollBarRef.parentElement.parentElement.parentElement.classList.remove('hover-scrollbar');
        }
        updateScrollContainerClasses();
        updateScrollBarPosition();
    });
    react_1.useEffect(function () {
        updateScrollContainerClasses();
        updateScrollBarPosition();
    });
    return React.createElement("div", { className: "flex flex-row items-stretch flex-auto hover-scrollbar overflow-hidden", id: props.id },
        React.createElement(color_editable_1.ColorEditable, { colorKeys: ["scrollbar_background", "scrollbar_body"], colors: props.colors, editedColors: null },
            React.createElement("div", { className: "w0_5 absolute h-100 right-0 z-1 scrollbar user-select-disabled", style: { backgroundColor: props.colors["scrollbar_background"] }, onMouseDown: onDragStart, onTouchStart: onMobileDragStart },
                React.createElement("div", { ref: function (e) { return scrollBarRef = e; }, style: { backgroundColor: props.colors["scrollbar_body"] } }))),
        React.createElement("div", { className: "flex flex-column w-100 mr0_5rem", onWheel: onScroll, onScroll: onScroll, ref: function (e) { return scrollContainerParentRef = e; } }, props.children));
}
exports.ScrollBarContainer = ScrollBarContainer;
