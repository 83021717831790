"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function arrayContentsTracker(tracked) {
    if (tracked === void 0) { tracked = undefined; }
    return function (arr) {
        if (tracked === arr)
            return false;
        try {
            if (tracked == null || arr == null)
                return true;
            if (tracked.length !== arr.length) {
                return true;
            }
            for (var i = 0; i < tracked.length; ++i) {
                if (tracked[i] !== arr[i]) {
                    return true;
                }
            }
            return false;
        }
        finally {
            tracked = arr;
        }
    };
}
exports.arrayContentsTracker = arrayContentsTracker;
function instanceTracker(last) {
    if (last === void 0) { last = undefined; }
    return function (obj) {
        if (obj === last)
            return false;
        last = obj;
        return true;
    };
}
exports.instanceTracker = instanceTracker;
function objectContentsTracker(tracked) {
    if (tracked === void 0) { tracked = undefined; }
    return function (obj) {
        if (tracked === obj)
            return false;
        try {
            if (tracked == null || obj == null)
                return true;
            for (var k in tracked) {
                if (tracked[k] !== obj[k])
                    return true;
            }
            for (k in obj) {
                if (tracked[k] !== obj[k])
                    return true;
            }
            return false;
        }
        finally {
            tracked = obj;
        }
    };
}
exports.objectContentsTracker = objectContentsTracker;
function nestedTrackersObjectTracker(trackers, tracked) {
    if (tracked === void 0) { tracked = undefined; }
    var nestedTrackers = [];
    var attributeTrackers = [];
    for (var k in trackers) {
        if (trackers[k]) {
            nestedTrackers.push([trackers[k], k]);
        }
        else {
            attributeTrackers.push(k);
        }
    }
    return function (obj) {
        if (tracked === obj)
            return false;
        try {
            var nestedTrackerResult_1 = false;
            nestedTrackers.forEach(function (_a) {
                var tracker = _a[0], k = _a[1];
                if (tracker(obj ? obj[k] : undefined))
                    nestedTrackerResult_1 = true;
            });
            if (nestedTrackerResult_1)
                return true;
            if (tracked == null || obj == null) {
                return true;
            }
            for (var _i = 0, attributeTrackers_1 = attributeTrackers; _i < attributeTrackers_1.length; _i++) {
                var k = attributeTrackers_1[_i];
                if (tracked[k] !== obj[k]) {
                    return true;
                }
            }
            return false;
        }
        finally {
            tracked = obj;
        }
    };
}
exports.nestedTrackersObjectTracker = nestedTrackersObjectTracker;
function propertyTrackersFor(t) {
    var result = {};
    for (var k in t) {
        var v = t[k];
        if (!Array.isArray(v) && typeof v == "object") {
            var empty = true;
            for (var _ in v) {
                empty = false;
                break;
            }
            if (empty) {
                result[k] = instanceTracker();
            }
            else {
                result[k] = nestedTrackersObjectTracker(propertyTrackersFor(v));
            }
        }
        else {
            result[k] = null;
        }
    }
    return result;
}
function memoize(f, tracker) {
    var called = false;
    var lastValue = undefined;
    return function () {
        if (tracker(arguments) || !called) {
            called = true;
            return lastValue = f.apply(this, arguments);
        }
        return lastValue;
    };
}
function memoizeByEachArgument(t) {
    return memoize(t, arrayContentsTracker());
}
exports.memoizeByEachArgument = memoizeByEachArgument;
function memoizeByAllProperties(t) {
    var objectTracker = objectContentsTracker();
    return memoize(t, function (args) { return objectTracker(args[0]); });
}
exports.memoizeByAllProperties = memoizeByAllProperties;
function memoizeBySomeProperties(a, f) {
    var objectTracker = nestedTrackersObjectTracker(propertyTrackersFor(a));
    return memoize(f, function (args) { return objectTracker(args[0]); });
}
exports.memoizeBySomeProperties = memoizeBySomeProperties;
