"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sizing_reducer_1 = require("../../reducers/subreducers/sizing-reducer");
function withResizeWatcher(dispatch, key) {
    window.addEventListener("resize", function () {
        dispatch(sizing_reducer_1.sizeUpdate(key, [window.innerWidth, window.innerHeight]));
    });
    return function () {
    };
}
exports.withResizeWatcher = withResizeWatcher;
