"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function createShowVideoTagRootUrl(name) {
    return {
        effectType: "create-show-video-tag-root-url",
        name: name,
    };
}
exports.createShowVideoTagRootUrl = createShowVideoTagRootUrl;
function responseShowVideoTagRootUrl(url, name) {
    return {
        type: "response-show-video-tag-root-url",
        url: url,
        name: name,
    };
}
exports.responseShowVideoTagRootUrl = responseShowVideoTagRootUrl;
function withShowVideoTag(dispatch, rootUrl) {
    if (rootUrl === void 0) { rootUrl = ""; }
    return function (effect) {
        switch (effect.effectType) {
            case "create-show-video-tag-root-url":
                dispatch(responseShowVideoTagRootUrl(rootUrl, effect.name));
                break;
        }
    };
}
exports.withShowVideoTag = withShowVideoTag;
