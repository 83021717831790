"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var resource_api_1 = require("../schemas/resource-api");
var newResource = resource_api_1.ResourceApi.newResource;
var addRelationship = resource_api_1.ResourceApi.addRelationship;
var bisect_1 = require("../utils/bisect");
var json_resources_1 = require("../core/json-resources");
var routes_1 = require("../routes");
var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
var sequence_services_1 = require("../core/services/sequence-services");
var input_debouncing_services_1 = require("../core/services/input-debouncing-services");
var google_analytics_services_1 = require("../core/services/google-analytics-services");
function suggestionClick(suggestionId, messagesData) {
    return {
        type: "suggestion-click",
        suggestionId: suggestionId,
        messagesData: messagesData
    };
}
exports.suggestionClick = suggestionClick;
function borderKnobClick() {
    return {
        type: "border-knob-click"
    };
}
exports.borderKnobClick = borderKnobClick;
function roomInputKeypressDispatcher(roomId, dispatch, sendOnShift) {
    if (sendOnShift === void 0) { sendOnShift = true; }
    return function (e) {
        if (e.key == 'Enter' && e.shiftKey == sendOnShift) {
            e.preventDefault();
            var target = e.target;
            dispatch(sendChatInputMessage(roomId, target.value));
        }
    };
}
exports.roomInputKeypressDispatcher = roomInputKeypressDispatcher;
function sendChatInputMessage(roomId, value) {
    if (value === void 0) { value = undefined; }
    return {
        type: "send-chat-input-message",
        roomId: roomId,
        value: value
    };
}
exports.sendChatInputMessage = sendChatInputMessage;
function createNewTextMessageFor(room, text) {
    var message = newResource("messages");
    message.attributes.content = text;
    addRelationship(message, "room", room);
    return message;
}
exports.createNewTextMessageFor = createNewTextMessageFor;
function createNewMessageFromSuggest(room, messagesData) {
    var message = newResource("messages");
    if (messagesData.content)
        message.attributes.content = messagesData.content;
    if (messagesData.settings)
        message.attributes.settings = messagesData.settings;
    message.attributes.kind = messagesData.kind;
    addRelationship(message, "room", room);
    return message;
}
exports.createNewMessageFromSuggest = createNewMessageFromSuggest;
function reduceChatInput(state, action) {
    var effects = [];
    switch (action.type) {
        case "send-chat-input-message":
            var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [action.roomId]);
            if (!room)
                break;
            state = tslib_1.__assign({}, state);
            effects = sequence_services_1.sequence(effects, input_debouncing_services_1.clearInputDebouncing());
            var text = action.value || state.roomInputs[action.roomId] || "";
            if (!text.trim())
                break;
            var request = json_resources_1.requestResourceWrite([exports.sendMessageRequestName, action.roomId], createNewTextMessageFor(room, text), routes_1.RapiV1MessagesPath);
            if (state.roomTemplateResponses[action.roomId]) {
                request.config.json.is_template_message = state.roomTemplateResponses[action.roomId] === text;
                state.roomTemplateResponses = tslib_1.__assign({}, state.roomTemplateResponses);
                delete state.roomTemplateResponses[action.roomId];
                state.inputs = tslib_1.__assign({}, state.inputs);
                state.inputs.templateResponsesModalSelectedId = "";
                state.inputs.templateResponseSearch = "";
            }
            effects = sequence_services_1.sequence(effects, request);
            state.roomInputs = tslib_1.__assign({}, state.roomInputs);
            state.roomInputs[action.roomId] = "";
            state.isMessageSendingByRoom = tslib_1.__assign({}, state.isMessageSendingByRoom);
            state.isMessageSendingByRoom[action.roomId] = true;
            effects = sequence_services_1.sequence(effects, google_analytics_services_1.sendGoogleAnalyticsEvent('Send WidgetMessage by Operator', 'ok-sky_chat', state.appSettings.name));
            break;
        case "complete-request":
            if (action.name[0] == exports.sendMessageRequestName) {
                var roomId = action.name[1];
                state = tslib_1.__assign({}, state);
                state.isMessageSendingByRoom = tslib_1.__assign({}, state.isMessageSendingByRoom);
                delete state.isMessageSendingByRoom[roomId];
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                if (!responseDetails.resources || responseDetails.resources.length == 0) {
                    state.toggles = tslib_1.__assign({}, state.toggles);
                    if (action.status === 429) {
                        state.toggles.showDuplicateMessageModal = true;
                    }
                    else {
                        state.toggles.showDraftMessageAlert = true;
                    }
                }
            }
            break;
        case "suggestion-click":
            var suggestion = bisect_1.Indexer.getFirstMatching(state.stores.suggestions.byId, [action.suggestionId]);
            if (!suggestion)
                break;
            var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [getRelationshipId(suggestion, "room")]);
            if (!room)
                break;
            state = tslib_1.__assign({}, state);
            switch (action.messagesData.kind) {
                case "text":
                    state.roomInputs = tslib_1.__assign({}, state.roomInputs);
                    state.roomInputs[getRelationshipId(suggestion, "room")] = action.messagesData.content;
                    break;
                default:
                    var newSuggestionMessage = createNewMessageFromSuggest(room, action.messagesData);
                    var writeRequest = json_resources_1.requestResourceWrite([exports.sendMessageRequestName, room.id], newSuggestionMessage, routes_1.RapiV1MessagesPath);
                    effects = sequence_services_1.sequence(effects, writeRequest);
                    break;
            }
            break;
        case "border-knob-click":
            state = tslib_1.__assign({}, state);
            state.customersPage = tslib_1.__assign({}, state.customersPage);
            state.customersPage.borderKnobExpandSize = state.customersPage.borderKnobExpandSize === "min" ? "max" : "min";
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceChatInput = reduceChatInput;
exports.sendMessageRequestName = "send-message-request-name";
