"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var json_resources_1 = require("../core/json-resources");
var operator_state_1 = require("./operator-state");
var resource_api_1 = require("../schemas/resource-api");
var json_resources_2 = require("../core/json-resources");
var routes_1 = require("../routes");
var operatorsPageInputs = operator_state_1.OperatorState.operatorsPageInputs;
var resource_page_common_reducer_1 = require("./resource-page-common-reducer");
var operatorsPageToggles = operator_state_1.OperatorState.operatorsPageToggles;
var bisect_1 = require("../utils/bisect");
var newResource = resource_api_1.ResourceApi.newResource;
var toast_reducer_1 = require("./toast-reducer");
var I18n_1 = require("../I18n");
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var email_valid_1 = require("../utils/email-valid");
var ajax_services_1 = require("../core/services/ajax-services");
var sequence_services_1 = require("../core/services/sequence-services");
var upload_services_1 = require("../core/services/upload-services");
var logout_services_1 = require("../core/services/logout-services");
function saveUser() {
    return { type: "save-user" };
}
exports.saveUser = saveUser;
function uploadUserImage(files) {
    return {
        type: "upload-user-image",
        files: files
    };
}
exports.uploadUserImage = uploadUserImage;
function regenerateAccessToken() {
    return {
        type: "regenerate-access-token"
    };
}
exports.regenerateAccessToken = regenerateAccessToken;
function updateIsActive(userIds, isActive) {
    return { type: "update-is-active", userIds: userIds, isActive: isActive };
}
exports.updateIsActive = updateIsActive;
function operatorPageLoad(state, sort, searchString, requestName) {
    if (sort === void 0) { sort = "name"; }
    var effects = [];
    var query = {};
    query.filter = { is_customer: 0, name_like: searchString || state.inputs.operatorsPageSearchInput };
    query.sort = [(state.toggles.resourcePageReverseOrder ? "-" : "") + sort];
    var name = requestName || [exports.operatorsPageLoadingRequestName];
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch(name, routes_1.RapiV1UsersPath, query));
    return { state: state, effects: effects };
}
exports.operatorPageLoad = operatorPageLoad;
function validateEditUser(state) {
    if (!state.inputs.operatorUserType ||
        !(parseInt(state.inputs.operatorMaxSupportCount, 10) >= 0) ||
        (state.resourcePageCommonState.currentEditingIds.length === 0 && isOperatorEmailDuplicate(state)) ||
        (state.resourcePageCommonState.currentEditingIds.length === 0 && isOperatorNameDuplicate(state))) {
        return false;
    }
    if (state.resourcePageCommonState.currentEditingIds.length > 0)
        return true;
    return email_valid_1.emailValid(state.inputs.operatorEmail) && !!state.inputs.operatorName;
}
exports.validateEditUser = validateEditUser;
function isOperatorFullNameDuplicate(state) {
    var operatorFullNameDuplicates = bisect_1.Indexer.getAllMatching(state.stores.users.byNotCustomerAndFullName, [true, state.inputs.operatorDisplayName]);
    if (operatorFullNameDuplicates.length === 1) {
        return operatorFullNameDuplicates[0].attributes.uuid != state.inputs.operatorUuid;
    }
    return operatorFullNameDuplicates.length > 0 && operatorFullNameDuplicates.length != 1;
}
exports.isOperatorFullNameDuplicate = isOperatorFullNameDuplicate;
function isOperatorNameDuplicate(state) {
    return !!bisect_1.Indexer.getFirstMatching(state.stores.users.byName, [state.inputs.operatorName]);
}
exports.isOperatorNameDuplicate = isOperatorNameDuplicate;
function isOperatorEmailDuplicate(state) {
    return !!bisect_1.Indexer.getFirstMatching(state.stores.users.byEmail, [state.inputs.operatorEmail]);
}
exports.isOperatorEmailDuplicate = isOperatorEmailDuplicate;
function createUserData(state, user) {
    var newUser = newResource("users");
    if (user) {
        newUser.id = user.id;
        newUser.attributes.timezone = state.inputs.operatorTimezone || user.attributes.timezone;
        newUser.attributes.user_type = state.inputs.operatorUserType || user.attributes.user_type;
        newUser.attributes.full_name = state.inputs.operatorDisplayName;
        if (parseInt(state.inputs.operatorMaxSupportCount) >= 0) {
            newUser.attributes.max_support_count = parseInt(state.inputs.operatorMaxSupportCount);
        }
        else {
            newUser.attributes.max_support_count = user.attributes.max_support_count;
        }
    }
    else {
        newUser.attributes.name = state.inputs.operatorName;
        newUser.attributes.email = state.inputs.operatorEmail;
        newUser.attributes.user_type = state.inputs.operatorUserType;
        newUser.attributes.full_name = state.inputs.operatorDisplayName;
        newUser.attributes.max_support_count = parseInt(state.inputs.operatorMaxSupportCount);
    }
    newUser.attributes.assign_priority = parseInt(state.inputs.operatorAssignPriority);
    newUser.attributes.in_training = state.toggles.operatorInTraining;
    newUser.attributes.avatar_url = state.inputs.operatorAvatarUrl;
    newUser.attributes.language = state.inputs.operatorLanguage;
    newUser.attributes.sound_notification = state.toggles.operatorSoundNotification;
    newUser.attributes.sound_notification_type = parseInt(state.inputs.operatorSoundNotificationType);
    return newUser;
}
function reduceOperatorPage(state, action) {
    var _a, _b, _c, _d, _e, _f;
    var effects = [];
    var ids = state.resourcePageCommonState.currentEditingIds;
    var language = state.loggedInUser.attributes.language;
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.createUserRequestName) {
                if (action.success) {
                    state = tslib_1.__assign({}, state);
                    state.toggles = tslib_1.__assign({}, state.toggles);
                    state.toggles.showUserCreatedModal = true;
                }
            }
            if (action.name[0] === exports.operatorsPageLoadingRequestName) {
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                state = tslib_1.__assign({}, state);
                state.operatorsPage = tslib_1.__assign({}, state.operatorsPage);
                if (state.operatorsPage.numberOfOperators === 0 && apiResponse.meta) {
                    state.operatorsPage.numberOfOperators = apiResponse.meta.record_count;
                }
            }
            break;
        case "input-change":
            if (action.target === "operatorsPageSearchInput") {
                (_a = sequence_services_1.sequenceReduction(effects, operatorPageLoad(state)), state = _a.state, effects = _a.effects);
            }
            else if (action.target === "operatorEmail") {
                (_b = sequence_services_1.sequenceReduction(effects, operatorPageLoad(state, "id", state.inputs.operatorEmail, [exports.operatorsPageValidationRequestName])), state = _b.state, effects = _b.effects);
            }
            else if (action.target === "operatorName") {
                (_c = sequence_services_1.sequenceReduction(effects, operatorPageLoad(state, "id", state.inputs.operatorName, [exports.operatorsPageValidationRequestName])), state = _c.state, effects = _c.effects);
            }
            else if (action.target === "operatorsPageOrderBy") {
                (_d = sequence_services_1.sequenceReduction(effects, operatorPageLoad(state, state.inputs.operatorsPageOrderBy)), state = _d.state, effects = _d.effects);
            }
            break;
        case "regenerate-access-token":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.showRegenerateUserConfirmModal = false;
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourcePut([exports.regenerateAccessTokenRequestName], routes_1.RapiV1UsersPath + "/" + state.loggedInUser.id + "/regenerate_access_token"));
            effects = sequence_services_1.sequence(effects, logout_services_1.logout());
            break;
        case "visit":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles, operatorsPageToggles);
            state.inputs = tslib_1.__assign({}, state.inputs, operatorsPageInputs);
            break;
        case "upload-success":
            if (action.name[0] === exports.userImageUploadRequestName) {
                state = tslib_1.__assign({}, state);
                state.inputs = tslib_1.__assign({}, state.inputs);
                state.inputs.operatorAvatarUrl = action.cloudinaryResponse.secure_url;
            }
            break;
        case "begin-edit-resource":
            if (action.resourceType !== "users")
                break;
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs, operatorsPageInputs);
            state.toggles = tslib_1.__assign({}, state.toggles, operatorsPageToggles);
            if (ids.length == 1) {
                var user = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [ids[0]]);
                if (user) {
                    state.inputs.operatorMaxSupportCount = user.attributes.max_support_count + "";
                    state.inputs.operatorAssignPriority = user.attributes.assign_priority + "";
                    state.inputs.operatorDisplayName = user.attributes.full_name;
                    state.inputs.operatorTimezone = user.attributes.timezone;
                    state.inputs.operatorName = user.attributes.name;
                    state.inputs.operatorEmail = user.attributes.email;
                    state.inputs.operatorUserType = user.attributes.user_type;
                    state.inputs.operatorUuid = user.attributes.uuid;
                    state.inputs.operatorAvatarUrl = user.attributes.avatar_url;
                    state.toggles.operatorInTraining = user.attributes.in_training;
                    state.inputs.operatorLanguage = user.attributes.language;
                    state.toggles.operatorSoundNotification = user.attributes.sound_notification;
                    if (user.attributes.sound_notification_type) {
                        state.inputs.operatorSoundNotificationType = user.attributes.sound_notification_type.toString();
                    }
                    else {
                        state.inputs.operatorSoundNotificationType = '1';
                    }
                }
            }
            break;
        case "begin-create-resource":
            if (action.resourceType !== "users")
                break;
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs, operatorsPageInputs);
            state.toggles = tslib_1.__assign({}, state.toggles, operatorsPageToggles);
            break;
        case "save-user":
            if (state.editingOperatorValid) {
                if (ids.length) {
                    for (var _i = 0, ids_1 = ids; _i < ids_1.length; _i++) {
                        var id = ids_1[_i];
                        var user = bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [id]);
                        if (user) {
                            var userData = createUserData(state, user);
                            effects = sequence_services_1.sequence(effects, json_resources_2.requestResourceWrite([exports.updateUserRequestName, id], userData, routes_1.RapiV1UsersPath + "/" + id));
                        }
                        else {
                            console.error("user doesn't exist");
                        }
                    }
                }
                else {
                    var userData = createUserData(state, null);
                    effects = sequence_services_1.sequence(effects, json_resources_2.requestResourceWrite([exports.createUserRequestName], userData, routes_1.RapiV1UsersPath));
                }
                state = resource_page_common_reducer_1.reduceFinishEditingResource(state);
                (_e = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastOperatorPageMembersSaved[language])), state = _e.state, effects = _e.effects);
            }
            break;
        case "update-is-active":
            state = tslib_1.__assign({}, state);
            var config = {
                headers: json_resources_1.jsonResourceHeaders,
                url: routes_1.RapiV1UsersPath + "/", method: "PATCH", json: { user_ids: action.userIds }
            };
            config.url += action.isActive ? "reactivate" : "deactivate";
            effects = sequence_services_1.sequence(effects, ajax_services_1.requestAjax([exports.updateUserActiveRequestName], config));
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.operatorDeactivateModal = false;
            var content = action.isActive ? I18n_1.i18n.feedbackToastOperatorActivated[language] : I18n_1.i18n.feedbackToastOperatorDeactivated[language];
            (_f = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, content)), state = _f.state, effects = _f.effects);
            break;
        case "upload-user-image":
            effects = sequence_services_1.sequence(effects, upload_services_1.requestUpload([exports.userImageUploadRequestName], action.files));
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceOperatorPage = reduceOperatorPage;
exports.operatorsPageValidationRequestName = "operators-page-validation-request-name";
exports.operatorsPageLoadingRequestName = "operators-page-loading-request-name";
exports.operatorsPagePagedRequestNames = [exports.operatorsPageLoadingRequestName];
exports.regenerateAccessTokenRequestName = "regenerate-user-access-token-request-name";
exports.userImageUploadRequestName = "user-image-upload-request";
exports.createUserRequestName = "create-user-request";
exports.updateUserRequestName = "update-user-request";
exports.updateUserActiveRequestName = "update-user-active-request";
