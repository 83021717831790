"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var subject_1 = require("./subject");
var style_utils_1 = require("../utils/style-utils");
function generateRootElement(rootElementId) {
    return {
        subscribe: function (dispatch) {
            var subscription = new subject_1.Subscription();
            function createElement() {
                if (document.readyState !== 'complete') {
                    return;
                }
                subscription.unsubscribe();
                var rootElement;
                if (rootElementId) {
                    rootElement = document.getElementById(rootElementId);
                }
                else {
                    rootElement = document.createElement("DIV");
                    document.body.appendChild(rootElement);
                    style_utils_1.setOKSKYStyleClassToRoot(document.body.parentElement);
                    style_utils_1.appendOKSKYStyleToHead(document.head);
                }
                subscription.add(function () { return rootElement.remove(); });
                dispatch(rootElement);
            }
            if (document.readyState === 'complete') {
                createElement();
            }
            else {
                document.addEventListener('readystatechange', createElement);
                subscription.add(function () { return document.removeEventListener('readystatechange', createElement); });
            }
            return subscription.unsubscribe;
        }
    };
}
exports.generateRootElement = generateRootElement;
