"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
function dismissFlash(target) {
    return { type: "dismiss-flash", target: target };
}
exports.dismissFlash = dismissFlash;
function createFlash(level, hideAt, message) {
    if (message === void 0) { message = ""; }
    var flash = {};
    flash.level = level;
    flash.message = message;
    flash.hideAt = hideAt;
    return flash;
}
exports.createFlash = createFlash;
function reduceFlash(flashes, a) {
    var effects = [];
    switch (a.type) {
        case "update-time":
            for (var k in flashes) {
                var flash = flashes[k];
                if (flash && flash.hideAt && a.absoluteTime >= flash.hideAt) {
                    flashes = tslib_1.__assign({}, flashes);
                    delete flashes[k];
                }
            }
            break;
        case "dismiss-flash":
            var flashState = flashes[a.target];
            if (flashState) {
                flashes = tslib_1.__assign({}, flashes);
                delete flashes[a.target];
            }
            break;
    }
    return { state: flashes, effects: effects };
}
exports.reduceFlash = reduceFlash;
