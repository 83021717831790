"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCloudinaryResizeUrl = function (url, width, height, options) {
    if (url == null)
        return url;
    var parts = url.split("/");
    var i = parts.indexOf("upload");
    if (i !== -1) {
        parts.splice(i + 1, 0, "w_" + width + (height ? ",h_" + height : '') + (options ? "," + options + "/" : ""));
        return parts.join("/");
    }
    else {
        return url;
    }
};
