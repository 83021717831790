"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WIDGET_V2_TRACKER_CHAT_OPEN = 'oksky-chat-widget-v2-tracker-chat-open';
exports.WIDGET_V2_TRACKER_CHAT_CLOSE = 'oksky-chat-widget-v2-tracker-chat-close';
exports.WIDGET_V2_TRACKER_CHAT_HIDDEN = 'oksky-chat-widget-v2-tracker-chat-hidden';
exports.WIDGET_V2_TRACKER_CHAT_EVENT = 'oksky-chat-widget-v2-tracker-chat-event';
var WIDGET_ID = 'widget-canvas-iframe';
var clickFlgList = [];
var isApiLoaded = false;
var apiReadyCallbacks = [];
function chatEventTracker(action, link, messageId, videoTime, roomId, clientId, clientCode) {
    if (link === void 0) { link = null; }
    if (messageId === void 0) { messageId = null; }
    if (videoTime === void 0) { videoTime = null; }
    if (roomId === void 0) { roomId = null; }
    if (clientId === void 0) { clientId = null; }
    if (clientCode === void 0) { clientCode = null; }
    dispatchEvent(exports.WIDGET_V2_TRACKER_CHAT_EVENT, { action: action, link: link, messageId: messageId, roomId: roomId, clientId: clientId, clientCode: clientCode, videoTime: videoTime });
}
exports.chatEventTracker = chatEventTracker;
function dispatchEvent(target, detail) {
    if (detail === void 0) { detail = {}; }
    document.dispatchEvent(new CustomEvent(target, { detail: detail }));
}
exports.dispatchEvent = dispatchEvent;
function eventClickHandler(event) {
    var target = event.target;
    var iframeDocument = getIframeDocument();
    if (iframeDocument === null || !target || !iframeDocument.contains(target)) {
        return;
    }
    handleLinkClick(target);
}
exports.eventClickHandler = eventClickHandler;
function eventKeyDownHandler(e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
        var target = document.activeElement;
        var iframeDocument = getIframeDocument();
        if (iframeDocument === null || !target || !iframeDocument.contains(target)) {
            return;
        }
        handleLinkClick(target);
    }
}
exports.eventKeyDownHandler = eventKeyDownHandler;
function getIframeDocument() {
    var iframe = document.getElementById(WIDGET_ID);
    if (iframe) {
        if (iframe.contentDocument) {
            return iframe.contentDocument;
        }
        else if (iframe.contentWindow && iframe.contentWindow.document) {
            return iframe.contentWindow.document;
        }
    }
    return null;
}
function handleLinkClick(target) {
    if (target.tagName === 'A') {
        var href = target.getAttribute('href');
        if (href) {
            var checkId = target.getAttribute('data-link-action-id') + "-" + href;
            var checkIds = checkId.split("-");
            if (checkIds.length >= 4) {
                var messageId = checkIds[3];
                chatEventTracker('link', href, Number(messageId));
            }
        }
    }
}
function videoPlayHandler(event) {
    videoTrackerSend('video-tag-start', event.currentTarget);
}
exports.videoPlayHandler = videoPlayHandler;
function videoPauseHandler(event) {
    if (!event.currentTarget.ended) {
        videoTrackerSend('video-tag-pause', event.currentTarget);
    }
}
exports.videoPauseHandler = videoPauseHandler;
function videoEndedHandler(event) {
    videoTrackerSend('video-tag-end', event.currentTarget);
    var checkId = event.currentTarget.getAttribute('data-video-tag-action-id');
    clickFlgList.push(checkId);
}
exports.videoEndedHandler = videoEndedHandler;
function videoOnerrorHandler(event) {
    videoTrackerSend('video-tag-error', event.currentTarget);
}
exports.videoOnerrorHandler = videoOnerrorHandler;
function videoTrackerSend(action, target) {
    var checkId = target.getAttribute('data-video-tag-action-id');
    if (clickFlgList.includes(checkId)) {
        return;
    }
    if (checkId === undefined || checkId === null) {
        return;
    }
    var checkIds = checkId.split("-");
    if (checkIds.length >= 5) {
        var messageId = checkIds[4];
        chatEventTracker(action, null, Number(messageId), target.currentTime);
    }
}
function youtubeTrackerSend(action, dataActionId, time) {
    var checkId = dataActionId;
    if (clickFlgList.includes(checkId)) {
        return;
    }
    if (action === 'youtube-end') {
        clickFlgList.push(checkId);
    }
    var checkIds = checkId.split("-");
    if (checkIds.length >= 4) {
        var messageId = checkIds[3];
        chatEventTracker(action, null, Number(messageId), time);
    }
}
exports.youtubeTrackerSend = youtubeTrackerSend;
function getIframeWindow() {
    var iframe = document.getElementById(WIDGET_ID);
    if (iframe === undefined || iframe === null ||
        iframe.contentWindow === undefined || iframe.contentWindow === null) {
        return null;
    }
    return iframe.contentWindow;
}
exports.getIframeWindow = getIframeWindow;
function loadYouTubeApi() {
    return new Promise(function (resolve) {
        if (isApiLoaded) {
            resolve();
            return;
        }
        var iframeWindow = getIframeWindow();
        if (!iframeWindow || iframeWindow === null || !iframeWindow.document || iframeWindow.document === null) {
            resolve();
            return;
        }
        if (iframeWindow.YT && iframeWindow.YT.Player) {
            isApiLoaded = true;
            resolve();
            return;
        }
        apiReadyCallbacks.push(resolve);
        if (!iframeWindow.document.getElementById('youtube-api')) {
            var iframeDoc = iframeWindow.document;
            var script = document.createElement('script');
            script.src = 'https://www.youtube.com/iframe_api';
            script.id = 'youtube-api';
            iframeDoc.head.appendChild(script);
        }
        iframeWindow.onYouTubeIframeAPIReady = function () {
            isApiLoaded = true;
            apiReadyCallbacks.forEach(function (callback) { return callback(); });
            apiReadyCallbacks = [];
        };
    });
}
exports.loadYouTubeApi = loadYouTubeApi;
function handlePlayerStateChange(event, dataActionId) {
    var iframeWindow = getIframeWindow();
    if (!iframeWindow || !iframeWindow.YT)
        return;
    var player = event.target;
    var currentTime = player.getCurrentTime();
    if (event.data === iframeWindow.YT.PlayerState.PLAYING) {
        youtubeTrackerSend('youtube-start', dataActionId, currentTime);
    }
    else if (event.data === iframeWindow.YT.PlayerState.PAUSED) {
        youtubeTrackerSend('youtube-pause', dataActionId, currentTime);
    }
    else if (event.data === iframeWindow.YT.PlayerState.ENDED) {
        youtubeTrackerSend('youtube-end', dataActionId, currentTime);
        player.seekTo(0);
        player.playVideo();
    }
}
exports.handlePlayerStateChange = handlePlayerStateChange;
