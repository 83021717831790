"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var layouts_1 = require("../../components/layouts");
var scroll_bar_container_1 = require("../../components/scroll-bar-container");
var React = require("react");
var widget_state_1 = require("../reducers/widget-state");
var fancy_checkbox_1 = require("../../components/fancy-checkbox");
var toggles_reducer_1 = require("../../reducers/subreducers/toggles-reducer");
var I18n_1 = require("../../I18n");
var two_column_layout_1 = require("../../components/two-column-layout");
var debouncing_inputs_1 = require("../../components/debouncing-inputs");
var inputs_reducer_1 = require("../../reducers/subreducers/inputs-reducer");
var settings_page_reducer_1 = require("../reducers/settings-page-reducer");
var memoizers_1 = require("../../utils/memoizers");
var key_navigation_utils_1 = require("../../utils/key-navigation-utils");
var color_editable_1 = require("../../components/color-editable");
var select_single_1 = require("../../components/select-single");
var operators_full_screen_modal_1 = require("../../views/operators-page/operators-full-screen-modal");
var SettingsPage;
(function (SettingsPage) {
    function render(dispatch) {
        var dispatcher = {
            saveWidgetSettings: function () { return dispatch(settings_page_reducer_1.saveWidgetSettings()); },
        };
        return memoizers_1.memoizeBySomeProperties({
            loggedInUser: widget_state_1.WidgetV2State.initialState.loggedInUser,
            colors: widget_state_1.WidgetV2State.initialState.colors,
            fakeWidget: widget_state_1.WidgetV2State.initialState.fakeWidget,
            errorMessages: widget_state_1.WidgetV2State.initialState.errorMessages,
            inputs: widget_state_1.WidgetV2State.initialState.inputs,
            toggles: widget_state_1.WidgetV2State.initialState.toggles,
            collect_emails_when_not_during_business_hours: widget_state_1.WidgetV2State.initialState.collect_emails_when_not_during_business_hours,
        }, function (state) {
            var language = state.loggedInUser.attributes.language;
            var hasErrors = Object.keys(state.errorMessages.widgetSettingsPage).filter(function (k) {
                return state.errorMessages.widgetSettingsPage[k];
            }).length > 0;
            var languageValues = ["en", "ja"];
            return React.createElement(layouts_1.Column, { stretchColumn: true, className: "w-100 bblr3 bbrr3 dt test--settings-page" },
                React.createElement(scroll_bar_container_1.ScrollBarContainer, { colors: state.colors, inEditMode: state.fakeWidget.inEditMode },
                    React.createElement("div", { className: "min-h-100 pv3 pl2 overflow-y-scroll" },
                        React.createElement(two_column_layout_1.TwoColumnLayout, null,
                            React.createElement("div", { className: "test--enable-gathering-email " + (state.collect_emails_when_not_during_business_hours ? "" : "dn") },
                                React.createElement(layouts_1.Row, null,
                                    React.createElement("div", { className: "dib pt5 mla mra" },
                                        React.createElement(debouncing_inputs_1.DebouncingInput, { placeholder: I18n_1.i18n.email[language], tabIndex: 0, className: "br2 pa2 ba b--moon-gray", size: 32, maxLength: 254, defaultValue: state.inputs.userEmailInput, value: state.inputs.userEmailInput, type: "email", onChange: inputs_reducer_1.inputChangeDispatcher(dispatch, "userEmailInput"), style: { outlineColor: state.colors.outline } }))),
                                React.createElement(two_column_layout_1.TwoColumnLayoutRow, { className: "flex flex-row " + (state.inputs.userEmailInput ? "" : "o-60") },
                                    React.createElement(color_editable_1.ColorEditable, { className: "dib ma", tabIndex: 0, onKeyDown: function (e) { return key_navigation_utils_1.isEnterOrSpace(e) && state.inputs.userEmailInput ? toggles_reducer_1.toggleDispatcher(dispatch, "getEmailUpdates")() : null; }, colors: state.colors, editedColors: {
                                            outlineColor: "outline",
                                        } },
                                        React.createElement("div", { className: "dib pv3 pr3" },
                                            React.createElement(fancy_checkbox_1.FancyCheckbox, { onToggle: state.inputs.userEmailInput ? toggles_reducer_1.toggleDispatcher(dispatch, "getEmailUpdates") : null, disabled: !state.inputs.userEmailInput, checked: state.toggles.getEmailUpdates, tabIndex: -1 })),
                                        React.createElement(color_editable_1.ColorEditable, { className: "dib mv3 fw6  pointer", colors: state.colors, editedColors: {
                                                color: "settings_text",
                                            } }, I18n_1.i18n.widgetGetEmailUpdates[language]))),
                                React.createElement(color_editable_1.ColorEditable, { colors: state.colors, className: "ma w-70 f4", editedColors: {
                                        color: "settings_subtext",
                                    } }, I18n_1.i18n.widgetCollectEmailDescription[language]),
                                state.errorMessages.widgetSettingsPage.customerNotificationEmail && React.createElement(color_editable_1.ColorEditable, { colors: state.colors, className: "red mt3 mla mra w-30 right-0 left-0", editedColors: {
                                        color: "collect_emails_error",
                                    } }, I18n_1.i18n.emailInvalidErrorMessage[language])),
                            React.createElement("div", { className: "w-70 ma bb bb--light-gray" },
                                React.createElement("div", { className: " dib pv4 fw6" }, I18n_1.i18n.operatorModalLanguage[language]),
                                React.createElement("div", { className: "dib pv4 w-50 ml3" },
                                    React.createElement(color_editable_1.ColorEditable, { className: "dib ma w-100", colors: state.colors, editedColors: {
                                            outlineColor: "outline",
                                        } },
                                        React.createElement(select_single_1.SelectSingle, { labeler: operators_full_screen_modal_1.languageLabeler, values: languageValues, value: state.inputs.customerLanguage, style: { outlineColor: state.colors.outline }, onChange: function (language) { return dispatch(inputs_reducer_1.inputChange("customerLanguage", language)); }, hidePlaceholder: true }))))))),
                React.createElement(color_editable_1.ColorEditable, { className: "absolute bottom-1 right-0 left-0 dtc v-mid w-30 center h3 f4 fw1 ba br3 " + (hasErrors ? "o-70" : "pointer dim"), colors: state.colors, editedColors: {
                        backgroundColor: "save_settings_button_background",
                        fill: "save_settings_button_text",
                        color: "save_settings_button_text",
                        outlineColor: "outline",
                    }, onClick: function () {
                        if (!hasErrors) {
                            dispatcher.saveWidgetSettings();
                        }
                    }, onKeyDown: function (e) {
                        if (!hasErrors && key_navigation_utils_1.isEnterOrSpace(e)) {
                            dispatcher.saveWidgetSettings();
                        }
                    }, tabIndex: 0 },
                    React.createElement("div", { className: "truncate tc pa3" }, I18n_1.i18n.saveMessage[language])));
        });
    }
    SettingsPage.render = render;
})(SettingsPage = exports.SettingsPage || (exports.SettingsPage = {}));
