"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lastId = -1;
function genLocalId(prefix) {
    if (prefix === void 0) { prefix = ""; }
    return prefix + (++lastId);
}
exports.genLocalId = genLocalId;
function genTime() {
    return sequenceNum() * 1000;
}
exports.genTime = genTime;
function sequenceNum() {
    return ++lastId;
}
exports.sequenceNum = sequenceNum;
function genUuid() {
    var d = Date.now();
    if (window.performance && typeof window.performance.now === "function") {
        d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}
exports.genUuid = genUuid;
