"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var chat_list_message_1 = require("./chat-list-message");
var formats_1 = require("./formats");
var svgs_1 = require("./svgs");
function FileMessageContent(props) {
    var file = props.message.attributes.settings;
    if (!file)
        return null;
    var showShareButton = (typeof props.startFileMessageShare === 'function');
    return React.createElement("div", { className: "pa3 bg-white br2 dib w-100" },
        React.createElement("div", { className: "fl" },
            React.createElement("div", { className: "fill-bright-blue dib" },
                React.createElement(svgs_1.SVGDocuments, { width: 30, height: 30 })),
            React.createElement("div", { className: "dib pl2" },
                React.createElement("div", { className: "fw7 f3 pa1" }, file.filename),
                React.createElement("div", { className: "light-silver pa1" }, formats_1.humanFileSize(file.size)))),
        React.createElement("div", { className: "hide-child tr overflow-hidden gpu" },
            showShareButton && React.createElement(chat_list_message_1.ToolButton, { className: "ma2", onClick: function () { return props.startFileMessageShare(props.message.id); } },
                React.createElement(svgs_1.SVGShare, { width: 16, height: 16 })),
            React.createElement("a", { href: file.url, download: true },
                React.createElement(chat_list_message_1.ToolButton, { className: "ma2" },
                    React.createElement(svgs_1.SVGDownload, { width: 16, height: 16 })))));
}
exports.FileMessageContent = FileMessageContent;
