"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var debouncing_inputs_1 = require("./debouncing-inputs");
var I18n_1 = require("../I18n");
var string_utils_1 = require("../utils/string-utils");
function TextMessageContent(props) {
    var isDisabled = (props.curEditingMessageText === "") || (props.curEditingMessageText === props.message.attributes.content);
    return React.createElement("div", { key: "text-message-content-key-" + props.message.id }, props.isEditing ?
        React.createElement("div", null,
            React.createElement(debouncing_inputs_1.DebouncingTextArea, { onChange: props.onChange, className: "rv w-100 ba b--moon-gray br2 b--light-blue--hover test--edit-text-area", value: props.curEditingMessageText, id: "edit-text-area" }),
            React.createElement("button", { className: "dib br2 bg-silver fw7 f4 pa2 gray ba b--moon-gray w4 tc hover-light-blue test--edit-cancel", onClick: function () { return props.onCancelEdit(props.message.id); } }, I18n_1.i18n.cancelMessage[props.language]),
            React.createElement("button", { className: "dib br2  fw7 f4 pa2 white ba b--moon-gray ml2 w4 tc  test--edit-save " + (isDisabled ? 'bg-light-gray hover-dark-gray' : 'bg-light-blue'), onClick: function () { return props.onFinishEdit(props.message.id, props.curEditingMessageText); }, disabled: isDisabled }, I18n_1.i18n.saveMessage[props.language]))
        :
            React.createElement("div", { className: "span word-wrap test--text-message", key: "text-message-content-content-" + props.message.id }, string_utils_1.highlightString(props.message.attributes.content && props.message.attributes.content.trim(), props.highlightedText, "fw7 bg-lightest-blue", props.message.id, "#2ea7e0")));
}
exports.TextMessageContent = TextMessageContent;
