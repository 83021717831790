"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var json_resources_1 = require("../core/json-resources");
var routes_1 = require("../routes");
var users_util_1 = require("../utils/users-util");
var tenant_modal_reducer_1 = require("./tenant-modal-reducer");
var resource_api_1 = require("../schemas/resource-api");
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var sequence_services_1 = require("../core/services/sequence-services");
var navigation_services_1 = require("../core/services/navigation-services");
var action_cable_services_1 = require("../core/services/action-cable-services");
function reduceInitialLoading(state, action) {
    var effects = [];
    switch (action.type) {
        case "send-online-status-action":
            var status = state.loggedInUser.attributes.status;
            if (status === "offline") {
                status = "available";
                state = tslib_1.__assign({}, state);
                state.loggedInUser.attributes.status = status;
            }
            effects = sequence_services_1.sequence(effects, action_cable_services_1.actionCableSendMessage("UserStatusChannel", "indicate_online", { "status": status }));
            break;
        case "complete-request":
            if (roomCountsRequestNames.indexOf(action.name[0]) !== -1) {
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                if (!responseDetails.resources)
                    break;
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                state = tslib_1.__assign({}, state);
                if (action.name[0] === exports.getRoomCountsRequestName) {
                    for (var i = 0; i < responseDetails.resources.length; i++) {
                        var resource = responseDetails.resources[i];
                        if (resource && resource.type === "room_counts") {
                            if (!state.roomCounts) {
                                state.roomCounts = {};
                            }
                            var originalReadOnly = state.roomCounts.read_only;
                            state.roomCounts = tslib_1.__assign({}, state.roomCounts, resource.attributes);
                            state.roomCounts.read_only = tslib_1.__assign({}, originalReadOnly, resource.attributes.read_only);
                        }
                    }
                    break;
                }
                else if (apiResponse.meta) {
                    if (!state.roomCounts) {
                        state.roomCounts = {};
                        state.roomCounts.read_only = {};
                    }
                    state.roomCounts = tslib_1.__assign({}, state.roomCounts);
                    state.roomCounts.read_only = tslib_1.__assign({}, state.roomCounts.read_only);
                    switch (action.name[0]) {
                        case exports.loadAllUnRepliedSupportsRequestName:
                            state.roomCounts.read_only.total_unreplied_support_count = apiResponse.meta.record_count;
                            break;
                        case exports.loadAllRepliedSupportsRequestName:
                            state.roomCounts.read_only.total_replied_support_count = apiResponse.meta.record_count;
                            break;
                        case exports.loadUnRepliedOperatorsSupportsRequestName:
                            if (!state.roomCounts.read_only.unreplied_support_count_by_user)
                                state.roomCounts.read_only.unreplied_support_count_by_user = {};
                            state.roomCounts.read_only.unreplied_support_count_by_user = tslib_1.__assign({}, state.roomCounts.read_only.unreplied_support_count_by_user);
                            var supporterId = action.name[1];
                            state.roomCounts.read_only.unreplied_support_count_by_user[supporterId] = apiResponse.meta.record_count;
                            break;
                        case exports.loadRepliedOperatorsSupportsRequestName:
                            if (!state.roomCounts.read_only.replied_support_count_by_user)
                                state.roomCounts.read_only.replied_support_count_by_user = {};
                            state.roomCounts.read_only.replied_support_count_by_user = tslib_1.__assign({}, state.roomCounts.read_only.replied_support_count_by_user);
                            var supporterId = action.name[1];
                            state.roomCounts.read_only.replied_support_count_by_user[supporterId] = apiResponse.meta.record_count;
                            break;
                        case exports.loadAwaitingSupportRoomsRequestName:
                            state.roomCounts.read_only.awaiting_support_count = apiResponse.meta.record_count;
                            break;
                        case exports.loadPrivateRoomsRequestName:
                            state.roomCounts.read_only.private_room_count = apiResponse.meta.record_count;
                            break;
                    }
                }
            }
            else if (action.name[0] === tenant_modal_reducer_1.tenantsLoadingRequestName || action.name[0] === tenant_modal_reducer_1.updateTenantRequestName) {
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                if (!responseDetails.resources)
                    break;
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                state = tslib_1.__assign({}, state);
                state.thisTenant = apiResponse.data;
            }
            break;
        case "update-time":
            if (state.showInitialLoadingModal && action.absoluteTime - state.loadingStart > 3000) {
                state = tslib_1.__assign({}, state);
                state.showInitialLoadingModal = false;
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceInitialLoading = reduceInitialLoading;
function requestInitialSupportLoading(state) {
    var effects = [];
    effects = sequence_services_1.sequence(effects, loadAwaitingSupportRooms());
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.masterKeywordsRequestName], routes_1.RapiV1MasterKeywordsPath));
    if (users_util_1.isAtLeastA(state.loggedInUser, "Administrator")) {
        effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([tenant_modal_reducer_1.tenantsLoadingRequestName], routes_1.RapiV1TenantsPath));
        effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([tenant_modal_reducer_1.stripeCardsLoadingRequestName], routes_1.RapiV1StripeCardsPath));
        effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([tenant_modal_reducer_1.stripeCustomersLoadingRequestName], routes_1.RapiV1StripeCustomersPath));
        effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([tenant_modal_reducer_1.stripePlansLoadingRequestName], routes_1.RapiV1StripePlansPath));
        effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([tenant_modal_reducer_1.stripeProductsLoadingRequestName], routes_1.RapiV1StripeProductsPath));
        effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([tenant_modal_reducer_1.stripeSubscriptionsLoadingRequestName], routes_1.RapiV1StripeSubscriptionsPath));
    }
    if (users_util_1.isAtLeastA(state.loggedInUser, "Supervisor")) {
        effects = sequence_services_1.sequence(effects, loadAllUnRepliedSupports());
        effects = sequence_services_1.sequence(effects, loadAllRepliedSupports());
    }
    else {
        effects = sequence_services_1.sequence(effects, loadUnRepliedOperatorsSupports(state.loggedInUser.id));
        effects = sequence_services_1.sequence(effects, loadRepliedOperatorsSupports(state.loggedInUser.id));
    }
    return effects;
}
exports.requestInitialSupportLoading = requestInitialSupportLoading;
function requestInitialLoading(state) {
    var effects = [];
    state = tslib_1.__assign({}, state);
    state.hasPageLoaded = {};
    effects = sequence_services_1.sequence(effects, navigation_services_1.historyPush({ pathname: "/" + state.pathParts.join('/') }));
    return { state: state, effects: effects };
}
exports.requestInitialLoading = requestInitialLoading;
function loadPrivateRooms(user) {
    var query = {};
    query.filter = {
        private_rooms_with_active_or_awaiting_support: 1,
        sort_unread_read_for_user_id: user.id,
        current_user_is_member: 1
    };
    query.include = ["latest_message", "latest_message.user", "current_member", "helped_room"];
    return json_resources_1.requestResourceFetch([exports.loadPrivateRoomsRequestName], routes_1.RapiV1RoomsPath, query);
}
exports.loadPrivateRooms = loadPrivateRooms;
function getRoomCounts() {
    return json_resources_1.requestResourceFetch([exports.getRoomCountsRequestName], routes_1.RapiV1RoomsPath + "/room_counts");
}
exports.getRoomCounts = getRoomCounts;
function loadAllUnRepliedSupports() {
    var query = {};
    query.filter = { active: 1, replied: 0, latest_in_room: 1 };
    query.include = ["customer", "user", "latest_message", "room", "room.current_member", "room.latest_memo", "latest_message.user"];
    query.sort = ["id"];
    return json_resources_1.requestResourceFetch([exports.loadAllUnRepliedSupportsRequestName], routes_1.RapiV1SupportsPath, query);
}
exports.loadAllUnRepliedSupports = loadAllUnRepliedSupports;
function loadAllRepliedSupports() {
    var query = {};
    query.filter = { active: 1, replied: 1, latest_in_room: 1 };
    query.include = ["customer", "user", "latest_message", "room", "room.current_member", "room.latest_memo", "latest_message.user"];
    query.sort = ["id"];
    return json_resources_1.requestResourceFetch([exports.loadAllRepliedSupportsRequestName], routes_1.RapiV1SupportsPath, query);
}
exports.loadAllRepliedSupports = loadAllRepliedSupports;
function loadUnRepliedOperatorsSupports(userId) {
    var query = {};
    query.filter = { active: 1, replied: 0, latest_in_room: 1 };
    query.include = ["customer", "user", "latest_message", "room", "room.current_member", "room.latest_memo", "latest_message.user"];
    query.sort = ["id"];
    return json_resources_1.requestResourceFetch([exports.loadUnRepliedOperatorsSupportsRequestName, userId], routes_1.RapiV1UsersPath + "/" + userId + "/supports", query);
}
exports.loadUnRepliedOperatorsSupports = loadUnRepliedOperatorsSupports;
function loadRepliedOperatorsSupports(userId) {
    var query = {};
    query.filter = { active: 1, replied: 1, latest_in_room: 1 };
    query.include = ["customer", "user", "latest_message", "room", "room.current_member", "room.latest_memo", "latest_message.user"];
    query.sort = ["id"];
    return json_resources_1.requestResourceFetch([exports.loadRepliedOperatorsSupportsRequestName, userId], routes_1.RapiV1UsersPath + "/" + userId + "/supports", query);
}
exports.loadRepliedOperatorsSupports = loadRepliedOperatorsSupports;
function loadAwaitingSupportRooms() {
    var query = {};
    query.include = ["latest_message", "latest_message.user", "create_user", "current_member", "latest_memo"];
    query.filter = { awaiting_support: 1 };
    return json_resources_1.requestResourceFetch([exports.loadAwaitingSupportRoomsRequestName], "/rapi/v1/rooms", query);
}
exports.loadAwaitingSupportRooms = loadAwaitingSupportRooms;
exports.loadPrivateRoomsRequestName = "load-private-rooms-resource-data";
exports.loadAwaitingSupportRoomsRequestName = "load-awaiting-support-rooms-resource-data";
exports.loadAllUnRepliedSupportsRequestName = "load-all-unreplied-supports-resource-data";
exports.loadAllRepliedSupportsRequestName = "load-all-replied-supports-resource-data";
exports.loadUnRepliedOperatorsSupportsRequestName = "load-unreplied-operators-supports-resource-data";
exports.loadRepliedOperatorsSupportsRequestName = "load-replied-operators-supports-resource-data";
exports.getRoomCountsRequestName = "rooms-counts-request-resource-counts";
exports.masterKeywordsRequestName = "master-keywords-resource-data";
var roomCountsRequestNames = [exports.getRoomCountsRequestName, exports.loadAwaitingSupportRoomsRequestName,
    exports.loadAllUnRepliedSupportsRequestName, exports.loadAllRepliedSupportsRequestName, exports.loadUnRepliedOperatorsSupportsRequestName, exports.loadRepliedOperatorsSupportsRequestName];
exports.initialLoadingPagedRequestNames = [exports.loadAllUnRepliedSupportsRequestName, exports.loadAllRepliedSupportsRequestName, exports.loadUnRepliedOperatorsSupportsRequestName, exports.loadRepliedOperatorsSupportsRequestName,
    exports.loadAwaitingSupportRoomsRequestName, exports.loadPrivateRoomsRequestName];
