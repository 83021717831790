"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var I18n_1 = require("../I18n");
var svgs_1 = require("./svgs");
function SurveyContent(props) {
    var hasBeenAnswered = (props.question && props.question.attributes.answered_at_unix && !!props.question.attributes.answer);
    var choiceDisabled = !props.answerQuestion || hasBeenAnswered;
    return React.createElement("div", { className: "db shadow-1 bg-white br3 pb2 mv2 mr4 ml2" },
        props.survey.attributes.image_url &&
            React.createElement("div", { className: "tc" },
                React.createElement("img", { className: "h-auto mw-100 btrr3 btlr3", src: props.survey.attributes.image_url, alt: props.survey.attributes.image_alt })),
        props.survey.attributes.title && React.createElement("div", { className: "pv3 tc f2 fw3 light-blue" }, props.survey.attributes.title),
        props.survey.attributes.description && React.createElement("div", { className: "pb3 tc f3 fw2 light-blue" }, props.survey.attributes.description),
        props.survey.attributes.links.map(function (link, i) {
            var url = link.display_message || (I18n_1.i18n.clientsSurveyUrl[props.language] + link.url);
            return React.createElement("div", { key: "link-" + i },
                React.createElement("div", { className: "bb b--light-gray" }),
                React.createElement("a", { className: "db center tc f4 fw3 pa2 pointer no-underline light-blue hover-white bg--light-blue--hover", style: { outlineColor: props.colors }, key: "survey-choice-link-" + props.survey.id + "-" + i, href: link.url, target: "_blank" },
                    React.createElement("div", { tabIndex: -1, className: "no-focus-glow" }, url)));
        }),
        props.survey.attributes.choices.map(function (choice, i) {
            return React.createElement("div", { key: "question-choice-" + i },
                React.createElement("div", { className: "bb b--light-gray" }),
                React.createElement("button", { className: "db center tc f4 fw3 w-100 pa2 bn " + (choiceDisabled ? " b--gray gray " : "pointer light-blue bg--light-blue--hover hover-white"), key: "survey-choice-text-" + props.survey.id + "-" + i, disabled: choiceDisabled, style: { outlineColor: props.colors }, onClick: function () { return props.answerQuestion(choice.text); } },
                    hasBeenAnswered && choice.text === props.question.attributes.answer &&
                        React.createElement("div", { className: "fill-gray stroke--gray fl" },
                            React.createElement(svgs_1.SVGChecked, { width: 12, height: 12 })),
                    React.createElement("div", { tabIndex: -1, className: "no-focus-glow" }, I18n_1.i18n.clientsSurveyMessage[props.language] + ":" + choice.text)));
        }));
}
exports.SurveyContent = SurveyContent;
