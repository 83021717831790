"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var action_cable_services_1 = require("./action-cable-services");
var logout_services_1 = require("./logout-services");
var local_settings_services_1 = require("./local-settings-services");
var sizing_services_1 = require("./sizing-services");
var ajax_services_1 = require("./ajax-services");
var navigation_services_1 = require("./navigation-services");
var notification_services_1 = require("./notification-services");
var time_services_1 = require("./time-services");
var resize_services_1 = require("./resize-services");
var online_services_1 = require("./online-services");
var upload_services_1 = require("./upload-services");
var createMemoryHistory_1 = require("history/createMemoryHistory");
var createBrowserHistory_1 = require("history/createBrowserHistory");
var csv_service_1 = require("./csv-service");
var scroll_to_services_1 = require("./scroll-to-services");
var debounce_services_1 = require("./debounce-services");
var ajax_auth_services_1 = require("./ajax-auth-services");
var copy_clipboard_services_1 = require("./copy-clipboard-services");
var input_debouncing_services_1 = require("./input-debouncing-services");
var recorded_request_helpers_1 = require("../../../tests/recorded-requests/recorded-request-helpers");
var indicate_online_services_1 = require("./indicate-online-services");
var window_drag_services_1 = require("./window-drag-services");
var google_analytics_services_1 = require("./google-analytics-services");
var headwayapp_service_1 = require("./headwayapp-service");
var tracker_checker_services_1 = require("./tracker-checker-services");
var upload_normal_services_1 = require("./upload-normal-services");
var show_video_tag_services_1 = require("./show-video-tag-services");
var defaultConfig = {
    ajax: null,
    recordedCableData: null,
    actionCable: action_cable_services_1.withActionCable,
    fakeHistory: false,
    logout: logout_services_1.withLogout,
    storage: window.localStorage,
    access_token: "",
    googleAnalyticsConfig: {},
    cloudinary_info: {},
    action_cable_url: "",
    tenant_name: "",
    root_url: "",
    xd_cookie_names: [],
    appSettings: {},
    this_tenant: {},
};
function getCoreServices(config) {
    if (config === void 0) { config = {}; }
    return function (dispatch) {
        config = tslib_1.__assign({}, defaultConfig, config);
        var services = [];
        var ajax = config.ajax || ajax_services_1.withAjax(dispatch, 6, config.root_url || null, config.xd_cookie_names);
        if (config.fakeHistory) {
            services.push(navigation_services_1.withHistory(dispatch, createMemoryHistory_1.default()));
        }
        else {
            services.push(navigation_services_1.withHistory(dispatch, createBrowserHistory_1.default({ basename: navigation_services_1.inferBasePath() })));
        }
        services.push(csv_service_1.withCSV(dispatch, config.access_token));
        services.push(scroll_to_services_1.withScrollTo);
        services.push(indicate_online_services_1.withIndicateOnline(dispatch));
        services.push(online_services_1.withOnlineWatcher(dispatch));
        services.push(resize_services_1.withResizeWatcher(dispatch, "windowSize"));
        services.push(time_services_1.withTime(dispatch));
        services.push(debounce_services_1.withDebounce(dispatch));
        services.push(action_cable_services_1.withActionCable(dispatch, config.action_cable_url, config.access_token, config.tenant_name));
        services.push(notification_services_1.withBrowserNotifications(dispatch));
        services.push(sizing_services_1.withSizeCalculator(dispatch));
        services.push(ajax_auth_services_1.wrapWithAuthHeaders(ajax, config.access_token));
        services.push(upload_services_1.withUpload(dispatch, config.cloudinary_info));
        services.push(local_settings_services_1.withLocalSettings(dispatch, config.storage));
        services.push(copy_clipboard_services_1.withCopyToClipboard());
        services.push(input_debouncing_services_1.withInputDebouncing(dispatch));
        if (config.logout)
            services.push(config.logout(dispatch));
        services.push(window_drag_services_1.withPreventWindowDefaultDrag);
        services.push(google_analytics_services_1.withGoogleAnalytics(dispatch, config.googleAnalyticsConfig));
        services.push(headwayapp_service_1.withHeadwayApp(dispatch, config.appSettings.headwayapp_account));
        if (config.recordedCableData)
            services.push(recorded_request_helpers_1.withRecordedCableData(dispatch, config.recordedCableData));
        services.push(tracker_checker_services_1.withTrackerChecker(dispatch));
        services.push(upload_normal_services_1.withNormalUpload(dispatch, config.access_token, config.xd_cookie_names));
        services.push(show_video_tag_services_1.withShowVideoTag(dispatch, config.root_url));
        return services;
    };
}
exports.getCoreServices = getCoreServices;
