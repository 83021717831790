"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var MarketingDatasStore;
(function (MarketingDatasStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    MarketingDatasStore.indexer = new bisect_1.Indexer("byId");
    MarketingDatasStore.indexer.addIndex("byId", function (marketing_datas) { return [assertInnerId(marketing_datas)]; });
})(MarketingDatasStore = exports.MarketingDatasStore || (exports.MarketingDatasStore = {}));
